import { cx } from "emotion";
import React from "react";
import stepper from "./Stepper.module.css";

type Props = {
  steps: string[];
  activeStep: number;
  onStepClick?: (step: number) => void;
  className?: string;
}

const Stepper = ({ steps, activeStep, onStepClick, className }: Props) => {
  return (
    <div className={cx(stepper.body, className)}>
      {steps.map((step, i) => {
        const isFutureStep = i + 1 > activeStep;
        const isActiveStep = i + 1 === activeStep;
        return (
          <span
            key={step}
            className={cx(stepper.step, {
              [stepper.active] : isActiveStep,
              [stepper.futureStep] : isFutureStep,
              [stepper.clickable] : !isFutureStep && !isActiveStep && !!onStepClick,
            })}
            onClick={() => onStepClick ? isFutureStep ? null : onStepClick(i + 1) : null}
          >
          <span className={stepper.value}>{i + 1}</span>
          <p className={stepper.label}>{step}</p>
        </span>
        );
      })}
    </div>
  );
};

export default Stepper;
