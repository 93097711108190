import React from "react";

const Close = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.5 87.5" className={className}>
    <title>close</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M85.63,1.87a6.06,6.06,0,0,0-8.75,0L43.75,35,10.63,1.87a6.06,6.06,0,0,0-8.75,0,6,6,0,0,0,0,8.75L35,43.75,1.87,76.88a6,6,0,0,0,0,8.74A5.71,5.71,0,0,0,6.25,87.5a5.68,5.68,0,0,0,4.38-1.88L43.75,52.5,76.88,85.62a6,6,0,0,0,8.75,0,6,6,0,0,0,0-8.74L52.5,43.75,85.63,10.62A6,6,0,0,0,85.63,1.87Z" />
      </g>
    </g>
  </svg>
);

export default Close;
