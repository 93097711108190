import { DateTime } from "luxon";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./InputDate.css";

import ModalInputField from "../ModalInputField";

export const InputDate = (props) => {
  const { value, onChange, minDate, showTimeSelect, showYearPicker, timeIntervals } = props;

  const onInternalChange = (value) => {
    if (!showTimeSelect && value) {
      value = DateTime.fromJSDate(value).startOf("day").toJSDate();
    }
    if (!value) {
      value = null;
    }
    onChange(value);
  };

  let datePickerProps = {
    selected: value,
  };

  if (props.range) {
    datePickerProps = {
      selectsRange: true,
      selected: value.from,
      startDate: value.from,
      endDate: value.to,
      onChange: (newValue) => {
        onChange({
          from: newValue[0] ? DateTime.fromJSDate(newValue[0]).startOf("day").toJSDate() : null,
          to: newValue[1] ? DateTime.fromJSDate(newValue[1]).endOf("day").toJSDate() : null,
        });
      },
    };
  }

  // @todo make "customInput" it configurable or use the same field everywhere
  return (
    <DatePicker
      showTimeSelect={showTimeSelect}
      showYearPicker={showYearPicker}
      dateFormat={showTimeSelect ? "Pp" : showYearPicker ? "yyyy" : undefined}
      timeIntervals={timeIntervals || 15}
      onChange={(date) => onInternalChange(date)}
      minDate={minDate}
      wrapperClassName={props.className}
      placeholderText={props.placeholder || "dd/mm/yyyy"}
      customInput={<ModalInputField label={props.label} />}
      {...datePickerProps}
    />
  );
};
