import { cx } from "emotion";
import * as React from 'react';

import css from './ButtonHTML.module.css';

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const ButtonHTML: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  return <button className={cx(css.button, className)} {...rest} />
}