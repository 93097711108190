import * as React from "react";
import { cx } from "emotion";
import css from "./IconButton.module.css";

export function IconButton(props) {
  return (
    <button className={cx(css.iconButton, props.className, props.isActive && css.active)} onClick={props.onClick}>
      {props.children}
    </button>
  );
}
