import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { InputDate } from "../input/InputDate/InputDate";
import { DateTime } from "luxon";
import ModalInputField from "../input/ModalInputField";
import { userService } from "../../screens/projects/UserService";
import { Checkbox } from "../DiscoverNew/Form/Checkbox/Chekbox";

export function AdminSubscriptionModal({ onClose, data, organizationId, onSuccess }) {
  const notifications = useNotifications();
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState();
  const [values, setValues] = useState({
    isActive: data.isActive || false,
    seatCount: data.seatCount || 0,
    externalId: data.externalId || '',
    startDate: DateTime.fromISO(data.startDate || new Date().toISOString()).toJSDate(),
    endDate: data.endDate ? DateTime.fromISO(data.endDate).toJSDate() :  DateTime.fromISO(new Date().toISOString()).plus({ month: 1 }).toJSDate(),
  });

  const onChange = (value) => {
    const fieldName = Object.keys(value)[0];
    setErrors({
      ...errors,
      [fieldName]: null,
    });
    const nextValues = {
      ...values,
      ...value,
    };
    setValues(nextValues);
  };

  async function handleSubmit() {
    setSubmitting(true);
    if (data.id) {
      try {
        await userService.updateOrganizationSubscription(organizationId, data.id, {
          external_id: values.externalId,
          seat_count: parseInt(values.seatCount),
          subscription_start_date: values.startDate,
          subscription_end_date: values.endDate,
          is_active: values.isActive,
        });
        notifications.showSuccess(`Subscription has been updated!`);
        onSuccess();
      } catch (err) {
        notifications.showError(`Couldn't update the subscription!`);
      }
    } else {
      try {
        await userService.createOrganizationSubscription(organizationId, values);
        notifications.showSuccess(`Subscription has been created!`);
        onSuccess();
      } catch (err) {
        notifications.showError(`Couldn't create the subscription!`);
      }
    }
    setSubmitting(false);
    onClose();
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>{data.id ? 'Edit Subscription' : 'Create Subscription'}</div>
        <div className={modalStyles.modalFormContainer}>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>Stripe ID</div>}
            placeholder="Enter Stripe ID"
            onChange={(e) => onChange({ externalId: e.target.value })}
            value={values.externalId}
          ></ModalInputField>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>Seat Count</div>}
            placeholder="Enter Seat Count"
            onChange={(e) => onChange({ seatCount: e.target.value })}
            value={values.seatCount}
          ></ModalInputField>
          <div style={{ marginBottom: 20, marginTop: -8 }}>
            <InputDate
              label={<div className={modalStyles.modalInputLabel}>Start Date</div>}
              onChange={(v) => onChange({ startDate: v })}
              value={values.startDate}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <InputDate
              label={<div className={modalStyles.modalInputLabel}>End Date</div>}
              onChange={(v) => onChange({ endDate: v })}
              value={values.endDate}
            />
          </div>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>Active</div>}
            component={Checkbox}
            onComponentClick={(e) => onChange({ isActive: !values.isActive })}
            checked={values.isActive}
          ></ModalInputField>
        </div>
        <ModalButtonContainer title={data.id ? `Update Subscription` : 'Create Subscription'} onClick={handleSubmit} isWaiting={submitting} />
      </div>
    </Modal>
  );
}
