import React from "react";
import { NavLink } from "react-router-dom";
import { cx } from "emotion";
import PropTypes from "prop-types";

import styles from "./SiderMenu.module.css";

const SiderMenu = ({ activeItem, menuItems, onItemClick }) => {
  return (
    <div className={styles.menu}>
      {menuItems.map(({ path, label }) => (
        <div key={path} className={cx(styles.menuItem, { [styles.active]: path.includes(activeItem) })}>
          <NavLink key={path} to={path} className={styles.link} onClick={onItemClick}>
            <span className={styles.text}>{label}</span>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

SiderMenu.propTypes = {
  activeItem: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      lable: PropTypes.string,
    })
  ),
  onItemClick: PropTypes.func,
};

export default SiderMenu;
