import abbreviateState from "../../../formatters/stateAbbreviator";
import capitalizeWords from "../../../formatters/capitalizeWords";
import { shortId } from "../util/util";
import { calculateRankedExperiences, calculateRankedSkills } from "../util/SearchHighlight";
import * as api from "../../../api/apiService";
import { candidateService } from "../../../screens/projects/CandidateService";

function makeEmptyResponseResult() {
  return {
    items: [],
    pageInfo: {
      total: 0,
    },
  };
}

class DiscoverSearchService {
  
  parseCandidateContactData(data) {
    return {
      phoneNumbers: (data.phoneNumbers || []).map((item) => ({ ...item, id: shortId() })),
      emails: (data.emails || []).map((item) => ({ ...item, id: shortId() })),
    }
  }

  parseCandidateData(data) {
    let address = null;
    let rawSource = data._source;
    let explanation = data._explanation;
    let highlight = data.highlight;
    let score = data._score;

    if (rawSource) {
      data = rawSource;
    }

    if (data.location) {
      address = candidateService.getAddressFromLocation(data.location);
    }

    let result = {
      _source: data,
      _explanation: explanation,
      _highlight: highlight,
      _score: score,
      address,
      id: data.candidateId,
      firstName: data.firstName,
      lastName: data.lastName,
      skills: data.skills || [],
      _rankedSkills: undefined,
      companies: data.companies?.map((company) => {
          return {
            ...company,
            id: company.companyId,
          };
        }) || [],
      socialProfiles: data.socialProfiles
        ? data.socialProfiles.reduce(
            (acc, item) => ({
              ...acc,
              [item.site]: item,
            }),
            {}
          )
        : null,
      experiences: data.experiences,
      lastViewedAt: data.lastViewedAt,
      isLocked: !data.unlockedAt,
      unlockedBy: data.unlockedBy,
      ...this.parseCandidateContactData(data),
    };
    
    if (window.devmode && rawSource) {
      result._raw = rawSource;
    }

    if (highlight) {
      let rankedExperiences = calculateRankedExperiences(result.experiences, highlight);
      if (rankedExperiences) {
        result.experiences = rankedExperiences;
      }
      
      let rankedSkills = calculateRankedSkills(result.skills, highlight);
      if (rankedSkills) {
        result.skills = rankedSkills.map(item => item.value);
        result._rankedSkills = rankedSkills;
      }
    }

    return result;
  }

  async fetchCandidates(elasticQuery, internalFilters) {
    const res = await api.SearchCandidates({
      query: elasticQuery,
      internalFilters: internalFilters,
    });
    const result = makeEmptyResponseResult();

    if (!res.data?.hits) {
      return result;
    }

    result.items = res.data.hits.hits.map((item) => this.parseCandidateData(item));
    result.pageInfo.total = res.data.hits?.total?.value;
    if (!result.pageInfo.total && result.pageInfo.total !== 0) {
      result.pageInfo.total = 10000;
    }

    const candidate1 = result.items.find(item => item.id === 130338703);
    if (candidate1) {
      candidate1.experiences = candidate1.experiences.map(item => {
        if (item.company?.name === 'chatterworks') {
          item.isPrimary = true;
        }
        return item;
      })
    }

    const candidate2 = result.items.find(item => item.id === 104351687);
    if (candidate2) {
      candidate2.experiences = candidate2.experiences.map(item => {
        if (item.company?.name === 'mdc-ventures') {
          item.isPrimary = true;
        }
        return item;
      })
    }

    return result;
  }
}

export const discoverSearchService = new DiscoverSearchService();
