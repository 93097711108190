const calcScore = (data: any[], highlights: string[], getKey: (v: any) => any, wight = 1, field = "") => {
  let tmp = highlights.map((item) => {
    let hitCount = item.split("<em>").length - 1;
    let pureText = item.replace(/<\/?em>/g, "");
    return {
      pureText,
      hitCount,
    };
  });

  for (let i = 0; i < data.length; i++) {
    let d = data[i];
    let key = getKey(d);

    if (!key) {
      continue;
    }

    let exactHit = tmp.find((item) => item.pureText.toLowerCase() === key.toLowerCase());
    if (exactHit) {
      d._score += exactHit.hitCount * wight * 2;
      if (field) {
        d._hits.push(field);
      }
      continue;
    } else {
      let hit = tmp.find((item) => key.toLowerCase().includes(item.pureText.toLowerCase()));
      if (hit) {
        d._score += hit.hitCount * wight;
        if (field) {
          d._hits.push(field);
        }
      }
    }
  }
};

export function calculateRankedExperiences(initialExp: any, highlight: any) {
  if (!initialExp) {
    return initialExp;
  }
  let experiences;
  try {
    experiences = JSON.parse(JSON.stringify(initialExp));
  } catch (e) {
    return initialExp;
  }

  experiences = experiences.map((item: any) => ({
    ...item,
    _score: 0,
    _hits: [],
  }));

  let titleHighlights = highlight["experiences.title"] || highlight["currentExperiences.title"] || null;
  if (titleHighlights?.length) {
    calcScore(experiences, titleHighlights, (experience) => experience?.title, 1, "title");
  }

  let companyNameHighlights =
    highlight["experiences.company.name"] || highlight["currentExperiences.company.name"] || null;
  if (companyNameHighlights?.length) {
    calcScore(experiences, companyNameHighlights, (experience) => experience?.company?.name, 1.1, "company.name");
  }

  let industriesHighlights =
    highlight["experiences.company.industry.keyword"] ||
    highlight["currentExperiences.company.industry.keyword"] ||
    null;
  if (industriesHighlights?.length) {
    calcScore(
      experiences,
      industriesHighlights,
      (experience) => experience?.company?.industry,
      1.1,
      "company.industry"
    );
  }

  if (!!(<any>window).highlightDiscoverExp) {
    let max_score = experiences[0]?._score || 0;
    experiences.sort((a: any, b: any) => {
      let aCount = a._score || 0;
      let bCount = b._score || 0;

      if (aCount > max_score) {
        max_score = aCount;
      }

      if (bCount > max_score) {
        max_score = bCount;
      }

      return bCount - aCount;
    });

    experiences = experiences.map((item: any) => (item._score === max_score ? { ...item, _highlight: true } : item));
  }

  return experiences;
}

export function calculateRankedSkills(initSkills: string[], highlight: any) {
  if (!initSkills) {
    return initSkills;
  }
  let skills;
  try {
    skills = JSON.parse(JSON.stringify(initSkills));
  } catch (e) {
    return initSkills;
  }

  skills = skills.map((item: any) => ({
    value: item,
    _score: 0,
  }));

  let skillHighlights = highlight["skills"] || null;
  if (skillHighlights?.length) {
    calcScore(skills, skillHighlights, (skill) => skill.value);
  }

  let max_score = skills[0]?._score || 0;
  skills.sort((a: any, b: any) => {
    let aCount = a._score || 0;
    let bCount = b._score || 0;

    if (aCount > max_score) {
      max_score = aCount;
    }

    if (bCount > max_score) {
      max_score = bCount;
    }

    return bCount - aCount;
  });

  // disabled highlight by team's request
  // if (max_score > 0) {
  //   skills = skills.map((item: any) => (item._score == max_score ? { ...item, _highlight: true } : item));
  // }

  return skills;
}
