import * as React from "react";
import css from "./Form.module.css";

export function Form(props: any) {
  return <form {...props} />;
}

Form.Row = (props: any) => (
  <div className={css.row} style={props.style}>
    {props.children}
  </div>
);

type Values = Record<string, string | undefined>

//export function validateFields(values: Values, fields: string[]): Values {
//  return fields.reduce((errors: Values, field) => {
//    if (values[field] === undefined || values[field] === null || values[field]?.toString().trim() === '') {
//      errors[field] = 'This field is required';
//    }
//    return errors;
//  }, {});
//}