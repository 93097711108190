import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import cn from "classnames";
import * as React from "react";
import { candidateService } from "screens/projects/CandidateService";
import { StageFlowItemInterface, StageFlowItemUpdateDTOInterface } from "../StageFlowService";
import { DraggableStageRow } from "./DraggableStageRow";

import css from "./StagesTable.module.css";

type Props = {
  stages: StageFlowItemInterface[];
  isEditable: boolean;
  onDelete: (stageId: number) => void;
  onShow?: (stageId: number) => void;
  onHide?: (stageId: number) => void;
  onReorder: (stages:  StageFlowItemInterface[]) => void;
  onInlineEdit: (stageId: number, stageData: StageFlowItemUpdateDTOInterface) => void;
  showHeader?: boolean;
} & ({
  mode: "full"
  onShow: (stageId: number) => void;
  onHide: (stageId: number) => void;
} | {
  mode: "short"
});

export const StagesTable: React.FC<Props> = ({
   onDelete,
   mode,
   onReorder,
   onShow,
   onHide,
   onInlineEdit,
   stages,
   isEditable,
   showHeader = true,
 }) => {
  const tableRef = React.useRef<HTMLTableElement>(null);
  const sortedStages = stages.slice().sort((a, b) => a.order - b.order);

  const onDragEnd = (e: any) => {
    const { active, over } = e;
    if (over && active.id !== over.id) {
      const draggingItemIndex = sortedStages.findIndex((item) => item.stageId === active.id)!;
      const overItemIndex = sortedStages.findIndex((item) => item.stageId === over.id)!;
      const resortedStages = arrayMove(sortedStages.slice(), draggingItemIndex!, overItemIndex!);
      const reindexedStages = resortedStages.map((item, index) => ({ ...item, order : index }));
      onReorder(reindexedStages);
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={sortedStages.map((item) => ({ id : item.stageId }))}>
        <table className={cn(css.table, mode === "short" && css.short)} ref={tableRef}>
          {showHeader && (
            <thead>
              <tr>
                <th className={css.tdOrder}>{isEditable ? "" : "№"}</th>
                <th className={css.tdName}>Name</th>
                {mode !== "short" && (
                  <>
                    <th>Days On Stage</th>
                    <th>Color</th>
                    <th>Automations</th>
                    <th>Board Visibility</th>
                  </>
                )}
                <th className={css.tdActions} />
              </tr>
            </thead>
          )}
          <tbody>
            {sortedStages.map((stage, i) => {
              const stageStyle = candidateService.getStageStyle(stage);
              return (
                <DraggableStageRow
                  key={stage.stageId}
                  id={stage.stageId}
                  onInlineEdit={(stageData) => onInlineEdit(stage.stageId, stageData)}
                  onDelete={() => onDelete(stage.stageId)}
                  onShow={() => mode === "full" ? onShow(stage.stageId) : null}
                  onHide={() => mode === "full" ? onHide(stage.stageId) : null}
                  stage={stage}
                  stageStyle={stageStyle}
                  isEditable={isEditable}
                  isDeletable={sortedStages.length > 1}
                  mode={mode}
                />
              );
            })}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};
