import * as api from "../api/apiService";

export type EnableWorkableIntegrationDTO = {
  apiKey: string;
  subdomain: string;
}

class WorkableIntegrationService {
  enable = async (data: EnableWorkableIntegrationDTO) => {
    const res = await api.EnableWorkableIntegration(data);
    return res;
  }

  disable = async () => {
    const res = await api.DisableWorkableIntegration();
    return res;
  }

  addCandidates = async (candidateIds: number[], jobId: number) => {
    const res = await api.AddCandidatesToWorkable({ candidateIDs : candidateIds, jobId });
    return res;
  };
}

export const workableIntegrationService = new WorkableIntegrationService();