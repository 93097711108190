import React, { useState, useEffect, useRef, useMemo } from "react";
import { Modal } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { cx, css } from "emotion/macro";
import { debounce } from "lodash";

import ModalNavigatorActions from "./ModalNavigatorActions";

import { xs, sm } from "../../css/mediaQueries";
import "./transitions.css";
import CloseIcon from "../../icons/Close";
import CaretLeft from "../../icons/CaretLeft";

export const modalHeight = "90vh";

const dynamicChildFactory = (classNames) => (child) =>
  React.cloneElement(child, {
    classNames,
  });

const createCustomTransitionGroup = (ref) => {
  return ({ children }) => (
    <div
      className={css`
        position: relative;
        overflow: auto;
        overflow-x: hidden;
        height: 100%;
      `}
      ref={ref}
    >
      {children}
    </div>
  );
};

const IconButton = ({ Icon, onClick, label }) => {
  return (
    <div onClick={onClick} className={styles.iconButton}>
      <Icon />
      {label}
    </div>
  );
};

const BackButton = ({ onClick, label }) => <IconButton Icon={CaretLeft} onClick={onClick} label={label} />;

const CloseButton = ({ onClick }) => <IconButton Icon={CloseIcon} onClick={onClick} />;

export const ModalNavigator = ({
  children,
  isOpen = false,
  toggle = () => {},
  centered = true,
  size = "xl",
  className = "project-detail-modal",
  initialView,
}) => {
  const [navigationStack, setNavigationStack] = useState([]);

  const [currentAction, setCurrentAction] = useState("push");

  const contentRef = useRef();

  const popFn = () => {
    setCurrentAction("pop");
    setNavigationStack((navigationStack) => navigationStack.slice(0, -1));
  };

  const pop = debounce(popFn, 200);

  const pushFn = (backLabel, modalContentRenderer, currentView) => {
    setCurrentAction("push");
    setNavigationStack((navigationStack) => [
      ...navigationStack,
      { backLabel, currentView, content: modalContentRenderer(push, pop) },
    ]);
  };

  const push = debounce(pushFn, 200);

  // eslint-disable-next-line
  useEffect(() => pushFn(null, children, initialView), []);

  const transitionStyles = currentAction === "push" ? "modal-slide-left" : "modal-slide-right";

  const currentView = navigationStack.length > 0 && navigationStack[navigationStack.length - 1];

  const CustomTransitionGroup = useMemo(() => createCustomTransitionGroup(contentRef), [contentRef]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered} size={size} className={cx(className, styles.modal)}>
      <div className={cx(styles.modalHeaderContainer, styles.closeButtonHidden)}>
        {navigationStack.length <= 1 && <CloseButton onClick={toggle} />}
        {navigationStack.length > 1 && <BackButton label={currentView.backLabel} onClick={pop} />}
        <ModalNavigatorActions currentView={currentView} />
      </div>
      <TransitionGroup component={CustomTransitionGroup} childFactory={dynamicChildFactory(transitionStyles)}>
        <CSSTransition
          onEntered={() => (contentRef.current.scrollTop = 0)}
          classNames={transitionStyles}
          timeout={350}
          key={navigationStack.length}
        >
          {(state) => (
            <div
              className={css`
                position: ${state === "entered" ? "initial" : "absolute"};
                min-width: 100%;
                height: 100%;
              `}
            >
              {currentView.content}
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    </Modal>
  );
};

const styles = {
  modal: css`
    width: 1058px !important;
    ${sm} {
      width: 100% !important;
    }

    .modal-content {
      overflow: hidden !important;
      height: ${modalHeight};
      max-height: 1000px;
      ${xs} {
        max-height: none;
        height: 100vh;
        border: none;
        border-radius: 0;
      }
    }
    .modal-body {
      padding: 0;
      height: 100%;
    }
  `,
  modalHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    position: sticky;
    background-color: white;
    align-items: center;
    padding: 11px 19px 0px 17px;
    min-height: 50px;
  `,
  closeButtonHidden: css`
    .close {
      display: none;
    }
  `,
  iconButton: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #a8a8a8;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: #373737;
    }

    svg {
      fill: #a8a8a8;
      stroke: #a8a8a8;
      transition: all 0.2s ease-in-out;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover svg {
      fill: #373737;
      stroke: #373737;
    }

    font-weight: 400;
  `,
  caret: css`
    height: 18px;
    margin-right: 16px;
  `,
};

export default ModalNavigator;
