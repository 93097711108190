import { css } from "emotion";

// It is important that these media query definitions do not end with
// semicolons. Semicolons will cause all breakpoints defined with these media
// queries to malfunction.

// The following definitions are used to provide easy access to media queries when
// creating css classes with emotion.  The screen width boundaries are split up
// into 5 sections: xs, sm, md, lg, and xlg.
//
// <Extra Small> is defined as the boundary between 0px to 576px
// <Small> is defined as the boundary between 576px to 768px
// <Medium> is defined as the boundary between 768px to 992px
// <Large> is defined as the boundary between 992px to 1200px
// <Extra Large> is defined as the boundary between 1200px to infinite
//
// Each of definitions below will cover a range that includes a set boundary,
// along with all widths above or below it. For example, using the `md`
// definition will create a media query that includes the <Medium> boundary
// defined above, along with all boundaries below it (width <= 992px). On the
// other hand, using `mdU` will create a media query that includes the <Medium>
// boundary, along with all boundaries above it (width >= 768px)
//
// Example usage:
//
// css`${md} {
//       margin: 10
//     }`

// prettier-ignore
export const xs = css`
  @media (max-width: 575.98px)
`;

// prettier-ignore
export const sm = css`
  @media (max-width: 767.98px)
`;

// prettier-ignore
export const md = css`
  @media (max-width: 991.98px)
`;

// prettier-ignore
export const lg = css`
  @media (max-width: 1199.98px)
`;

// prettier-ignore
export const smU = css`
  @media (min-width: 576px)
`;

// prettier-ignore
export const mdU = css`
  @media (min-width: 768px)
`;

// prettier-ignore
export const lgU = css`
  @media (min-width: 992px)
`;

// prettier-ignore
export const xlgU = css`
  @media (min-width: 1200px)
`;
