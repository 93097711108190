import * as React from "react";
import { authService } from "../components/DiscoverNew/services/AuthService";
import { userService } from "../screens/projects/UserService";
import { landingUrlTo } from "../components/DiscoverNew/util/UrlHelper";
import { OrganizationMemberRole } from "../services/OrganizationService";

const AuthContext = React.createContext({
  logout: () => undefined,
  fetchUser: () => undefined,
  user: {
    id: '',
    email: '',
    organizationId: -1  ,
    currentPlan : {
      planInfo: {
        name: ''
      }
    },
    organizationV2: {
      id: -1,
      isTrialAvailable : false,
      ownerRef: {
        id: -1,
        firstName: '',
        lastName: '',
        email: '',
      }
    },
    seat : {
      role : OrganizationMemberRole.Owner
    }
  },
  isAuthenticated: false,
  isImpersonated: false,
});

export let authProviderInstance;

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !!authService.getTokenData(),
      error: undefined,
      isAuthenticated: !!authService.getTokenData(),
      isImpersonated: !!authService.getPausedSessionData(),
      // user: StorageService.getJsonItem("user"),
    };
  }

  async componentDidMount() {
    if (this.state.isAuthenticated) {
      try {
        await this.fetchUser();
        this.setState({ loading: false });
      } catch (err) {
        // this.logout();
      }
    }
  }

  fetchUser = async () => {
    const user = await userService.fetchMe();
    return new Promise((resolve) => {
      this.setState({ user, isAuthenticated: !!user }, () => {
        resolve(user);
      });
    });
  };

  logout = () => {
    authService.logout();
    this.setState({
      isAuthenticated: false,
      user: undefined,
      loading: false,
    });
    window.location.href = landingUrlTo("/sign-in");
  };

  render() {
    authProviderInstance = this;
    return (
      <>
        <AuthContext.Provider
          value={{
            logout: this.logout,
            fetchUser: this.fetchUser,
            user: this.state.user,
            loading: this.state.loading,
            isAuthenticated: this.state.isAuthenticated,
            isImpersonated: this.state.isImpersonated,
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
      </>
    );
  }
}

export function withAuthController(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <AuthContext.Consumer>
          {(authController) => <WrappedComponent {...this.props} authController={authController} />}
        </AuthContext.Consumer>
      );
    }
  };
}

export function useAuthController() {
  return React.useContext(AuthContext);
}
