import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import NoteDetail from "../../note/NoteDetail";
import "../Mobile.css";

function Notes({ candidateNotes, show, toggleNotesModal, onSave, onEditNoteSaved }) {
  const [noteContent, setNoteContent] = useState("");

  const createNote = () => {
    if (!noteContent) return;
    onSave(noteContent);
    setNoteContent("");
  };

  const textAreaElement = useRef(null);

  useEffect(() => {
    if (textAreaElement.current) {
      textAreaElement.current.focus();
    }
  }, []);

  return (
    <div>
      <Modal show={show} dialogClassName="modal-100w add-project-modal" onHide={toggleNotesModal} id="view-notes-modal">
        <div className="row add-project-header-icon-container" style={{ margin: "initial !important" }}>
          <div>
            <button onClick={toggleNotesModal} type="button" className="close bs-close-button" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <Modal.Body>
          <div className="create-note-container">
            <div className="create-note-header">Notes</div>
            <textarea
              name="createNote"
              id="create-note-text-area"
              cols={30}
              rows={10}
              onChange={(e) => setNoteContent(e.target.value)}
              ref={textAreaElement}
              autoFocus={true}
              value={noteContent}
            ></textarea>
            <div className={"create-note-save-button-container"}>
              <span className={`create-note-save-button ${noteContent === "" ? "" : " active"}`} onClick={createNote}>
                Save
              </span>
            </div>
            {/* NOTES LIST */}
            {candidateNotes?.length !== 0 && (
              <div className="notes-list-container">
                {candidateNotes.map((note, key) => {
                  return (
                    <NoteDetail
                      key={JSON.stringify(note)}
                      note={note}
                      onEditNoteSaved={onEditNoteSaved}
                      isStaticNote={false}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Notes;
