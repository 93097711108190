import { Button } from "components/DiscoverNew/UI/Button/Button";
import React, { useState } from "react";

import BillingMemberEditForm from "screens/profile/ProfileBilling/BillingMember/BillingMemberEditForm";
import BillingMemberItem from "screens/profile/ProfileBilling/BillingMember/BillingMemberItem";
import { useAuthController } from "../../../../contexts/AuthProvider";
import { organizationService } from "../../../../services/OrganizationService";
import { PurchasePlanMember } from "../../../../types";
import { apiErrorToFrontendError, errorMessages } from "../../../../utils/messages";

import classes from "../PurchasePlanModal.module.css";

type Props = {
  seatsCount : number,
  members : PurchasePlanMember[],
  plan : any,
  nextStep : () => void,
  prevStep : () => void,
  onMemberSave : (member: PurchasePlanMember) => Promise<string | void>
  onToggleMember : (member: PurchasePlanMember) => void
}

const MembersStep = ({ seatsCount, members, nextStep, onToggleMember, prevStep, onMemberSave }: Props) => {
  const authController = useAuthController();
  const isTrial = authController.user.currentPlan?.planInfo?.name === 'trial';
  const forceDisableMembers = seatsCount < members.length && isTrial;

  const [editingMember, setEditingMember] = useState<PurchasePlanMember | undefined>(members.length > 1 ? undefined : members[0]);

  const saveMember = async (values: PurchasePlanMember) => {
    if (members.find(item => item.email === values.email && values.email !== editingMember?.email)) {
      throw errorMessages.ALREADY_MEMBER_EMAIL;
    }
    const canInvite = await organizationService.canInviteMembers([values.email]);
    const res = canInvite[values.email];
    if (res !== undefined) {
      throw apiErrorToFrontendError[res] || errorMessages.INVITE_MEMBER_GENERAL;
    }
    onMemberSave({...editingMember || {}, ...values});
    setEditingMember(undefined);
  };

  const activeMembersCount = members.filter(member => member.isActive).length;
  const canProceed = activeMembersCount <= seatsCount;

  const renderMembers = () => {
    let prevIsNew = members[0] ? members[0].isNew : true;
    return (
      <div className={classes.members}>
        {members.map((member, i) => {
          let label = null;
          if (forceDisableMembers) {
            if (i === 0) {
              if (!canProceed) {
                label = <h3 className={classes.membersSectionTitle} style={{ lineHeight: '16px', marginBottom: 10 }}>Please deactivate some members ({activeMembersCount - seatsCount} left):<br/><span style={{ fontSize: 12, color: '#868686' }}>these members will still be a part of your organization</span></h3>;
              } else {
                label =
                  <h3 className={classes.membersSectionTitle} style={{ lineHeight: '16px', marginBottom: 10 }}>You can proceed with plan change<br/><span style={{ fontSize: 12, color: '#868686' }}>these members will still be a part of your organization</span></h3>
              }
            }
          } else if (i === 0) {
            if (member.isNew) {
              label = <h3 className={classes.membersSectionTitle}>New Members <small>(optional)</small></h3>;
            } else {
              label = <h3 className={classes.membersSectionTitle}>Assigned Members</h3>;
            }
          }
          if (!prevIsNew && member.isNew) {
            label = <h3 className={classes.membersSectionTitle}>New Members <small>(optional)</small></h3>;
          }
          prevIsNew = member.isNew;
          return (
            <div key={i}>
              {label}
              <BillingMemberItem
                canDisable={forceDisableMembers}
                toggleDisabled={() => onToggleMember(member)}
                key={member.id}
                member={member}
                position={i + 1}
                onMemberEdit={() => setEditingMember(member)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const onCloseEdit = () => {
    setEditingMember(undefined);
  };

  return (
    <>
      {editingMember ? (
        <BillingMemberEditForm
          membersCount={seatsCount}
          member={editingMember}
          onSubmit={saveMember}
          renderControls={(isSubmitAllowed, loading) => {
            return (
              <div className={classes.navigation}>
                <Button className={classes.button} onClick={seatsCount === 1 ? prevStep : onCloseEdit} theme="outline">
                  Back
                </Button>
                {seatsCount === 1 ? (
                  <Button className={classes.button} onClick={nextStep}>
                    Next
                  </Button>
                ) : (
                  <Button className={classes.button} disabled={!isSubmitAllowed || loading} type='submit'>
                    {loading ? 'Processing...' : 'Save'}
                  </Button>
                )}
              </div>
            );
          }}
        />
      ) : renderMembers()}
      <div className={classes.navigation}>
        {!editingMember && seatsCount >= 1 && (
          <>
            <Button className={classes.button} onClick={prevStep} theme="outline">
              Back
            </Button>
            <Button className={classes.button} disabled={!canProceed} onClick={nextStep}>
              Next
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default MembersStep;
