export type CollaboratorInterface = {
  id: number
  firstName: string
  lastName: string
  email: string
  role: 'admin'|'editor'|'viewer'
  createdAt: string
  imgUrl: string
}

class CollaboratorService {
  mapToInterface(obj: any): CollaboratorInterface {
    return {
      id : obj.user.id,
      role : obj.role?.toUpperCase(),
      createdAt : obj.createdAt,
      imgUrl : obj.user.profileImgUrl,
      firstName : obj.user?.firstName,
      lastName : obj.user?.lastName,
      email: obj.user?.email,
    };
  }
}

export const collaboratorService = new CollaboratorService();
