import * as React from "react";
import { cx } from "emotion";

import { ReactComponent as IconArrow } from "./icon-arrow.svg";
import { ReactComponent as IconArrowDownToLine } from "./icon-arrow-down-to-line.svg";
import { ReactComponent as IconCross } from "./icon-cross.svg";
import { ReactComponent as IconCross2 } from "./icon-cross-2.svg";
import { ReactComponent as IconCancel } from "./icon-cancel.svg";
import { ReactComponent as IconDots } from "./icon-dots.svg";
import { ReactComponent as IconDotsVertical } from "./icon-dots-vertical.svg";
import { ReactComponent as IconDotsVerticalSmall } from "./icon-dots-vertical-small.svg";
import { ReactComponent as IconError } from "./icon-error.svg";
import { ReactComponent as IconPlus } from "./icon-plus.svg";
import { ReactComponent as IconSearch } from "./icon-search.svg";
import { ReactComponent as IconChevron } from "./icon-chevron.svg";
import { ReactComponent as IconChevronDouble } from "./icon-chevron-double.svg";
import { ReactComponent as IconChevronThin } from "./icon-chevron-thin.svg";
import { ReactComponent as IconTick } from "./icon-tick.svg";
import { ReactComponent as IconTick2 } from "./icon-tick-2.svg";
import { ReactComponent as IconViewGrid } from "./icon-view-grid.svg";
import { ReactComponent as IconViewList } from "./icon-view-list.svg";
import { ReactComponent as IconAddContact } from "./icon-add-contact.svg";
import { ReactComponent as IconDribbble } from "./icon-dribbble.svg";
import { ReactComponent as IconEmail } from "./icon-email.svg";
import { ReactComponent as IconFacebook } from "./icon-facebook.svg";
import { ReactComponent as IconGooglePlus } from "./icon-google-plus.svg";
import { ReactComponent as IconLinkedin } from "./icon-linkedin.svg";
import { ReactComponent as IconMessage } from "./icon-message.svg";
import { ReactComponent as IconGithub } from "./icon-github.svg";
import { ReactComponent as IconGithub2 } from "./icon-github-2.svg";
import { ReactComponent as IconMeetup } from "./icon-meetup.svg";
import { ReactComponent as IconStackoverflow } from "./icon-stackoverflow.svg";
import { ReactComponent as IconCrunchbase } from "./icon-crunchbase.svg";
import { ReactComponent as IconAngellist } from "./icon-angellist.svg";
import { ReactComponent as IconYoutube } from "./icon-youtube.svg";
import { ReactComponent as IconFlickr } from "./icon-flickr.svg";
import { ReactComponent as IconQuora } from "./icon-quora.svg";
import { ReactComponent as IconTwitter } from "./icon-twitter.svg";
import { ReactComponent as IconVideoCall } from "./icon-video-call.svg";
import { ReactComponent as IconPhone } from "./icon-phone.svg";
import { ReactComponent as IconLock } from "./icon-lock.svg";
import { ReactComponent as IconCheck } from "./icon-check.svg";
import { ReactComponent as IconCalendar } from "./icon-calendar.svg";
import { ReactComponent as IconTrash } from "./icon-trash.svg";
import { ReactComponent as IconTrashCancel } from "./icon-trash-cancel.svg";
import { ReactComponent as IconPencil } from "./icon-pencil.svg";
import { ReactComponent as IconPencil2 } from "./icon-pencil-2.svg";
import { ReactComponent as IconColorVisa } from "./icon-color-visa.svg";
import { ReactComponent as IconColorMastercard } from "./icon-color-mastercard.svg";
import { ReactComponent as IconMenuFold } from "./icon-menu-fold.svg";
import { ReactComponent as IconMenuUnfold } from "./icon-menu-unfold.svg";
import { ReactComponent as IconFaArrowRotateRight } from "./fa/icon-fa-arrow-rotate-right.svg";
import { ReactComponent as IconFaChevron } from "./fa/icon-fa-chevron.svg";
import { ReactComponent as IconFaChevronBold } from "./fa/icon-fa-chevron-bold.svg";
import { ReactComponent as IconFaCircleCheck } from "./fa/icon-fa-circle-check.svg";
import { ReactComponent as IconFaCircleCheckFilled } from "./fa/icon-fa-circle-check-filled.svg";
import { ReactComponent as IconFaCircleXmark } from "./fa/icon-fa-circle-xmark.svg";
import { ReactComponent as IconFaCircle } from "./fa/icon-fa-circle.svg";
import { ReactComponent as IconFaCreditCard } from "./fa/icon-fa-credit-card.svg";
import { ReactComponent as IconFaMinus } from "./fa/icon-fa-minus.svg";
import { ReactComponent as IconFaPlus } from "./fa/icon-fa-plus.svg";
import { ReactComponent as IconFaXmark } from "./fa/icon-fa-xmark.svg";
import { ReactComponent as IconFaPen } from "./fa/icon-fa-pen.svg";
import { ReactComponent as IconFaTrash } from "./fa/icon-fa-trash.svg";
import { ReactComponent as IconFaCircleDot } from "./fa/icon-fa-circle-dot.svg";
import { ReactComponent as IconFaCheck } from "./fa/icon-fa-check.svg";
import { ReactComponent as IconFaPlay } from "./fa/icon-fa-play.svg";
import { ReactComponent as IconFaPause } from "./fa/icon-fa-pause.svg";
import { ReactComponent as IconFilter } from "./icon-filter.svg";
import { ReactComponent as IconFilterFilled } from "./icon-filter-filled.svg";
import { ReactComponent as IconFolder } from "./icon-folder.svg";
import { ReactComponent as IconTableView } from "./icon-table-view.svg";
import { ReactComponent as IconBoardView } from "./icon-board-view.svg";
import { ReactComponent as IconSort } from "./icon-sort.svg";
import { ReactComponent as IconEnvelopeOutline } from "./icon-envelope-outline.svg";
import { ReactComponent as IconPhoneOutline } from "./icon-phone-outline.svg";
import { ReactComponent as IconSmsOutline } from "./icon-sms-outline.svg";
import { ReactComponent as IconInstagram } from "./icon-instagram.svg";
import { ReactComponent as IconSequence } from "./icon-sequence.svg";
import { ReactComponent as IconArrowRightShort } from "./icon-arrow-right-short.svg";
import { ReactComponent as IconEye } from "./icon-eye.svg";
import { ReactComponent as IconArchive } from "./icon-archive.svg";
import { ReactComponent as IconPerson } from "./icon-person.svg";
import { ReactComponent as IconGear } from "./icon-gear.svg";
import { ReactComponent as IconDragHandle } from "./icon-drag-handle.svg";
import { ReactComponent as IconFaBan } from "./fa/icon-fa-ban.svg";
import { ReactComponent as IconFaEdit } from "./fa/icon-fa-edit.svg";
import { ReactComponent as IconFaDownload } from "./fa/icon-fa-download.svg";
import { ReactComponent as IconWorkable } from "./icon-workable.svg";
import { ReactComponent as IconContactInfoVerificationPending } from "./icon-contact-info-verification-pending.svg";
import { ReactComponent as IconContactInfoVerificationTrusted } from "./icon-contact-info-verification-trusted.svg";
import { ReactComponent as IconContactInfoVerificationUntrusted } from "./icon-contact-info-verification-untrusted.svg";
import { ReactComponent as IconCopy } from "./icon-copy.svg";
import { ReactComponent as IconPaperPlane } from "./icon-paper-plane.svg";
import { ReactComponent as IconWordpress } from './icon-wordpress.svg';
import { ReactComponent as IconBehance } from './icon-behance.svg';
import { ReactComponent as IconEllo } from './icon-ello.svg';
import { ReactComponent as IconFoursquare } from './icon-foursquare.svg';
import { ReactComponent as IconGitlab } from './icon-gitlab.svg';
import { ReactComponent as IconGoogle } from './icon-google.svg';
import { ReactComponent as IconSoundcloud } from './icon-soundcloud.svg';
import { ReactComponent as IconTiktok } from './icon-tiktok.svg';
import { ReactComponent as IconReddit } from './icon-reddit.svg';
import { ReactComponent as IconWhatsapp } from './icon-whatsapp.svg';
import { ReactComponent as IconPinterest } from './icon-pinterest.svg';
import { ReactComponent as IconSkype } from './icon-skype.svg';
import { ReactComponent as IconSnapchat } from './icon-snapchat.svg';
import { ReactComponent as IconXing } from './icon-xing.svg';
import { ReactComponent as IconIndeed } from './icon-indeed.svg';
import { ReactComponent as IconKlout } from './icon-klout.svg';
import { ReactComponent as IconMyspace } from './icon-myspace.svg';
import { ReactComponent as IconAboutme } from './icon-aboutme.svg';
import { ReactComponent as IconGravatar } from './icon-gravatar.svg';
import { ReactComponent as IconVimeo } from './icon-vimeo.svg';
import { ReactComponent as IconRefresh } from './icon-refresh.svg';
import { ReactComponent as IconCamera } from './icon-camera.svg';
import { ReactComponent as IconMove } from './icon-move.svg';
import { ReactComponent as IconExpand } from './icon-expand.svg';
import { ReactComponent as IconSuitcase } from './icon-suitcase.svg';
import { ReactComponent as IconUsers } from './icon-users.svg';

const iconMap: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  IconArrow,
  IconArrowDownToLine,
  IconCancel,
  IconCross,
  IconCross2,
  IconDots,
  IconDotsVertical,
  IconDotsVerticalSmall,
  IconPlus,
  IconSearch,
  IconChevron,
  IconChevronDouble,
  IconChevronThin,
  IconError,
  IconTick,
  IconTick2,
  IconViewGrid,
  IconViewList,
  IconAddContact,
  IconDribbble,
  IconEmail,
  IconFacebook,
  IconGooglePlus,
  IconLinkedin,
  IconMessage,
  IconGithub,
  IconGithub2,
  IconMeetup,
  IconStackoverflow,
  IconCrunchbase,
  IconAngellist,
  IconFlickr,
  IconYoutube,
  IconQuora,
  IconTwitter,
  IconWordpress,
  IconBehance,
  IconEllo,
  IconFoursquare,
  IconGitlab,
  IconGoogle,
  IconSoundcloud,
  IconTiktok,
  IconReddit,
  IconWhatsapp,
  IconPinterest,
  IconSkype,
  IconSnapchat,
  IconVideoCall,
  IconXing,
  IconIndeed,
  IconKlout,
  IconMyspace,
  IconAboutme,
  IconGravatar,
  IconPhone,
  IconLock,
  IconCheck,
  IconCalendar,
  IconTrash,
  IconTrashCancel,
  IconPencil,
  IconPencil2,
  IconFilter,
  IconFilterFilled,
  IconFolder,
  IconTableView,
  IconBoardView,
  IconSort,
  IconEnvelopeOutline,
  IconPhoneOutline,
  IconSmsOutline,
  IconInstagram,
  IconSequence,
  IconArrowRightShort,
  IconEye,
  IconArchive,
  IconPerson,
  IconGear,
  IconDragHandle,
  IconMenuFold,
  IconMenuUnfold,
  IconColorVisa,
  IconColorMastercard,
  IconFaArrowRotateRight,
  IconFaChevron,
  IconFaChevronBold,
  IconFaCircleCheck,
  IconFaCircleCheckFilled,
  IconFaCircleXmark,
  IconFaCircle,
  IconFaCircleDot,
  IconFaCreditCard,
  IconFaEdit,
  IconFaMinus,
  IconFaPlus,
  IconFaXmark,
  IconFaPen,
  IconFaTrash,
  IconFaCheck,
  IconFaBan,
  IconFaDownload,
  IconFaPlay,
  IconFaPause,
  IconWorkable,
  IconContactInfoVerificationPending,
  IconContactInfoVerificationTrusted,
  IconContactInfoVerificationUntrusted,
  IconCopy,
  IconPaperPlane,
  IconRefresh,
  IconCamera,
  IconVimeo,
  IconMove,
  IconExpand,
  IconSuitcase,
  IconUsers,
};

type Props = {
  name: string;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

export function Icon(props: Props) {
  if (!props.name) {
    console.warn(`Icon: name prop is required`);
    return null;
  }

  const componentName = props.name
    .split("-")
    .map((str: string) => str[0].toUpperCase() + str.slice(1))
    .join("");
  const iconComponentName = "Icon" + componentName;
  const IconComponent = iconMap[iconComponentName];
  if (!IconComponent) {
    console.warn("Icon not found: ", iconComponentName);
    return null;
  }
  return (
    <span
      className={cx("icon-" + props.name, props.className)}
      title={props.title || props.name}
      style={props.style}
      onClick={props.onClick}
    >
      <IconComponent style={props.iconStyle} />
    </span>
  );
}
