import * as React from "react";
import css from "./NoData.module.css";
import noSearchResultsCharacter from "../../../../assets/images/no-search-results-character.png";

export function NoData(props) {
  return (
    <div className={css.holder} style={props.style}>
      {props.showIcon === false ? null : <img src={noSearchResultsCharacter} alt="No Results" />}
      <p>{props.text || "Enter information on the left and results will appear here."}</p>
    </div>
  );
}
