import * as React from "react";
import css from "./Radio.module.css";
import { cx } from "emotion";

type Props = {
  id: string
  checked: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  style?: React.CSSProperties
  className?: string
}

export function Radio(props: Props) {
  return (
    <div className={cx(css.radio, { [css.checked]: props.checked }, props.className)} style={props.style}>
      <input type="radio" id={props.id} className={css.input} checked={props.checked} onChange={props.onChange} />
      <div className={css.icon} />
    </div>
  );
}
