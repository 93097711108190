import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ORGANIZATION_ROLE_OPTIONS, OrganizationMemberRole } from "../../../../services/OrganizationService";
import CloseButton from "../../../button/CloseButton";
import ModalButtonContainer from "../../../button/ModalButtonContainer";
import { modalStyles } from "../../../modals/modalStyles";
import { FormField } from "../../Form/FormField/FormField";
import { Input } from "../../Form/Input/Input";
import { Select } from "../../Form/Select/Select";
import { TextareaHighlightable } from "../../Form/TextareaHighlightable/TextareaHighlightable";

type Props = {
  title: string
  onSubmit: (data: any) => Promise<{ errors?: FieldErrors } | void> | undefined;
  onClose: () => void;
  config: { name: string, label: string, defaultValue: any, type?: string, placeholder?: string, options?: any[], disabled?: boolean }[]
  submitButtonTitle?: string
}

type FieldErrors = Record<string, string>

export const ModalEdit: React.FC<Props> = ({ title, config, onClose, onSubmit, submitButtonTitle }) => {
  const [rerenderKey, setRerenderKey] = useState(0);
  const [errors, setErrors] = useState<FieldErrors>({});
  const defaultValues = config.reduce((acc, item) => ({ ...acc, [item.name] : item.defaultValue || "" }), {});
  const [values, setValues] = useState<any>(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await onSubmit(values);
      setLoading(false);
      if (res && res.errors) {
        setErrors(res.errors)
      }
    } catch (err) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setRerenderKey(rerenderKey + 1);
  }, []);

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>{title}</div>
        <div className={modalStyles.modalFormContainer} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          {config.map((item) => {
            if (item.type === 'text') {
              return (
                <FormField error={errors[item.name]} label={item.label} key={item.label}>
                  <Input
                    disabled={item.disabled}
                    value={values[item.name]}
                    onChange={(e: any) => setValues({ ...values, [item.name] : e.target.value })}
                    placeholder={item.placeholder || item.label}
                  />
                </FormField>
              )
            }
            if (item.type === 'select') {
              return (
                <FormField error={errors[item.name]} label={item.label} key={item.label}>
                  <Select
                    isDisabled={item.disabled}
                    value={values[item.name]}
                    onChange={(e: any) => setValues({ ...values, [item.name] : e })}
                    options={ORGANIZATION_ROLE_OPTIONS.filter(item => item.value !== OrganizationMemberRole.Owner)}
                    placeholder={item.placeholder || item.label}
                  />
                </FormField>
              )
            }
            return (
              <FormField label={item.label} key={item.label}>
                <TextareaHighlightable
                  disabled={item.disabled}
                  key={rerenderKey}
                  className={css`
                    max-height: 240px !important;
                  `}
                  value={values[item.name]}
                  onChange={(e: any) => setValues({ ...values, [item.name] : e.target.value })}
                  placeholder={item.placeholder || item.label}
                />
              </FormField>
            );
          })}
        </div>
        <ModalButtonContainer
          title={submitButtonTitle || 'Confirm'}
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};