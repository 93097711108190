import { cookieService } from "./CookieService";
import { COOKIE_NAME } from "../../../constants/constants";
import * as api from "../../../api/apiService";
import { Formatter } from "../util/Formatter";

/**
 * @typedef JWTTokenData
 * @property {string} access_token
 * @property {number} expires_in
 * @property {string} refresh_token
 * @property {string} token_type
 */

class AuthService {
  /**
   * @param user
   * @param {JWTTokenData} jwtTokenData
   */
  setTokenData(user, jwtTokenData) {
    cookieService.set(COOKIE_NAME.access_token, jwtTokenData.access_token);
    cookieService.set(COOKIE_NAME.expires_in, jwtTokenData.expires_in);
    cookieService.set(COOKIE_NAME.refresh_token, jwtTokenData.refresh_token);
    cookieService.set(COOKIE_NAME.token_type, jwtTokenData.token_type);
  }

  /**
   * @param {JWTTokenData} jwtTokenData
   * @param {any} currentUser
   */
  pauseSessionAndLoginAs(jwtTokenData, currentUser) {
    const currentSessionTokenData = this.getTokenData();
    if (!currentSessionTokenData) {
      return;
    }
    cookieService.set(COOKIE_NAME.access_token, jwtTokenData.access_token);
    cookieService.set(COOKIE_NAME.expires_in, jwtTokenData.expires_in);
    cookieService.set(COOKIE_NAME.refresh_token, jwtTokenData.refresh_token);
    cookieService.set(COOKIE_NAME.token_type, jwtTokenData.token_type);
    cookieService.set(COOKIE_NAME.paused_session_access_token, currentSessionTokenData.access_token);
    cookieService.set(COOKIE_NAME.paused_session_expires_in, currentSessionTokenData.expires_in);
    cookieService.set(COOKIE_NAME.paused_session_refresh_token, currentSessionTokenData.refresh_token);
    cookieService.set(COOKIE_NAME.paused_session_token_type, currentSessionTokenData.token_type);
    cookieService.set(COOKIE_NAME.paused_session_user_name, Formatter.fullName(currentUser));
    cookieService.set(COOKIE_NAME.paused_session_user_email, currentUser.email)
    cookieService.set(COOKIE_NAME.paused_session_url, window.location.href);
    window.location.href = '/';
  }


  /**
   * @returns {null|JWTTokenData & { url : string }}
   */
  getPausedSessionData = () => {
    const accessToken = cookieService.get(COOKIE_NAME.paused_session_access_token);
    if (!accessToken) {
      return null;
    }

    return {
      access_token: cookieService.get(COOKIE_NAME.paused_session_access_token),
      expires_in: parseInt(cookieService.get(COOKIE_NAME.paused_session_expires_in), 10),
      refresh_token: cookieService.get(COOKIE_NAME.paused_session_refresh_token),
      token_type: cookieService.get(COOKIE_NAME.paused_session_token_type),
      url: cookieService.get(COOKIE_NAME.paused_session_url),
      user_name: cookieService.get(COOKIE_NAME.paused_session_user_name),
      user_email: cookieService.get(COOKIE_NAME.paused_session_user_email),
    };
  }

  resumePausedSession = () => {
    const pausedSessionTokenData = this.getPausedSessionData();
    if (!pausedSessionTokenData) {
      return;
    }
    cookieService.set(COOKIE_NAME.access_token, pausedSessionTokenData.access_token);
    cookieService.set(COOKIE_NAME.expires_in, pausedSessionTokenData.expires_in);
    cookieService.set(COOKIE_NAME.refresh_token, pausedSessionTokenData.refresh_token);
    cookieService.set(COOKIE_NAME.token_type, pausedSessionTokenData.token_type);
    window.location.href = pausedSessionTokenData.url;
    this.clearPausedSessionData();
  }

  clearTokenData() {
    cookieService.remove(COOKIE_NAME.access_token);
    cookieService.remove(COOKIE_NAME.expires_in);
    cookieService.remove(COOKIE_NAME.refresh_token);
    cookieService.remove(COOKIE_NAME.token_type);
    this.clearPausedSessionData();
  }

  clearPausedSessionData() {
    cookieService.remove(COOKIE_NAME.paused_session_access_token);
    cookieService.remove(COOKIE_NAME.paused_session_expires_in);
    cookieService.remove(COOKIE_NAME.paused_session_refresh_token);
    cookieService.remove(COOKIE_NAME.paused_session_token_type);
    cookieService.remove(COOKIE_NAME.paused_session_url);
    cookieService.remove(COOKIE_NAME.paused_session_user_name);
    cookieService.remove(COOKIE_NAME.paused_session_user_email);
  }

  /**
   * @returns {null|JWTTokenData}
   */
  refreshAccessToken(refreshToken) {
    return api.RefreshAccessToken({ refresh_token: refreshToken, grant_type: "refresh_token" }).then((r) => {
      if (r.data) {
        const tokenData = r.data;
        authService.setTokenData(null, tokenData);
        return tokenData;
      } else {
        return null;
      }
    });
  }

  async logout() {
    await api.Logout();
    this.clearTokenData();
  }

  /**
   * @returns {null|JWTTokenData}
   */
  getTokenData() {
    const accessToken = cookieService.get(COOKIE_NAME.access_token);
    if (!accessToken) {
      return null;
    }

    return {
      access_token: cookieService.get(COOKIE_NAME.access_token),
      expires_in: parseInt(cookieService.get(COOKIE_NAME.expires_in), 10),
      refresh_token: cookieService.get(COOKIE_NAME.refresh_token),
      token_type: cookieService.get(COOKIE_NAME.token_type),
    };
  }
}

export const authService = new AuthService();
