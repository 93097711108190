import React from "react";
import { take } from "lodash";
import { css } from "emotion/macro";

import { capitalizeWords } from "../../../formatters/capitalizeWords";

import "../Mobile.css";

// Will house the bottom Notes Container on Candidate Details Modal
const MobileSkills = ({ entries = [], viewAll = true, maxEntries = 3 }) => {
  const displayedEntries = viewAll ? entries : take(entries, maxEntries);

  const getSkills = () => {
    if (!entries) return;
    if (entries?.length === 0) {
      return (
        <div className="text-center">
          <span>No skills</span>
        </div>
      );
    }

    return displayedEntries.map((skill, key) => {
      let formattedSkill = capitalizeWords(skill);
      return (
        <button key={key} type="button" className="btn btn-outline-secondary mb-2 mr-1 skills-button">
          {formattedSkill}
        </button>
      );
    });
  };

  return (
    <div className="row d-block d-lg-none mobile-skills-container">
      <div>
        <div className={viewAll ? `row expand-container` : `row condensed-skills-list-container`}>
          <div
            className={css`
              padding-left: 10px;
            `}
          >
            {getSkills()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSkills;
