import * as api from "../../api/apiService";
import { billingService } from "../../services/BillingService";
import { organizationService } from "../../services/OrganizationService";

/**
 * @typedef ProcessSubscriptionDTO
 * @property {number} subscription_id
 * @property {number} operation_id
 */

class UserService {
  /**
   * @param {any} obj
   * @returns {UserInterface}
   */
  mapToInterface(obj) {
    return {
      id : obj.id || obj.userId,
      firstName : obj.firstName,
      lastName : obj.lastName,
      email : obj.email,
      externalId : obj.externalId,
      name : obj.name,
      organizationId : obj.organizationId,
      organizationType : obj.organizationType,
      phoneNumber : obj.phoneNumber,
      profileImgUrl : obj.profileImgUrl,
      role: obj.role,
      createdAt : obj.createdAt,
      organization : obj.organization,
      activatedAt : obj.activatedAt,
      emailConfirmed : !!obj.activatedAt,
    };
  }

  /**
   * @param obj
   * @returns {AdminUserInterface}
   */
  mapToAdminInterface(obj) {
    const currentUserCreditsLeft = obj.seatCreditsUsage ? (obj.seatCreditsUsage.seat.CandidateUnlockCreditsTotal - obj.seatCreditsUsage.seat.CandidateUnlockCreditsRedeemed) : 0;
    const currentOrganizationCreditsLeft = obj.seatCreditsUsage ? (obj.seatCreditsUsage.organization.CandidateUnlockCreditsTotal - obj.seatCreditsUsage.organization.CandidateUnlockCreditsRedeemed) : 0;

    return {
      id: obj.id,
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      organizationSeat: obj.organizationSeat ? {
        role: obj.organizationSeat.role
      } : {},
      organization: obj.organizationSeat?.organizationRef,
      credits: {
        limit : obj.seatCreditsUsage ? obj.seatCreditsUsage.seat.CandidateUnlockCreditsTotal: 0,
        used : obj.seatCreditsUsage ? obj.seatCreditsUsage.seat.CandidateUnlockCreditsTotal - Math.min(currentOrganizationCreditsLeft, currentUserCreditsLeft): 0,
      },
      createdAt: obj.createdAt,
      activatedAt: obj.activatedAt,
    }
  }

  /**
   * @returns {Promise<CurrentUserInterface | null>}
   */
  fetchMe() {
    return api.GetCurrentUser().then(async (r) => {
      if (!r.data) {
        return null;
      }
      const user = userService.mapToInterface(r.data);
      try {
        user.organizationV2 = await organizationService.fetchCurrentOrganizationInfo();
        user.organizationId = user.organizationV2.id;
        user.organizationType = user.organizationV2.type;
        user.seat = await organizationService.fetchCurrentOrganizationSeatInfo();
      } catch (err) {
        console.error("couldn't fetch organization info", err);
      }
      user.currentPlan = await billingService.fetchCurrentPlanInfo();
      // user.hasActiveSubscription = !!user.currentPlan || user.seat?.role;
      user.hasActiveSubscription = !!user.currentPlan;
      user.isAdmin = user.role?.name === "full-admin";
      user.uiSettings = r.data.uiSettings;

      return user;
    });
  }

  /**
   * @param {ProcessSubscriptionDTO} data
   */
  processSubscription(data) {
    return api.ProcessSubscription(data).then((r) => r.data || null);
  }

  /**
   * @param {any} args
   * @returns {Promise<EntityConnection<AdminUserInterface>>}
   */
  fetchAll = async (args) => {
    const usersRes = await api.AdminGetUsers(args);
    const usersCountRes = await api.AdminGetUsersCount(args);
    return {
      nodes : usersRes.data.filter(Boolean).map(this.mapToAdminInterface),
      pageInfo : {
        pageCount : Math.max(Math.ceil(usersCountRes.data / args.limit), 1),
        total : usersCountRes.data,
      },
    };
  };

  /**
   * @param {string} id
   * @returns {Promise<unknown>}
   */
  fetchOne = async (id) => {
    const userRes = await api.AdminGetUser(id);
    return userRes?.data ? this.mapToAdminInterface(userRes.data) : null;
  };

  /**
   * @param {string} id
   * @returns {Promise<unknown>}
   */
  delete = (id) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  /**
   * @param {UpdateProfileDTO} data}
   * @returns {Promise<void>}
   */
  updateProfile = async (data) => {
    const res = await api.UpdateUserProfile(data);
    return res;
  };

  /**
   * @param {Blob} blob
   * @param {string} extension
   * @returns {Promise<void>}
   */
  updateProfileImage = async (blob, extension) => {
    const res = await api.UploadUserProfileImage(blob, extension);
    return res;
  };

  // todo move to AdminService
  /**
   * @param {string} id
   * @param {any} data
   * @returns {Promise<unknown>}
   */
  update = async (id, data) => {
    const activateRes = await api.AdminUpdateUser(id, data);
    return activateRes.data || null;
  };

  loginAs = async (id) => {
    const activateRes = await api.AdminLoginAs(id);
    return activateRes.data || null;
  };

  activate = async (userId) => {
    const activateRes = await api.AdminActivateUser(userId);
    return activateRes.data || null;
  };

  createOrganizationSubscription = async (organizationId, data) => {
    const res = await api.AdminCreateOrganizationSubscription(organizationId, data);
    return res.data || null;
  };

  updateOrganizationSubscription = async (organizationId, subscriptionId, data) => {
    const res = await api.AdminUpdateOrganizationSubscription(organizationId, subscriptionId, data);
    return res.data || null;
  };

  getStripeSubscriptionURL(subscriptionId) {
    return `https://dashboard.stripe.com/${process.env.REACT_APP_STRIPE_PUBLIC_KEY.includes('test') ? 'test/' : ''}subscriptions/${subscriptionId}`;
  }

  getStripeCustomerURL(customerId) {
    return `https://dashboard.stripe.com/${process.env.REACT_APP_STRIPE_PUBLIC_KEY.includes('test') ? 'test/' : ''}customers/${customerId}`;
  }
}

export const userService = new UserService();
