import React from "react";
import { take } from "lodash";

import "../Mobile.css";
import { capitalizeWords } from "../../../formatters/capitalizeWords";
import { cx, css } from "emotion";

const MobileExperience = ({ entries = [], viewAll = true, maxEntries = 3 }) => {
  const displayedEntries = viewAll ? entries : take(entries, maxEntries);

  const renderExperienceString = (title, company) => {
    if (title) {
      title = capitalizeWords(title);
    }

    if (title && company) {
      company = ` | ${capitalizeWords(company)}`;
    } else if (company) {
      company = ` ${capitalizeWords(company)}`;
    }

    return (
      <>
        <span className="font-weight-bold">{title}</span>
        <span className="light-grey">{company}</span>
      </>
    );
  };

  return (
    <div className="row d-flex mobile-experience-container">
      {entries.length > 0 ? (
        <ul className={cx("mobile-experience-list", styles.mobileExperienceList)}>
          {displayedEntries.map((xp, key) => {
            return (
              <li key={key}>
                <div className={cx(styles.experienceSpanContainer)}>
                  {renderExperienceString(xp?.title, xp?.company?.name)}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="text-center">
          <span>No experience</span>
        </div>
      )}
    </div>
  );
};

const styles = {
  mobileExperienceList: css`
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.61px;
    padding-left: 20px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 10px;
  `,
  experienceSpanContainer: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `,
};

export default MobileExperience;
