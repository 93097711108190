import * as React from "react";
import PropTypes from "prop-types";
import { Pagination } from "../Pagination/Pagination";

import css from "./PaginationFull.module.css";
import { cx } from "emotion";
import { PAGE_SIZE_OPTIONS } from "../../../../constants/constants";
import { UnderlinedSelectHtml } from "../SelectHtml/UnderlinedSelectHtml/UnderlinedSelectHtml";

// @todo move to some general place
export class PaginationFull extends React.PureComponent {
  render() {
    const pageSizeValue = PAGE_SIZE_OPTIONS.find((item) => item.value === this.props.pageSize) || {
      value: this.props.pageSize,
      label: this.props.pageSize,
    };
    return (
      <div className={cx(css.pagination, this.props.className)} style={this.props.style}>
        <div>
          {!!this.props.pageCount && (
            <span className={css.pageInfo}>
            Page {this.props.currentPage} of {this.props.pageCount}
          </span>
          )}
          {this.props.leftChildren}
        </div>
        <Pagination
          currentPage={this.props.currentPage}
          pageCount={this.props.pageCount}
          onChangeCurrentPage={this.props.onChangeCurrentPage}
        />
        <div>
          {this.props.rightChildren}
          <UnderlinedSelectHtml
            label={`${pageSizeValue.label} per page`}
            isDimmed
            className={css.pageSizeSelect}
            value={pageSizeValue.value}
            onChange={(v) => this.props.onChangePageSize(parseInt(v))}
            options={this.props.options || PAGE_SIZE_OPTIONS}
          />
        </div>
      </div>
    );
  }
}

PaginationFull.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.number,
    })
  ),
  className: PropTypes.string,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  onChangePageSize: PropTypes.func,
  currentPage: PropTypes.number,
  onChangeCurrentPage: PropTypes.func,
};
