import React from "react";
import { take } from "lodash";

// candidate avatars
import educationImg from "../../assets/images/education-avatar.svg";

import capitalizeWords from "../../formatters/capitalizeWords";

import { cx, css } from "emotion/macro";

function Education({ entries = [], viewAll = true, maxEntries = 3 }) {
  const displayedEntries = viewAll ? entries : take(entries, maxEntries);
  return entries.length > 0 ? (
    displayedEntries.map((education, index) => {
      return (
        <div key={index} style={{ display: "flex", marginBottom: "12px" }}>
          <div style={{ display: "inline-block" }}>
            <img
              className="job-img"
              src={educationImg}
              alt=""
              width="60px"
              style={{
                borderRadius: "50%",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />
          </div>
          <div style={{ display: "inline-block", marginLeft: "18px" }}>
            {education.school && education.school.name ? (
              <div className={cx("standard-theme-text", "candidate-detail-list-item-title")}>
                {capitalizeWords(education.school.name)}
              </div>
            ) : (
              ""
            )}
            {education.summaries && education.summaries.length ? (
              <div className={cx("standard-theme-text", "candidate-detail-list-item-details")}>
                {education.summaries[0]}
              </div>
            ) : (
              ""
            )}
            <div className={cx("standard-theme-text", "candidate-detail-list-item-details")}>
              {[education.startDate, education.endDate]
                .filter((x) => x)
                .map((x) =>
                  new Date(x).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  })
                )
                .join(" to ")}
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div
      className={css`
        font-size: 12px;
        color: #a8a8a8;
      `}
    >
      No education
    </div>
  );
}

export default Education;
