import * as React from "react";
import { useAuthController } from "../../../contexts/AuthProvider";
import {
  StageColor,
  StageFlowItemInterface,
  StageFlowItemUpdateDTOInterface,
  stageFlowService,
} from "../../../screens/profile/ProfileStageFlow/StageFlowService";
import { StagesTable } from "../../../screens/profile/ProfileStageFlow/StagesTable/StagesTable";
import { Button } from "../../DiscoverNew/UI/Button/Button";

type Props = {
  value: StageFlowItemInterface[]
  onChange(value: StageFlowItemInterface[]): void
}

export function InterviewProcessStagesEditor(props: Props) {

  const onDelete = (stageId: number) => {
    const newStages = props.value
      .filter(stage => stage.stageId !== stageId)
      .map((item, i) => {
        return { ...item, order: i + 1 };
      });
    props.onChange(newStages);
  };

  const onReorder = (stages: StageFlowItemInterface[]) => {
    props.onChange(stages);
  };

  const onInlineEdit = (stageId: number, stageData: StageFlowItemUpdateDTOInterface) => {
    const newStages = props.value.map(stage => {
      if (stage.stageId === stageId) {
        return { ...stage, ...stageData };
      }
      return stage;
    });
    props.onChange(newStages);
  };

  const onCreate = () => {
    const nextIndex = props.value.length + 1;
    const newStages = [
      ...props.value,
      stageFlowService.makeStageFlowItem({
        name : `New Stage ${nextIndex}`,
        hoursToComplete : 24,
        order : nextIndex,
        color : StageColor.BLUE,
      }),
    ];
    props.onChange(newStages);
  };

  return (
    <div>
      <StagesTable
        isEditable
        showHeader={false}
        mode="short"
        stages={props.value}
        onDelete={onDelete}
        onReorder={onReorder}
        onInlineEdit={onInlineEdit}
      />
      <Button theme='outline' style={{ width: '100%', marginTop: 8 }} onClick={onCreate}>Add Stage</Button>
    </div>
  );
}