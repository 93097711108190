import css from "./ProjectCard.module.css";
import { COLLABORATOR_ROLE } from "../../../constants/constants";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import * as React from "react";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";
import { Link as CustomLink } from "../../DiscoverNew/UI/Link/Link";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import CompanyImg from "../../company/CompanyImg";
import { extractInitials } from "../../../screens/companies/Companies";
import { useState } from "react";
import InviteCollaboratorModal from "../../modals/InviteCollaboratorModal/InviteCollaboratorModal";
import CollaboratorsModal from "../../modals/CollaboratorsModal/CollaboratorsModal";

export const ProjectCardCollaborators = (props) => {
  const { data } = props;

  const [isInviteCollaboratorModalOpen, setIsInviteCollaboratorModalOpen] = useState(false);
  const [isCollaboratorsModalOpen, setIsCollaboratorsModalOpen] = useState(false);

  function renderCollaborators() {
    const inviteLink = permissionService.can(data, PermissionEnum.projectInviteCollaborator) ? (
      <CustomLink
        className={css.inviteLink}
        leftIcon={<Icon name="plus" />}
        onClick={() => setIsInviteCollaboratorModalOpen(true)}
      >
        Invite
      </CustomLink>
    ) : null;

    const limit = 5;
    const extraCandidatesCount = data.collaborators?.length > limit ? data.collaborators.length - limit : 0;

    if (data.collaborators?.length) {
      return (
        <div className={css.collaboratorsList}>
          {data.collaborators?.slice(0, 5)?.map((item) => {
            return (
              <CompanyImg
                key={item.id}
                className={css.collaboratorItem}
                onClick={() => setIsCollaboratorsModalOpen(true)}
                company={{ imgUrl: item.imgUrl }}
                style={{ width: 24, height: 24, fontSize: 11 }}
                initials={extractInitials([item.firstName, item.lastName].join(" "))}
                title={Formatter.fullName(item)}
              />
            );
          })}
          {!!extraCandidatesCount && (
            <CompanyImg
              className={css.collaboratorItem}
              onClick={() => setIsCollaboratorsModalOpen(true)}
              style={{ width: 24, height: 24, fontSize: 11 }}
              initials={`+${extraCandidatesCount}`}
              title={`+${extraCandidatesCount}`}
            />
          )}
          <span style={{ marginLeft: 12 }}>{inviteLink}</span>
        </div>
      );
    }

    return <p className={css.noData}>This project is not shared with anyone. {inviteLink}</p>;
  }

  return (
    <>
      <div className={css.sectionTitle} onClick={() => setIsCollaboratorsModalOpen(true)}>
        Collaborators{" "}
        {data.ownedBy && data.currentUserRole !== COLLABORATOR_ROLE.OWNER && (
          <span style={{ marginLeft: 10, opacity: 0.5, textDecoration: "none" }}>
            {/* @todo delete */}
            {/*You are <b>{Formatter.humanize(data.currentUserRole)}</b>, Shared by{" "}*/}
            Shared by {Formatter.fullName(data.ownedBy)}
          </span>
        )}
      </div>
      {renderCollaborators()}
      {isInviteCollaboratorModalOpen && (
        <InviteCollaboratorModal
          project={data}
          onClose={() => setIsInviteCollaboratorModalOpen(false)}
          isOpen={isInviteCollaboratorModalOpen}
        />
      )}
      {isCollaboratorsModalOpen && (
        <CollaboratorsModal
          project={data}
          onClose={() => setIsCollaboratorsModalOpen(false)}
          isOpen={isCollaboratorsModalOpen}
          onUpdated={props.onUpdated}
        />
      )}
    </>
  );
};
