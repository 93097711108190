import * as React from "react";
import { Tag } from "../../Tag/Tag";
import { Icon } from "../../IconComponent/Icon";
import { SelectHtml } from "../SelectHtml";
import PropTypes from "prop-types";

import css from "./TagSelectHtml.module.css";

export const TagSelectHtml = (props) => {
  function getOptionByValue(value) {
    return props.options.find((item) => item.value === value);
  }

  const selected = getOptionByValue(props.value);
  if (!selected) return null;

  return (
    <SelectHtml
      component={Tag}
      className={css.container}
      disabled={props.disabled}
      options={props.options}
      style={props.style}
      value={selected.value}
      onChange={(v) => props.onChange(getOptionByValue(props.isIntValue ? parseInt(v) : v))}
    >
      {selected.label}
      {!props.disabled && (
        <div className={css.chevron}>
          <Icon name="chevron-thin" />
        </div>
      )}
    </SelectHtml>
  );
};

TagSelectHtml.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  isIntValue: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ).isRequired,
};
