import * as React from "react";
import { css, cx } from "emotion";

import dribbbleIcon from "../../assets/images/social/dribbble.svg";
import facebookIcon from "../../assets/images/social/facebook.svg";
import githubIcon from "../../assets/images/social/github.svg";
import linkedInIcon from "../../assets/images/social/linkedIn.svg";
import twitterIcon from "../../assets/images/social/twitter.svg";

import "./icons.css";

import { lg } from "../../css/mediaQueries";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";
import { colorToSocialMap, iconToSocialMap } from "../candidate/v2/CandidatePreview/CandidateSocials/CandidateSocials";
import { candidateService } from "../../screens/projects/CandidateService";
import { isValidURL } from "../DiscoverNew/util/util";

const smallIcons = ["twitter", "linkedin", "facebook", "dribbble"];
const imgIcons = {
  dribbble: dribbbleIcon,
  facebook: facebookIcon,
  github: githubIcon,
  linkedin: linkedInIcon,
  twitter: twitterIcon,
};

//TODO: Refactor into a render method that maps over the socialProfile list
function SocialIcons(props) {

  const handleSocialIconClick = (event, network, url) => {
    // prevents opening the detail modal (parent click handler)
    event.stopPropagation();
    if (!props.candidateData.socialProfiles || props.candidateData.socialProfiles.length === 0) return;
    window.open(url, "_blank");

    window.analytics.track("Candidate Social Profile Viewed", {
      candidate_id : props?.candidateData?.candidateId,
      state : props?.candidateData?.location?.region,
      locality : props?.candidateData?.location?.locality,
      is_unlocked : props?.candidateData?.unlockedAt,
      social_platform : network,
      social_profile_url : url,
    });
  };

  const renderSocialIcon = (site, url) => {
    return (
      <div onClick={(e) => handleSocialIconClick(e, site, url)} className={styles.item} key={url}>
        <div style={{ backgroundColor: colorToSocialMap[site] }} className={cx(styles.iconContainer, "icon-background-container", "contact-icon-background-default")}>
          {smallIcons.includes(site) ? (
            <img src={imgIcons[site]} alt="" className={styles.iconImg} />
          ) : (<Icon className={styles.icon} name={iconToSocialMap[site]} />)}
        </div>
      </div>
    )
  };

  return (
    <div
      className={cx(
        "",
        css`
          ${lg} {
            width: 100%;
          }
        `,
      )}
    >
      <div
        className={cx(
          "contact-header-container",
          css`
            display: flex;
            justify-content: space-between;
            flex-flow: row;
            align-items: center;
          `,
        )}
      >
        <div
          className={cx(
            "social-contact-header-text",
            css`
              width: 100%;
              font-weight: bold;
              letter-spacing: -0.81px;
              color: #373737;
              font-size: 16px;
            `,
          )}
        >
          Social Media
        </div>
      </div>
      <div
        className={cx(
          "social-icon-row",
          css`
            padding-left: 10px;
            width: 100%;

            ${lg} {
              padding: 0;
            }
          `,
        )}
      >
        <div
          className={cx(
            "social-icon-row-wrapper",
            css`
              display: flex;
              flex-wrap: wrap;
            `,
            styles.container
          )}
        >
          {candidateService.getSortedSocialMedia(props.candidateData.socialProfiles || [])
            .filter(isValidURL)
            .map(item => {
            return (
              renderSocialIcon(item.site, item.url)
            );
          })}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container : css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    grid-row-gap: 10px;
  `,
  item : css`
    cursor: pointer;
  `,
  icon : css`
    font-size: 0;
    svg path {
      fill: #ffffff;
    }
  `,
  iconContainer : css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  iconImg : css`
    width: 20px;
    height: 20px;
  `,
};

export default SocialIcons;
