import industries from "./industries.json";
import majors from "./majors.json";

// Company and Project Order By Constants
import { DiscoverSearchLocationHelper } from "../components/DiscoverNew/helpers/location/DiscoverSearchLocationHelper";

export const ROUTES = {
  discover: "/discover",
  candidates: "/my-candidates",
  projects: "/projects",
  paymentProcessing: "/payment-processing",
  paymentProcessingFirstPurchase: "/payment-processing/first-purchase",
  paymentProcessingExtraCreditsPurchase: "/payment-processing/extra-credits-purchase",
}

export const COMPANY_NAME_DISPLAY = "Company Name";
export const PROJECT_NAME_DISPLAY = "Project Name";
export const OPPORTUNITY_NAME_DISPLAY = "Opportunity Name";
export const MOST_RECENT_DISPLAY = "Most Recent";

export const COMPANY_NAME_VAL = "company_name";
export const PROJECT_NAME_VAL = "project_name";
export const MOST_RECENT_VAL = "most_recent";

// Company and Project Sort By Constants
export const ASC = "asc";
export const DESC = "desc";

// Project Candidate Stage
export const STAGE_NEW = "New";
export const STAGE_PENDING_CONTACT = "Pending Contact";
export const STAGE_PHONE_VIDEO_INTERVIEW_1 = "Phone/Video Interview";
export const STAGE_PHONE_VIDEO_INTERVIEW_2 = "Phone/Video Interview 2 Maybe I will get the job";

export const STAGE_FACE_INTERVIEW_1 = "Face to Face Interview";
export const STAGE_FACE_INTERVIEW_2 = "Face to Face Interview 2";

export const STAGE_REFERENCE_CHECK = "Reference Check";
export const STAGE_BACKGROUND_CHECK = "Background Check";

export const STAGE_OFFER_OUT = "Offer - Out";
export const STAGE_OFFER_ACCEPTED = "Offer - Accepted";
export const STAGE_OFFER_DECLINED = "Offer - Declined";

export const STAGE_CANDIDATE_REJECTED = "Candidate Rejected";
export const STAGE_NOT_INTERESTED = "Not Interested";

// Modal Navigator
export const CANDIDATE_MODAL_NAME = "CandidateDetailModalBody";
export const COMPANY_MODAL_NAME = "CompanyDetailModalBody";
export const PROJECT_MODAL_NAME = "ViewProjectModalBody";

// My Candidate Sort Options
export const CANDIDATE_FIRST_NAME_DISPLAY = "First Name";
export const CANDIDATE_LAST_NAME_DISPLAY = "Last Name";

export const CANDIDATE_FIRST_NAME_VAL = "first_name";
export const CANDIDATE_LAST_NAME_VAL = "last_name";

// Landing Page
export const RECRUITER = "recruiter";
export const SALES = "sales";

// Candidate Search
export const DEFAULT_SEARCH_QUERY =
  "?social[0]=facebook&social[1]=twitter&social[2]=linkedin&social[3]=dribbble&social[4]=github&social[5]=googleplus";

// Social Activity Section
export const SOCIAL_TWITTER = "twitter";
export const SOCIAL_INSTAGRAM = "instagram";
export const SOCIAL_LINKEDIN = "linkedin";
export const SOCIAL_FACEBOOK = "facebook";

export const TIME_SPENT = "time_spent";
export const TOTAL_TIME = "total_time";
export const TIME_OF_DAY = "time_of_day";
export const AVERAGE_SESSION = "average_session";

export const AVERAGE_SESSION_TIME_HEURISTIC = "min";
export const AVERAGE_TIME_FREQUENCY_HEURISTIC = "day";

export const ACTIVITY_METRICS = [TIME_SPENT, TOTAL_TIME, TIME_OF_DAY, AVERAGE_SESSION];

export const DEFAULT_TOTAL_TIME_DATA = [
  {
    name: "Sunday",
    totalTime: 0,
  },
  {
    name: "Monday",
    totalTime: 0,
  },
  {
    name: "Tuesday",
    totalTime: 0,
  },
  {
    name: "Wednesday",
    totalTime: 0,
  },
  {
    name: "Thursday",
    totalTime: 0,
  },
  {
    name: "Friday",
    totalTime: 0,
  },
  {
    name: "Saturday",
    totalTime: 0,
  },
];

export const DEFAULT_TIME_OF_DAY_DATA = [
  {
    name: "12-3am",
    totalTime: 0,
  },
  {
    name: "3-6am",
    totalTime: 0,
  },
  {
    name: "6-9am",
    totalTime: 0,
  },
  {
    name: "9-12pm",
    totalTime: 0,
  },
  {
    name: "12-3pm",
    totalTime: 0,
  },
  {
    name: "3-6pm",
    totalTime: 0,
  },
  {
    name: "6-9pm",
    totalTime: 0,
  },
  {
    name: "9-12am",
    totalTime: 0,
  },
];

export const ORDERED_SOCIAL_PLATFORMS = ["twitter", "instagram", "linkedin", "facebook"];

// User Constants
export const USER_INACTIVE_MESSAGE = "inactive user subscription";

export const NOTIFICATION_TYPE = {
  success: "success",
  error: "error",
};

export const DISCOVER_SIDEBAR_SIZE = {
  default: "default",
  expanded: "expanded",
};

export const LOCATION_SEARCH_MODE = {
  state: "state",
  city: "city",
  zip: "zip",
};

export const GEOCODE_FIELD_TYPES = {
  city: ["locality", "administrative_area_level_3"],
  state: ["administrative_area_level_1"],
  zip: ["postal_code"],
};

export const DISTANCE_OPTIONS = [
  { value: 1, label: "Exact Location" },
  { value: 5, label: "5 Miles" },
  { value: 10, label: "10 Miles" },
  { value: 20, label: "20 Miles" },
  { value: 30, label: "30 Miles" },
  { value: 50, label: "50 Miles" },
  { value: 75, label: "75 Miles" },
  { value: 100, label: "100 Miles" },
  { value: 200, label: "200 Miles" },
];
export const DEFAULT_DISTANCE_OPTION = DISTANCE_OPTIONS[7];

export const TITLE_MODE_OPTIONS = [
  { value: "allJobs", label: "all jobs" },
  { value: "current", label: "only current jobs" },
  // { value: "currentPriority", label: "prioritise current jobs" },
];
export const TITLE_MODE_DEFAULT_OPTION = TITLE_MODE_OPTIONS[0];

export const COMPANY_NAME_OPTIONS = [
  { value: "allJobs", label: "all jobs" },
  { value: "current", label: "only current jobs" },
];
export const COMPANY_NAME_DEFAULT_OPTION = COMPANY_NAME_OPTIONS[0];

export const SKILLS_MODE_OPTIONS = [
  { value: "bool", label: "bool" },
  { value: "select", label: "select" },
];
export const SKILLS_MODE_DEFAULT_OPTION = SKILLS_MODE_OPTIONS[0];

export const DISCOVER_DEFAULT_COUNTRY_CODE = "US";
export const DISCOVER_DEFAULT_COUNTRY_OPTION = DiscoverSearchLocationHelper.getCountryOptions().find(
  (item) => item.value === DISCOVER_DEFAULT_COUNTRY_CODE
);
export const DISCOVER_DEFAULT_STATE_OPTION = {
  value: "",
  label: "All States",
};

export const INDUSTRY_OPTIONS = industries.map((item) => {
  return {
    ...item,
    label: item.label.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase()),
  };
});

export const AUTOCOMPLETE_OFF = "chatterworks_autocomplete_off";

export const DISCOVER_DEGREE_OPTIONS = [
  { value: "bachelors", label: "Bachelors" },
  { value: "masters", label: "Masters" },
  { value: "doctorates", label: "PhD" },
];

export const COMPANY_SIZE_OPTIONS = [
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001-5000", label: "1001-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: "10001+", label: "10001+" },
];

export const EDUCATION_MAJOR_OPTIONS = majors.map((item) => {
  return {
    ...item,
    label: item.label.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase()),
  };
});

// Organization Subscriptions
export const ORG_SUBSCRIPTION_BETA = "beta";
export const ORG_SUBSCRIPTION_INTERNAL = "internal";
export const ORG_SUBSCRIPTION_UNLIMITED = "unlimited";

export const CSV_DOWNLOAD_SUBSCRIPTIONS = {
  [ORG_SUBSCRIPTION_BETA]: true,
  [ORG_SUBSCRIPTION_INTERNAL]: true,
  [ORG_SUBSCRIPTION_UNLIMITED]: true,
};

export const COLLABORATOR_ROLE = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
};

export const COLLABORATOR_ROLE_OPTIONS = [
  {
    id: COLLABORATOR_ROLE.ADMIN,
    name: "Administrator",
    rights: ["viewing candidates", "adding candidates", "removing candidates", "inviting new collaborators"],
  },
  {
    id: COLLABORATOR_ROLE.EDITOR,
    name: "Editor",
    rights: ["viewing candidates", "adding candidates", "removing candidates"],
  },
  {
    id: COLLABORATOR_ROLE.VIEWER,
    name: "Viewer",
    rights: ["viewing candidates"],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  // { value: 1, label: 1 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  // { value: 150, label: 150 },
  // { value: 200, label: 200 },
];

export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[1].value;

export const COOKIE_NAME = {
  access_token: "access_token",
  expires_in: "expires_in",
  refresh_token: "refresh_token",
  token_type: "token_type",

  low_credits_banner_data: "low_credits_banner_data",
  saved_search_note_dismissed: "saved_search_note_dismissed",

  paused_session_access_token: "paused_session_access_token",
  paused_session_expires_in: "paused_session_expires_in",
  paused_session_refresh_token: "paused_session_refresh_token",
  paused_session_token_type: "paused_session_token_type",
  paused_session_url: "paused_session_url",
  paused_session_user_name: "paused_session_user_name",
  paused_session_user_email: "paused_session_user_email",
};

export const SUPPORT_EMAIL = "support@chatterworks.com";
export const BOUNCER_VALID_STATUS = "deliverable";

export const IMPORTANT_SOCIAL_PROFILES = [
  "linkedin",
  "twitter",
  "facebook",
  "github",
  "stackoverflow",
  "dribbble"
];

export const IMPORTANT_SOCIAL_PROFILES_BOOSTS = {
  linkedin: 0.2,
  twitter: 0.2,
  facebook: 0.2,
  github: 0.23,
  stackoverflow: 0.23,
  dribbble: 0.2,
};

export const INDEX_OPTIONS = []