import { css } from "emotion/macro";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../components/button/CloseButton";
import ModalButtonContainer from "../../../components/button/ModalButtonContainer";
import { Input } from "../../../components/DiscoverNew/Form/Input/Input";
import { modalStyles } from "../../../components/modals/modalStyles";
import { StageFlowInterface, StageFlowCreateDTOInterface } from "./StageFlowService";
import { Select } from "../../../components/DiscoverNew/Form/Select/Select";
import { Checkbox } from "../../../components/DiscoverNew/Form/Checkbox/Chekbox";

type Props = {
  stageFlow?: Partial<StageFlowInterface>
  title: string
  submitTitle: string
  onSubmit: (data: StageFlowCreateDTOInterface) => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
  createFromExistingFlows?: StageFlowInterface[];
}

export const ModalStageFlow: React.FC<Props> = ({ isOpen, onClose, stageFlow, onSubmit, title, submitTitle, createFromExistingFlows }) => {
  const [name, setName] = useState(stageFlow?.name || '');
  const [loading, setLoading] = useState(false);
  const [stageFlowOptions, setStageFlowOptions] = useState<any[]>([]);
  const [stageFlowToCopy, setStageFlowToCopy] = useState<StageFlowInterface | null>(null);
  const [copyStages, setCopyStages] = useState(false);
  const [useDefaultTemplate, setUseDefaultTemplate] = useState(!stageFlow);

  const handleSubmit = async () => {
    const data: StageFlowCreateDTOInterface = {
      name,
    };
    if (copyStages && stageFlowToCopy) {
      data.copyFromStageFlowId = stageFlowToCopy.stageFlowId;
    } else if (!stageFlow && !useDefaultTemplate) {
      data.stages = [];
    }
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const UpdateStageFlowOptions = (stageFlows: StageFlowInterface[]) => {
    if (stageFlows?.length) {
      const options = stageFlows.map(item => {
        if (item.isDefault && !stageFlowToCopy) {
          setStageFlowToCopy(item);
        }
        return {
          value: item.stageFlowId,
          label: item.name,
          isDefault: item.isDefault,
        }
      });
      setStageFlowOptions(options);
    }
  };

  useEffect(() => {
    if (createFromExistingFlows?.length) {
      UpdateStageFlowOptions(createFromExistingFlows);
    }
  }, [createFromExistingFlows]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      backdropClassName={css`
        z-index: 1050 !important;
      `}
      className={css`
        .modal-dialog {
          max-width: 540px;
        }
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>{title}</div>
        <div className={modalStyles.modalFormContainer}>
          <div className={modalStyles.modalInputLabel}>Name</div>
          <Input value={name} onChange={(e: any) => setName(e.target.value)} placeholder="e.g. Software Engineer" />
          {!stageFlow && (
            <>
              <div style={{ height : 20 }} />
              <div className={modalStyles.modalInputLabel}>
                <Checkbox onClick={() => setUseDefaultTemplate(!useDefaultTemplate)} checked={useDefaultTemplate}
                          style={{
                            marginTop : 2,
                            marginRight : 10,
                            display : "flex",
                          }} /><span style={{ cursor : "pointer" }}
                                     onClick={() => {
                                       setUseDefaultTemplate(!useDefaultTemplate);
                                       setCopyStages(false);
                                     }}>Use default template</span>
              </div>
            </>
          )}
          {stageFlowOptions.length > 0 && (
            <fieldset disabled={useDefaultTemplate}
                      style={useDefaultTemplate ? { pointerEvents : "none", opacity : 0.7 } : {}}>
              <div style={{ height : 20 }} />
              <div className={modalStyles.modalInputLabel}>
                <Checkbox onClick={() => setCopyStages((prev) => !prev)} checked={copyStages} style={{
                  marginTop : 2,
                  marginRight : 10,
                  display : "flex"
                }} /><span style={{ cursor : 'pointer' }} onClick={() => setCopyStages((prev) => !prev)}>Copy stages from</span>
              </div>
              <Select
                // @ts-ignore
                isDisabled={!copyStages}
                placeholder="Choose a process"
                options={stageFlowOptions}
                onChange={(v: any) => setStageFlowToCopy(createFromExistingFlows!.find(item => item.stageFlowId === v.value) || null)}
                value={stageFlowOptions.find(item => item.value === stageFlowToCopy?.stageFlowId) || undefined}
              />
            </fieldset>
          )}

        </div>
        <ModalButtonContainer
          title={submitTitle}
          onClick={handleSubmit}
          isWaiting={loading}
          isDisabled={!name || !name.trim()}
        />
      </div>
    </Modal>
  );
};