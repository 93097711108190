import { render } from "@testing-library/react";
import * as React from "react";
import { Checkbox } from "../../DiscoverNew/Form/Checkbox/Chekbox";
import { Radio } from "../../DiscoverNew/Form/Radio/Radio";
import { ButtonHTML } from "../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Dropdown } from "../../DiscoverNew/UI/Dropdown/Dropdown";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";

import css from "./DropdownFilterMultiselect.module.css";

type OptionValue = string | number
type Option = { value: OptionValue, label: React.ReactNode }

type Props = {
  options: Option[]
  value: OptionValue[] | OptionValue
  onChange: (value: OptionValue[] | OptionValue) => void
  labelStyle?: React.CSSProperties
  type?: 'checkbox' | 'radio'
}

export function DropdownFilterMultiselect({ options, value, onChange, labelStyle, type = 'checkbox' }: Props) {

  const toggleCheckboxOption = (option: Option) => {
    value = value as OptionValue[];
    if (value.includes(option.value)) {
      onChange(value.filter(v => v !== option.value));
    } else {
      onChange([...value, option.value]);
    }
  };

  const toggleRadioOption = (option: Option) => {
    value = value as OptionValue;
    onChange(option.value);
  };

  const renderLabel = () => {
    let icon = 'filter';
    if (type === 'checkbox') {
      value = value as OptionValue[];
      return value.length ? "filter-filled" : "";
    }
    return <ButtonHTML style={labelStyle}><Icon name={icon} /></ButtonHTML>
  }

  const renderOption = (option: Option) => {
    if (type === 'checkbox') {
      value = value as OptionValue[];
      return (
        <div key={option.value} className={css.item}>
          <span className={css.itemInner} onClick={() => toggleCheckboxOption(option)}>
            <Checkbox className={css.checkbox} checked={(value as OptionValue[]).includes(option.value)} />
            {option.label}
          </span>
        </div>
      )
    }

    value = value as OptionValue;
    return (
      <div key={option.value} className={css.item}>
          <span className={css.itemInner} onClick={() => toggleRadioOption(option)}>
            <Radio id={option.value.toString()} className={css.checkbox} checked={value === option.value} />
            {option.label}
          </span>
      </div>
    )
  }

  return (
    <Dropdown
      classNames={{ content : css.content }}
      label={renderLabel()}
    >
      {options.map(renderOption)}
      {type != 'radio' && (
        <div className={css.itemClearAll}>
          <ButtonHTML onClick={() => onChange([])} className={css.itemInner}>
            Clear all filters
          </ButtonHTML>
        </div>
      )}
    </Dropdown>
  );
};