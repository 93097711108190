import debounce from "debounce";
import { cx } from "emotion";
import { DateTime } from "luxon";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { useUserData } from "../../../contexts/userContext";
import { StageFlowInterface } from "../../../screens/profile/ProfileStageFlow/StageFlowService";
import { ProjectCandidateInterface, ProjectInterface } from "../../../screens/projects/CandidateService";
import { projectService } from "../../../screens/projects/ProjectService";
import { EntityConnection } from "../../../types";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { injectStyleOverride } from "../../DiscoverNew/DiscoverNewWrapper";
import { Loading } from "../../DiscoverNew/UI/Loading/Loading";
import { ViewMode } from "../../DiscoverNew/UI/ViewModeSwitcher/ViewModeSwitcher";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import { UrlHelper } from "../../DiscoverNew/util/UrlHelper";
import { withConfirm, WithConfirmProps } from "../../hoc/withConfirm";
import CandidateDetailModalWrapper from "../../modals/CandidateDetailModalWrapper";
import { CandidatesExportMode } from "../../ui/ExportSelect/CandidateExportSelect";
import { CandidateBoard } from "./CandidateBoard/CandidateBoard";
import { CandidatesNoData } from "./CandidatesNoData/CandidatesNoData";

import css from "./CandidatesPage.module.css";
import { CandidatesPageHeader } from "./CandidatesPageHeader/CandidatesPageHeader";
import { CandidatesPageSidebar } from "./CandidatesPageSidebar/CandidatesPageSidebar";
import { CandidateTable } from "./CandidateTable/CandidateTable";
import { CandidateTableAll } from "./CandidateTable/CandidateTableAll";
import { ModalAddCandidate } from "./ModalAddCandidate/ModalAddCandidate";
import { ModalCandidatesExportDelayed } from "./ModalCandidatesExportDelayed/ModalCandidatesExportDelayed";

type Props = RouteComponentProps<{ projectId: string }> & WithConfirmProps & {
  // todo
  notifications: any
  // todo
  uiSettings: any
  user: any,
};

type State = {
  selectedCandidate: ProjectCandidateInterface | undefined
  selectedCandidates: Array<ProjectCandidateInterface>
  candidates: EntityConnection<ProjectCandidateInterface> | undefined
  projects: EntityConnection<ProjectInterface> | undefined
  stageFlow: StageFlowInterface | undefined
  project: ProjectInterface | undefined
  search: string
  pageLoading: boolean
  projectLoading: boolean
  candidatesLoading: boolean
  error: string | undefined
  isArchived: boolean
  isNewCandidateModalOpen: boolean
  isCandidatesExportDelayedModalOpen: boolean
  exportData: Partial<{
    url: string
    fileName: string
    allowRestart: boolean
    mode: CandidatesExportMode
  }> | undefined
  viewMode: ViewMode
  pagination: {
    pageSize: number
    currentPage: number
  }
}

const getDefaultPagination = () => {
  return {
    pageSize : DEFAULT_PAGE_SIZE,
    currentPage : 1,
  };
};

class CandidatesPageCore extends React.Component<Props, State> {

  cleanOverride: () => void = () => {
  };

  constructor(props: any) {
    super(props);
    this.state = {
      selectedCandidate : undefined,
      selectedCandidates : [],
      candidates : undefined,
      projects : undefined,
      project : undefined,
      stageFlow : undefined,
      pageLoading : true,
      projectLoading : false,
      candidatesLoading : false,
      isArchived : false,
      isNewCandidateModalOpen : false,
      isCandidatesExportDelayedModalOpen : false,
      exportData : undefined,
      error : undefined,
      search : "",
      viewMode : ViewMode.board,
      pagination : getDefaultPagination(),
    };
  }

  async componentDidMount() {
    this.cleanOverride = injectStyleOverride();

    if (window.location.search.length > 1) {
      await this.restoreStateFromUrl(window.location.search.slice(1));
    }

    const projectId = this.getProjectId();
    const promises: any = [
      projectService.fetchProjects({ limit : 100 }),
      this.fetchCandidates(),
    ];

    if (projectId) {
      promises.push(projectService.fetchProject(projectId));
      promises.push(projectService.fetchProjectStageFlow(projectId, { force : true }));
    }

    try {
      const result = await Promise.all(promises);
      this.setState({
        projects : result[0],
        candidates : result[1],
        project : result[2],
        stageFlow : result[3],
        pageLoading : false,
      });
    } catch (err) {
      this.setState({
        error : `Couldn't load the page. Please try again later.`,
        pageLoading : false,
      });
    }
  }

  componentWillUnmount() {
    this.cleanOverride();
  }

  fetchCandidates = async (projectId = this.getProjectId()) => {
    const { pageSize, currentPage } = this.state.pagination;
    const { isArchived } = this.state;

    let candidates: EntityConnection<ProjectCandidateInterface>;

    const filters = {
      query : this.state.search,
      archived : isArchived ? "archived" : "active",
    };

    const pagination = {
      limit : pageSize,
      skip : (currentPage - 1) * pageSize,
    };

    if (!projectId) {
      candidates = await projectService.fetchMyCandidates({ ...pagination, ...filters });
    } else if (this.state.viewMode === ViewMode.board) {
      candidates = await projectService.fetchProjectCandidates({ projectId, limit : 200, skip : 0, ...filters });
    } else {
      candidates = await projectService.fetchProjectCandidates({ projectId, ...pagination, ...filters });
    }
    return candidates;
  };

  fetchCandidatesAndSetState = async () => {
    this.setState({ candidatesLoading : true });
    const candidates = await this.fetchCandidates();
    this.setState({ candidates, candidatesLoading : false });
  };

  debouncedFetchCandidatesAndSetState = debounce(this.fetchCandidatesAndSetState, 500);

  onNewCandidateClick = () => {
    this.setState({ isNewCandidateModalOpen : true });
  };

  onChangeSearch = (value: string) => {
    this.setState({
      search : value,
      pagination : getDefaultPagination(),
    }, () => {
      this.debouncedFetchCandidatesAndSetState();
      this.saveStateToUrl();
    });
  };

  onChangeViewMode = (viewMode: ViewMode) => {
    if (viewMode === this.state.viewMode) {
      return;
    }

    this.setState({
      viewMode,
      search : "",
      pagination : getDefaultPagination(),
      selectedCandidates : [],
    }, () => {
      this.saveStateToUrl();
      this.fetchCandidatesAndSetState();
    });
  };

  fetchProjectData = async (projectId = this.getProjectId()) => {
    if (!projectId) {
      return;
    }
    const promises = [
      projectService.fetchProject(projectId),
      projectService.fetchProjectStageFlow(projectId, { force : true }),
    ];
    const [project, stageFlow] = await Promise.all(promises);
    return { project, stageFlow };
  };

  onUpdateCandidateStage = async (candidate: ProjectCandidateInterface, toStageId: number) => {
    const projectId = this.getProjectId() as number;
    const stageFlow = this.state.stageFlow!;
    const candidates = this.state.candidates!;
    const stage = stageFlow.stages.find(item => item.stageId === toStageId)!;
    const { notifications } = this.props;

    // optimistic update
    this.setState({
      candidates : {
        ...candidates,
        nodes : candidates.nodes.map(c => {
          if (c.id === candidate.candidateId) {
            return {
              ...c,
              stage : { ...stage },
              lastStageChangedAt : DateTime.fromJSDate(new Date()).minus({ second : 1 }).toISO(),
            };
          }
          return c;
        }),
      },
    });
    notifications.showSuccess('Candidate stage has been updated!');

    try {
      await projectService.updateCandidateStage(projectId, candidate.candidateId, toStageId);
      // todo move to analytics service
      //@ts-ignore
      window.analytics.track("Candidate Stage Updated", {
        candidate_id : candidate.candidateId,
        state : candidate.location?.region,
        locality : candidate.location?.locality,
        is_unlocked : candidate.unlockedAt ?? false,
        stage : stageFlow.stages.find(item => item.stageId === toStageId)!.name,
      });
      const { project, stageFlow : updatedStageFlow } = (await this.fetchProjectData())!;
      this.setState({ project, stageFlow : updatedStageFlow });
    } catch (err) {
      console.error(err);
      notifications.showError("Could not update candidate stage");
      // todo revert optimistic update on error
    }
  };

  onUpdateSelectedCandidatesStage = async (toStageId: number) => {
    const projectId = this.getProjectId() as number;
    const stageFlow = this.state.stageFlow!;
    const candidates = this.state.candidates!;
    const stage = stageFlow.stages.find(item => item.stageId === toStageId)!;
    const { notifications } = this.props;

    // optimistic update
    this.setState({
      selectedCandidates: [],
      candidates : {
        ...candidates,
        nodes : candidates.nodes.map(c => {
          if (this.state.selectedCandidates.find(item => item.id === c.id)) {
            return {
              ...c,
              stage : { ...stage },
              lastStageChangedAt : DateTime.fromJSDate(new Date()).minus({ second : 1 }).toISO(),
            };
          }
          return c;
        }),
      },
    });
    notifications.showSuccess('Candidates stage has been updated!');

    try {
      let promises = []
      for (const c of this.state.selectedCandidates) {
        promises.push(projectService.updateCandidateStage(projectId, c.candidateId, toStageId));
      }
      await promises;
      const { project, stageFlow : updatedStageFlow } = (await this.fetchProjectData())!;
      this.setState({ project, stageFlow : updatedStageFlow });
    } catch (err) {
      console.error(err);
      notifications.showError("Could not update candidates stage");
      // todo revert optimistic update on error
    }
  };

  removeCandidates = async (selectedCandidates: ProjectCandidateInterface[]) => {
    // @todo reset current page if all candidates from the page have been removed
    const { project } = this.state;
    const { notifications, uiSettings, confirm } = this.props;
    const candidatesCount = selectedCandidates.length;
    const candidatesText = candidatesCount > 1 ? uiSettings.mappings.candidates : uiSettings.mappings.candidate;

    let isConfirmed: boolean;
    if (candidatesCount === 1) {
      isConfirmed = await confirm.open({
        destructive : true,
        content : (
          <>
            Are you sure you want to remove <b>{Formatter.fullName(selectedCandidates[0])}</b> from
            the {uiSettings.mappings.project.toLowerCase()}?
          </>
        ),
        confirmButtonTitle : "Remove",
      });
    } else {
      isConfirmed = await confirm.open({
        destructive : true,
        content : (
          <>
            Are you sure you want to remove {candidatesCount} {candidatesText} from
            the {uiSettings.mappings.project.toLowerCase()}?
          </>
        ),
        confirmButtonTitle : "Remove",
      });
    }
    if (!isConfirmed) {
      return;
    }

    try {
      const candidates = this.state.candidates!;
      const candidateIdsMap = selectedCandidates.reduce((acc, item) => {
        acc[item.candidateId] = true;
        return acc;
      }, {} as {
        [key: number]: boolean
      });
      this.setState({
        candidates : {
          ...candidates,
          nodes : candidates!.nodes.filter(c => !candidateIdsMap[c.id]),
        },
      });
      notifications.showSuccess(`${candidatesText} ${candidatesCount > 1 ? "have" : "has"} been removed from the project!`);
      const ids = selectedCandidates.map(item => item.candidateId);
      await projectService.removeCandidatesFromProject(project!.id, ids);
      // @todo analytics service
      this.fetchCandidatesAndSetState();
    } catch (err) {
      notifications.showError(`Couldn't remove the ${candidatesText}!`);
    }
  };

  getProjectId = () => this.props.match?.params?.projectId ? parseInt(this.props.match?.params?.projectId) : null;

  restoreStateFromUrl = (filter: string) => {
    const data = UrlHelper.parseSearch(filter);
    if (!data || !Object.keys(data).length) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const defaultPagination = getDefaultPagination();
      this.setState(
        {
          isArchived : data.isArchived === 'true',
          search : data.search ? data.search as string : "",
          viewMode : data.viewMode ? data.viewMode as ViewMode : ViewMode.board,
          pagination : data.pageSize ? {
            pageSize : parseInt(data.pageSize as string),
            currentPage : parseInt(data.currentPage as string),
          } : defaultPagination,
        },
        () => resolve(null),
      );
    });
  };

  saveStateToUrl() {
    const { pagination : { pageSize, currentPage }, viewMode, search, isArchived } = this.state;
    const filterString = UrlHelper.stringifyParams({ pageSize, currentPage, viewMode, search, isArchived });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  }

  onChangeCurrentPage = (value: string) => {
    this.setState(
      {
        selectedCandidates : [],
        pagination : {
          ...this.state.pagination,
          currentPage : parseInt(value),
        },
      },
      () => {
        this.saveStateToUrl();
        this.fetchCandidatesAndSetState();
      },
    );
  };

  onChangePageSize = (value: string) => {
    this.setState(
      {
        selectedCandidates : [],
        pagination : {
          currentPage : 1,
          pageSize : parseInt(value),
        },
      },
      () => {
        this.saveStateToUrl();
        this.fetchCandidatesAndSetState();
      },
    );
  };

  onChangeProject = async (project: ProjectInterface | null) => {
    let nextState: any = {
      search : "",
      selectedCandidates : [],
      pagination : getDefaultPagination(),
      viewMode : project ? this.state.viewMode : ViewMode.table,
      projectLoading : true,
      candidatesLoading : true,
    };
    this.setState(nextState);

    if (project) {
      const { project : p, stageFlow } = (await this.fetchProjectData(project.id))!;
      nextState.candidates = await this.fetchCandidates(project.id);
      nextState.project = p;
      nextState.stageFlow = stageFlow;
    } else {
      nextState = { ...nextState, project : undefined, stageFlow : undefined };
      nextState.candidates = await this.fetchCandidates(null);
    }
    nextState.projectLoading = false;
    nextState.candidatesLoading = false;

    this.setState(nextState, this.saveStateToUrl);
  };

  onToggleArchivedCandidates = async () => {
    let nextState: any = {
      search : "",
      selectedCandidates : [],
      pagination : getDefaultPagination(),
      viewMode : this.state.viewMode,
      candidatesLoading : true,
      isArchived: !this.state.isArchived,
    };
    this.setState(nextState, async () => {
      nextState.candidates = await this.fetchCandidates();
      nextState.candidatesLoading = false;
      this.setState(nextState, this.saveStateToUrl);
    });
  };

  onOpenProfile = (candidate: ProjectCandidateInterface) => {
    this.setState({ selectedCandidate : candidate });
  };

  onExport = async (mode: CandidatesExportMode, force?: boolean) => {
    const verifiedOnly = mode === CandidatesExportMode.Verified;
    try {
      const result = await projectService.downloadPDF(this.state.project, { verifiedOnly, force });
      if (['delayed', 'ready'].includes(result.status)) {
        this.setState({
          isCandidatesExportDelayedModalOpen : true,
          exportData: result,
        });
      } else {
        alert("Couldn't start download. Please contact with support.");
      }
    } catch (err) {
      alert("Couldn't start download. Please contact with support.");
      console.error("could not download project detail csv", err);
      //Sentry.captureException(err, { user: this.props.user, extra: { location: 'CandidatesPage' }, tags: { candidatesExport : 1 }  });
    }
  };

  onRestartExport = async () => {
    await projectService.downloadPDF(this.state.project, { verifiedOnly: true, force: true });
    this.props.notifications.showSuccess('Your export is being prepared. We will notify you when it is ready.');
    this.setState({
      isCandidatesExportDelayedModalOpen : false,
      exportData: undefined,
    });
  }

  onArchiveCandidate = async (candidate: ProjectCandidateInterface) => {
    const candidates = this.state.candidates!;
    try {
      this.setState({
        candidates : {
          ...candidates,
          nodes : candidates!.nodes.filter(c => c.id !== candidate.candidateId),
        },
      });
      this.props.notifications.showSuccess("Candidate has been archived!");
      await projectService.archiveCandidate(this.getProjectId()!, candidate.candidateId);
      this.fetchCandidatesAndSetState();
    } catch (err) {
      console.error("Couldn't archive candidate", err);
      this.props.notifications.showError("Couldn't archive candidate");
    }
  };

  onArchiveSelectedCandidates = async () => {
    const { pagination, selectedCandidates } = this.state;
    const candidates = this.state.candidates!;
    const selectedCandidatesCount = selectedCandidates.length;
    const selectedIdsMap = this.state.selectedCandidates.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {} as any);
    try {
      this.setState({
        candidates : {
          ...candidates,
          nodes : candidates!.nodes.filter(c => !selectedIdsMap[c.candidateId]),
        },
      });
      const promises = [];
      for (const candidate of selectedCandidates) {
        promises.push(projectService.archiveCandidate(this.getProjectId()!, candidate.candidateId))
      }
      this.setState({ selectedCandidates : [] });
      await Promise.all(promises);
      this.props.notifications.showSuccess("Candidates have been archived!");
      if (pagination.currentPage > 1 && candidates!.pageInfo.pageCount === pagination.currentPage && selectedCandidatesCount === candidates!.pageInfo.total) {
        this.onChangeCurrentPage((pagination.currentPage - 1).toString());
      } else {
        this.fetchCandidatesAndSetState();
      }
    } catch (err) {
      console.error("Couldn't archive candidates", err);
      this.props.notifications.showError("Couldn't archive candidates");
    }
  };


  onUnarchiveCandidate = async (candidate: ProjectCandidateInterface) => {
    const candidates = this.state.candidates!;
    try {
      this.setState({
        candidates : {
          ...candidates,
          nodes : candidates!.nodes.filter(c => c.id !== candidate.candidateId),
        },
      });
      this.props.notifications.showSuccess("Candidate has been unarchived!");
      await projectService.unarchiveCandidate(this.getProjectId()!, candidate.candidateId);
      this.fetchCandidatesAndSetState();
    } catch (err) {
      console.error("Couldn't unarchive candidate", err);
      this.props.notifications.showError("Couldn't unarchive candidate");
    }
  };

  onUnarchiveSelectedCandidates = async () => {
    const { pagination, selectedCandidates } = this.state;
    const candidates = this.state.candidates!;
    const selectedCandidatesCount = selectedCandidates.length;
    const selectedIdsMap = this.state.selectedCandidates.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {} as any);
    try {
      this.setState({
        candidates : {
          ...candidates,
          nodes : candidates!.nodes.filter(c => !selectedIdsMap[c.candidateId]),
        },
      });
      const promises = [];
      for (const candidate of selectedCandidates) {
        promises.push(projectService.unarchiveCandidate(this.getProjectId()!, candidate.candidateId))
      }
      this.setState({ selectedCandidates : []})
      await Promise.all(promises);
      this.props.notifications.showSuccess("Candidates have been unarchived!");

      if (pagination.currentPage > 1 && candidates!.pageInfo.pageCount === pagination.currentPage && selectedCandidatesCount === candidates!.pageInfo.total) {
        this.onChangeCurrentPage((pagination.currentPage - 1).toString());
      } else {
        this.fetchCandidatesAndSetState();
      }
    } catch (err) {
      console.error("Couldn't unarchive candidates", err);
      this.props.notifications.showError("Couldn't unarchive candidates");
    }
  };

  renderCandidates = () => {
    const {
      isArchived,
      project,
      projectLoading,
      candidatesLoading,
      search,
      viewMode,
      stageFlow,
      candidates,
      pagination,
    } = this.state;

    if (projectLoading) {
      return <div className={css.contentRight}><Loading /></div>;
    }

    let candidatesContent = null;

    if (candidatesLoading) {
      candidatesContent = <Loading />;
    } else if (!candidatesLoading && !candidates?.nodes.length && !isArchived) {
      const urlParams = UrlHelper.parseSearch(window.location.search.slice(1));
      const urlSearch = urlParams?.search as string || "";
      if (urlSearch) {
        candidatesContent = <CandidatesNoData search={urlSearch} />;
      } else {
        candidatesContent = <CandidatesNoData />;
      }
    } else if (!this.getProjectId()) {
      candidatesContent = (
        <CandidateTableAll
          candidates={candidates!}
          onOpenProfile={this.onOpenProfile}
          pagination={{
            pageCount : candidates!.pageInfo.pageCount,
            pageSize : pagination.pageSize,
            currentPage : pagination.currentPage,
            onChangeCurrentPage : this.onChangeCurrentPage,
            onChangePageSize : this.onChangePageSize,
          }}
        />
      );
    } else if (viewMode === ViewMode.table) {
      candidatesContent = (
        <CandidateTable
          isArchived={isArchived}
          project={project!}
          selectedCandidates={this.state.selectedCandidates}
          setSelectedCandidates={c => this.setState({ selectedCandidates : c })}
          stageFlow={stageFlow!}
          candidates={candidates!}
          onExport={this.onExport}
          onArchiveCandidate={this.onArchiveCandidate}
          onUnarchiveCandidate={this.onUnarchiveCandidate}
          onRemoveCandidate={(c) => this.removeCandidates([c])}
          onUpdateCandidateStage={this.onUpdateCandidateStage}
          onOpenProfile={this.onOpenProfile}
          onToggleArchivedCandidates={this.onToggleArchivedCandidates}
          pagination={{
            pageCount : candidates!.pageInfo.pageCount,
            pageSize : pagination.pageSize,
            currentPage : pagination.currentPage,
            onChangeCurrentPage : this.onChangeCurrentPage,
            onChangePageSize : this.onChangePageSize,
          }}
        />
      );
    } else {
      candidatesContent = (
        <CandidateBoard
          isArchived={isArchived}
          onExport={this.onExport}
          onOpenProfile={this.onOpenProfile}
          onRemoveCandidate={(c) => this.removeCandidates([c])}
          onToggleArchivedCandidates={this.onToggleArchivedCandidates}
          onUpdateCandidateStage={this.onUpdateCandidateStage}
          onArchiveCandidate={this.onArchiveCandidate}
          onUnarchiveCandidate={this.onUnarchiveCandidate}
          stageFlow={stageFlow!}
          candidates={candidates!}
          project={project!}
        />
      );
    }

    return (
      <div className={css.contentRight}>
        <CandidatesPageHeader
          project={project}
          stageFlow={stageFlow!}
          className={css.contentHeader}
          searchValue={search}
          viewMode={viewMode}
          selectedCandidates={this.state.selectedCandidates}
          onSearchChange={this.onChangeSearch}
          onNewCandidateClick={this.onNewCandidateClick}
          onViewModeChange={this.onChangeViewMode}
          onArchiveSelected={this.onArchiveSelectedCandidates}
          onUnarchiveSelected={this.onUnarchiveSelectedCandidates}
          onRemoveSelected={() => this.removeCandidates(this.state.selectedCandidates)}
          onChangeStageSelected={this.onUpdateSelectedCandidatesStage}
        />
        {candidatesContent}
      </div>
    );
  };

  render() {
    const { projects, pageLoading, viewMode, selectedCandidate, error, project, isNewCandidateModalOpen, isCandidatesExportDelayedModalOpen } = this.state;

    if (pageLoading) {
      return <Loading />;
    }

    if (error) {
      return <h3 style={{ padding : "100px 0", textAlign : "center", fontSize : 20 }}>{error}</h3>;
    }

    if (!projects) {
      return null;
    }

    return (
      <div className={cx(css.holder, viewMode === ViewMode.table && css.isTableView)}>
        <header className={css.header}>
          <h1>Candidates</h1>
        </header>
        <div className={css.content}>
          <CandidatesPageSidebar project={project} projects={projects} onProjectClick={this.onChangeProject} />
          {this.renderCandidates()}
        </div>
        {!!selectedCandidate && (
          // @ts-ignore
          <CandidateDetailModalWrapper
            uiSettings={this.props.uiSettings}
            initialPage="detail"
            toggleModalWindow={() => this.setState({ selectedCandidate : undefined })}
            viewModalOpenState={true}
            candidate={selectedCandidate}
          />
        )}
        {isNewCandidateModalOpen && (
          <ModalAddCandidate onClose={() => this.setState({ isNewCandidateModalOpen : false })} />
        )}
        {isCandidatesExportDelayedModalOpen && (
          <ModalCandidatesExportDelayed
            onClose={() => this.setState({ isCandidatesExportDelayedModalOpen : false })}
            allowRestart={!this.state.exportData?.allowRestart}
            onRestart={this.onRestartExport}
          />
        )}
      </div>
    );
  }
}

// todo
// @ts-ignore
export const CandidatesPage: React.FC<RouteComponentProps<{ projectId: string }>> = withConfirm((props: any) => {
  const userData = useUserData();
  const notifications = useNotifications();
  return <CandidatesPageCore
    {...props}
    confirm={props.confirm}
    notifications={notifications}
    user={userData.state.user}
    uiSettings={userData.state.user.uiSettings}
  />;
});