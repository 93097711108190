import { cx } from "emotion";
import * as React from "react";

import css from "./Button.module.css";
import styles from "./NewButton.module.css";

type Props = {
  theme?: "default" | "primary" | "outline" | "danger" | "dangerOutlined"
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  loading?: boolean
  component?: any
  onClick : (e: React.ChangeEvent) => void
  style?: React.CSSProperties
} & any

export const Button = (props: Props) => {
  const {
    leftIcon,
    rightIcon,
    children,
    loading,
    disabled,
    className,
    type,
    component,
    theme = "default",
    isNewStyle,
    ...rest
  } = props;

  const classesObject = isNewStyle ? styles : css;
  const Component = component || "button";

  return (
    <Component
      disabled={disabled || loading}
      className={cx(classesObject.button, classesObject[theme], className)}
      type={type || "button"}
      {...rest}
    >
      {!!leftIcon && <div className={classesObject.leftIcon}>{leftIcon}</div>}
      {children}
      {!!rightIcon && <div className={classesObject.rightIcon}>{rightIcon}</div>}
    </Component>
  );
};
