import * as React from "react";
import { Tag } from "../../Tag/Tag";
import { Icon } from "../../IconComponent/Icon";
import { SelectHtml } from "../SelectHtml";
import PropTypes from "prop-types";

import css from "./FilterSelectHtml.module.css";

export const FilterSelectHtml = (props) => {
  function getOptionByValue(value) {
    return props.options.find((item) => item.value === value);
  }

  return (
    <div className={css.container} style={props.style}>
      {!!props.label && <div className={css.label}>{props.label}</div>}
      <SelectHtml
        component={Tag}
        className={css.selectContainer}
        options={props.options}
        style={props.style}
        value={props.value.value}
        onChange={(v) => props.onChange(getOptionByValue(props.isIntValue ? parseInt(v) : props.isBoolValue ? v === "true" : v))}
      >
        {props.value.label}
        <div className={css.chevron}>
          <Icon name="chevron-thin" />
        </div>
      </SelectHtml>
    </div>
  );
};

FilterSelectHtml.propTypes = {
  label: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  isIntValue: PropTypes.bool,
  isBoolValue: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ).isRequired,
};
