import { useStripe } from "@stripe/react-stripe-js";
import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ROUTES } from "../../../constants/constants";
import { useUserData } from "../../../contexts/userContext";
import { billingService } from "../../../services/BillingService";
import Counter from "../../Counter/Counter";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import modal from "../modal.module.css";
import styles from "../PurchasePlanModal/PlanStep/PlanStep.module.css";
import classes from "../PurchasePlanModal/PurchasePlanModal.module.css";
import purchaseCreditsModalCss from "./PurchaseCreditsModal.module.css";

type Props = {
  onClose: () => void
  onSuccess?: () => void
}

const PurchaseCreditsModal = ({ onClose, onSuccess }: Props) => {
  const [creditsCount, setCreditsCount] = useState(1000);
  const [loading, setLoading] = useState(false);
  const userData = useUserData();
  const notifications = useNotifications();
  const stripe = useStripe();

  const getPricingBreakdown = () => {
    return {
      totalToPay : creditsCount * 0.05,
    };
  };

  const breakdown = getPricingBreakdown();

  const onSubmitForm = async () => {
    setLoading(true);
    try {
      const res = await billingService.purchaseCredits(creditsCount);
      const { clientSecret } = res.data.paymentIntent;
      const { error } = await stripe!.confirmPayment({
        clientSecret,
        redirect: "if_required",
        confirmParams : {
          return_url : window.location.origin + ROUTES.paymentProcessingExtraCreditsPurchase,
        },
      });
      if (error) {
        throw error;
      }
      await new Promise(resolve => {
        setTimeout(() => {
          userData.fetchUserAndUsageData();
          notifications.showSuccess(`Extra credits have been added to your account!`);
          onSuccess && onSuccess();
          resolve(null);
        }, 1500);
      })
    } catch (err) {
      console.log(err);
      notifications.showError(`Couldn't purchase extra credits`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal backdropClassName={classes.purchaseModalBackdrop} className={css`
        .modal-content {
          min-height: initial;
        }
      `} show={true} onHide={onClose} centered>
      <Icon name="fa-xmark" className={modal.close} onClick={onClose} />
      <div className={purchaseCreditsModalCss.body}>
        <div className={modal.info} style={{ margin : 0 }}>
          <h3 className={modal.title}>Extra Credits</h3>
          <p className={modal.subtitle}>Choose how many single-use credits you need</p>
          <Counter label="single use credits" min={1000} max={10000} step={1000} value={creditsCount}
                   onChange={setCreditsCount}
                   inputClassName={classes.counterCreditsInput}
                   className={classes.counter} readOnly />
          <div className={styles.breakdown}>
            <div className={styles.breakdownItem}>
              <div className={styles.breakdownItemTitle}>
                Total Price
              </div>
              <div>
                <div className={styles.breakdownItemValue}>
                  ${breakdown.totalToPay.toLocaleString()}
                </div>
                <div className={styles.subtext}>
                  $50 per 1,000 credits x {(creditsCount / 1000).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.navigation}>
            <Button className={purchaseCreditsModalCss.button} disabled={loading} onClick={onSubmitForm}>
              {loading ? 'Processing...' : <>Purchase for ${breakdown.totalToPay.toLocaleString()}</>}
            </Button>
          </div>
          <div className={purchaseCreditsModalCss.subtext}>
            your default payment method<br/> will be charged immediately
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PurchaseCreditsModal;
