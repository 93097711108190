import queryString from "query-string";

class UrlHelper {
  static parseSearch = (search) => {
    if (!search) {
      return null;
    }
    try {
      return queryString.parse(search);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  static stringifyParams = (params) => {
    if (!params) {
      return "";
    }
    try {
      return queryString.stringify(params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };
}

/**
 * @param route {string}
 * @param params {object}
 * @returns {string}
 */
export function urlTo(route, params = {}) {
  let routeWithParams = route;
  ["id", "slug", "key", "title", "type", "identifier", "hash"].forEach((field) => {
    if (params[field] !== undefined) {
      routeWithParams = routeWithParams.replace(`:${field}`, params[field]);
    }
  });
  return routeWithParams;
}

/**
 * @param route {string}
 * @param params {object}
 * @returns {string}
 */
export function backendUrlTo(route, params = {}) {
  const backendBase = (process.env.API_ENDPOINT || "").substr(0, process.env.API_ENDPOINT.lastIndexOf("/"));
  return backendBase + urlTo(route, params);
}

/**
 * @param route {string}
 * @returns {string}
 */
export function landingUrlTo(route) {
  const base = process.env.REACT_APP_LANDING_URL;
  return base + route;
}

export { UrlHelper };
