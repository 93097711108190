import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import ModalInputField from "../input/ModalInputField";
import { Loading } from "../DiscoverNew/UI/Loading/Loading";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { AUTOCOMPLETE_OFF } from "../../constants/constants";
import { adminService } from "../../services/AdminService";
import { Checkbox } from "../DiscoverNew/Form/Checkbox/Chekbox";

export function AdminOrganizationModal({ onClose, organizationId, onSuccess }) {
  const isUnmounted = useRef(false);
  const notifications = useNotifications();
  const [formError, setFormError] = useState();
  const [initializing, setInitializing] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState();

  const [values, setValues] = useState({
    name: "",
    isTrialAvailable: false,
    stripeCustomerId: "",
  });

  const onChange = (value) => {
    const fieldName = Object.keys(value)[0];
    setErrors({
      ...errors,
      [fieldName]: null,
    });
    const nextValues = {
      ...values,
      ...value,
    };
    setValues(nextValues);
  };

  async function handleSubmit() {
    setSubmitting(true);
    try {
      await adminService.updateOrganization(organizationId, {
        name: values.name,
        isTrialAvailable: values.isTrialAvailable,
        stripeCustomerId: values.stripeCustomerId,
      });
      notifications.showSuccess(`Organization has been updated!`);
      onSuccess();
    } catch (err) {
      notifications.showError(`Couldn't update the organization!`);
    }
    if (!isUnmounted.current) {
      setSubmitting(false);
      onClose();
    }
  }

  useEffect(() => {
    async function fetchUser() {
      setInitializing(true);
      try {
        const organization = await adminService.fetchOrganizationById(organizationId);
        setValues({
          name: organization.name,
          isTrialAvailable: organization.isTrialAvailable,
          stripeCustomerId: organization.stripeCustomerId,
        });
        setInitializing(false);
      } catch (err) {
        console.error("Couldn't fetch the organization", err);
        setFormError(err);
        setInitializing(false);
      }
    }
    fetchUser();
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>Edit Organization</div>
        {initializing ? (
          <Loading />
        ) : formError ? (
          formError
        ) : (
          <>
            <div className={modalStyles.modalFormContainer}>
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>Name</div>}
                placeholder="Enter name"
                autocomplete={AUTOCOMPLETE_OFF}
                onChange={(e) => onChange({ name: e.target.value })}
                value={values.name}
              />
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>Stripe Customer ID</div>}
                placeholder="Enter Stripe Customer Id"
                autocomplete={AUTOCOMPLETE_OFF}
                onChange={(e) => onChange({ stripeCustomerId: e.target.value })}
                value={values.stripeCustomerId}
              />
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>Trial Available</div>}
                onComponentClick={(e) => onChange({ isTrialAvailable: !values.isTrialAvailable })}
                checked={values.isTrialAvailable}
                component={Checkbox}
              />
            </div>
            <ModalButtonContainer title={`Update Organization`} onClick={handleSubmit} isWaiting={submitting || initializing} />
          </>
        )}
      </div>
    </Modal>
  );
}