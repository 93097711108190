import * as React from "react";
import { cx } from "emotion";
import PropTypes from "prop-types";

import css from "./Tabs.module.css";

export const Tabs = ({ className, children, style }) => {
  return <div className={cx(css.container, className)} style={style}>{children}</div>;
};

Tabs.Tab = (props) => {
  const { active, disabled, component, className, children, ...rest } = props;
  const Component = component || "button";

  return (
    <Component type="button" className={cx(css.tab, active && css.tabActive, disabled && css.tabDisabled, className)} {...rest}>
      {children}
    </Component>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.any,
};

Tabs.Tab.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node,
};
