import * as React from "react";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { useAuthController } from "../../../contexts/AuthProvider";
import { errorMessages } from "../../../utils/messages";
import { ModalStageFlow } from "./ModalStageFlow";
import { StageFlowCreateDTOInterface, StageFlowInterface, stageFlowService } from "./StageFlowService";

type Props = {
  onCreated: (id: number) => void
  onClose: () => void
}

export const ModalCreateStageFlow = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [stageFlows, setStageFlows] = React.useState<StageFlowInterface[]>([]);
  const notifications = useNotifications();

  const authController = useAuthController();
  const { user } = authController;

  function fetchData() {
    setLoading(true);
    stageFlowService.fetchAll(user.organizationId, {
      archived: 'all',
      limit: 100,
      skip: 0,
    })
      .then((stageFlows) => {
        setStageFlows(stageFlows);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const onCreate = async (data: StageFlowCreateDTOInterface) => {
    try {
      let id;
      if (!data.copyFromStageFlowId) {
        id = await stageFlowService.create(user.organizationId, data);
      } else {
        id = await stageFlowService.duplicate(user.organizationId, data.copyFromStageFlowId, data);
      }
      props.onCreated(id);
      notifications.showSuccess("Interview Process has been created!");
    } catch (err) {
      if ((err as any).response?.data?.message?.includes('duplicate') || (err as any).response?.data?.message?.includes('exists')) {
        notifications.showError(errorMessages.INTERVIEW_PROCESS_NAME_EXISTS);
      } else {
        notifications.showError("Couldn't create the Interview Process");
      }
    }
  };

  return (
    <ModalStageFlow
      title="New Interview Process"
      submitTitle="Create"
      onSubmit={onCreate}
      createFromExistingFlows={stageFlows}
      isOpen={true}
      onClose={props.onClose}
    />
  )
}