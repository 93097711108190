import React, { useState } from "react";
import { cx, css } from "emotion/macro";

// Contexts
import { useUserData } from "../../contexts/userContext";

// Styles
import "../mobile/Mobile.css";
import { lg, lgU } from "../../css/mediaQueries";

// Assets
import editPencilIcon from "../../assets/images/edit-pencil-icon.svg";
import { Formatter } from "../DiscoverNew/util/Formatter";

function NoteDetail({ note, onEditNoteSaved, isStaticNote = false }) {
  const {
    state: { user },
  } = useUserData();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editNoteContent, setEditNoteContent] = useState(note?.note || "");

  // Only allowed notes created by current user to be edited.
  const canEditNote = !!(user && note?.createdBy && user?.id === note?.createdBy?.id);

  // If createdAt and updatedAt are different, we have edited the note in the past
  const isPreviouslyEdited = note?.createdAt !== note?.updatedAt;

  const date = new Date(note?.createdAt);

  const dateFormatted = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const timeFormatted = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", timeZoneName: "short" });

  const toggleEditMode = () => {
    // If we cancel editing the note, set to original value
    if (isEditMode) {
      setEditNoteContent(note?.note);
    }
    setIsEditMode(!isEditMode);
  };

  const saveEditedNote = () => {
    if (!editNoteContent || !onEditNoteSaved) return;
    // Prevent uncessary save if note hasn't changed
    if (editNoteContent === note?.note) {
      toggleEditMode();
      return;
    }
    const editNoteObj = {
      noteId: note?.noteId,
      note: editNoteContent,
    };
    onEditNoteSaved(editNoteObj);
  };

  return (
    <div
      className={cx("note-detail-container", styles.noteDetailContainer, {
        [css`
          ${lgU} {
            &:hover {
              .note-edit-pencil-container {
                display: block;
                padding-right: 10px;
              }
            }
          }
        `]: canEditNote && !isEditMode,
      })}
      key={note?.noteId}
    >
      <div className={styles.noteHeaderContainer}>
        <div className={cx(styles.noteTitleContainer)}>
          <div className={styles.noteTitle}>
            <span className={styles.noteDate}>{dateFormatted}</span>
            <span className={styles.noteTime}>&nbsp;@ {timeFormatted}</span>
            {isPreviouslyEdited && <span className={styles.editedText}>(Edited)</span>}
          </div>
          <span className={styles.noteAuthor}>{Formatter.fullName(note?.createdBy)}</span>
        </div>
        {!isStaticNote && (
          <div
            className={cx(
              "note-edit-pencil-container",
              styles.noteEditPencilContainer,
              css`
                ${lgU} {
                  display: none;
                }
              `,
              {
                [css`
                  ${lg} {
                    display: none;
                  }
                `]: !canEditNote,
              }
            )}
          >
            <img
              onClick={toggleEditMode}
              src={editPencilIcon}
              alt="edit-pencil"
              className={cx(
                "note-edit-pencil",
                styles.noteEditPencil,
                css`
                  cursor: pointer;
                  width: 25px;
                `
              )}
            />
          </div>
        )}
      </div>
      {/* Edit Note Section */}
      {!isEditMode ? (
        <div className={cx("note-content", styles.noteContent)}>{note?.note}</div>
      ) : (
        <div
          className={cx(
            styles.editNoteContainer,
            css`
              padding-right: 10px;
            `
          )}
        >
          <textarea
            name="createNote"
            id="create-note-text-area"
            cols={10}
            rows={10}
            onChange={(e) => setEditNoteContent(e.target.value)}
            style={{ marginTop: "initial" }}
            autoFocus={true}
            value={editNoteContent}
            onFocus={(e) => {
              const val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
          ></textarea>
          <div className={"create-note-save-button-container"} style={{ marginBottom: "initial" }}>
            <span
              className={cx(
                css`
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.25px;
                  outline: none;
                  cursor: pointer;
                  color: #9b9b9b;
                  margin-right: 10px;
                  &:hover {
                    color: #787878;
                  }
                `
              )}
              onClick={toggleEditMode}
            >
              Cancel
            </span>
            <span
              className={`create-note-save-button ${editNoteContent === "" ? "" : " active"}`}
              onClick={saveEditedNote}
            >
              Save
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  noteDetailContainer: css`
    width: 100%;
    display: flex;
    flex-flow: column;
    border-bottom: 1px solid #d8d8d8;
    padding: 22px 0px;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      border-bottom: none;
    }
  `,
  noteHeaderContainer: css`
    margin-bottom: 7px;
    display: flex;
    flex-flow: row;
    font-size: 14px;
    justify-content: space-between;
  `,
  noteTitleContainer: css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  `,
  noteTitle: css`
    display: flex;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #9b9b9b;
    align-items: center;
  `,
  editedText: css`
    display: flex;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #9b9b9b;
    margin-left: 4px;
    font-size: 11px;
    font-weight: bold;
  `,
  noteDate: css`
    font-family: Montserrat-Bold !important;
    font-weight: bold;
    color: #373737;
    text-transform: uppercase;
  `,
  noteTime: css`
    font-weight: bold;
  `,
  noteAuthor: css`
    line-height: 1.5;
    letter-spacing: -0.61px;
    color: #9b9b9b;
    font-size: 12px;
    margin-top: 1px;
    font-weight: bold;
    text-transform: uppercase;
  `,
  noteContent: css`
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #373737;
    overflow-wrap: break-word;
    font-weight: bold;
  `,
};

export default NoteDetail;
