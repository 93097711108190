import React from "react";
import PropTypes from "prop-types";
import { cx } from "emotion";
import { SelectHtml } from "../SelectHtml";

import css from "./UnderlinedSelectHtml.module.css";

export function UnderlinedSelectHtml(props) {
  const { label, isDimmed, className, ...rest } = props;
  return (
    <SelectHtml className={cx(className, { [css.dimmedLabel]: props.isDimmed })} {...rest}>
      <span className={css.label}>{props.label}</span>
    </SelectHtml>
  );
}

UnderlinedSelectHtml.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  isDimmed: PropTypes.bool,
};
