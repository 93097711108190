import React from "react";
import { cx } from "emotion";
import PropTypes from "prop-types";

import css from "./SelectHtml.module.css";

export function SelectHtml(props) {
  const Component = props.component || "div";
  return (
    <Component className={cx(css.holder, props.className)} style={props.style}>
      {props.children}
      <select disabled={props.disabled} className={css.select} onChange={(e) => props.onChange(e.target.value)} value={props.value}>
        {props.options.map((item) => {
          return (
            <option key={item.value} value={item.value} disabled={item.disabled} hidden={item.hidden}>
              {item.label}
            </option>
          );
        })}
      </select>
    </Component>
  );
}

SelectHtml.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  component: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
};
