import { Checkbox, Form, Input, Select } from "antd";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { useAuthController } from "../../../contexts/AuthProvider";
import styles from "../../../screens/profile/ProfileBilling/BillingMember/BillingMember.module.css";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";
import { projectService } from "../../../screens/projects/ProjectService";
import { CurrentPlanInterface } from "../../../services/BillingService";
import {
  ORGANIZATION_MEMBER_ROLES,
  ORGANIZATION_ROLE_OPTIONS,
  OrganizationMemberRole,
  organizationService,
} from "../../../services/OrganizationService";
import { EMAIL_MASK } from "../../../utils/constants";
import { apiErrorMessages, errorMessages } from "../../../utils/messages";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import { Alert } from "../../ui/Alert/Alert";
import modal from "../modal.module.css";
import { modalStyles } from "../modalStyles";

type Props = {
  onClose: () => void
  onSubmitSuccess: () => void
  onPurchaseSeat: () => void
}

export const InviteMemberModal = (props: Props) => {
  const [values, setValues] = React.useState<{
    email: string,
    role: OrganizationMemberRole,
    projects: any[],
    viewOnly: boolean
  }>({
    email: '',
    role : OrganizationMemberRole.Admin,
    projects: [],
    viewOnly: false,
  });
  const ref = React.useRef<any>();
  const [projects, setProjects] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const notifications = useNotifications();
  const authController = useAuthController();
  const currentPlan = (authController.user as any).currentPlan as CurrentPlanInterface;
  const isTrial = useAuthController().user.currentPlan.planInfo.name === "trial";

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await organizationService.inviteMember({
        role: values.role,
        email: values.email,
        projectIDs : values.projects,
        isActive : !values.viewOnly,
      });
      notifications.showSuccess("A new member has been invited!");
      props.onSubmitSuccess();
    } catch (err) {
      let errorMessage = errorMessages.INVITE_MEMBER_GENERAL;
      if ((err as any).response?.data?.message === apiErrorMessages.INVITE_MEMBER_ALREADY_IN_ORG) {
        errorMessage = errorMessages.INVITE_MEMBER_ALREADY_IN_ORG;
      }
      if ((err as any).response?.data?.message === apiErrorMessages.NOT_BUSINESS_EMAIL) {
        errorMessage = errorMessages.NOT_BUSINESS_EMAIL;
      }
      ref.current.setFields([{ name : "email", errors : [errorMessage] }]);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    projectService.fetchProjects({ ownership : "organization" })
      .then(r => {
        setProjects(r.nodes.map(item => {
          return ({
            value : item.id,
            label : item.name,
          });
        }));
      });
  }, []);

  const allowProjectSelection = [OrganizationMemberRole.Member].includes(values.role);
  const requiresEmptyPaidSeat = !isTrial && !values.viewOnly;
  const isEmailValid = !!values.email?.match(EMAIL_MASK);
  const hasEmptySeat = currentPlan.seats.count > currentPlan.seats.used;
  const canEditBilling = permissionService.can(authController.user, PermissionEnum.editOrganizationBilling);

  const ownerName = Formatter.fullName(authController.user.organizationV2.ownerRef)

  return (
    <Modal show={true} onHide={props.onClose} centered>
      <Icon name="fa-xmark" className={modal.close} onClick={props.onClose} />
      <div className={modal.body}>
        <div className={modal.info}>
          <h3 className={modal.title}>Invite Member</h3>
        </div>
        <div className={modalStyles.modalFormContainer}>
          <Form
            className={styles.form}
            initialValues={values}
            ref={ref}
            layout="vertical"
            requiredMark={false}
            onValuesChange={(changedValues) => setValues({ ...values, ...changedValues })}
            onFinish={onSubmit}
          >
            <Form.Item
              label={<span className={styles.formLabel}>Member's email</span>}
              name="email"
              hasFeedback
              rules={[
                {
                  pattern : EMAIL_MASK,
                  message : errorMessages.INVALID_EMAIL,
                  required : true,
                },
              ]}
            >
              <Input className={styles.formControl} type="email" placeholder="Enter the member's email" />
            </Form.Item>
            <Form.Item label={<span className={styles.formLabel}>Member's role</span>} name="role">
              <Select
                className={styles.select}
                options={ORGANIZATION_ROLE_OPTIONS.filter(item => item.value !== OrganizationMemberRole.Owner)}
                suffixIcon={<Icon name="fa-chevron" className={styles.chevronIcon} />}
                popupClassName={styles.options}
              />
            </Form.Item>
            <Form.Item name="viewOnly" valuePropName="checked">
              <Checkbox
                className={styles.select}
              ><span className={styles.formLabel}>View only</span></Checkbox>
            </Form.Item>
            {allowProjectSelection && (
              <Form.Item label={<span
                className={styles.formLabel}>Invite to projects {(!values.viewOnly || (values.viewOnly && OrganizationMemberRole.Member !== values.role)) &&
                <small>(optional)</small>}</span>} name="projects" rules={[
                {
                  required : values.viewOnly && OrganizationMemberRole.Member === values.role,
                  message : "Please select projects",
                }]}>
                <Select
                  mode="multiple"
                  placeholder="Select projects"
                  className={styles.select}
                  options={projects}
                  suffixIcon={<Icon name="fa-chevron" className={styles.chevronIcon} />}
                  popupClassName={styles.options}
                />
              </Form.Item>
            )}
            {!!ORGANIZATION_MEMBER_ROLES[values.role] && (
              <div className={styles.text} style={{ margin : 0, minHeight : "unset" }}>
                <p>
                  <span>{ORGANIZATION_MEMBER_ROLES[values.role].label}</span>'s permissions
                </p>
                <ul>
                  {ORGANIZATION_MEMBER_ROLES[values.role].permissions.map((permission) => (
                    <li key={permission}>{permission}</li>
                  ))}
                </ul>
              </div>
            )}
            <div style={{ marginTop : 20 }}>
              {!requiresEmptyPaidSeat ? (
                <div style={{ display : "flex", alignItems : "center", justifyContent : "center" }}>
                  <Button
                    disabled={!isEmailValid || (!values.projects?.length && values.viewOnly && OrganizationMemberRole.Member === values.role) || loading}
                    type="primary" style={{ width : 200 }}>
                    {loading ? "Processing..." : "Invite"}
                  </Button>
                </div>
              ) : requiresEmptyPaidSeat && hasEmptySeat ? (
                <div style={{ display : "flex", alignItems : "center", justifyContent : "center" }}>
                  <Button
                    disabled={!isEmailValid || (!values.projects?.length && values.viewOnly && OrganizationMemberRole.Member === values.role) || loading}
                    type="primary" style={{ width : 200 }}>
                    {loading ? "Processing..." : "Invite"}
                  </Button>
                </div>
              ) : (
                canEditBilling ? (
                  <Alert>
                    You need more paid seats to invite this new user. <br /><br />
                    Please <span className="alert__link" onClick={props.onPurchaseSeat}>click here</span> to purchase more seats.
                  </Alert>
                ) : (
                  <Alert>
                    You need more paid seats to invite this new user. You will need to contact your account owner {ownerName}, who can to purchase more seats.
                  </Alert>
                )
              )}
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};