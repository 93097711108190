import React, { useState } from "react";
import { useNotifications } from "../../../../../components/DiscoverNew/contexts/Notification/notifications";
import NewPaymentMethodModal from "../../../../../components/modals/NewPaymentMethodModal/NewPaymentMethodModal";

import SectionTitle from "../../../../../components/SectionTitle/SectionTitle";
import Divider from "../../../../../components/ui/Divider/Divider";
import { billingService, CurrentPlanInterface, PaymentMethodInterface } from "../../../../../services/BillingService";
import PaymentMethods from "../PaymentMethods/PaymentMethods";

import styles from "./PaymentSettings.module.css";

type Props = {
  currentPlan: CurrentPlanInterface
  paymentMethods: PaymentMethodInterface[]
  fetchData: () => Promise<void>
}

const PaymentSettings = (props: Props) => {
  const [isAddCardModalVisible, setIsAddPaymentMethodModalOpen] = useState(false);
  const notifications = useNotifications();

  const onAddPaymentMethod = async (token: string) => {
    try {
      await billingService.addPaymentMethod(token);
      notifications.showSuccess("Payment method has been added!");
      props.fetchData();
    } catch (err) {
      notifications.showError("Couldn't add a payment method");
    } finally {
      setIsAddPaymentMethodModalOpen(false);
    }
  };

  const onSelectDefaultPaymentMethod = async (paymentMethod: PaymentMethodInterface) => {
    try {
      await billingService.setDefaultPaymentMethod(paymentMethod.id);
      notifications.showSuccess("The default payment method has been changed!");
      props.fetchData();
    } catch (err) {
      notifications.showError("Couldn't change the default payment method");
    }
  };

  const onDeletePaymentMethod = async (paymentMethod: PaymentMethodInterface) => {
    try {
      await billingService.deletePaymentMethod(paymentMethod.id);
      notifications.showSuccess("Payment method has been deleted!");
      props.fetchData();
    } catch (err) {
      notifications.showError("Couldn't delete the payment method")
    }
  };

  return (
    <div className={styles.paymentSettings}>
      <Divider />
      <div className={styles.row}>
        <div className={styles.col}>
          <SectionTitle level={2}>Payment method</SectionTitle>
          <span className={styles.explanatoryText}>Select default payment method</span>
        </div>
        <div className={styles.col}>
          <PaymentMethods
            isRemoveAbility
            paymentMethods={props.paymentMethods}
            onAddPaymentMethod={() => setIsAddPaymentMethodModalOpen(true)}
            onSelectDefaultPaymentMethod={onSelectDefaultPaymentMethod}
            onDeletePaymentMethod={onDeletePaymentMethod}
          />
        </div>
      </div>
      {/*<Divider />*/}
      {/*<div className={styles.row}>*/}
      {/*  <div className={styles.col}>*/}
      {/*    <SectionTitle level={4}>Billing contact</SectionTitle>*/}
      {/*    <span className={styles.explanatoryText}>Where should the invoices be sent?</span>*/}
      {/*  </div>*/}
      {/*  <div className={styles.col}>*/}
      {/*    <BillingContact currentPlan={props.currentPlan} />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {isAddCardModalVisible && (
        <NewPaymentMethodModal onSubmit={onAddPaymentMethod} onClose={() => setIsAddPaymentMethodModalOpen(false)} />
      )}
    </div>
  );
};

export default PaymentSettings;
