import * as React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";

import css from "./CandidatesNoData.module.css";

type Props = {
  search?: string
}

export const CandidatesNoData: React.FC<Props> = (props) => {
  return (
    <div className={css.holder}>
      {props.search ? (
        <p className={css.text}>
          No candidates found for your request
        </p>
      ) : (
        <p className={css.text}>
          No <b>Candidates</b> yet?<br/>
          Click <Link to="/discover" target='_blank'>here</Link> and start creating your database.
        </p>
      )}
      <div className={css.icon}>
        <Icon name="person" />
      </div>
    </div>
  );
};