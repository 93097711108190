import * as React from "react";
import CompanyImg from "../../company/CompanyImg";
import { getCompanyInitials } from "../../../screens/companies/Companies";

import css from "./ProjectCardPreview.module.css";

export const ProjectCardPreview = (props) => {
  return (
    <div className={css.holder}>
      <div className={css.imageHolder}>
        <CompanyImg
          style={{ width: 44, height: 44, fontSize: 18 }}
          company={{
            imgUrl: props.project.company.imgUrl,
          }}
          initials={getCompanyInitials(props.project.company.name)}
        />
      </div>
      <div>
        <p className={css.title}>{props.project.name}</p>
        <p className={css.company}>{props.project.company.name}</p>
      </div>
    </div>
  );
};
