import React from "react";
import { cx, css } from "emotion/macro";
import { BarChart, Bar, XAxis, YAxis } from "recharts";

import * as globals from "../../constants/constants";

const socialProfilePlatformToBackgroundColor = {
  twitter: "#93d7f1",
  instagram: "#e1a6c2",
  linkedin: "#7ab2c8",
  facebook: "#8c9cbf",
};

/** 
  Used to display X or Y axis label text.
  Example for Total Time graph would be "Sun", "Mon", "Tue"
  Example for Time of Day graph would be "12-3am", "3am-6am"
*/
const CustomizedAxisTick = ({ metric, x, y, stroke, payload }) => {
  let formattedTickLabel = "";
  const tickVal = payload?.value;

  if (payload?.value) {
    formattedTickLabel = metric === globals.TOTAL_TIME ? tickVal?.substring(0, 3) : tickVal;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fontSize={metric === globals.TOTAL_TIME ? 9 : 7} textAnchor="middle" fill="#000000">
        {formattedTickLabel}
      </text>
    </g>
  );
};

/** 
    Used to draw labels on the graph for each data point.
    The label appears inside the graph as opposed to the axis.
  */
const CustomizedLabel = ({ metric, x, y, stroke, value }) => {
  const formattedLabel = metric === globals.TOTAL_TIME ? `${value} ${globals.AVERAGE_SESSION_TIME_HEURISTIC}` : "";

  return (
    <text x={x} y={y} dx={10} dy={-4} fill={stroke} fontSize={6} textAnchor="middle">
      {formattedLabel}
    </text>
  );
};

function Histogram({ metric, data, socialPlatform }) {
  return (
    <BarChart width={250} height={175} barGap={7} data={data} margin={{ top: 20 }}>
      <XAxis dataKey="name" tick={<CustomizedAxisTick metric={metric} />} interval={0} tickLine={false} />
      <YAxis dataKey="totalTime" hide={true} />
      <Bar
        className={cx(styles.bar)}
        dataKey="totalTime"
        width={24}
        barSize={24}
        fill={socialProfilePlatformToBackgroundColor[socialPlatform] || "#8884d8"}
        label={<CustomizedLabel metric={metric} />}
        radius={[3, 3, 3, 3]}
      />
    </BarChart>
  );
}

const styles = {
  emptyBarDataContainer: css`
    width: 250px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  emptyAverageSessionContainer: css`
    // margin: 23px auto;
  `,
  bar: css`
    color: red !important;
    border-radius: 3px !important;
  `,
};

export default Histogram;
