export const formatDateRange = (startDate, endDate, displaySearchRanking = false) => {
  let shouldHighlightPresent = (!!window.lastSearchFilters?.title && window.lastSearchFilters?.titleMode !== "allJobs") || (!!window.lastSearchFilters?.employer && window.lastSearchFilters?.companyNameMode !== "allJobs");
  shouldHighlightPresent = !!window.highlightPresent && shouldHighlightPresent;
  
  const present = displaySearchRanking && shouldHighlightPresent ? `<span style="color: #408cf6">Present</span>` : "Present";

  if (!startDate) {
    return shouldHighlightPresent ? present : "";
  }
  if (!endDate) {
    return `${formatDate(startDate)} – ${present}`;
  }
  return `${formatDate(startDate)} – ${formatDate(endDate)}`;
}

const formatDate = (x) => new Date(x).toLocaleDateString("en-US", { year: "numeric", month: "short" });

export default formatDateRange;
