import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ModalButtonContainer from "../button/ModalButtonContainer";
import ModalInputField from "../input/ModalInputField";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";

import { css } from "emotion/macro";

function NewSavedSearchModal({ isOpen, onClose, onSubmit }) {
  const [name, setName] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  async function handleSubmit() {
    setIsWaiting(true);
    await onSubmit({ name });
    setIsWaiting(false);
    onClose();
  }

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>Save Search</div>
        <div className={modalStyles.modalFormContainer}>
          <ModalInputField
            label={<div className={modalStyles.modalInputLabel}>Name</div>}
            placeholder="Enter name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <ModalButtonContainer onClick={handleSubmit} isWaiting={isWaiting} isDisabled={!name || !name.trim()} />
      </div>
    </Modal>
  );
}
export default NewSavedSearchModal;
