import React from "react";
import BootstrapToast from "react-bootstrap/Toast";
import classes from "./Toast.module.css";

const Toast = ({ title, body, show, onClose }) => {
  return (
    <BootstrapToast show={show} onClose={onClose} className={classes.toast}>
      <BootstrapToast.Header>{title}</BootstrapToast.Header>
      <BootstrapToast.Body>{body}</BootstrapToast.Body>
    </BootstrapToast>
  );
};

export default Toast;
