import * as React from "react";
import { Link } from "../../../UI/Link/Link";
import { Icon } from "../../../UI/IconComponent/Icon";
import { Dropdown } from "../../../UI/Dropdown/Dropdown";
import { RemoveComponent } from "../../../../saved-search/SavedSearches";

import css from "./DiscoverSavedSearchButton.module.css";
import { Badge } from "../../../../ui/Badge/Badge";
import { Alert } from "../../../../ui/Alert/Alert";
import { cookieService } from "../../../services/CookieService";
import { COOKIE_NAME } from "../../../../../constants/constants";
import { DateTime } from "luxon";

export function DiscoverSavedSearchButton(props) {
  const { savedSearches, recentSavedSearches, onDeleteSavedSearch, onRestoreSavedSearch } = props;
  const [isAlertVisible, setIsAlertVisible] = React.useState(cookieService.get(COOKIE_NAME.saved_search_note_dismissed) !== 'true');

  function hideAlert(e) {
    setIsAlertVisible(false);
    cookieService.set(COOKIE_NAME.saved_search_note_dismissed, 'true');
  }

  function onRemoveClick(e, item) {
    e.stopPropagation();
    onDeleteSavedSearch(item);
  }

  function renderItem(item) {
    return (
      <Dropdown.Item key={item.id} onClick={() => onRestoreSavedSearch(item)}>
        <span title={item.name} className={css.itemTitle}>
          {item.name}
        </span>
        <div>
          <RemoveComponent handleRemoveClick={(e) => onRemoveClick(e, item)} />
        </div>
      </Dropdown.Item>
    );
  }

  const hasSavedSearches = recentSavedSearches.length || savedSearches.length;
  const date = DateTime.fromISO('2023-12-14');
  const showAlert = !!(hasSavedSearches && isAlertVisible && savedSearches.some((item) => {
    const createdDate = DateTime.fromISO(item.createdAt);
    return date.diff(createdDate, 'days').days > 0;
  }));

  return (
    <Dropdown
      classNames={{ content : css.dropdownContent }}
      label={
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Link className={css.savedSearch} rightIcon={<Icon name="chevron" />}>
            Saved Search
          </Link>
          {showAlert && <Badge style={{ marginLeft : 8 }}>1</Badge>}
        </div>
      }
    >
      {!hasSavedSearches && <div className={css.sectionTitle}>Your saved searches will appear here</div>}
      <Alert style={{ borderRadius: 4, marginBottom: 16, display: showAlert ? 'block' : 'none' }}>
        Please note: We recently changed our Boolean search parameters and your saved search may have been affected.<br /><br />
        If you don't see the results you're accustomed to when executing your saved search, please feel free to send
        us the URL of the search and we'll build an improved search for you.<br/><br/>
        <span className='alert__link' onClick={hideAlert}>Hide this message</span>
      </Alert>
      {!!recentSavedSearches.length && (
        <>
          <div className={css.sectionTitle}>Recent Searches</div>
          {recentSavedSearches.map(renderItem)}
        </>
      )}
      {!!savedSearches.length && (
        <>
          <Dropdown.Divider />
          <div className={css.sectionTitle}>All Saved Searches</div>
          {savedSearches.map(renderItem)}
        </>
      )}
    </Dropdown>
  );
}
