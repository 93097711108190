import * as api from '../api/apiService';
import { BOUNCER_VALID_STATUS } from "../constants/constants";

// canUserViewInsights takes in a user object and determines
// whether the user has access to view the insights tab.
export const canUserViewInsights = (user) => {
  if (!user?.organizationId) {
    return;
  }

  // REACT_APP_INSIGHTS_ORGANIZATION_IDS is a comma-separated list of org ids
  // Example: "55,4,12"
  const organizationIdsString = process.env.REACT_APP_INSIGHTS_ORGANIZATION_IDS;
  if (!organizationIdsString) {
    return false;
  }

  const insightsOrganizationsIDs = organizationIdsString.split(",");
  if (!insightsOrganizationsIDs) {
    return false;
  }

  return insightsOrganizationsIDs.indexOf(user.organizationId.toString()) >= 0;
};