import { cx } from "emotion";
import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../../components/button/CloseButton";
import ModalButtonContainer from "../../../../components/button/ModalButtonContainer";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { modalStyles } from "../../../../components/modals/modalStyles";
import { candidateService } from "../../../projects/CandidateService";
import {
  STAGE_COLORS,
  StageFlowItemInterface,
  StageFlowItemUpdateDTOInterface,
  StageFlowUpdateDTOInterface,
} from "../StageFlowService";

import cssModule from "./ModalStageFlowStageEdit.module.css";

type Props = {
  onSubmit: (data: Partial<StageFlowUpdateDTOInterface>) => Promise<any>;
  stage?: StageFlowItemInterface
  isOpen: boolean;
  onClose: () => void;
}

export const ModalStageFlowStageEdit: React.FC<Props> = ({ isOpen, onClose, onSubmit, stage }) => {
  const [name, setName] = useState(stage?.name || '');
  const [loading, setLoading] = useState(false);
  const [colorName, setColorName] = React.useState(stage?.color || STAGE_COLORS[0]);
  const [daysOnStage, setDaysOnStage] = React.useState(stage ? stage.hoursToComplete / 24 : 3);

  React.useEffect(() => {
    setName(stage?.name || "");
  }, [stage?.name]);

  const handleSubmit = async () => {
    const data: Partial<StageFlowItemUpdateDTOInterface> = {
      name,
      color: colorName || '',
      hoursToComplete: daysOnStage * 24
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={cx(modalStyles.modalBody, cssModule.holder)} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>
          {stage ? "Edit " + stage.name : "Add New Stage"}
        </div>
        <div className={modalStyles.modalFormContainer}>
          <div style={{ marginBottom : 8 }}>
            <span className={cssModule.label}>Stage name</span>
          </div>
          <Input value={name} onChange={(e: any) => setName(e.target.value)} placeholder="Enter stage name" />
          <div style={{ display : "flex", marginTop : 16 }}>
            <div>
              <div style={{ marginBottom : 8 }}>
                <span className={cssModule.label}>Days on stage</span>
              </div>
              <Input
                className={cssModule.daysOnStageInput} value={daysOnStage}
                onChange={(e: any) => setDaysOnStage(e.target.value)}
              />
            </div>
            <div style={{ marginLeft : 48 }}>
              <div style={{ marginBottom : 8 }}>
                <span className={cssModule.label}>Stage color</span>
              </div>
              <div className={cssModule.colorList}>
                {STAGE_COLORS.map(color => {
                  const style = candidateService.getStageStyleByColor(color);
                  const isActive = color === colorName;
                  return (
                    <div
                      key={color  }
                      onClick={() => setColorName(color)}
                      className={cx(cssModule.colorItem, { [cssModule.active] : isActive })}
                      style={{ backgroundColor : style.color, color : style.color }}
                    >
                      <Icon className={cssModule.icon} name='tick' />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ModalButtonContainer
          title={stage ? "Update Stage" : `Add Stage`}
          onClick={handleSubmit}
          isWaiting={loading}
          isDisabled={!name || !name.trim()}
        />
      </div>
    </Modal>
  );
};