import * as React from 'react';
import cn from 'classnames';

import css from './Badge.module.css';

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export const Badge = (props: Props) => {
  return (
    <div className={cn(css.holder, props.className)} style={props.style}>{props.children}</div>
  )
}