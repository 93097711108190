import * as React from "react";
import { cx } from "emotion";
import PropTypes from "prop-types";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Dropdown } from "../../DiscoverNew/UI/Dropdown/Dropdown";

import css from "./DropdownFilter.module.css";

export const DropdownFilter = (props) => {
  function isSelected(option) {
    return props.isSelected ? props.isSelected(option, props.value) : option.value === props.value;
  }

  const selectedOption = props.options.find(isSelected) || props.options[0];

  return (
    <div className={cx(css.holder, props.className)}>
      {!!props.label && <div className={css.label}>{props.label}</div>}
      <Dropdown
        classNames={{
          content: css.content,
        }}
        label={
          <div className={css.valueLabel}>
            {selectedOption.label}
            <div className={css.valueLabelIcon}>
              <Icon name="chevron" />
            </div>
          </div>
        }
      >
        {props.options.map((option) => {
          return (
            <Dropdown.Item
              key={props.getKey ? props.getKey(option) : option.value}
              className={cx(css.item, isSelected(option, props.value) && css.selected)}
              onClick={(close) => {
                props.onChange(option);
                close();
              }}
            >
              {option.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  );
};

DropdownFilter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.any,
  isSelected: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
    })
  ),
};
