import { Elements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useAuthController } from "../../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";
import { initializeStripe } from "../../../services/BillingService";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import PurchaseCreditsModal from "../../modals/PurchaseCreditsModal/PurchaseCreditsModal";
import { Alert } from "./Alert";

const stripePromise = initializeStripe();

type Props = {
  onExtraCreditsPurchased?: () => void
  renderContent?: (openPurchaseModal: () => void) => React.ReactNode
  userCredits: number
}

export const OutOfCreditsAlert = (props: Props) => {
  const [isPurchaseCreditsModalOpen, setIsPurchaseCreditsModalOpen] = useState(false);
  const authController = useAuthController();
  const isTrial = authController.user.currentPlan.planInfo.name === "trial";
  const canPurchase =  permissionService.can(authController.user, PermissionEnum.editOrganizationBilling);

  const ownerName = Formatter.fullName(authController.user.organizationV2.ownerRef);

  return (
    <>
      {canPurchase ? (
        isTrial ? (
          <Alert>
            {props.userCredits <= 0 ? (
              <>You need more credits. Please <a href="/profile/billing/plans" target="_blank" className="alert__link">click here</a> to upgrade to a paid plan.</>
            ) : (
              <>You need more credits to complete this action. Please reduce the number of candidates or <a href="/profile/billing/plans" target="_blank" className="alert__link">click here</a> to upgrade to a paid plan.</>
            )}
          </Alert>
        ) : (
          <Alert>
            {props.userCredits <= 0 ? (
              <>You need more credits. Please <span className="alert__link" onClick={() => setIsPurchaseCreditsModalOpen(true)}>click here</span> to purchase more credits.</>
            ) : (
              <>You need more credits to complete this action. Please reduce the number of candidates or <span className="alert__link" onClick={() => setIsPurchaseCreditsModalOpen(true)}>click here</span> to purchase more credits.</>
            )}

          </Alert>
        )
      ) : isTrial ? (
        <Alert>
          {props.userCredits <= 0 ? (
            <>You need more credits. You will need to contact your account owner {ownerName}. The account owner can upgrade to a paid plan so you can continue unlocking candidates.</>
          ) : (
            <>You need more credits to complete this action. Please reduce the number of candidates or contact your account owner {ownerName}. The account owner can upgrade to a paid plan so you can continue unlocking candidates.</>
          )}
        </Alert>
      ) : (
        <Alert>
          {props.userCredits <= 0 ? (
            <>You need more credits. You will need to contact your account owner {ownerName}. The account owner can purchase more credits so you can continue unlocking candidates.</>
          ) : (
            <>You need more credits to complete this action. Please reduce the number of candidates or contact your account owner {ownerName}. The account owner can purchase more credits so you can continue unlocking candidates.</>
          )}
        </Alert>
      )}
      {isPurchaseCreditsModalOpen && (
        <Elements stripe={stripePromise} options={{ currency : "usd" }}>
          <PurchaseCreditsModal
            onClose={() => setIsPurchaseCreditsModalOpen(false)}
            onSuccess={() => {
              setIsPurchaseCreditsModalOpen(false);
              props.onExtraCreditsPurchased && props.onExtraCreditsPurchased();
            }}
          />
        </Elements>
      )}
      {props.renderContent ? props.renderContent(() => setIsPurchaseCreditsModalOpen(true)) : null}
    </>
  );
};