import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../components/button/CloseButton";
import ModalButtonContainer from "../../../components/button/ModalButtonContainer";
import { Input } from "../../../components/DiscoverNew/Form/Input/Input";
import { modalStyles } from "../../../components/modals/modalStyles";
import { CurrentUserInterface } from "../../../types";

type Props = {
  data: CurrentUserInterface
  // todo
  onSubmit: (data: any) => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalPersonaInfoEdit: React.FC<Props> = ({ isOpen, onClose, data, onSubmit }) => {
  const [firstName, setFirstName] = useState(data.firstName || '');
  const [lastName, setLastName] = useState(data.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber || '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // todo
    const data: any = {
      firstName,
      lastName,
      phoneNumber,
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>Edit Personal Information</div>
        <div className={modalStyles.modalFormContainer}>
          <Input value={firstName} onChange={(e: any) => setFirstName(e.target.value)} placeholder="e.g. John" style={{ marginBottom: 12 }} />
          <Input value={lastName} onChange={(e: any) => setLastName(e.target.value)} placeholder="e.g. Doe" style={{ marginBottom: 12 }} />
          <Input value={phoneNumber} onChange={(e: any) => setPhoneNumber(e.target.value)} placeholder="Enter phone number" />
        </div>
        <ModalButtonContainer
          title="Update"
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};