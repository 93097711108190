import * as React from "react";
import { CandidateInterface } from "../../../../screens/projects/CandidateService";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";

type Props = {
  candidate: CandidateInterface
  className?: string
  style?: React.CSSProperties
};

export const CandidateVerificationIcon: React.FC<Props> = (props) => {
  const commonProps = {
    className: props.className || "",
    style: props.style || {},
  }

  if (typeof props.candidate.verificationStatus === "undefined") {
    return null;
  }

  if (props.candidate.verificationStatus === "positive") {
    return <Icon {...commonProps} name="contact-info-verification-trusted" />;
  }

  if (props.candidate.verificationStatus === "negative") {
    return <Icon {...commonProps} name="contact-info-verification-untrusted" />;
  }

  return null;
};