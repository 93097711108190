import * as React from "react";
import { omit } from "../components/DiscoverNew/util/util";

export type ErrorsType<T> = {
  [key in keyof Partial<T>]: string
}

export function useForm<T>(initialValues: T) {
  const [values, setValues] = React.useState<T>(initialValues);
  const [errors, setErrors] = React.useState<Partial<ErrorsType<T>>>({});

  const onChangeValues = (nextValues: Partial<T>) => {
    setValues({ ...values, ...nextValues });
    setErrors(omit(errors, Object.keys(nextValues)));
  };

  return {
    values,
    errors,
    setErrors,
    onChangeValues,
  };
}
