import * as React from "react";
import { BOUNCER_VALID_STATUS } from "../../../../constants/constants";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";

type Props = {
  status?: string
  className?: string
  style?: React.CSSProperties
};

export const EmailVerificationIcon: React.FC<Props> = (props) => {
  const commonProps = {
    className : props.className || "",
    style : props.style || {},
  };

  if (!props.status) {
    return <Icon {...commonProps} name="contact-info-verification-pending" />;
  }

  if (props.status === BOUNCER_VALID_STATUS) {
    return <Icon {...commonProps} name="contact-info-verification-trusted" />;
  }

  if (typeof props.status === "undefined") {
    return null;
  }

  return <Icon {...commonProps} name="contact-info-verification-untrusted" />;
};