import * as React from "react";
import css from "./ContactButton.module.css";
import { cx } from "emotion";

export function ContactButton(props) {
  const { component, className, ...rest } = props;
  const Component = component || "button";
  return (
    <Component className={cx(css.contactButton, props.className)} {...rest}>
      {props.children}
    </Component>
  );
}
