import * as React from "react";
import { useEffect } from "react";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import { AdminSubscriptionModal } from "../../../../components/modals/AdminSubscriptionModal";
import Table from "../../../../components/project/Table/Table";
import { adminService, AdminSubscriptionInterface } from "../../../../services/AdminService";
import { PlanInfoInterface } from "../../../../services/BillingService";
import { EntityConnection } from "../../../../types";
import { userService } from "../../../projects/UserService";

import css from "./AdminSubscriptionList.module.css";

type Props = {
  //match: any
  organizationId: string
  subscriptions: AdminSubscriptionInterface[]
  onUpdated: () => void
}

export const AdminSubscriptionList = (props: Props) => {

  //const [subscriptions, setSubscriptions] = React.useState<EntityConnection<AdminSubscriptionInterface>>();
  const [selectedSubscription, setSelectedSubscription] = React.useState()

  useEffect(() => {
    //adminService.fetchOrganizationSubscriptions(props.match.params.id, {}).then(setSubscriptions);
  }, []);

  return (
    <>
      <Table
        noData="No subscriptions"
        actions={{
          edit: {
            render: (v: any) => {
              return <Icon name="pencil" onClick={() => setSelectedSubscription(v)} className={css.editButton} />;
            },
          },
        }}
        dataObject={{
          data: {
            //subscriptions: subscriptions ? subscriptions.nodes : []
            subscriptions: props.subscriptions || []
          }
        }}
        columns={[
          { dataIndex: "id", title: "ID" },
          { dataIndex: "planInfo", title: "Plan", render: (v: PlanInfoInterface) => v.title },
          { dataIndex: "seatCount", title: "Seats" },
          { dataIndex: "planInfo", title: "Price", render: (v: PlanInfoInterface, item: AdminSubscriptionInterface) => <>${(v.pricePerSeat * item.seatCount).toLocaleString()}</> },
          { dataIndex: "isActive", title: "Active", render: Formatter.yesNo },
          {
            dataIndex: "externalId",
            title: "Stripe Subscription ID",
            render: (v: any) => {
              if (!v) {
                return "–";
              }
              return (
                <a href={userService.getStripeSubscriptionURL(v)} rel="noopener noreferrer" target="_blank">
                  {v}
                </a>
              );
            },
          },
          { dataIndex: "startDate", title: "Start Date", render: Formatter.fullDate },
          { dataIndex: "endDate", title: "End Date", render: Formatter.fullDate },
        ]}
      />
      {selectedSubscription && (
        <AdminSubscriptionModal
          organizationId={props.organizationId}
          data={selectedSubscription}
          onClose={() => setSelectedSubscription(undefined)}
          onSuccess={props.onUpdated}
        />
      )}
    </>
  )
}