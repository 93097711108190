import * as React from "react";
import css from "./Alert.module.css";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties
}

export const Alert = (props: Props) => {
  return (
    <div className={css.holder} style={props.style} role="alert">
      {props.children}
    </div>
  );
};