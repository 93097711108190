import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { ROUTES } from "../../../../constants/constants";
import { FormMessage } from "../../../ui/FormMessage/FormMessage";

type Props = {
  controls: (isLoading: boolean) => React.ReactNode
  isFirstPurchase?: boolean
} & ({
  type: "create-method"
  onCreated: (paymentMethodId: string) => void
} | {
  clientSecret: string
  type?: "setup-intent"
})

export const StripePaymentForm = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }

    setIsLoading(true);
    // Trigger form validation and wallet collection
    const { error : submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      setIsLoading(false);
      return;
    }

    if (props.type === "create-method") {
      const res = await stripe!.createPaymentMethod({ elements });
      props.onCreated(res.paymentMethod!.id!);
      setIsLoading(false);
    } else {
      const { error } = await stripe!.confirmPayment({
        elements,
        clientSecret: props.clientSecret,
        confirmParams : {
          return_url : window.location.origin + (props.isFirstPurchase ? ROUTES.paymentProcessingFirstPurchase : ROUTES.paymentProcessing),
        },
      });
      setIsLoading(false);
      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!!errorMessage && <FormMessage theme="danger" style={{ marginBottom: 16 }}>{errorMessage}</FormMessage>}
      <PaymentElement />
      {props.controls(isLoading)}
    </form>
  );
};