import React from "react";

import { css, cx } from "emotion/macro";

import NavigatorButton from "./NavigatorButton";

function Navigator({ candidate, currentPage = "", children, buttons = [], setCurrentPage = () => {} }) {
  const onNavigatorButtonPressed = (formalTabName, pageName) => {
    setCurrentPage(pageName);
    window.analytics.track(`Candidate ${formalTabName} Viewed`, {
      candidate_id: candidate?.candidateId,
      state: candidate?.location?.region,
      locality: candidate?.location?.locality,
      is_unlocked: candidate?.unlockedAt,
    });
  };
  return (
    <div
      className={cx(
        "candidate-detail-navigate-container",
        "col-xl-8",
        "col-lg-7",
        "col-md-12",
        "d-none",
        "d-lg-block",
        css`
          padding-left: 23px !important;
          padding-right: 19px !important;
        `
      )}
    >
      <div
        className={css`
          display: flex;
          background-color: white;
          margin-top: 0px;
          padding-bottom: 5px;
          position: sticky;
          top: 0;
        `}
      >
        {buttons.map(({ text, pageName }) => (
          <NavigatorButton
            key={text}
            isSelected={currentPage === pageName}
            onClick={(e) => onNavigatorButtonPressed(text, pageName)}
            text={text}
          />
        ))}
      </div>
      {React.Children.toArray(children).filter(({ props: { name } }) => name === currentPage)}
    </div>
  );
}

export default Navigator;
