import * as React from "react";
import SearchBox from "../../../components/SearchKit/SearchBoxDetached";
import SearchResultsStats from "../../../components/SearchResultsStats";
import PropTypes from "prop-types";
import { useUserData } from "../../../contexts/userContext";
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { DropdownFilter } from "../../../components/ui/DropdownFilter/DropdownFilter";

import css from "./ProjectsScreenHeader.module.css";
import { PermissionEnum, permissionService } from "../PermissionService";

export const ProjectsScreenHeader = (props) => {
  const {
    projects,
    search,
    onSearchChange,
    onCreateProjectClick,
    orderBy,
    setOrderBy,
    orderByOptions,
    ownedBy,
    setOwnedBy,
    ownedByOptions,
    status,
    setStatus,
    statusOptions,
  } = props;
  const {
    state: { user },
  } = useUserData();
  const canCreate = permissionService.can(null, PermissionEnum.projectCreate);
  const canFetchOrganization = permissionService.can(null, PermissionEnum.projectCanFetchOrganization)
  const uiSettings = user.uiSettings;

  return (
    <div className={css.header}>
      <h1>{uiSettings?.mappings?.projects}</h1>
      {canCreate && (
        <Button theme="outline" className={css.addButton} leftIcon={<Icon name="plus" />} onClick={onCreateProjectClick}>
          Add
        </Button>
      )}
      <SearchBox
        className={css.search}
        placeholder={`Search ${uiSettings?.mappings?.projects}`}
        onChange={onSearchChange}
        value={search}
      />
      <SearchResultsStats className={css.searchResults} count={projects?.length} />
      <DropdownFilter
        className={css.dropdown}
        options={ownedByOptions.filter((option) => canFetchOrganization ? true : option.value !== "organization")}
        isSelected={(option, value) => option.value === value.value}
        onChange={setOwnedBy}
        label="Filter"
        value={ownedBy}
      />
      <DropdownFilter
        className={css.dropdown}
        options={statusOptions}
        isSelected={(option, value) => option.value === value.value}
        onChange={setStatus}
        value={status}
      />
      <DropdownFilter
        className={css.dropdown}
        getKey={(option) => option.value + option.dir}
        options={orderByOptions}
        isSelected={(option, value) => option.value === value.value && option.dir === value.dir}
        onChange={setOrderBy}
        label="Sort By"
        value={orderBy}
      />
    </div>
  );
};

ProjectsScreenHeader.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onCreateProjectClick: PropTypes.func.isRequired,
  search: PropTypes.string,
  projects: PropTypes.array,
  orderBy: PropTypes.object,
  setOrderBy: PropTypes.func.isRequired,
  orderByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  ownedBy: PropTypes.object,
  setOwnedBy: PropTypes.func.isRequired,
  ownedByOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  status: PropTypes.object,
  setStatus: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
