export const QUERY_NAME = {
  fetchProjects: "fetchProjects",
};

// @todo it's a temporary solution, we need to implement a datastore or something
class QueryRegistry {
  queries = {};

  /**
   * @param {string} name
   * @param {function} fn
   */
  setQuery = (name, fn) => {
    this.queries[name] = fn;
  };

  /**
   * @param {string} name
   */
  deleteQuery = (name) => {
    delete this.queries[name];
  };

  /**
   * @param {string} name
   */
  getQuery = (name) => {
    return this.queries[name];
  };

  /**
   * @param {string} name
   */
  fetchQuery = (name) => {
    return this.queries[name] ? this.queries[name]() : null;
  };
}

export const queryRegistry = new QueryRegistry();
