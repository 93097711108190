import React from "react";
import { map, take } from "lodash";
import { css, cx } from "emotion/macro";

import capitalizeWords from "../../formatters/capitalizeWords";

const Skill = ({ name, highlight = false }) => {
  const formattedName = capitalizeWords(name);
  let className="mb-2 mr-2 skills-tag";
  if (highlight) {
    className = cx(className, "skills-tag-highlighted");
  }
  return (
    <div className={className}>
      {formattedName}
    </div>
  );
};

function Skills({ entries = [], viewAll = true, maxEntries = 6, ranked = null }) {
  const skills = ranked ? ranked : entries;
  const displayedEntries = viewAll ? skills : take(skills, maxEntries);

  const drawSkill = (skill) => {
    return <Skill key={skill} name={skill} />;
  };

  const drawRankedSkill = (skill) => {
    return <Skill key={skill.value} name={skill.value} highlight={skill._highlight} />;
  };

  return (
    <div
      className={cx("skills-row-container", {
        [styles.maxHeight]: !viewAll,
      })}
    >
      {displayedEntries?.length > 0 ? (
        map(displayedEntries, (skill) => (ranked ? drawRankedSkill(skill) : drawSkill(skill)))
      ) : (
        <div style={{ textAlign: "center" }}>
          <span>No skills</span>
        </div>
      )}
    </div>
  );
}

const styles = {
  maxHeight: css`
    max-height: 35px;
  `,
};

export default Skills;
