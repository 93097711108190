import { useHistory, useLocation } from "react-router-dom";
import * as React from "react";
import queryString from "query-string";
import { UrlHelper } from "../components/DiscoverNew/util/UrlHelper";
import debounce from 'debounce';

export const useQueryParams = (defaultParams) => {
  const { search } = useLocation();
  const history = useHistory();
  const currentParams = UrlHelper.parseSearch(search) || {};

  const setParams = React.useCallback((params) => {
    const activeParams = Object.keys(params).reduce((acc, key) => {
      if (!params[key]) {
        return acc;
      }
      return {
        ...acc,
        [key]: params[key],
      };
    }, []);

    const paramsString = queryString.stringify(activeParams);
    history.push(window.location.pathname + "?" + paramsString);
  }, []);

  const debouncedSetParams = React.useCallback(debounce(setParams, 300), []);

  return {
    params: {
      ...defaultParams,
      ...currentParams,
    },
    setParams,
    debouncedSetParams
  };
};
