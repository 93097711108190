import * as React from "react";
import PropTypes from "prop-types";
import { UnderlinedSelectHtml } from "../../UI/SelectHtml/UnderlinedSelectHtml/UnderlinedSelectHtml";

export function InputMode(props) {
  const selected = props.options.find((item) => item.value === props.value);
  return (
    <UnderlinedSelectHtml
      style={props.style}
      onChange={props.onChange}
      value={selected.value}
      label={selected.label}
      options={props.options}
    />
  );
}

InputMode.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};
