import React, { useState } from "react";
import { cx, css } from "emotion/macro";

import { md } from "../../css/mediaQueries";

function CandidateDetailSection({ title = "", children, showViewMore = false, marginBottom = "25px" }) {
  const [viewAll, setViewAll] = useState(false);
  return (
    <div className={cx("candidate-detail-section")} style={{ marginBottom: marginBottom }}>
      <div
        className={cx(
          "candidate-detail-section-header-container",
          css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 17px;
            ${md} {
              margin-bottom: 10px;
            }
          `
        )}
      >
        <span className={cx(CandidateDetailSectionStyles.candidateProfileInfoHeader, "candidate-profile-info-header")}>{title}</span>
        {showViewMore && (
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
              cursor: pointer;
            `}
          >
            <span
              className={cx(
                "view-all-button",
                css`
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  letter-spacing: -0.61px;
                  text-align: right;
                  color: #208bfe;
                  padding-right: 10px;
                `
              )}
              style={{ color: "#208bfe" }}
              onClick={(e) => {
                e.stopPropagation();
                setViewAll(!viewAll);
              }}
            >
              {viewAll ? "View Less" : "View All"}
            </span>
          </div>
        )}
      </div>
      {React.Children.map(children, (child) => React.cloneElement(child, { viewAll }))}
    </div>
  );
}

export const CandidateDetailSectionStyles = {
  candidateProfileInfoHeader: css`
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #373737;
    text-transform: capitalize;
    font-family: Montserrat-Bold !important;
  `,
};

export default CandidateDetailSection;
