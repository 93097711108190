import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";

import * as globals from "../../constants/constants";

function HorizontalStackedChart({ data, socialPlatform }) {
  if (!data.length) {
    return "";
  }

  return (
    <ResponsiveContainer>
      <BarChart width={250} height={75} barGap={7} data={data} layout="vertical">
        <XAxis type="number" hide={true} tick={false} interval={0} tickLine={false} />
        <YAxis hide={true} dataKey="name" type="category" />

        <Bar
          dataKey={globals.SOCIAL_TWITTER}
          fill={"#93d7f1"}
          stackId="x"
          radius={data[0]["linkedin"] ? [9, 0, 0, 9] : [9, 9, 9, 9]} //temporary solution for 2 social platforms
        >
          <LabelList dataKey={globals.SOCIAL_TWITTER} style={{ fill: "#ffffff" }} formatter={(val) => `${val}%`} />
        </Bar>

        <Bar
          dataKey={globals.SOCIAL_LINKEDIN}
          fill={"#7ab2c8"}
          stackId="x"
          radius={data[0]["twitter"] ? [0, 9, 9, 0] : [9, 9, 9, 9]} //temporary solution for 2 social platforms
        >
          <LabelList dataKey={globals.SOCIAL_LINKEDIN} style={{ fill: "#ffffff" }} formatter={(val) => `${val}%`} />
        </Bar>

        {/* Uncomment Bar when new platform is supported */}
        {/* <Bar dataKey={globals.SOCIAL_INSTAGRAM} fill={"#4a4a4a1a"} stackId="x">
          <LabelList dataKey={globals.SOCIAL_INSTAGRAM} style={{ fill: "#ffffff" }} />
        </Bar>

        <Bar dataKey={globals.SOCIAL_FACEBOOK} fill={"#4a4a4a1a"} stackId="x">
          <LabelList dataKey={globals.SOCIAL_FACEBOOK} style={{ fill: "#ffffff" }} />
        </Bar> */}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default HorizontalStackedChart;
