import React from "react";
import { css, cx } from "emotion/macro";

function CompanyProjectsAccordian({ companyName, bottomBorder = false, children }) {
  return (
    <div className={cx(styles.companyProjectAccordianContainer)}>
      <div
        className={cx(styles.companyProjectListContainer, {
          [styles.emptyCompanyProjectListContainer]: !children || children?.length < 1,
        })}
      >
        <div className={cx(styles.companyNameContainer)}>
          <span className={cx(styles.companyName)}>{companyName}</span>
        </div>

        <div className={cx(styles.companyProjectAccordian)}>{children}</div>
      </div>
    </div>
  );
}

const styles = {
  companyProjectAccordianContainer: css`
    &:nth-child(1) {
      padding-top: 15px;
    }
  `,
  companyProjectListContainer: css`
    padding: 8px 8px 2px 8px;
  `,
  emptyCompanyProjectListContainer: css`
    padding: 8px 8px 2px 8px;
  `,
  companyNameContainer: css`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    min-height: 28px;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 5px;
  `,
  companyName: css`
    font-family: Montserrat-Bold !important;
    font-stretch: normal;
    font-style: normal;
    font-size: 14px;
    margin-right: 10px;
    color: #000000;
    letter-spacing: -0.61px;
    line-height: normal;
  `,
  companyProjectAccordian: css`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    fontsize: 12px;
    color: #a8a8a8;
  `,
  displayNone: css`
    display: none;
  `,
};

export default CompanyProjectsAccordian;
