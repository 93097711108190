import { cx } from "emotion";
import React from "react";
import css from "./Pill.module.css";

type Props = {
  onClick?: (e: MouseEvent) => void
  style?: any
  tooltip?: any
  children: React.ReactNode
  className?: string
}

export const Pill: React.FC<Props> = (props) => {
  const { onClick, style, tooltip, className } = props;
  return (
    <span
      title={tooltip}
      className={cx(css.pill, className)}
      style={style}
      // @ts-ignore
      onClick={onClick}
    >{props.children}</span>
  );
}