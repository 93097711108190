import React from "react";
import { useState, useCallback } from "react";
// import Slider from '@material-ui/lab/Slider'
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

import { xs, sm, md } from "../css/mediaQueries";

import { css, cx } from "emotion";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CropComponent = (props) => {
  // Cropping State
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [aspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [imageSrc] = useState(props?.imageSrc);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  // Call when cropping is complete
  const showResult = async () => {
    try {
      setIsCropping(true);
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      setIsCropping(false);
      props.onCropSelectionComplete(croppedBlob);
    } catch (e) {
      console.error(e);
      setIsCropping(false);
    }
  };

  const onClose = async () => {
    props.onCropSelectionComplete(null);
  };

  return (
    <>
      {props?.imageSrc && (
        <div>
          <Modal
            isOpen={props.isModalOpen}
            toggle={() => onClose()}
            centered={true}
            size="lg"
            className={cx("profile-image-edit-modal")}
          >
            <ModalHeader
              className={cx(
                "add-project-header",
                css`
                  border-bottom: 1px solid #dee2e6 !important;
                  padding: 15px !important;
                  max-height: 115px;
                `
              )}
            >
              <div className="row add-project-header-icon-container">
                <div>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    type="button"
                    className="close bs-close-button"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="row add-project-title-container">
                <h6
                  className={cx(
                    css`
                      margin: initial 0px 40px;
                      font-family: Montserrat;
                      text-align: center;
                      font-size: 20px;
                      letter-spacing: -1.02px;
                      text-align: center;
                      color: #373737;
                    `
                  )}
                >
                  Edit Profile Image
                </h6>
              </div>
            </ModalHeader>
            <ModalBody
              className={cx(css`
                padding: 1rem !important;
                height: 100%;
                ${xs} {
                  display: flex;
                  align-items: center;
                  padding: 0 !important;
                }
              `)}
            >
              <div
                className={cx(css`
                  width: 100%;
                  height: 400px;
                  position: relative;
                  ${xs} {
                    height: 100%;
                  }
                `)}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  aspect={aspect}
                  zoom={zoom}
                  cropShape="round"
                  // cropSize={cropsSize}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
              </div>
            </ModalBody>
            <ModalFooter
              className={cx(
                css`
                  justify-content: space-around !important;
                  ${sm} {
                    flex-wrap: wrap-reverse !important;
                  }
                  ${md} {
                    flex-wrap: wrap-reverse !important;
                  }
                `
              )}
            >
              <div>
                <button
                  onClick={onClose}
                  className={cx(
                    "btn btn-outline-secondary",
                    css`
                      width: 250px;
                      height: 47px;
                      border-radius: 50px !important;
                      margin-top: 10px;
                      margin-bottom: 15px;
                      color: #a8a8a8 !important;
                      border-color: #a8a8a8 !important;
                      &:hover {
                        color: #ffffff !important;
                        background-color: #a8a8a8 !important;
                      }
                    `
                  )}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  onClick={showResult}
                  disabled={isCropping}
                  className={cx(
                    "btn btn-outline-primary",
                    css`
                      width: 250px;
                      height: 47px;
                      border-radius: 50px !important;
                      margin-top: 10px;
                      margin-bottom: 15px;
                    `
                  )}
                >
                  Accept
                </button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CropComponent;
