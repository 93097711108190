import * as React from "react";
import css from "./AdminBlacklistDomainsListControlls.module.css";
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { FilterSelectHtml } from "../../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { PageSubtitle } from "../../../../components/ui/PageSubtitle/PageSubtitle";
import { SearchInput } from "../../../../components/ui/SearchInput/SearchInput";

export const AdminBlacklistDomainsListControlls = ({
  data,
  sort,
  sortOptions,
  onChangeSort,
  query,
  onChangeQuery,
  active,
  activeOptions,
  onChangeActive,
  scope,
  scopeOptions,
  onChangeScope,
  onAdd,
}) => {
  return (
    <div>
      <div className={css.info}>
        <PageSubtitle>Domains {data?.pageInfo?.total ? `(${data.pageInfo.total})` : ""}</PageSubtitle>
      </div>
      <div className={css.controls}>
        <div>
          <SearchInput
            value={query}
            onChange={(e) => onChangeQuery(e.target.value)}
            rightIcon={<Icon name="search" />}
            placeholder="Quick Search"
          />
        </div>
        <div>
          <FilterSelectHtml label="Scope" onChange={onChangeScope} options={scopeOptions} value={scope} />
          <div style={{ width: 10 }} />
          <FilterSelectHtml label="Active" onChange={onChangeActive} options={activeOptions} value={active} />
          <div style={{ width: 10 }} />
          <FilterSelectHtml label="Sort By" onChange={onChangeSort} options={sortOptions} value={sort} />
          <div style={{ width: 10 }} />
          <Button theme="outline" className={css.addButton} leftIcon={<Icon name="plus" />} onClick={(e) => onAdd(e)}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
