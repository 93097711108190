import * as React from "react";
import { cx } from "emotion";
import { useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Highlighter from "react-highlight-words";
import { InputClearButton } from "../Input/Input";

import css from "./TextareaHighlightable.module.css";
import inputCSS from "../Input/Input.module.css";

const tokensRegexp = new RegExp(/\bnot\b|\bor\b|\band\b/gi);

export function TextareaHighlightable(props) {
  const oldCursor = useRef();
  const oldValue = useRef();

  const onKeyDown = (e) => {
    oldCursor.current = e.target.selectionEnd;
    oldValue.current = e.target.value;
  };

  const onInput = (e) => {
    e.target.value = toUpperCaseTokens(e.target.value);

    if (oldValue.current.length <= e.target.value.length) {
      e.target.setSelectionRange(oldCursor.current + 1, oldCursor.current + 1);
    } else if (oldValue.current.length > e.target.value.length) {
      const newCursor = oldCursor.current - 1 <= 0 ? 0 : oldCursor.current - 1;
      e.target.setSelectionRange(newCursor, newCursor);
    }
  };

  const getChunksToHighlight = ({ textToHighlight }) => {
    const withoutTextInQuotes = textToHighlight.replace(/"[^"]+"/gi, (v) => "x".repeat(v.length));
    const match = Array.from(withoutTextInQuotes.matchAll(tokensRegexp));
    return match.reduce((acc, item) => {
      acc.push({
        start: item.index,
        end: item.index + item[0].length,
      });
      return acc;
    }, []);
  };

  const toUpperCaseTokens = (text) => {
    let result = "";
    const skippedQuotes = text.replace(/"[^"]+"/gi, (v) => "x".repeat(v.length));
    const uppercaseTokens = skippedQuotes.replace(tokensRegexp, (v) => v.toUpperCase());

    for (let i = 0; i < uppercaseTokens.length; i++) {
      const letter = uppercaseTokens[i];
      if (letter === "x") {
        result += text[i];
      } else {
        result += letter;
      }
    }
    return result;
  };

  return (
    <div
      className={cx(css.container, props.className, {
        [inputCSS.clearable]: !!props.clearable && !!props.value,
      })}
    >
      <TextareaAutosize
        className={css.textarea}
        onKeyDown={onKeyDown}
        onInput={onInput}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
      />
      <Highlighter
        className={css.highlighter}
        searchWords={[]}
        highlightClassName={css.highlightedWord}
        findChunks={getChunksToHighlight}
        textToHighlight={props.value}
      />
      {props.clearable && <InputClearButton onClick={() => props.onChange({ target: { value: "" } })} />}
    </div>
  );
}
