import { Elements } from "@stripe/react-stripe-js";
import { css as emotionCss } from "emotion";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useNotifications } from "../../../../components/DiscoverNew/contexts/Notification/notifications";
import { Loading } from "../../../../components/DiscoverNew/UI/Loading/Loading";
import { withConfirm } from "../../../../components/hoc/withConfirm";
import EditPlanModal from "../../../../components/modals/EditPlanModal/EditPlanModal";
import { InviteMemberModal } from "../../../../components/modals/InviteMemberModal/InviteMemberModal";
import PurchaseCreditsModal from "../../../../components/modals/PurchaseCreditsModal/PurchaseCreditsModal";
import PurchasePlanModal from "../../../../components/modals/PurchasePlanModal/PurchasePlanModal";
import { useAuthController } from "../../../../contexts/AuthProvider";
import {
  billingService,
  CurrentPlanInterface,
  initializeStripe,
  PaymentMethodInterface,
  PlanInfoInterface,
  PlanName,
  PlansInfoMap,
} from "../../../../services/BillingService";
import { CurrentPlanPaidView } from "./CurrentPlanView/CurrentPlanPaidView/CurrentPlanPaidView";
import { CurrentPlanTrialView } from "./CurrentPlanView/CurrentPlanTrialView/CurrentPlanTrialView";
import PaymentSettings from "./PaymentSettings/PaymentSettings";

type Props = {
  confirm: any
  history: any
}

const stripePromise = initializeStripe();

const BillingPayment = (props: Props) => {
  const notifications = useNotifications();
  const [initialized, setInitialized] = useState(false);
  const [plans, setPlans] = useState<PlansInfoMap>();
  const authController = useAuthController();
  const currentPlan = (authController.user as any).currentPlan as CurrentPlanInterface;

  const [planToPurchase, setPlanToPurchase] = useState<PlanInfoInterface>();
  const [isInviteMemberOpen, setIsInviteMemberOpen] = useState(false);
  const [isEditPlanOpen, setIsEditPlanOpen] = useState(false);
  const [isPurchaseCreditsModalOpen, setIsPurchaseCreditsModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethodInterface[]>([]);

  const fetchPaymentMethods = () => {
    return billingService.fetchPaymentMethods().then(setPaymentMethods);
  }

  useEffect(() => {
    Promise.all([
      billingService.fetchAvailablePlans(),
      billingService.fetchPaymentMethods(),
    ]).then(r => {
      setPlans(r[0]);
      setPaymentMethods(r[1]);
    }).finally(() => {
      setInitialized(true);
    })

    if (props.history.location.search.includes('extra-credits-purchase-success')) {
      notifications.showSuccess(`Extra credits have been added to your account!`);
      window.history.replaceState({}, "", props.history.location.pathname);
    } else if (props.history.location.search.includes('plan-update-success')) {
      notifications.showSuccess(`Your plan has been successfully updated!`);
      window.history.replaceState({}, "", props.history.location.pathname);
    }

  }, []);

  const onInvitedMember = () => {
    setIsInviteMemberOpen(false);
    authController.fetchUser();
  }

  const onCancelPlan = async () => {
    try {
      const isConfirmed = await props.confirm.open({
        content : <>Are you sure you want to cancel your plan?<br /> You will still have access until the end of the
          current<br /> billing period.</>,
        destructive: true,
        confirmButtonTitle : "Cancel Plan",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      });
      if (!isConfirmed) {
        return;
      }
      await billingService.cancelPlan();
      notifications.showSuccess(`Your plan will not be renewed!`);
      authController.fetchUser();
    } catch (err) {
      notifications.showError(`Couldn't cancel your plan!`);
    }
  }

  const onRenewPlan = async () => {
    try {
      const isConfirmed = await props.confirm.open({
        content : <>Are you sure you want to renew your plan?<br/></>,
        confirmButtonTitle : "Renew Plan",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      });
      if (!isConfirmed) {
        return;
      }
      await billingService.renewPlan();
      notifications.showSuccess(`Your plan has been renewed!`);
      authController.fetchUser();
    } catch (err) {
      notifications.showError(`Couldn't renew your plan!`);
    }
  }

  if (!initialized) {
    return <Loading />
  }

  if (!plans) {
    return <h2>Couldn't fetch billing info</h2>
  }

  if (!currentPlan) {
    return null;
  }

  let planComponent = null;

  if (currentPlan.planInfo.name === PlanName.trial) {
    planComponent = <CurrentPlanTrialView
      onInviteMember={() => setIsInviteMemberOpen(true)} plans={plans} onPurchasePlan={setPlanToPurchase} currentPlan={currentPlan} />;
  }

  if (currentPlan.planInfo.name === PlanName.sourcing) {
    planComponent = <CurrentPlanPaidView
      onInviteMember={() => setIsInviteMemberOpen(true)} currentPlan={currentPlan}
      onEditPlan={() => setIsEditPlanOpen(true)}
      onCancelPlan={onCancelPlan}
      onRenewPlan={onRenewPlan}
      onPurchaseCredits={() => setIsPurchaseCreditsModalOpen(true)}
    />;
  }

  return (
    <div>
      {planComponent}
      <PaymentSettings fetchData={fetchPaymentMethods} paymentMethods={paymentMethods} currentPlan={currentPlan} />
      {!!planToPurchase && (
        <PurchasePlanModal onClose={() => setPlanToPurchase(undefined)} plan={planToPurchase} />
      )}
      {isInviteMemberOpen && (
        <InviteMemberModal onClose={() => setIsInviteMemberOpen(false)} onSubmitSuccess={onInvitedMember} onPurchaseSeat={() => {
          setIsInviteMemberOpen(false);
          setIsEditPlanOpen(true);
        }} />
      )}
      {isEditPlanOpen && (
        <Elements stripe={stripePromise} options={{ currency: 'usd' }}>
          <EditPlanModal onClose={() => setIsEditPlanOpen(false)} />
        </Elements>
      )}
      {isPurchaseCreditsModalOpen && (
        <Elements stripe={stripePromise} options={{ currency: 'usd' }}>
          <PurchaseCreditsModal
            onClose={() => setIsPurchaseCreditsModalOpen(false)}
            onSuccess={() => setIsPurchaseCreditsModalOpen(false)}
          />
        </Elements>
      )}
    </div>
  );
};

// @ts-ignore
export default withConfirm(withRouter(BillingPayment));
