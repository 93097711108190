import React, { useState } from "react";

// Components
import NoteFooterDetail from "./NoteFooterDetail";
import Notes from "./MobileNotes";

import "../Mobile.css";

function MobileNoteFooter({ candidate, candidateNotes, saveNote, onEditNoteSaved }) {
  const note = candidateNotes.length ? candidateNotes[0] : null;
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const toggleNotesModal = () => {
    setIsNotesModalOpen(!isNotesModalOpen);
  };

  const onViewNotes = () => {
    toggleNotesModal();
    window.analytics.track("Candidate Note Viewed", {
      candidate_id: candidate?.candidateId,
      state: candidate?.location?.region,
      locality: candidate?.location?.locality,
      is_unlocked: candidate?.unlockedAt,
    });
  };

  return (
    <div className="row d-block d-lg-none notes-container">
      <div className="notes-container-content">
        <div className="notes-header-container">
          <h6 className="bold-header-text">Notes</h6>
          <div className="create-view-note-button" onClick={() => onViewNotes()}>
            {note ? "Add/View All" : "Add Note"}
          </div>
        </div>
        <div className="note-preview-container">
          {note ? (
            <NoteFooterDetail note={note} />
          ) : (
            <div className="empty-notes-container">
              <p className="no-notes-text">There are no notes added yet. </p>
            </div>
          )}
        </div>
        {/* Create/View Notes Modal */}
        {isNotesModalOpen && (
          <Notes
            candidateNotes={candidateNotes}
            show={isNotesModalOpen}
            toggleNotesModal={toggleNotesModal}
            onSave={saveNote}
            onEditNoteSaved={onEditNoteSaved}
          />
        )}
        <div className="copyright-text-container">
          <p className="copyright-text">
            Copyright © 2020 Chatter, Inc. All rights reserved. Patents pending. Trademarks are the properties of their
            respective owners.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MobileNoteFooter;
