import React, { useState } from "react";
import PropTypes from "prop-types";
import { cx } from "emotion";
import Toast from "react-bootstrap/Toast";

import { BOUNCER_VALID_STATUS } from "constants/constants";

import validationPendingIcon from "assets/images/contact/email-badge-pending.svg";
import validationTrustedIcon from "assets/images/contact/email-badge-trusted.svg";
import validationUntrustedIcon from "assets/images/contact/email-badge-untrusted.svg";

import { Link } from "../../../../UI/Link/Link";
import { Icon } from "../../../../UI/IconComponent/Icon";
import { ContactButton } from "../../../../UI/ContactButton/ContactButton";
import { Dropdown } from "../../../../UI/Dropdown/Dropdown";
import { copyText } from "../../../../util/util";

import css from "./DiscoverCandidateContactButton.module.css";

export function DiscoverCandidateContactButton(props) {
  const [isVisibleSuccess, setIsVisibleSuccess] = useState(false);
  const disabled = !props.data?.length || props.disabled;

  function onTextCopy(text) {
    copyText(text);
    setIsVisibleSuccess(true);
  }

  const renderVerificationIcon = (status) => {
    let src = null;
    let isPendingIcon = false;

    if (!status) {
      src = validationPendingIcon;
      isPendingIcon = true;
    } else if (status === BOUNCER_VALID_STATUS) {
      src = validationTrustedIcon;
    } else {
      src = validationUntrustedIcon;
    }

    return (
      <img
        src={src}
        alt=""
        width="12px"
        height="12px"
        className={cx("align-super", css.icon, { [css.pendingIcon]: isPendingIcon })}
      />
    );
  };

  if (disabled) {
    return <ContactButton disabled>{props.children}</ContactButton>;
  }

  const linkTypeByContactType = {
    phone: "tel",
    sms: "sms",
    email: "mailto",
  }

  return (
    <Dropdown
      classNames={{ content: css.dropdownContent }}
      contentStyle={{ zIndex: 1000 }}
      label={<ContactButton>{props.children}</ContactButton>}
      onOpen={props.onOpen}
    >
      {isVisibleSuccess && (
        <Toast
          autohide
          onClose={() => setIsVisibleSuccess(false)}
          show={isVisibleSuccess}
          delay={500}
          className={css.successContainer}
        >
          <Toast.Body className={css.successContent}>Copied!</Toast.Body>
        </Toast>
      )}

      {props.data.map((item) => {
        return (
          <div className={css.item} key={item.id}>
            <div className={css.iconWithEmailWrapper}>
              {props.isStatusVisible && !props.isLocked && renderVerificationIcon(item.status)}
              {!props.isLocked ? (
                <a href={`${linkTypeByContactType[props.type]}:${item.label}`} className={css.value}>{item.label}</a>
              ) : <span className={css.value}>{item.label}</span>}
            </div>
            {props.isLocked ? (
              <Link onClick={props.onUnlock}>
                Unlock <Icon name="lock" />
              </Link>
            ) : (
              <Link onClick={() => onTextCopy(item.value)}>Copy</Link>
            )}
          </div>
        );
      })}
    </Dropdown>
  );
}

DiscoverCandidateContactButton.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isLocked: PropTypes.bool,
  onUnlock: PropTypes.func,
  onOpen: PropTypes.func,
};
