import React, { Component } from "react";
import { googleApiService } from "../../services/GoogleApiService";
import { Input } from "../Input/Input";
import { AUTOCOMPLETE_OFF } from "../../../../constants/constants";

export class AddressAutocomplete extends Component {
  inputRef = React.createRef();
  mounted = true;
  autocomplete;

  async componentDidMount() {
    await googleApiService.loadMapsApi();
    if (this.mounted) {
      this.attachAutocomplete();
      document.addEventListener("click", this.onBlur);
    }
  }

  componentWillUnmount() {
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
    }
    document.removeEventListener("click", this.onBlur);
    this.mounted = false;
  }

  attachAutocomplete = () => {
    if (!this.inputRef.current) {
      return;
    }

    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(this.inputRef.current, {
      types: this.props.cityOnly ? ["(cities)"] : [],
      componentRestrictions: { country: this.props.country || "us" },
    });

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);

    setTimeout(() => {
      // @todo this.inputRef.current cannot be undefined
      if (this.inputRef.current) {
        this.inputRef.current.autocomplete = AUTOCOMPLETE_OFF;
      }
    }, 100);
  };

  onPlaceChanged = () => {
    const { onChange, cityOnly } = this.props;
    const place = this.autocomplete.getPlace();
    if (place && place.address_components) {
      const address = googleApiService.parseAddress(place);
      if (!onChange) {
        return null;
      }

      const data = {
        ...address,
        googlePlace: place,
      };

      if (cityOnly) {
        if (address.city) {
          onChange(data);
        } else {
          this.inputRef.current.value = "";
        }
      } else {
        onChange(data);
      }
    }
  };

  onBlur = (e) => {
    const hasClickedOnGoogleDropdown = document.querySelector(".pac-item")?.contains(e.target);
    // reset the input value in case the user didn't choose any value and just closed the dropdown
    if (!hasClickedOnGoogleDropdown && this.props.defaultValue !== undefined) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  };

  render() {
    const { defaultValue, placeholder, icon, className } = this.props;
    return (
      <Input
        ref={this.inputRef}
        icon={icon}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={className}
      />
    );
  }
}
