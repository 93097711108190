import React, { KeyboardEventHandler } from "react";

// @ts-ignore
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

export const FreeSelect = ({ value, onChange, placeholder }: any) => {
  const [inputValue, setInputValue] = React.useState("");
  // const [value, setValue] = React.useState<readonly Option[]>([]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        // setValue((prev: any) => [...prev, createOption(inputValue)]);
        onChange([...value, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onInputChange={(newValue: any) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      onChange={(newValue: any) => onChange(newValue)}
      value={value}
    />
  );
};
