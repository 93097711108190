import React from "react";
import { AddressAutocomplete } from "../AddressAutocomplete/AddressAutocomplete";

export function InputCity(props) {
  const { placeholder, className, icon, value, onChange, disabled, style, country } = props;

  async function handleChange(value) {
    const name = value.formattedAddress;
    const lat = value.googlePlace.geometry.location.lat();
    const lng = value.googlePlace.geometry.location.lng();
    onChange({ name, lat, lng });
  }

  return (
    <AddressAutocomplete
      cityOnly
      country={country}
      className={className}
      icon={icon}
      style={style}
      onChange={handleChange}
      disabled={disabled}
      key={JSON.stringify(value || {})}
      defaultValue={value?.name || ""}
      placeholder={placeholder}
    />
  );
}
