import React from "react";

import { css, cx } from "emotion/macro";

function CompanyProject({ project = {}, onClick, isChecked = false }) {
  return (
    <div
      className={cx(
        "CompanyProject__project-name-container",
        css`
          margin-bottom: 5px;
        `
      )}
      onClick={onClick}
    >
      <div className="CompanyProject__candidate-project-name-icon-container">
        <div className={"CompanyProject__candidate-project-icon-container" + (isChecked ? "-checked" : "")} />
        <span className="CompanyProject__name truncate-least">{project.name}</span>
      </div>
      <div className="CompanyProject__add-remove-button-container">
        <span>{isChecked ? "Remove" : "Add"}</span>
      </div>
    </div>
  );
}

export default CompanyProject;
