import { cx } from "emotion";
import * as React from "react";

import css from "./Tag.module.css";

const classNameByTheme = {
  red: css.red,
  gray: css.gray,
};

const classNameByThemeType = {
  outline: css.outline,
  underline: css.underline
};

type Props = {
  theme?: 'red' | 'gray'
  themeType?: 'outline' | 'underline'
  href?: string
  target?: string
  onClick?: () => void
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export function Tag(props: Props) {
  const Component = props.href ? "a" : "div";
  return (
    <Component
      className={cx(css.tag,
        props.theme ? classNameByTheme[props.theme] : '',
        props.themeType ? classNameByThemeType[props.themeType] : '',
        props.className
      )}
      href={props.href}
      target={props.target}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </Component>
  );
}