import * as React from "react";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import { BackButton } from "../../../components/ui/BackButton/BackButton";
import { InfoTable } from "../../../components/ui/InfoTable/InfoTable";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { Tabs } from "../../../components/ui/Tabs/Tabs";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ContextMenuDropdown } from "../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

import css from "./AdminOrganization.module.css";
import { useAuthController } from "../../../contexts/AuthProvider";
import { adminService } from "../../../services/AdminService";
import { AdminSubscriptionList } from "./AdminSubscriptionList/AdminSubscriptionList";
import { AdminOrganizationMemberList } from "./AdminOrganizationMemberList/AdminOrganizationMemberList";
import { AdminOrganizationModal } from "../../../components/modals/AdminOrganizationModal";
import { userService } from "../../projects/UserService";
import { Link } from "../../../components/DiscoverNew/UI/Link/Link";

export class AdminOrganization extends React.Component {
  cleanStyleOverride;

  constructor(props) {
    super(props);
    this.state = {
      initializing : true,
      activating : false,
      organization : undefined,
      isEditOrganizationModalOpen : false,
    };
  }

  async componentDidMount() {
    this.cleanStyleOverride = injectStyleOverride();
    try {
      await this.fetchOrganization();
      this.setState({ initializing : false });
    } catch (err) {
      this.setState({ initializing : false });
      console.error(`couldn't fetch the organization`, err);
    }
  }

  componentWillUnmount() {
    this.cleanStyleOverride();
  }

  fetchOrganization = async () => {
    const organization = await adminService.fetchOrganizationById(this.props.match.params.organizationId);
    this.setState({ organization });
  };

  onEditOrganization = () => this.setState({ isEditOrganizationModalOpen : true });
  onCloseEditOrganization = () => this.setState({ isEditOrganizationModalOpen : false });
  onOrganizationUpdated = () => {
    this.onCloseEditOrganization();
    this.fetchOrganization();
  };


  render() {
    const { initializing, organization } = this.state;

    if (initializing) {
      return (
        <div className="app">
          <div className="max-width">
            <Loading />
          </div>
        </div>
      );
    }


    return (
      <div className="app">
        <div className="max-width">
          <BackButton to="/admin/organizationss" />
          <div style={{ display : "flex", justifyContent : "space-between", marginBottom : 10 }}>
            <h1 className={css.title}>{organization.name}</h1>
            <ContextMenuDropdown
              contentStyle={{ width: 'unset' }}
              label={<ContextMenuDropdown.ActionButton />}
            >
              <ContextMenuDropdown.Item icon='pencil-2' onClick={this.onEditOrganization}>
                Edit
              </ContextMenuDropdown.Item>
            </ContextMenuDropdown>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start'}}>
            <InfoTable>
              <tr>
                <td>Name:</td>
                <td>{organization.name}</td>
              </tr>
              <tr>
                <td>Created:</td>
                <td>{Formatter.fullDateTime(organization.createdAt)}</td>
              </tr>
              <tr>
                <td>Has Active Subscription:</td>
                <td>{Formatter.yesNo(organization.hasActiveSubscription)}</td>
              </tr>
              <tr>
                <td>Stripe Customer:</td>
                <td>{organization.stripeCustomerId ? <a href={userService.getStripeCustomerURL(organization.stripeCustomerId)} target="_blank">{organization.stripeCustomerId}</a>   : '–'}</td>
              </tr>
            </InfoTable>
          </div>
          <Tabs style={{ marginTop : 30, marginBottom : 20 }}>
            <Tabs.Tab
              style={{ minWidth: 185}}
              component={Link}
              to={`/admin/organizations/${organization.id}/subscriptions`}
              active={this.props.history.location.pathname === `/admin/organizations/${organization.id}/subscriptions`}
            >
              Subscriptions
            </Tabs.Tab>
            <Tabs.Tab
              style={{ minWidth: 185}}
              component={Link}
              to={`/admin/organizations/${organization.id}/members`}
              active={this.props.history.location.pathname === `/admin/organizations/${organization.id}/members`}
            >
              Members
            </Tabs.Tab>
          </Tabs>
          <Switch>
            <Route path="/admin/organizations/:organizationId/subscriptions">
              <AdminSubscriptionList organizationId={organization.id} onUpdated={this.fetchOrganization} subscriptions={organization.subscriptions} />
            </Route>
            <Route path="/admin/organizations/:organizationId/members">
              <AdminOrganizationMemberList members={organization.members} />
            </Route>
            <Redirect to={`/admin/organizations/${this.props.match.params.organizationId}/subscriptions`} />
          </Switch>
        </div>
        {this.state.isEditOrganizationModalOpen && (
          <AdminOrganizationModal
            organizationId={organization.id}
            onClose={this.onCloseEditOrganization}
            onOrganizationUpdated={this.onOrganizationUpdated}
          />
        )}
      </div>
    );
  }
}

AdminOrganization = (function withUserReducer(WrappedComponent) {
  return function(props) {
    const notifications = useNotifications();
    const history = useHistory();
    const { user } = useAuthController();
    return <WrappedComponent {...props} history={history} notifications={notifications} currentUser={user} />;
  };
})(AdminOrganization);

AdminOrganization = withConfirm(AdminOrganization);
