import { cx } from "emotion";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { COLLABORATOR_ROLE, ROUTES } from "../../../../constants/constants";
import { ProjectInterface } from "../../../../screens/projects/CandidateService";
import { EntityConnection } from "../../../../types";
import { ButtonHTML } from "../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { DropdownFilterMultiselect } from "../../../ui/DropdownFilterMultiselect/DropdownFilterMultiselect";

import css from "./CandidatesPageSidebar.module.css";

type Props = {
  projects: EntityConnection<ProjectInterface>
  project?: ProjectInterface
  onProjectClick: (project: ProjectInterface | null) => void
}

type FilterValue = 'all' | 'mine' | 'shared-with-me';

export const CandidatesPageSidebar: React.FC<Props> = (props) => {
  const [openedCompanyIds, setOpenedCompanyIds] = React.useState<Record<string, boolean>>({});
  const [filter, setFilter] = React.useState<FilterValue>('all');

  let projects = props.projects.nodes;
  if (filter === 'mine') {
    projects = props.projects.nodes.filter(project => project.currentUserRole === COLLABORATOR_ROLE.OWNER);
  } else if (filter === 'shared-with-me') {
    projects = props.projects.nodes.filter(project => project.currentUserRole !== COLLABORATOR_ROLE.OWNER);
  }

  const companiesWithProjects = projects.reduce((acc, project) => {
    if (project.company) {
      if (!acc[project.company.id]) {
        acc[project.company.id] = {
          ...project.company,
        };
      }
      acc[project.company.id].projects = [...(acc[project.company.id].projects || []), project];
    }
    return acc;
  }, {} as { [key: string]: any });

  const projectToCompanyIdMap = props.projects.nodes.reduce((acc, project) => {
    if (project.company) {
      acc[project.id] = project.company.id;
    }
    return acc;
  }, {} as { [key: string]: any });

  const toggleOpenedCompany = (id: string) => {
    setOpenedCompanyIds({
      ...openedCompanyIds,
      [id]: !openedCompanyIds[id],
    });
  }

  React.useEffect(() => {
    if (props.project) {
      toggleOpenedCompany(projectToCompanyIdMap[props.project.id]);
    }
  }, []);

  const companiesWithProjectsArray = Object.values(companiesWithProjects);

  return (
    <aside className={css.sidebar}>
      <span className={cx(css.linkListSection, css.active)} style={{ borderBottom: 0, paddingBottom: 4, paddingTop: 24 }}>
        Projects
        <DropdownFilterMultiselect type="radio" labelStyle={{ marginLeft : 8 }} value={filter} onChange={value => {
          setFilter(value as FilterValue)
        }} options={[
          {value : 'all', label: 'All'},
          {value : 'mine', label: 'Only mine'},
          {value : 'shared-with-me', label: 'Only shared with me'},
        ]}/>
      </span>
      <NavLink to={ROUTES.candidates} exact activeClassName={css.active} onClick={() => props.onProjectClick(null)}
               className={css.linkListSection}>
        <Icon name="folder" style={{ marginRight : 8 }} />
        All
      </NavLink>
      {companiesWithProjectsArray?.length ? companiesWithProjectsArray.map(company => {
        const isOpened = openedCompanyIds[company.id];
        return (
          <div key={company.id}>
            <span className={cx(css.linkListSection, { [css.opened] : isOpened })} style={{ cursor: 'pointer' }} onClick={() => toggleOpenedCompany(company.id)}>
              <Icon name="folder" style={{ marginRight : 8 }} />
              {company.name}
              <ButtonHTML className={css.linkListItemToggle}><Icon name="fa-chevron" /></ButtonHTML>
            </span>
            {isOpened && (
              <div>
                {company.projects.map((project: ProjectInterface) => {
                  return (
                    <NavLink to={`${ROUTES.candidates}/${project.id}`} key={project.id} activeClassName={css.active}
                             className={css.linkListItemInner} onClick={() => props.onProjectClick(project)}>
                      <Icon name="suitcase" style={{ marginRight : 8 }} />
                      {project.name}
                    </NavLink>
                  );
                })}
              </div>
            )}
          </div>
        );
      }) : (
        <p className={css.noProjects}>You don't have any {filter === 'shared-with-me' ? 'shared ' : ' '}projects yet</p>
      )}
      {/* todo [candidates] [feature] */}
      {/*<NavLink to={`/my-candidates/archive`} activeClassName={css.active} className={css.linkListItem}>*/}
      {/*  <Icon name='archive' style={{ marginRight: 8 }} />*/}
      {/*  Archive*/}
      {/*</NavLink>*/}
    </aside>
  );
};