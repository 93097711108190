import * as React from "react";
import { useEffect } from "react";
import { Notifications } from "../../components/DiscoverNew/contexts/Notification/notifications";
import Projects from "./projects";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";

export function ProjectsWrapper() {
  useEffect(() => {
    return injectStyleOverride();
  }, []);
  return (
    <Notifications>
      <div className="app">
        <Projects />
      </div>
    </Notifications>
  );
}
