import { identity, capitalize } from "lodash";
import { DateTime, Interval } from "luxon";

export const Formatter = {
  fullName: (user) => {
    if (!user) {
      return "";
    }
    return [user.firstName, user.lastName].filter(Boolean).map(capitalize).join(" ").trim();
  },
  humanize: (text) => {
    return text.toLowerCase().replace(/_/g, " ").split(" ").filter(Boolean).map(capitalize).join(" ");
  },
  yesNo: (value) => {
    return value ? "Yes" : "No";
  },
  address: (address) => {
    return [address.city, address.state].filter(Boolean).join(", ");
  },
  date: (date) => {
    if (!date) {
      return "";
    }
    return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "short" });
  },
  fullDate: (date) => {
    if (!date) {
      return "";
    }
    return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "2-digit" });
  },
  fullDateTime: (date) => {
    if (!date) {
      return "";
    }
    return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });
  },
  dateRange: (startDate, endDate) => {
    const dates = [startDate, endDate];
    return dates.filter(identity).map(Formatter.date).join(" – ");
  },
  duration: (startDate, endDate) => {
    if (!startDate || !endDate) {
      return "";
    }

    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);

    const interval = Interval.fromDateTimes(start, end);

    if (!interval.isValid) {
      return "";
    }

    const data = interval.toDuration(["years", "months"]).values;
    const years = Math.floor(data.years);
    const months = Math.floor(data.months);
    return `${years ? years + ` year${years > 1 ? "s" : ""} ` : ""} ${
      months ? months + ` month${months > 1 ? "s" : ""}` : ""
    }`;
  },

  highlightJson: (json) => {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var style = 'color: #A6D189'; // number
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          style = 'color: #E78284'; // key
        } else {
          style = 'color: #EF9F76'; // string
        }
      } else if (/true|false/.test(match)) {
        style = 'color: #89B4FA'; // bool
      } else if (/null/.test(match)) {
        style = 'color: #89B4FA'; // null
      }
      return '<span style="' + style + '">' + match + '</span>';
    });
  }
};
