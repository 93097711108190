import React, { useState, useEffect, useRef } from "react";
import { css, cx } from "emotion/macro";
import { useHistory, useLocation } from "react-router-dom";

import Icon from "../../icons/Icon";
import CloseIcon from "../../icons/Close";

import { fromSavedSearchFieldsModel } from "../../formatters/urlMapper";
import { useCandidates } from "../../contexts/candidates";

const IconButton = ({ Icon, onClick, label }) => {
  return (
    <div
      onClick={onClick}
      className={css`
        cursor: pointer;
        color: #a8a8a8;
        margin-top: -3px;

        svg {
          fill: #a8a8a8;
          stroke: #a8a8a8;
          transition: all 0.2s ease-in-out;
          width: 10px;
          height: 10px;
          margin-right: 5px;
        }

        &:hover svg {
          fill: #373737;
          stroke: #373737;
        }

        font-weight: 400;
      `}
    >
      <Icon />
      {label}
    </div>
  );
};

const DeleteButton = ({ onClick, shouldShowRemove }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div className={shouldShowRemove ? "slide-out-left" : ""}>
      <IconButton Icon={CloseIcon} onClick={handleClick} />
    </div>
  );
};

function useOutsideClick(containerRef, onOutsideClick) {
  useEffect(() => {
    function onOutsideClickHandler(e) {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        return;
      }

      onOutsideClick();
    }

    document.addEventListener("mousedown", onOutsideClickHandler);
    return () => {
      document.removeEventListener("mousedown", onOutsideClickHandler);
    };
  }, [containerRef, onOutsideClick]);
}

const ClickOutsideAlerter = (props) => {
  const ref = useRef();
  useOutsideClick(ref, props.onOutsideClick);

  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
      `}
      ref={ref}
    >
      {props.children}
    </div>
  );
};

export const RemoveComponent = ({ handleRemoveClick }) => {
  const [shouldShowRemove, setShouldShowRemove] = useState(false);

  const initialRemoveClick = () => {
    setShouldShowRemove(!shouldShowRemove);
  };

  const onOutsideClick = () => {
    setShouldShowRemove(false);
  };

  return (
    <>
      <ClickOutsideAlerter onOutsideClick={onOutsideClick}>
        <div
          className={css`
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
          `}
        >
          <DeleteButton shouldShowRemove={shouldShowRemove} onClick={initialRemoveClick} />
          <div
            className={cx(
              shouldShowRemove ? "fade-in-no-translate" : "faded",
              css`
                font-family: Montserrat-Medium !important;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.78px;
                color: #008bff;
                cursor: pointer;
                opacity: 0;
                position: absolute;
              `
            )}
            onClick={handleRemoveClick}
          >
            Remove
          </div>
        </div>
      </ClickOutsideAlerter>
    </>
  );
};

const ListItem = ({ removeAndRefreshSavedSearches, hideSavedSearchPanel, savedSearch, lastSavedSearch }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { dispatch } = useCandidates();

  const handleDeleteButtonPressed = (e) => {
    if (!savedSearch?.savedSearchId) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (removeAndRefreshSavedSearches) {
      removeAndRefreshSavedSearches(savedSearch?.savedSearchId);
    }
  };

  const handleSavedSearchClick = () => {
    if (!savedSearch?.fields) {
      return;
    }
    const urlString = fromSavedSearchFieldsModel(savedSearch?.fields);

    if (!urlString) {
      return;
    }
    history.replace(`${pathname}${urlString}`);

    dispatch({ type: "SET_SAVED_SEARCH_CACHE_MAP", payload: { [urlString]: true } });

    if (hideSavedSearchPanel) {
      hideSavedSearchPanel();
    }
  };

  return (
    <div
      onClick={() => handleSavedSearchClick()}
      className={cx(
        styles.listItemContainer,
        css`
          background-color: #ffffff;
          color: #000000;
          &:hover {
            cursor: pointer;
            background-color: #f2f2f2;
            .list-item-name {
              color: #000000;
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        `
      )}
    >
      <span
        className={cx(
          "list-item-name",
          { "bold-fade-text": lastSavedSearch?.savedSearchId === savedSearch?.savedSearchId },
          styles.name
        )}
      >
        {savedSearch?.name}
      </span>
      <RemoveComponent handleRemoveClick={handleDeleteButtonPressed}></RemoveComponent>
    </div>
  );
};

function SavedSearches({
  recentSearches = [],
  savedSearches = [],
  removeAndRefreshSavedSearches,
  hideSavedSearchPanel,
  closeSavedSearchPanel,
  lastSavedSearch,
}) {
  return (
    <>
      <div
        className={cx(css`
          width: 100%;
          display: flex;
          flex-flow: row;
          justify-content: flex-end;
          align-items: center;
        `)}
      >
        <Icon
          Icon={CloseIcon}
          onClick={closeSavedSearchPanel}
          extraStyles={css`
            text-align: right;
            margin-top: 15px;
            margin-right: 15px;
          `}
        />
      </div>

      <div className={cx(styles.savedSearchListContainer)}>
        <div className={cx(styles.listHeader)}>Recent Searches</div>
        <div className={cx(styles.recentListContainer)}>
          {recentSearches.map((savedSearch, key) => {
            return (
              <React.Fragment key={key}>
                <ListItem
                  savedSearch={savedSearch}
                  removeAndRefreshSavedSearches={removeAndRefreshSavedSearches}
                  hideSavedSearchPanel={hideSavedSearchPanel}
                  lastSavedSearch={lastSavedSearch}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div
          className={css`
            width: 192px;
            height: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0px 26.5px 0px;
            background-color: #d6d6d6;
          `}
        />
        <div className={cx(styles.listHeader)}>All Saved Searches</div>
        <div className={cx(styles.allSavedListContainer)}>
          {savedSearches.map((savedSearch, key) => {
            return (
              <React.Fragment key={key}>
                <ListItem
                  savedSearch={savedSearch}
                  removeAndRefreshSavedSearches={removeAndRefreshSavedSearches}
                  hideSavedSearchPanel={hideSavedSearchPanel}
                  lastSavedSearch={lastSavedSearch}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}

const styles = {
  savedSearchListContainer: css`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-top: 15px;
    height: 100%;
    padding: 0px 2px 0px 24px;
  `,
  listHeader: css`
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.41px;
    color: #000000;
    text-transform: uppercase;
  `,
  recentListContainer: css`
    width: 100%;
  `,
  allSavedListContainer: css`
    width: 100%;
    height: calc(100% - 345px);
    overflow-y: auto;
    padding-bottom: 30px;
  `,
  listItemContainer: css`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;

    height: 24px;
    margin-bottom: 12px;
    padding: 3px;
    padding-right: 5px;
    &:first-child {
      margin-top: 18px;
    }
  `,
  name: css`
    margin: 10px 0px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.57px;
    color: #6c6c6c;
  `,
  delete: css`
    display: none;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.61px;
    text-align: right;
    color: #208bfe;
  `,
  confirm: css`
    display: none;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.61px;
    text-align: right;
    color: #ffff00;
  `,
};

export default SavedSearches;
