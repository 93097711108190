import React from "react";
import { css, cx } from "emotion/macro";

import closeIcon from "../modals/close.svg";

export const CloseButton = ({ onClick = () => {} }) => (
  <div
    className={cx(
      "close-button-container",
      css`
        position: absolute;
        width: 30px;
        height: 30px;
        top: 15px;
        cursor: pointer;
        left: 20px;
        text-align: center;
      `
    )}
    onClick={onClick}
  >
    <img
      className={css`
        width: 13px;
        height: 15px;
      `}
      src={closeIcon}
      alt=""
    />
  </div>
);

export default CloseButton;
