import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { css, cx } from "emotion/macro";

import ModalButtonContainer from "../button/ModalButtonContainer";
import ModalInputField from "../input/ModalInputField";
import CloseButton from "../button/CloseButton";

import * as api from "../../api/apiService";

import { modalStyles } from "./modalStyles";

function NewCompanyModal({ isCreateCompanyModalOpen, toggleCreateModalWindow, close }) {
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  const [isWaiting, setIsWaiting] = useState(false);

  // Used for Save Button
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (companyName) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [companyName]);

  const clearInputs = () => {
    setCompanyName("");
    setCompanyWebsite();
  };

  const handleSaveButtonPressed = async () => {
    if (companyName === "") return;
    setIsWaiting(true);
    try {
      const response = await api.CreateCompany(companyName, companyWebsite);
      if (response.data) {
        window.analytics.track("Company Created", {
          name: companyName,
          website: companyWebsite,
          app_location: "company",
        });
      }
    } catch (e) {
      console.error("error creating company", e);
      return;
    }
    clearInputs();
    setIsWaiting(false);
    close();
  };

  // Handles case in which user clicks X button or clicks outside of the modal
  const handleModalClose = () => {
    clearInputs();
    toggleCreateModalWindow();
  };

  return (
    <Modal
      id="create-company-modal"
      show={isCreateCompanyModalOpen}
      onHide={handleModalClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={handleModalClose} />
      {/* Modal Body */}
      <div className={modalStyles.modalBody}>
        {/* Modal Header */}
        <div className={modalStyles.modalTitle}>New Company</div>
        {/* Modal Form Container */}
        <div className={cx("modal-form-container", modalStyles.modalFormContainer)}>
          {/* Modal Inputs Container */}
          <div className="modal-inputs-container">
            {/* Company Name */}
            <ModalInputField
              id="company-name-input"
              label={<div className={modalStyles.modalInputLabel}>Company Name</div>}
              placeholder="New Company, LLC"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
            {/* Company Website */}
            <ModalInputField
              id="company-website-input"
              label={
                <div className={modalStyles.modalInputLabel}>
                  <div
                    className={css`
                      display: inline;
                    `}
                  >
                    Website
                  </div>
                  &nbsp;
                  <div
                    className={css`
                      display: inline;
                      color: #a5a5a5;
                    `}
                  >
                    (for company logo)
                  </div>
                </div>
              }
              placeholder="www.example.com"
              onChange={(e) => setCompanyWebsite(e.target.value)}
              value={companyWebsite}
            />
          </div>
          <ModalButtonContainer title='Create' onClick={handleSaveButtonPressed} isDisabled={isDisabled} isWaiting={isWaiting} />
        </div>
      </div>
    </Modal>
  );
}

export default NewCompanyModal;
