import { DateTime } from "luxon";

export const getRelativeCalendarDate = (isoDateString) => {
  if (!isoDateString) return;
  return DateTime.fromISO(isoDateString).toRelativeCalendar();
};

export const getLocalShortDate = (isoDateString) => {
  if (!isoDateString) return;
  return DateTime.fromISO(isoDateString).toLocaleString(DateTime.DATE_SHORT);
};

export const getCurrentLocalShortDate = () => {
  return DateTime.local().toLocaleString(DateTime.DATE_SHORT);
};

export const getCurrentISOString = () => {
  return DateTime.local();
};

export const isBefore = (isoDateString) => {
  if (!isoDateString) {
    return true;
  }

  const t1 = DateTime.fromISO(isoDateString).toLocal();

  return t1 < DateTime.local();
};

export const isAfter = (isoDateString) => {
  if (!isoDateString) {
    return false;
  }

  const t1 = DateTime.fromISO(isoDateString).toLocal();

  return t1 > DateTime.local();
};