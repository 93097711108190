import { ProjectCandidateInterface } from "../../../../screens/projects/CandidateService";

type ProjectStageConfig = {
  sort: string
}

export type ProjectBoardConfig = {
  stages: Record<string, ProjectStageConfig>
}

function createEmptyConfig(): ProjectBoardConfig {
  return {
    stages: {}
  }
}

export class CandidateBoardHelper {

  static saveConfigForProject = async (projectId: number, config: ProjectBoardConfig): Promise<ProjectBoardConfig> => {
    const existingConfig = await this.getConfigForProject(projectId);
    const updatedConfig = {
      ...(existingConfig || {}),
      ...config
    }
    localStorage.setItem(`projectBoardConfig_${projectId}`, JSON.stringify(updatedConfig));
    return updatedConfig;
  }

  static getConfigForProject = async (projectId: number): Promise<ProjectBoardConfig> => {
    let config = localStorage.getItem(`projectBoardConfig_${projectId}`);
    if (!config) {
      config = JSON.stringify(createEmptyConfig());
      localStorage.setItem(`projectBoardConfig_${projectId}`, config);
    }
    return JSON.parse(config);
  }

  static updateStageConfigForProject = async (projectId: number, stageId: number, config: Partial<ProjectStageConfig>): Promise<ProjectBoardConfig> => {
    const existingProjectConfig = await this.getConfigForProject(projectId);
    existingProjectConfig.stages[stageId] = {
      ...(existingProjectConfig.stages[stageId] || {}),
      ...config,
    };
    return this.saveConfigForProject(projectId, existingProjectConfig)
  }

  static getSortOptions = () => {
    return [
      { label : "First Name (asc)", value : "first_name_asc" },
      { label : "First Name (desc)", value : "first_name_desc" },
      { label : "Last Name (asc)", value : "last_name_asc" },
      { label : "Last Name (desc)", value : "last_name_desc" },
      { label : "Date Added (asc)", value : "date_added_asc" },
      { label : "Date Added (desc)", value : "date_added_desc" },
      { label : "Date Last Activity (asc)", value : "date_last_activity_asc" },
      { label : "Date Last Activity (desc)", value : "date_last_activity_desc" },
    ];
  }

  static getSortedCandidates = (candidates: Array<ProjectCandidateInterface>, sort: string) => {
    const dataCopy = candidates.slice();
    switch (sort) {
      case 'first_name_asc':
        return dataCopy.sort((a, b) => a.firstName.localeCompare(b.firstName));
      case 'first_name_desc':
        return dataCopy.sort((a, b) => b.firstName.localeCompare(a.firstName));
      case 'last_name_asc':
        return dataCopy.sort((a, b) => a.lastName.localeCompare(b.lastName));
      case 'last_name_desc':
        return dataCopy.sort((a, b) => b.lastName.localeCompare(a.lastName));
      default:
        return dataCopy;
        // todo: add date added and date last activity
    }
  }
}