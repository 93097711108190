import React, { useState, useEffect, useContext } from "react";

export const AppContext = React.createContext();
export const useApp = () => useContext(AppContext);
export const AppProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const onResize = () => {
    if (window.innerWidth > 767) {
      setIsMobile(false);
    }

    if (window.innerWidth <= 767) {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return <AppContext.Provider value={{ isMobile }}>{children}</AppContext.Provider>;
};
