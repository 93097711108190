import React from "react";
import { useState } from "react";
import { Input, Label } from "reactstrap";
import { css, cx } from "emotion";

import { md } from "../../css/mediaQueries";

const CompanyEdit = ({ onSave, onCancel, company }) => {
  const [name, setName] = useState(company?.name || "");
  const [isNameError, setIsNameError] = useState(false);

  const [website, setWebsite] = useState(company?.website || "");

  const handleSaveButtonPressed = () => {
    const isValid = validateFields();
    if (!isValid) return;

    const trimmedName = name.trim();
    const trimmedWebsite = website.trim();

    let updatedCompany = {
      companyId: company?.companyId,
      name: trimmedName,
      website: trimmedWebsite,
    };

    setName("");
    setWebsite("");

    onSave(updatedCompany);
  };

  const validateFields = () => {
    let valid = true;
    if (name === "") {
      setIsNameError(true);
      valid = false;
    }

    return valid;
  };

  const refreshErrorMessage = (field) => {
    switch (field) {
      case "name":
        setIsNameError(false);
        break;
      default:
        console.log("field not supported");
    }
  };

  return (
    <div className={cx("col-12")}>
      <div
        className={cx(
          "row",
          css`
            margin-top: 30px;
          `
        )}
      >
        <Label
          for="company-name-input"
          className={cx(
            "standard-theme-text",
            css`
              margin-bottom: 15px;
            `
          )}
        >
          Company Name
        </Label>
        <div className={isNameError ? styles.inputContainerError : styles.inputContainer}>
          <Input
            id="company-name-input"
            bsSize="small"
            type="text"
            placeholder=""
            value={name}
            className={css`
              font-size: 16px;
            `}
            onChange={(e) => {
              refreshErrorMessage("name");
              setName(e.target.value);
            }}
          />
        </div>

        {isNameError && (
          <span
            className={css`
              width: 100%;
              margin: 5px 0 20px 0px;
              color: red;
              font-size: 11px;
              text-align: left;
            `}
          >
            Please enter the company name.
          </span>
        )}
        <Label
          for="company-webiste-input"
          className={cx(
            "standard-theme-text",
            css`
              margin-bottom: 15px;
            `
          )}
        >
          <div
            className={css`
              display: inline;
            `}
          >
            Website
          </div>
          &nbsp;
          <div
            className={css`
              display: inline;
              color: #a5a5a5;
            `}
          >
            (for company logo)
          </div>
        </Label>
        <div className={styles.inputContainer}>
          <Input
            id="company-website-input"
            bsSize="small"
            type="text"
            placeholder=""
            value={website}
            className={css`
              font-size: 16px;
            `}
            onChange={(e) => {
              setWebsite(e.target.value);
            }}
          />
        </div>
        <div
          className={css`
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 20px;
            ${md} {
              width: 100%;
              flex-flow: wrap-reverse;
            }
          `}
        >
          <div
            className={cx(
              css`
                width: 55%;
                ${md} {
                  width: 100%;
                  margin-top: 15px;
                  text-align: center;
                }
              `
            )}
          >
            <button
              onClick={() => onCancel()}
              className={cx(
                "btn btn-link",
                "standard-theme-text",
                css`
                  font-size: 18px;
                  letter-spacing: -0.69px;
                  color: #9b9b9b !important;
                  text-decoration: none !important;
                  height: 100%;
                  &:hover {
                    color: #787878 !important;
                  }
                `
              )}
            >
              Cancel
            </button>
          </div>
          <div
            className={cx(
              css`
                ${md} {
                  width: 100%;
                }
              `
            )}
          >
            <button
              onClick={() => handleSaveButtonPressed()}
              className={cx(
                "btn btn-outline-primary",
                css`
                  margin-top: 5px;
                  width: 93px;
                  height: 47px;
                  border-radius: 50px !important;
                  ${md} {
                    width: 100%;
                  }
                `
              )}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  inputContainer: css`
    width: 100%;
    margin-bottom: 30px;
  `,
  inputContainerError: css`
    width: 100%;
    margin-bottom: 0px;
  `,
};

export default CompanyEdit;
