import cn from "classnames";
import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import React from "react";
import { Checkbox } from "../../../../components/DiscoverNew/Form/Checkbox/Chekbox";
import { ORGANIZATION_MEMBER_ROLES, OrganizationMemberRole } from "../../../../services/OrganizationService";
import { PurchasePlanMember } from "../../../../types";

import classes from "./BillingMember.module.css";

type Props = {
  position: number;
  member: PurchasePlanMember
  onMemberEdit: () => void;
  canDisable: boolean;
  toggleDisabled: () => void;
};

const BillingMemberItem = ({ position, member, toggleDisabled, canDisable, onMemberEdit }: Props) => {
  const isAssigned = !!(member.email && member.role);
  return (
    <div className={cn(classes.item, canDisable && member.isActive && classes.isActive)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ minWidth: 30 }} className={classes.position}>{position}</span>
        {canDisable && <Checkbox checked={member.isActive} onClick={toggleDisabled} /> }
      </div>
      {isAssigned ? (
        <div style={{ display : "flex", alignItems : "center", maxWidth: '80%' }}>
          <div className={classes.member}>
            <p className={classes.memberName}>{member.email}</p>
            <p className={classes.memberRole}>{ORGANIZATION_MEMBER_ROLES[member.role].label}</p>
          </div>
          {member.role !== OrganizationMemberRole.Owner && member.isNew && (
            <button className={classes.editButton} onClick={onMemberEdit}>
              <Icon name="fa-pen" className={classes.penIcon} />
            </button>
          )}
        </div>
      ) : (
        <button className={classes.assignButton} onClick={onMemberEdit}>
          Assign member
        </button>
      )}
    </div>
  );
};

export default BillingMemberItem;
