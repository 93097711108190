import jsCookie from "js-cookie";

class CookieService {
  domain;

  constructor(domain) {
    this.domain = domain;
  }

  set(name, value) {
    if (!value) {
      console.warn(`[CookieService]: no value provided for ${value}`);
      return;
    }
    jsCookie.set(name, value, { path: "/", domain: this.domain });
  }

  get(name) {
    return jsCookie.get(name);
  }

  remove(name) {
    jsCookie.remove(name, { path: "/", domain: this.domain });
  }
}

export const cookieService = new CookieService(getCookieDomain());

function getCookieDomain() {
  const { hostname } = window.location;
  if (hostname.includes("localhost")) {
    return undefined;
  }
  return "." + window.location.hostname.split(".").slice(-2).join(".");
}
