import React from "react";

const CaretLeft = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 11.08" className={className}>
    <title>carrot-left</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <line x1="0.5" y1="5.58" x2="5.5" y2="10.58" style={{ strokeLinecap: "round", strokeMiterlimit: 10 }} />
        <line x1="0.5" y1="5.58" x2="5.5" y2="0.5" style={{ strokeLinecap: "round", strokeMiterlimit: 10 }} />
      </g>
    </g>
  </svg>
);

export default CaretLeft;
