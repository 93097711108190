import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "./antd-styles.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";

import { UserDataProvider } from "./contexts/userContext";
import { CandidatesProvider } from "./contexts/candidates";
import { GridViewProvider } from "./contexts/gridView";
import { ModalNavigatorProvider } from "./contexts/modalNavigator";
import { SortProvider } from "./contexts/sort";
import { AuthProvider } from "./contexts/AuthProvider";
import { Notifications } from "./components/DiscoverNew/contexts/Notification/notifications";
import { ProcessingStateProvider } from "./contexts/ProcessingState";
import { AppProvider } from "./contexts/appProvider";

ReactDOM.render(
  <AuthProvider>
    <AppProvider>
      <UserDataProvider state dispatch>
        <CandidatesProvider state dispatch>
          <GridViewProvider gridView updateGridView>
            <SortProvider state dispatch>
              <ModalNavigatorProvider state dispatch>
                <Notifications>
                  <ProcessingStateProvider>
                    <App />
                  </ProcessingStateProvider>
                </Notifications>
              </ModalNavigatorProvider>
            </SortProvider>
          </GridViewProvider>
        </CandidatesProvider>
      </UserDataProvider>
    </AppProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
