export const parseExplainDescription = (item) => {
  // weight(experiences.title:softwar in 1061009) [PerFieldSimilarity], result of:
  // ConstantScore(currentExperiences.company.size:51-200)
  let result = {
    fieldName: "",
    value: "",
    func: "",
  };

  const valueRegex = /^([a-zA-z0-9]*)\(([^:]*):([^)]*)/m;
  let valueMatches = valueRegex.exec(item.description);
  
  if (valueMatches) {
    result.func = valueMatches[1];
    result.fieldName = valueMatches[2];
    result.value = valueMatches[3].split(" in ")[0];
    return result;
  }

  const existRegex = /^([a-zA-z0-9]*)\(.*FieldExists.*\[field=(.*)\]\)/;
  let existMatches = existRegex.exec(item.description);
  if (existMatches) {
    result.func = existMatches[1];
    result.fieldName = existMatches[2];
    result.value = "_exists";
    return result;
  }

  else if (item.details?.length) {
    item.details.forEach(subItem => {
      let subParsed = parseExplainDescription(subItem);
      if (subParsed.fieldName) {
        result = subParsed;
      }
    });
  }

  return result;
};

export const getUnifiedExplain = (data) => {
  const parsedDetails = {};
  // TODO recursive
  data._explanation.details.filter(item => item.value > 0).forEach(item => {
    if (item.description === "sum of:" && item.details?.length) {
      const subParses = [];
      item.details.forEach(subItem => {
        let subParsed = parseExplainDescription(subItem);
        if (subParsed.fieldName) {
          subParses.push({
            ...subParsed,
            score: subItem.value
          });
        }
      });

      const temp = subParses.reduce((acc, item) => {
        if (!acc[item.fieldName]) {
          acc[item.fieldName] = {
            values: [item.value],
            funcs: [item.func],
            score: item.score,
          };
        } else {
          acc[item.fieldName].score += item.score;
          acc[item.fieldName].values.push(item.value);
          if (!acc[item.fieldName].funcs.includes(item.func)) {
            acc[item.fieldName].funcs.push(item.func);
          }
        }
        return acc;
      }, {});

      Object.keys(temp).forEach(key => {
        if (!parsedDetails[key]) {
          parsedDetails[key] = {
            values: temp[key].values,
            funcs: temp[key].funcs,
            score: temp[key].score,
          };
        } else {
          parsedDetails[key].score += temp[key].score;
          temp[key].values.forEach(item => {
            if (!parsedDetails[key].values.includes(item)) {
              parsedDetails[key].values.push(item);
            }
          });
          temp[key].funcs.forEach(item => {
            if (!parsedDetails[key].funcs.includes(item)) {
              parsedDetails[key].funcs.push(item);
            }
          });
        }
      });
      return;
    }

    if (item.description.startsWith("Score based on")) {
      parsedDetails["nested"] = {
        values: [item.value],
        funcs: ["nested"],
        score: item.value,
      };
      return;
    }
    const parsed = parseExplainDescription(item);
    if (parsed.fieldName) {
      if (!parsedDetails[parsed.fieldName]) {
        parsedDetails[parsed.fieldName] = {
          values: [parsed.value],
          funcs: [parsed.func],
          score: item.value,
        };
      } else {
        parsedDetails[parsed.fieldName].score += item.value;
        parsedDetails[parsed.fieldName].values.push(parsed.value);
        if (!parsedDetails[parsed.fieldName].funcs.includes(parsed.func)) {
          parsedDetails[parsed.fieldName].funcs.push(parsed.func);
        }
      }
      return;
    }

    // TODO:
    // if (item.description === "match on required clause, product of:") {
    //   let effectiveDetails = item.details.filter(item => item.value > 0);
    //   effectiveDetails.forEach(subItem => {

    //   });
    // }

    console.error("unhandled description", item.description, item.details);
  });

  return parsedDetails;
}