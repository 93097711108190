import { Elements } from "@stripe/react-stripe-js";

import { Button } from "components/DiscoverNew/UI/Button/Button";
import React from "react";
import { billingService, initializeStripe } from "../../../../services/BillingService";

import billingModalStyles from "../PurchasePlanModal.module.css";
import { StripePaymentForm } from "../StripePaymentForm/StripePaymentForm";

import styles from "./PaymentStep.module.css";

const stripePromise = initializeStripe();

type Props = {
  prevStep: () => void
  planData: any
  breakdown: {
    monthlyCredits: number
    totalPrice: number
  }
}

const PaymentStep = ({ prevStep, breakdown, planData }: Props) => {

  const [clientSecret, setClientSecret] = React.useState('');

  React.useEffect(() => {
    const onCreateIntent = async () => {
      const res = await billingService.createPaymentIntent(planData.planId, { seatsCount: planData.seatsCount, members: planData.members });
      return {
        clientSecret: res.paymentIntent.clientSecret,
      }
    }

    onCreateIntent().then(r => setClientSecret(r.clientSecret));
  }, []);

  if (!clientSecret) {
    return null;
  }

  return (
    <div className={styles.choosePaymentMethod}>
      <Elements options={{ clientSecret, loader: 'always' }} stripe={stripePromise}>
        <StripePaymentForm isFirstPurchase={!window.location.pathname.includes('billing')} clientSecret={clientSecret} controls={(isLoading) => (
          <>
            <div className={billingModalStyles.navigation}>
              <Button className={billingModalStyles.button} onClick={prevStep} theme="outline">
                Back
              </Button>
              <Button className={billingModalStyles.button} type='submit' disabled={isLoading}>
                {isLoading ? 'Processing...' : `Pay $${breakdown.totalPrice.toLocaleString()}`}
              </Button>
            </div>
          </>
        )} />
      </Elements>
    </div>
  );
};

export default PaymentStep;
