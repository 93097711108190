import cn from "classnames";
import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import { DateTime } from "luxon";
import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../components/DiscoverNew/UI/Button/Button";
import { ButtonText } from "../../../../../../components/DiscoverNew/UI/ButtonText/ButtonText";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import { ProgressBar } from "../../../../../../components/ui/ProgressBar/ProgressBar";
import { useAuthController } from "../../../../../../contexts/AuthProvider";
import {
  BillingPeriod,
  CurrentPlanInterface,
  ExternalSubscriptionStatus,
} from "../../../../../../services/BillingService";
import { PermissionEnum, permissionService } from "../../../../../projects/PermissionService";
import { PlanInfoCard } from "../../../ui/PlanInfoCard/PlanInfoCard";

import css from "./CurrentPlanPaidView.module.css";

type Props = {
  currentPlan: CurrentPlanInterface
  onInviteMember: () => void
  onEditPlan: () => void
  onCancelPlan: () => void
  onRenewPlan: () => void
  onPurchaseCredits: () => void
}

export const CurrentPlanPaidView = ({
  currentPlan,
  onInviteMember,
  onEditPlan,
  onCancelPlan,
  onRenewPlan,
  onPurchaseCredits,
}: Props) => {
  const user = useAuthController().user as any;
  const canInviteMember = permissionService.can(user, PermissionEnum.editOrganizationMembers);
  return (
    <div className={css.holder}>
      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems : 'stretch' }}>
        <SectionTitle level={2}>Current Plan</SectionTitle>
        <Button onClick={onEditPlan} leftIcon={<Icon name='fa-plus' />}>Purchase more seats</Button>
      </div>
      <PlanInfoCard style={{ display : "flex", justifyContent : "space-between" }}>
        <div style={{ display : "flex", gap : 50 }}>
          <PlanInfoCard.Item>
            <PlanInfoCard.ItemTitle>Plan</PlanInfoCard.ItemTitle>
            <PlanInfoCard.ItemValue>{currentPlan.planInfo.title}</PlanInfoCard.ItemValue>
          </PlanInfoCard.Item>
          <PlanInfoCard.Item>
            <PlanInfoCard.ItemTitle>Seats</PlanInfoCard.ItemTitle>
            <PlanInfoCard.ItemValue>{currentPlan.seats.count}</PlanInfoCard.ItemValue>
          </PlanInfoCard.Item>
          <PlanInfoCard.Item>
            <PlanInfoCard.ItemTitle>Credits</PlanInfoCard.ItemTitle>
            <PlanInfoCard.ItemValue>{currentPlan.planInfo.creditsPerSeat.toLocaleString()} <small>/
              seat</small></PlanInfoCard.ItemValue>
          </PlanInfoCard.Item>
          <PlanInfoCard.Item>
            <PlanInfoCard.ItemTitle>Cost</PlanInfoCard.ItemTitle>
            <PlanInfoCard.ItemValue>${currentPlan.price.toLocaleString()}{" "}<small>/ {currentPlan.planInfo.billingPeriod === BillingPeriod.monthly ? "month" : "year"}</small></PlanInfoCard.ItemValue>
          </PlanInfoCard.Item>
          <PlanInfoCard.Item>
            <PlanInfoCard.ItemTitle>{currentPlan.externalSubscriptionStatus === ExternalSubscriptionStatus.cancelled ? "Expiration Date" : "Renewal Date"}</PlanInfoCard.ItemTitle>
            <PlanInfoCard.ItemValue>{DateTime.fromISO(currentPlan.renewsAt!).toFormat("DD")}</PlanInfoCard.ItemValue>
          </PlanInfoCard.Item>
          {!!currentPlan.singleUseCredits.count && (
            <>
              <div style={{ height : '100%', borderLeft : '1px solid var(--c-blue)' }} />
              <PlanInfoCard.Item>
                <PlanInfoCard.ItemTitle>Extra Credits</PlanInfoCard.ItemTitle>
                <PlanInfoCard.ItemValue>{currentPlan.singleUseCredits.count.toLocaleString()}</PlanInfoCard.ItemValue>
              </PlanInfoCard.Item>
            </>
          )}
        </div>
        <div style={{
          display : "flex",
          flexDirection : "column",
          justifyContent : "space-between",
          alignItems : "flex-end",
        }}>
          {currentPlan.externalSubscriptionStatus === ExternalSubscriptionStatus.cancelled ? (
            <ButtonText iconName="fa-play" onClick={onRenewPlan}>Renew Plan</ButtonText>
          ) : (
            <>
              <ButtonText iconName="fa-edit" onClick={onEditPlan}>Modify Plan</ButtonText>
              <ButtonText theme="danger" iconName="cancel" onClick={onCancelPlan}>Cancel Plan</ButtonText>
            </>
          )}
        </div>
      </PlanInfoCard>
      <div className={css.row}>
        <PlanInfoCard className={css.progressBarCard}>
          <div className={css.progressBarCardRow}>
            <PlanInfoCard.ItemTitle>Members</PlanInfoCard.ItemTitle>
            <Link to={'/profile/billing/members'} className={cn(css.progressBarCardValue, css.clickable)}>
              {currentPlan.seats.used.toLocaleString()} of {currentPlan.seats.count.toLocaleString()} assigned
            </Link>
          </div>
          <ProgressBar value={currentPlan.seats.used / currentPlan.seats.count * 100} />
          <div className={css.progressBarCardRow}>
            {canInviteMember ?
              <ButtonText iconName="paper-plane" onClick={onInviteMember}>Invite new member</ButtonText> : <div />}
            <ButtonText iconName="fa-plus" onClick={onEditPlan}>Purchase more seats</ButtonText>
          </div>
        </PlanInfoCard>
        <PlanInfoCard className={css.progressBarCard}>
          <div className={css.progressBarCardRow}>
            <PlanInfoCard.ItemTitle>Yearly Credits</PlanInfoCard.ItemTitle>
            <div className={css.progressBarCardValue}>
              {currentPlan.organizationCredits.used.toLocaleString()} of {currentPlan.organizationCredits.count.toLocaleString()} credits
              used
            </div>
          </div>
          <ProgressBar value={currentPlan.organizationCredits.used / currentPlan.organizationCredits.count * 100} />
          <div className={css.progressBarCardRow}>
            <div />
            <ButtonText iconName="fa-plus" onClick={onPurchaseCredits}>Purchase extra credits</ButtonText>
          </div>
        </PlanInfoCard>
      </div>
    </div>
  );
};