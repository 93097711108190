import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../button/CloseButton";
import { modalStyles } from "../../../modals/modalStyles";
import { AddCandidateManually } from "./AddCandidateManually/AddCandidateManually";
type Props = {
  onClose: () => void;
}

enum AddCandidateMode {
  manual = 'manual',
  importFromService = 'importFromService',
  uploadFile = 'uploadFile',
  copyPaste = 'copyPaste',
}

export const ModalAddCandidate: React.FC<Props> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<AddCandidateMode>(AddCandidateMode.manual)
  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>Add Candidates</div>
        <div className={modalStyles.modalFormContainer}>
          {mode === AddCandidateMode.manual && <AddCandidateManually onSuccess={onClose} />}
        </div>
      </div>
    </Modal>
  );
};