import * as React from "react";
import css from "./FormField.module.css";

import { cx } from "emotion";

type FormFieldProps = {
  className?: string
  labelClassName?: string
  style?: React.CSSProperties
  label: any
  supportText?: string
  labelStyle?: React.CSSProperties
  htmlFor?: string
  children: React.ReactNode
  error?: any
  required?: boolean
}

export function FormField(props: FormFieldProps) {
  return (
    <div className={cx(props.className, css.field, { [css.error] : props.error })} style={props.style}>
      {props.label && (
        <FormField.Label className={props.labelClassName} style={props.labelStyle} htmlFor={props.htmlFor}>
          {props.label}{props.required && <sup className={css.requiredSign}>*</sup>}
        </FormField.Label>
      )}
      {props.children}
      {props.supportText && <div className={css.supportText}>{props.supportText}</div>}
      {!!props.error && <div className={css.errorText}>{props.error}</div>}
    </div>
  );
}

type FormLabelProps = {
  className?: string
  style?: React.CSSProperties
  htmlFor?: string
  children: React.ReactNode
}

FormField.Label = (props: FormLabelProps) => (
  <label style={props.style} htmlFor={props.htmlFor} className={cx(css.label, props.className)}>
    {props.children}
  </label>
);
