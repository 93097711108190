import { debounce } from "lodash";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";
import { Loading } from "../../components/DiscoverNew/UI/Loading/Loading";
import { cx } from "emotion";
import { useNotifications } from "../../components/DiscoverNew/contexts/Notification/notifications";
import { Formatter } from "../../components/DiscoverNew/util/Formatter";
import { UrlHelper } from "../../components/DiscoverNew/util/UrlHelper";
import { omit, pick } from "../../components/DiscoverNew/util/util";
import CandidateDetailModalWrapper from "../../components/modals/CandidateDetailModalWrapper";
import { DEFAULT_PAGE_SIZE } from "../../constants/constants";
import { useModalNavigator } from "../../contexts/modalNavigator";
import { useUserData } from "../../contexts/userContext";
import { projectService } from "../projects/ProjectService";
import { ProjectCandidatesTable } from "./ProjectCandidatesTable/ProjectCandidatesTable";
import { ProjectPageControls } from "./ProjectPageControls/ProjectPageControls";
import { ProjectPageHeader } from "./ProjectPageHeader/ProjectPageHeader";

import Complete from "../../components/Toast/AddingToWorkable/Complete";
import Processing from "../../components/Toast/AddingToWorkable/Processing";
import { withConfirm } from "../../components/hoc/withConfirm";
import { PROCESSGING_STATE_ACTION_TYPES, useProcessingState } from "../../contexts/ProcessingState";
import css from "./project.module.css";
import { sortIterableValuesOfObject } from "utils";
import { mapWorkableJobsToArray, unselectedWorkableJob } from "utils/projectFeatures";
import { EditProjectModal } from "../../components/modals/EditProjectModal/EditProjectModal";

const getDefaultProjectStage = () => ({ value: -1, label: "All Stages" });
const sortOptions = [
  { value: "ADDED_ASC", label: "Added (asc)", field: "created_at", dir: "ASC" },
  { value: "ADDED_DESC", label: "Added (desc)", field: "created_at", dir: "DESC" },
  { value: "NAME_ASC", label: "Name (asc)", field: "name", dir: "ASC" },
  { value: "NAME_DESC", label: "Name (desc)", field: "name", dir: "DESC" },
  { value: "STAGE_ASC", label: "Stage (asc)", field: "stage", dir: "ASC" },
  { value: "STAGE_DESC", label: "Stage (desc)", field: "stage", dir: "DESC" },
];

const jobSortOptions = [
  { value: "NAME_ASC", label: "Name (asc)", field: "label", asc: true, fieldType: "string" },
  { value: "NAME_DESC", label: "Name (desc)", field: "label", dir: "desc", fieldType: "string" },
  { value: "ADDED_ASC", label: "Date created (asc)", field: "createDate", asc: true, fieldType: "number" },
  { value: "ADDED_DESC", label: "Date created (desc)", field: "createDate", fieldType: "number" },
];

class Project extends React.Component {
  cleanOverride = () => null;

  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      candidatesLoading: true,
      filters: this.getDefaultFilters(),
      pagination: this.getDefaultPagination(),
      projectsData: undefined,
      candidates: undefined,
      selectedCandidate: undefined,
      selectedCandidates: {},
      workableJobs: {},
      selectedWorkableJob: unselectedWorkableJob,
      jobListSort: jobSortOptions[0],
    };
  }

  getDefaultFilters() {
    return {
      query: "",
      // @todo I need this because of using plain html select, it doesn't support 'null' or 'undefined'
      projectStage: getDefaultProjectStage(),
      sort: sortOptions[0],
    };
  }

  getDefaultPagination() {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  componentWillUnmount() {
    const {
      processingState: { dispatch },
    } = this.props;

    dispatch({ type: PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable, payload: { message: null } });

    this.cleanOverride();
  }

  async componentDidMount() {
    await this.restoreSearchFromUrl();
    this.cleanOverride = injectStyleOverride();
    try {
      await this.fetchData();
      this.setState({ pageLoading: false });
    } catch (err) {
      console.error("couldn't load the page", err);
      this.setState({ pageLoading: false });
    }
  }

  restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};
    const defaultPagination = this.getDefaultPagination();
    const defaultFilters = this.getDefaultFilters();

    return new Promise((resolve) => {
      this.setState(
        {
          filters: {
            ...defaultFilters,
            ...pick(filters, Object.keys(defaultFilters)),
            sort: filters.sort
              ? sortOptions.find((item) => item.value === filters.sort) || defaultFilters.sort
              : defaultFilters.sort,
            projectStage: filters.projectStageValue
              ? {
                  value: parseInt(filters.projectStageValue),
                  label: filters.projectStageLabel,
                }
              : defaultFilters.projectStage,
          },
          pagination: {
            currentPage: parseInt(currentPage || defaultPagination.currentPage),
            pageSize: parseInt(pageSize || defaultPagination.pageSize),
          },
        },
        resolve
      );
    });
  };

  saveSearchToUrl() {
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;
    const activeFilters = pick(filters, Object.keys(filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["projectStage", "sort"]),
      projectStageValue: activeFilters.projectStage.value,
      projectStageLabel: activeFilters.projectStage.label,
      sort: activeFilters.sort.value,
      pageSize,
      currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  }

  debouncedSaveSearchToUrl = debounce(this.saveSearchToUrl, 200);

  fetchData = async () => {
    const { query, sort, projectStage } = this.state.filters;
    const { id } = this.props.match.params;
    const { pageSize, currentPage } = this.state.pagination;
    const [project, candidates, projectStageOptions] = await Promise.all([
      projectService.fetchProject(id),
      projectService.fetchProjectCandidates({
        query,
        limit: pageSize,
        projectId: id,
        stageId: projectStage.value === -1 ? undefined : projectStage.value,
        skip: (currentPage - 1) * pageSize,
        orderBy: sort.field,
        sortBy: sort.dir.toLowerCase(),
      }),
      projectService.fetchProjectStageFlowOptions(id),
    ]);

    this.setState({
      project,
      candidates,
      projectStageOptions,
      workableJobs: project.workableJobs || {},
    });
  };

  debouncedFetchData = debounce(this.fetchData, 250);

  fetchWorkableJobs = async () => {
    const { id } = this.props.match.params;

    const workableJobs = await projectService.fetchWorkableJobs(id);

    this.setState({ workableJobs });
  };

  onChangeFilter = (filter) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filter,
        },
        pagination: {
          ...this.state.pagination,
          currentPage: 1,
        },
      },
      () => {
        if (filter.query) {
          this.debouncedFetchData();
          this.debouncedSaveSearchToUrl();
        } else {
          this.fetchData();
          this.saveSearchToUrl();
        }
      }
    );
  };

  onJobSortChange = (sortOption) => {
    this.setState({
      jobListSort: sortOption,
      workableJobs: sortIterableValuesOfObject(this.state.workableJobs, sortOption),
    });
  };

  onChangePagination = (pagination) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      },
      () => {
        this.saveSearchToUrl();
        this.fetchData();
      }
    );
  };

  onCandidateClick = (candidate) => {
    this.setState({ selectedCandidate: candidate });
  };

  onChangeCandidateStage = async (stage, candidate) => {
    const { notifications, confirm } = this.props;

    const isConfirmed = await confirm.open({
      content: (
        <>
          Are you sure you want to change stage for <b>{Formatter.fullName(candidate)}</b> to <b>{stage.label}</b>?
        </>
      ),
      confirmButtonTitle: "Change Stage",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await projectService.updateCandidateStage(this.state.project.id, candidate.id, stage.value);
      // @todo analytics
      window.analytics.track("Candidate Stage Updated", {
        candidate_id: candidate?.candidateId,
        state: candidate?.location?.region,
        locality: candidate?.location?.locality,
        is_unlocked: candidate?.unlockedAt ?? false,
        stage: stage?.label,
      });
      notifications.showSuccess("Candidate stage has been updated");
      this.fetchData();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't update the candidate stage");
    }
  };

  onSelectCandidate = (candidate) => {
    const { selectedCandidates } = this.state;
    if (selectedCandidates[candidate.id]) {
      this.setState({ selectedCandidates: omit(selectedCandidates, [candidate.id]) });
    } else {
      this.setState({
        selectedCandidates: {
          ...selectedCandidates,
          [candidate.id]: candidate.id,
        },
      });
    }
  };

  onAddingCandidatesToWorkable = async () => {
    const {
      processingState: { dispatch },
    } = this.props;

    dispatch({ type: PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable, payload: { state: true } });

    const { data } = await projectService.addCandidatesToWorkable(
      Object.values(this.state.selectedCandidates),
      this.state.selectedWorkableJob.value
    );

    let addedCandidatesCounter = 0;
    this.setState({
      candidates: {
        ...this.state.candidates,
        nodes: this.state.candidates.nodes.map((candidate) => {
          const integration = data.items.find((item) => item.candidateId === candidate.id);

          if (!integration?.workable) {
            return candidate;
          }

          addedCandidatesCounter += 1;
          return { ...candidate, integrations: { ...candidate.integrations, workable: integration.workable } };
        }),
      },
    });

    const resultMessage = `Successfuly added ${addedCandidatesCounter} of ${data.count} candidates`;

    dispatch({
      type: PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable,
      payload: { state: false, message: resultMessage },
    });
  };

  onProcessingNotificationDismiss = () => {
    const {
      processingState: { dispatch },
    } = this.props;

    dispatch({ type: PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable, payload: { state: false } });
  };

  onAddingCopleteNotificationDismiss = () => {
    const {
      processingState: { dispatch },
    } = this.props;

    dispatch({ type: PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable, payload: { message: null } });
  };

  isAllCandidatesSelected = () => {
    const { candidates, selectedCandidates } = this.state;
    return !!candidates?.nodes?.length && Object.keys(selectedCandidates).length === candidates?.nodes?.length;
  };

  onSelectAllCandidates = () => {
    if (this.isAllCandidatesSelected()) {
      this.setState({ selectedCandidates: {} });
    } else {
      this.setState({
        selectedCandidates: this.state.candidates?.nodes?.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.id,
          };
        }, {}),
      });
    }
  };

  archive = async (close) => {
    close();
    const { project } = this.state;
    const { notifications, uiSettings, confirm } = this.props;

    const isConfirmed = await confirm.open({
      content: (
        <>
          Are you sure you want to archive <b>{project.name}</b> {uiSettings.mappings.project.toLowerCase()}?
        </>
      ),
      confirmButtonTitle: "Archive",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await projectService.archive(project.projectId);
      // @todo analytics service
      window.analytics.track("Project Archived", {
        project_id: project.projectId,
        name: project.name,
        company_id: project.company?.companyId,
        company_name: project.company?.name,
      });

      notifications.showSuccess(`${uiSettings.mappings.project} has been archived!`);
      this.fetchData();
    } catch (err) {
      notifications.showError(`Couldn't archive the ${uiSettings.mappings.project}!`);
    }
  };

  unarchive = async (close) => {
    close();
    const { project } = this.state;
    const { notifications, uiSettings, confirm } = this.props;

    const isConfirmed = await confirm.open({
      content: (
        <>
          Are you sure you want to unarchive <b>{project.name}</b> {uiSettings.mappings.project.toLowerCase()}?
        </>
      ),
      confirmButtonTitle: "Unarchive",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await projectService.unarchive(project.projectId);
      // @todo analytics service
      window.analytics.track("Project Unarchived", {
        project_id: project.projectId,
        name: project.name,
        company_id: project.company?.companyId,
        company_name: project.company?.name,
      });

      notifications.showSuccess(`${uiSettings.mappings.project} has been unarchived!`);
      this.fetchData();
    } catch (err) {
      notifications.showError(`Couldn't unarchive the ${uiSettings.mappings.project}!`);
    }
  };

  delete = async (close) => {
    close();
    const { project } = this.state;
    const { notifications, uiSettings, confirm } = this.props;

    const isConfirmed = await confirm.open({
      destructive: true,
      content: (
        <>
          Are you sure you want to delete <b>{project.name}</b> {uiSettings.mappings.project.toLowerCase()}?
        </>
      ),
      confirmButtonTitle: "Delete",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await projectService.delete(project.projectId);
      // @todo analytics service
      window.analytics.track("Project Deleted", {
        project_id: project.projectId,
        name: project.name,
        company_id: project.company?.companyId,
        company_name: project.company?.name,
      });

      notifications.showSuccess(`${uiSettings.mappings.project} has been deleted!`);
      this.props.history.push("/projects");
    } catch (err) {
      notifications.showError(`Couldn't delete the ${uiSettings.mappings.project}!`);
    }
  };

  update = async () => {
    this.setState({ isEditModalOpened: false });
    this.fetchData();
  };

  removeCandidates = async () => {
    // @todo reset current page if all candidates from the page have been removed
    const { project, selectedCandidates } = this.state;
    const { notifications, uiSettings, confirm } = this.props;
    const candidatesCount = Object.keys(selectedCandidates).length;
    const candidatesText = candidatesCount > 1 ? uiSettings.mappings.candidates : uiSettings.mappings.candidate;

    const isConfirmed = await confirm.open({
      destructive: true,
      content: (
        <>
          Are you sure you want to remove {candidatesCount} {candidatesText} from the
          {uiSettings.mappings.project.toLowerCase()}?
        </>
      ),
      confirmButtonTitle: "Remove",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      const candidateIDs = Object.values(selectedCandidates);
      await projectService.removeCandidatesFromProject(project.id, candidateIDs);
      // for (const candidate of Object.values(selectedCandidates)) {
      //   await projectService.removeCandidateFromProject(project.id, candidate.candidateId);
      // }
      // @todo analytics service
      notifications.showSuccess(`${candidatesText} ${candidatesCount > 1 ? "have" : "has"} been removed!`);
      this.setState({ selectedCandidates: {} });
      this.fetchData();
    } catch (err) {
      notifications.showError(`Couldn't remove the ${candidatesText}!`);
    }
  };

  render() {
    const {
      project,
      candidates,
      projectStageOptions,
      pageLoading,
      selectedCandidate,
      selectedCandidates,
      filters,
      isEditModalOpened,
    } = this.state;
    const { pageSize, currentPage } = this.state.pagination;
    const {
      uiSettings,
      processingState: { state: processingState },
    } = this.props;
    const { query, sort } = this.state.filters;

    if (pageLoading) {
      return (
        <div className="app">
          <Loading />
        </div>
      );
    }

    return (
      <div className="app position-relative">
        <div className={cx("max-width", css.container)}>
          <ProjectPageHeader
            project={project}
            onEdit={(close) => {
              close();
              this.setState({ isEditModalOpened: true });
            }}
            onArchive={this.archive}
            onUnarchive={this.unarchive}
            onDelete={this.delete}
            onCollaboratorsUpdated={this.fetchData}
          />
          <hr className={css.divider} />
          <ProjectPageControls
            project={project}
            candidates={candidates}
            projectStageOptions={[getDefaultProjectStage(), ...projectStageOptions]}
            onChangeProjectStage={(v) => this.onChangeFilter({ projectStage: v })}
            projectStage={filters.projectStage}
            query={query}
            onChangeQuery={(v) => this.onChangeFilter({ query: v })}
            sortOptions={sortOptions}
            sort={sort}
            onChangeSort={(v) => this.onChangeFilter({ sort: v })}
            selectedCandidates={selectedCandidates}
            onRemoveCandidates={this.removeCandidates}
            workableJobs={mapWorkableJobsToArray(this.state.workableJobs)}
            jobSortOptions={jobSortOptions}
            onJobSortChange={this.onJobSortChange}
            selectedJobSort={this.state.jobListSort}
            selectedWorkableJob={this.state.selectedWorkableJob}
            onselectedWorkableJobChange={(selectedWorkableJob) => this.setState({ selectedWorkableJob })}
            onAddingCandidatesToWorkable={this.onAddingCandidatesToWorkable}
            reloadWorkableJobs={this.fetchWorkableJobs}
          />
          <ProjectCandidatesTable
            project={project}
            uiSettings={uiSettings}
            candidates={candidates}
            pageSize={pageSize}
            currentPage={currentPage}
            projectStageOptions={projectStageOptions}
            onChangePagination={this.onChangePagination}
            onClickCandidate={this.onCandidateClick}
            onChangeCandidateStage={this.onChangeCandidateStage}
            onSelectCandidate={this.onSelectCandidate}
            selectedCandidates={selectedCandidates}
            isAllSelected={this.isAllCandidatesSelected()}
            onSelectAll={this.onSelectAllCandidates}
            selectedWorkableJob={this.state.selectedWorkableJob}
          />
        </div>
        {!!selectedCandidate && (
          <CandidateDetailModalWrapper
            projectId={project.id}
            uiSettings={uiSettings}
            initialPage="detail"
            toggleModalWindow={() => this.setState({ selectedCandidate: null })}
            viewModalOpenState={true}
            candidate={selectedCandidate}
          />
        )}
        {isEditModalOpened && (
          <EditProjectModal
            projectId={project.id}
            onUpdated={this.update}
            onClose={() => this.setState({ isEditModalOpened: false })}
          />
        )}
        <div className={css.notifications}>
          <Processing show={processingState.addingToWorkable.state} onClose={this.onProcessingNotificationDismiss} />
          <Complete
            message={processingState.addingToWorkable.message}
            onClose={this.onAddingCopleteNotificationDismiss}
          />
        </div>
      </div>
    );
  }
}

Project = (function withUserReducer(WrappedComponent) {
  return function (props) {
    const match = useRouteMatch();
    const notifications = useNotifications();
    const modalNavigator = useModalNavigator();
    const userData = useUserData();
    const processingState = useProcessingState();
    const uiSettings = userData.state.user.uiSettings;
    const history = useHistory();

    return (
      <WrappedComponent
        {...props}
        uiSettings={uiSettings}
        key={match.params.id}
        match={match}
        modalNavigator={modalNavigator}
        notifications={notifications}
        processingState={processingState}
        history={history}
      />
    );
  };
})(Project);

Project = withConfirm(Project);

export default Project;
