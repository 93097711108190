import { Spinner } from "reactstrap";
import * as React from "react";
import { cx } from "emotion/macro";

import styles from "./Loading.module.css";

export const Loading = (props) => {
  return (
    <div className={cx(styles.loading, props.className)}>
      <Spinner color="primary" />
    </div>
  );
};
