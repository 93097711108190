import * as React from "react";
import { cx } from "emotion";

import css from "./ProjectInviteCard.module.css";
import { Formatter } from "../../../DiscoverNew/util/Formatter";
import { Tag } from "../../../DiscoverNew/UI/Tag/Tag";
import { projectService } from "../../../../screens/projects/ProjectService";
import { QUERY_NAME, queryRegistry } from "../../../../utils/queryRegistry";
import { useNotifications } from "../../../DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../hoc/withConfirm";

export function ProjectInviteCardBase(props) {
  const { data, className, onUpdated } = props;
  const notifications = useNotifications();

  const onAcceptInvite = async () => {
    const isConfirmed = await props.confirm.open({
      title : "Accept Invitation",
      content : <>Are you sure you want to accept invitation to <b>{props.data.project.name}</b>?</>,
      confirmButtonTitle: 'Accept',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      await projectService.acceptInvite(props.data.id);
      notifications.showSuccess("Invitation has been accepted!");
      onUpdated();
      window.__chatterworks__fetchIncomingInvites && window.__chatterworks__fetchIncomingInvites();
      queryRegistry.fetchQuery(QUERY_NAME.fetchProjects);
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't accept the invitation");
    }
  };

  const onDeclineInvite = async () => {
    const isConfirmed = await props.confirm.open({
      destructive: true,
      title : "Decline Invitation",
      content : <>Are you sure you want to decline invitation to <b>{props.data.project.name}</b>?</>,
      confirmButtonTitle: 'Decline',
    });
    if (!isConfirmed) {
      return;
    }
    try {
      await projectService.declineInvite(props.data.id);
      notifications.showSuccess("Invitation has been declined!");
      onUpdated();
      window.__chatterworks__fetchIncomingInvites && window.__chatterworks__fetchIncomingInvites();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't decline the invitation");
    }
  };

  return (
    <article className={cx(className, css.container)}>
      <div>
        <b>{Formatter.fullName(data.createdBy)}</b>{" "}
        invited you to join "<b>{data.project.name}</b>"
      </div>
      <div className={css.controls}>
        <Tag onClick={onDeclineInvite} theme="red" themeType="underline">Decline</Tag>
        <Tag onClick={onAcceptInvite} themeType="outline">Accept</Tag>
      </div>
    </article>
  );
}

const ProjectInviteCard = withConfirm(ProjectInviteCardBase);
export { ProjectInviteCard };