import {
  CanInviteToOrganizationDTO,
  v2_AcceptOrganizationInvite, v2_CanInviteToOrganization,
  v2_CreateOrganization,
  v2_CreateOrganizationInvite,
  v2_DeclineOrganizationInvite,
  v2_DeleteOrganizationInvite,
  v2_DeleteOrganizationMember,
  v2_GetOrganization,
  v2_GetOrganizationActiveSubscriptionCreditsUsageBySeat,
  v2_GetOrganizationCurrentSeat,
  v2_GetOrganizationInvites,
  v2_GetOrganizationInvitesCount,
  v2_GetOrganizationMemberRoles,
  v2_GetOrganizationMineInvites,
  v2_GetOrganizationSeats,
  v2_GetOrganizationSeatsCount,
  v2_OrganizationType,
  v2_ResendOrganizationInvite,
  v2_UpdateOrganization,
  v2_UpdateOrganizationMember,
} from "../api/apiServiceV2";
import { shortId } from "../components/DiscoverNew/util/util";

export type OrganizationInterface = {
  id: number
  name: string
  isTrialAvailable: boolean
  hasActiveSubscription: boolean
  type: v2_OrganizationType
  createdAt: string
  ownerRef: {
    id: number
    email: string
    firstName: string
    lastName: string
  }
}

export type OrganizationCurrentSeatInterface = {
  role: string
  permissions: string[]
  isActive: boolean
}

export enum OrganizationMemberStatus {
  Active = 'active',
  InvitationPending = 'pending',
  InvitationExpired = 'INVITATION_EXPIRED',
  InvitationDeclined = 'declined',
  Deactivated = 'deactivated',
}

export enum OrganizationMemberRole {
  Owner = 'owner',
  SuperAdmin = 'super-admin',
  Admin = 'admin',
  Member = 'member'
  //Guest = 'guest',
}

export enum OrganizationMemberInvitationStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Expired = 'Expired',
}

export type OrganizationMemberInterface = {
  id: string
  firstName: string
  lastName: string
  email: string
  invitedAt: number
  credits: {
    used: number,
    limit: number,
  }
  role: OrganizationMemberRole
  status: OrganizationMemberStatus
}

export type OrganizationMemberInviteDTO = {
  email: string
  role: OrganizationMemberRole
  projectIDs?: string[]
  isActive?: boolean
}

export type OrganizationMemberInviteInterface = {
  id: number
  status: OrganizationMemberInvitationStatus,
  role: OrganizationMemberRole,
  organization: {
    id: string
    name: string
  }
  createdAt: number
}

type OrganizationMemberFetchAllFilter = {
  query: string
  limit?: number
  skip?: number
}

class OrganizationService {

  organization: OrganizationInterface | null = null;
  members: OrganizationMemberInterface[] = [];
  memberInvitations: OrganizationMemberInviteInterface[] = [];

  constructor() {
    //this.memberInvitations = this.mockMemberInvitations();
    this.memberInvitations = [];
  }

  __setOwnerState = () => {
    this.organization = {
      id: parseInt(Math.random().toString(16).slice(2)),
      name: 'Test Organization',
      //role: OrganizationMemberRole.Owner,
      hasActiveSubscription: true,
      isTrialAvailable: true,
      type: v2_OrganizationType.Recruiter,
      createdAt: Date.now().toString(),
      ownerRef: {
        id: 0,
        firstName: 'John',
        lastName: 'Doe',
        email: 'john.doe@example.com'
      }
    };
  }

  private mockMemberInvitations = () => {
    const memberInvitations: OrganizationMemberInviteInterface[] = [];
    for (let i = 0; i < 1; i++) {
      memberInvitations.push({
        id : parseInt(Math.random().toString(16).slice(2)),
        status : OrganizationMemberInvitationStatus.Accepted,
        role: OrganizationMemberRole.Member,
        organization : {
          id : Math.random().toString(16).slice(2),
          name : 'ChatterWorks',
        },
        createdAt : Date.now(),
      })
    }
    return memberInvitations;
  }

  mapToInterface = (data: any) => {
    return {
      id: data.id,
      name: data.name,
      hasActiveSubscription: data.hasActiveSubscription,
      isTrialAvailable: data.isTrialAvailable,
      type: data.type,
      createdAt: data.createdAt,
      ownerRef: data.ownerRef
    }
  }

  mapSeatToMemberInterface = (data: any) => {
    return {
      id : data.userRef.id,
      firstName: data.userRef.firstName,
      lastName: data.userRef.lastName,
      email: data.userRef.email,
      invitedAt: data.createdAt,
      credits: {
        used: data.creditsUsed,
        limit: data.seatParams.organizationCandidateUnlockCredits,
      },
      role: data.role.name,
      status: data.isActive ? OrganizationMemberStatus.Active : OrganizationMemberStatus.Deactivated
    }
  }

  mapInviteToMemberInterface = (data: any) => {
    return {
      id : data.id,
      firstName: '',
      lastName: '',
      email: data.email,
      invitedAt: data.createdAt,
      credits: {
        used: 0,
        limit: 0,
      },
      role: data.role.name,
      status: data.status
    }
  }

  createOrganization = async (name: string) => {
    const res = await v2_CreateOrganization({ name, type: v2_OrganizationType.Recruiter });
    return res.data;
  }

  canInviteMembers = async (data: CanInviteToOrganizationDTO): Promise<Record<string, string | undefined>> => {
    const res = await v2_CanInviteToOrganization(data);
    return res.data;
  }

  inviteMember = async (data: OrganizationMemberInviteDTO) => {
    // todo do not allow to invite more members than seats available
    //const existingMember = this.members.find(item => item.email === data.email);
    //if (existingMember) {
    //  existingMember.credits = {
    //    ...existingMember.credits,
    //    limit: billingService.__getCurrentPlanInfo()?.seatCredits.count || billingService.__getCurrentPlanInfo()?.singleUseCredits.count || 0,
    //  }
    //  return;
    //}
    //
    //this.members = [
    //  ...this.members,
    //  {
    //    id: Math.random().toString(16).slice(2),
    //    firstName: data.firstName,
    //    lastName: data.lastName,
    //    email: data.email,
    //    role: data.role,
    //    credits: {
    //      used: 0,
    //      limit: billingService.__getCurrentPlanInfo()?.seatCredits.count || billingService.__getCurrentPlanInfo()?.singleUseCredits.count || 0,
    //    },
    //    invitedAt: Date.now(),
    //    status: data.role === OrganizationMemberRole.Owner ? OrganizationMemberStatus.Active : OrganizationMemberStatus.InvitationPending,
    //  }
    //]
    //billingService.__setUsedSeats(this.members.length);
    return v2_CreateOrganizationInvite(data);
  }

  fetchInvitationsAndMembersCount = async () => {
    const resMembersCount = await v2_GetOrganizationSeatsCount();
    const resInviteesCount = await v2_GetOrganizationInvitesCount();
    return resMembersCount.data + resInviteesCount.data;
  }

  fetchMemberInvitations = async (): Promise<OrganizationMemberInviteInterface[]> => {
    //return this.memberInvitations.filter(item => item.status !== OrganizationMemberInvitationStatus.Declined && item.status !== OrganizationMemberInvitationStatus.Expired);
    try {
      const res = await v2_GetOrganizationMineInvites();
      return res.data.map((item: any) => {
        return {
          id : item.id,
          status : item.status,
          role: item.role,
          organization : {
            id : item.organizationRef.id,
            name : item.organizationRef.name,
          },
          createdAt : item.createdAt,
        }
      })
    } catch(err) {
      console.log('fetchMemberInvitations', err);
      return [];
    }
  }

  acceptInvitation = async (id: number) => {
    //this.memberInvitations = this.memberInvitations.map(invitation => {
    //  if (invitation.id === id) {
    //    this.organization = {
    //      id: invitation.organization.id,
    //      name: invitation.organization.name,
    //      role: invitation.role,
    //    };
    //    return {
    //      ...invitation,
    //      status: OrganizationMemberInvitationStatus.Accepted,
    //    }
    //  }
    //  return invitation;
    //});
    return v2_AcceptOrganizationInvite(id);
  }

  declineInvitation = async (id: number) => {
    //this.memberInvitations = this.memberInvitations.map(invitation => {
    //  if (invitation.id === id) {
    //    return {
    //      ...invitation,
    //      status: OrganizationMemberInvitationStatus.Declined,
    //    }
    //  }
    //  return invitation;
    //});
    return v2_DeclineOrganizationInvite(id);
  }

  fetchMembersAndInvitees = async (filter?: OrganizationMemberFetchAllFilter): Promise<OrganizationMemberInterface[]> => {
    //if (filter?.query) {
    //  return this.members.filter(member => {
    //    return member.email.includes(filter.query) || member.firstName.includes(filter.query) || member.lastName.includes(filter.query)
    //  })
    //}
    //return this.members;
    const resSeats = await v2_GetOrganizationSeats({ query: filter?.query || '' });
    const resInvitees = await v2_GetOrganizationInvites({ query: filter?.query || '' });
    const creditsUsageBySeatRes = await v2_GetOrganizationActiveSubscriptionCreditsUsageBySeat();
    let result = resSeats.data
      .map((item: any) => {
        return {
          ...item,
          creditsUsed: creditsUsageBySeatRes.data.CandidateUnlockCreditsRedeemedBy[item.userRef.id]
        }
      })
      .map(this.mapSeatToMemberInterface)
    return [
      ...result,
      ...resInvitees.data.map(this.mapInviteToMemberInterface)
    ]
  }


  fetchMemberEmailOptions = async (filter?: OrganizationMemberFetchAllFilter): Promise<{ id: string, email: string }[]> => {
    const resSeats = await v2_GetOrganizationSeats({ query: filter?.query || '' });
    return resSeats.data.map((data: any) => {
      return {
        id : data.userRef.id,
        email: data.userRef.email,
      }
    })
  }

  fetchMembers = async (filter?: OrganizationMemberFetchAllFilter): Promise<OrganizationMemberInterface[]> => {
    //if (filter?.query) {
    //  return this.members.filter(member => {
    //    return member.email.includes(filter.query) || member.firstName.includes(filter.query) || member.lastName.includes(filter.query)
    //  })
    //}
    //return this.members;
    const resSeats = await v2_GetOrganizationSeats({ query: filter?.query || '' });
    let creditsUsageBySeatRes: any = null;
    try {
      creditsUsageBySeatRes = await v2_GetOrganizationActiveSubscriptionCreditsUsageBySeat();
    } catch (err) {}

    let result = resSeats.data
      .map((item: any) => {
        return {
          ...item,
          creditsUsed: creditsUsageBySeatRes ? creditsUsageBySeatRes.data.CandidateUnlockCreditsRedeemedBy[item.userRef.id] : 0
        }
      })
      .map(this.mapSeatToMemberInterface)
    return [
      ...result,
    ]
  }

  resendMemberInvitation = async (id: string) => {
    //this.members = this.members.map(member => {
    //  if (member.id === id) {
    //    return {
    //      ...member,
    //      invitedAt: Date.now(),
    //      status: OrganizationMemberStatus.InvitationPending,
    //    }
    //  }
    //  return member;
    //});
    return v2_ResendOrganizationInvite(id);
  }

  deleteMember = async (id: string) => {
    // todo do not allow to delete members with projects
    // todo do not allow to delete themself
    //this.members = this.members.filter(member => member.id !== id);
    return v2_DeleteOrganizationMember(id)
  }

  deleteInvite = async (id: string) => {
    // todo do not allow to delete members with projects
    // todo do not allow to delete themself
    //this.members = this.members.filter(member => member.id !== id);
    return v2_DeleteOrganizationInvite(id)
  }

  activateMember = async (id: string) => {
    // todo do not allow to remove members with projects
    // todo do not allow to delete themself
    //this.members = this.members.map(member => {
    //  if (member.id === id) {
    //    return {
    //      ...member,
    //      status: OrganizationMemberStatus.Active,
    //    }
    //  }
    //  return member;
    //})
    return v2_UpdateOrganizationMember(id, { isActive: true });
  }

  deactivateMember = async (id: string) => {
    // todo do not allow to remove members with projects
    // todo do not allow to delete themself
    //this.members = this.members.map(member => {
    //  if (member.id === id) {
    //    return {
    //      ...member,
    //      status: OrganizationMemberStatus.Deactivated,
    //    }
    //  }
    //  return member;
    //})
    return v2_UpdateOrganizationMember(id, { isActive: false });
  }

  update = async (data: { name : string }) => {
    //this.organization = {
    //  ...this.organization,
    //  name: data.name,
    //}
    const res = await v2_UpdateOrganization({ name: data.name });
    return res.data;
  }

  updateMemberRole = async (id: string, roleName: OrganizationMemberRole) => {
    //this.members = this.members.map(member => {
    //  if (member.id === id) {
    //    return {
    //      ...member,
    //      role,
    //    }
    //  }
    //  return member;
    //})
    const rolesRes = await v2_GetOrganizationMemberRoles();
    const role = rolesRes.data.find((item: any) => item.name === roleName);
    return v2_UpdateOrganizationMember(id, { roleId: role.id })
  }

  updateMember = async (id: string, data: { creditsLimit: number, role?: string }) => {
    const rolesRes = await v2_GetOrganizationMemberRoles();
    const role = rolesRes.data.find((item: any) => item.name === data.role);
    const dto: any = {
      limitCandidateUnlockCredits: data.creditsLimit
    }
    if (role) {
      dto.roleId = role.id
    }

    return v2_UpdateOrganizationMember(id, dto);
  }

  fetchCurrentOrganizationInfo = async (): Promise<OrganizationInterface | null> => {
    const orgRes = await v2_GetOrganization();
    return orgRes.data ? this.mapToInterface(orgRes.data) : null;
  }

  fetchCurrentOrganizationSeatInfo = async (): Promise<OrganizationCurrentSeatInterface | null> => {
    const seatRes = await v2_GetOrganizationCurrentSeat();
    return {
      role: seatRes.data.role.name,
      permissions: seatRes.data.permissions,
      isActive: seatRes.data.isActive,
    };
  }

  getMemberStyle = (member: OrganizationMemberInterface) => {
    if (member.status === OrganizationMemberStatus.InvitationPending) {
      return { color : "#408cf6", backgroundColor : "#dfecff" };
    }
    if (member.status === OrganizationMemberStatus.Active) {
      return { color : "#56be24", backgroundColor : "#eafbe0" };
    }
    if (member.status === OrganizationMemberStatus.InvitationExpired) {
      return { color : "#8259d9", backgroundColor : "#f3edff" };
    }
    if (member.status === OrganizationMemberStatus.Deactivated) {
      return { color : "#ffc700", backgroundColor : "rgba(255,199,0,0.2)" };
    }
    if (member.status === OrganizationMemberStatus.InvitationDeclined) {
      return { color : "#ff5e5e", backgroundColor : "rgba(255,94,94,0.2)" };
    }
  }

}

export const organizationService = new OrganizationService()

export const ORGANIZATION_MEMBER_ROLES: Record<OrganizationMemberRole, { label: string, permissions: string[] }> = {
  [OrganizationMemberRole.SuperAdmin] : { // projects, seats, but no billing
    label : "Super Admin",
    permissions : [
      "Manage billing",
      "Manage seats and members",
      "Manage all projects",
    ],
  },
  [OrganizationMemberRole.Admin] : { // projects, seats, but no billing
    label : "Admin",
    permissions : [
      "Manage seats and members",
      "Manage all projects",
    ],
  },
  [OrganizationMemberRole.Member] : { // projects only access
    label : "Member",
    permissions : [
      "Manage personal projects",
    ],
  },
  //[OrganizationMemberRole.Guest]: {
  //  label: "View Only",
  //  permissions: [
  //    "View projects they have been invited to",
  //  ],
  //},
  [OrganizationMemberRole.Owner] : {
    label : "Owner",
    permissions : [],
  },
};

export const getEmptyOrganizationMember = () => {
  return {
    id: shortId(),
    email : "",
    role : OrganizationMemberRole.Admin,
    isNew : true,
  }
}

export const ORGANIZATION_ROLE_OPTIONS = Object.keys(ORGANIZATION_MEMBER_ROLES).map((role) => {
  return {
    value: role,
    label: ORGANIZATION_MEMBER_ROLES[role as OrganizationMemberRole].label
  }
});