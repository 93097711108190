import React from "react";
import Toast from "..";
import classes from "../Toast.module.css";

const Complete = ({ onClose, message }) => {
  return (
    <Toast
      title={<span className={classes.title}>Adding to Workable completed</span>}
      body={message}
      show={!!message}
      onClose={onClose}
    />
  );
};

export default Complete;
