import * as React from "react";
import PropTypes from "prop-types";
import CompanyImg from "../../../components/company/CompanyImg";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Tag } from "../../../components/DiscoverNew/UI/Tag/Tag";
import capitalizeWords from "../../../formatters/capitalizeWords";
import { ProjectCardCollaborators } from "../../../components/project/ProjectCard/ProjectCardCollaborators";
import { getCompanyInitials } from "../../companies/Companies";
import { PermissionEnum, permissionService } from "../../projects/PermissionService";
import styles from "../../../components/modals/CollaboratorsModal/CollaboratorsModal.module.css";
import { Dropdown } from "../../../components/DiscoverNew/UI/Dropdown/Dropdown";
import { BackButton } from "../../../components/ui/BackButton/BackButton";

import css from "./ProjectPageHeader.module.css";

export const ProjectPageHeader = ({ project, onEdit, onArchive, onUnarchive, onDelete, onCollaboratorsUpdated }) => {
  const canEdit = permissionService.can(project, PermissionEnum.projectEdit);
  const canDelete = permissionService.can(project, PermissionEnum.projectDelete);
  const isArchived = !!project.archivedAt;
  React.useEffect(() => {
      // @ts-ignore
      window.__refetchExportList && window.__refetchExportList();
  }, []);
  return (
    <>
      <BackButton to="/projects" goBack={true} />
      <div className={css.heading}>
        <h1 className={css.title}>{project.name}</h1>
        {canEdit && (
          <Dropdown
            classNames={{
              content: styles.roleDropdownContent,
            }}
            label={
              <div className={css.actionsButton}>
                <Icon name="dots" />
              </div>
            }
          >
            <Dropdown.Item className={styles.roleItem} onClick={onEdit}>
              Edit
            </Dropdown.Item>
            {isArchived ? (
              <Dropdown.Item className={styles.roleItem} onClick={onUnarchive}>
                Unarchive
              </Dropdown.Item>
            ) : (
              <Dropdown.Item className={styles.roleItem} onClick={onArchive}>
                Archive
              </Dropdown.Item>
            )}
            {canDelete && (
              <>
                <Dropdown.Divider className={styles.divider} />
                <Dropdown.Item
                  className={styles.roleItem}
                  style={{ color: "#F83E3E" }}
                  onClick={(close) => onDelete(close)}
                >
                  Delete
                </Dropdown.Item>
              </>
            )}
          </Dropdown>
        )}
        {/*{isArchived && <span className={css.archivedLabel}>archived</span>}*/}
        {isArchived && (
          <Tag theme={"gray"} className={css.archivedLabel}>
            Archived
          </Tag>
        )}
      </div>
      <div className={css.content}>
        <div>
          {!!project.company?.name && (
            <div className={css.company}>
              at{" "}
              <CompanyImg
                company={project.company}
                initials={getCompanyInitials(project.company.name)}
                className={css.companyImage}
              />{" "}
              {project.company.name}
            </div>
          )}
          <p className={css.description}>{project.description}</p>
          <div className={css.tags}>
            {project.tags?.slice(0, 5)?.map((item) => {
              return <Tag key={item}>{capitalizeWords(item)}</Tag>;
            })}
          </div>
        </div>
        <div>
          <ProjectCardCollaborators data={project} onUpdated={onCollaboratorsUpdated} />
        </div>
      </div>
    </>
  );
};

ProjectPageHeader.propTypes = {
  project: PropTypes.object,
  onEdit: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  onDelete: PropTypes.func,
  onCollaboratorsUpdated: PropTypes.func,
};
