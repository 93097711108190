import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import Stepper from "components/ui/Stepper/Stepper";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAuthController } from "../../../contexts/AuthProvider";
import { PlanInfoInterface } from "../../../services/BillingService";
import {
  getEmptyOrganizationMember,
  OrganizationMemberStatus,
  organizationService,
} from "../../../services/OrganizationService";
import { PurchasePlanMember } from "../../../types";
import { padRight } from "../../../utils/arrays";
import modal from "../modal.module.css";
import MembersStep from "./MembersStep/MembersStep";
import PaymentStep from "./PaymentStep/PaymentStep";

import PlanStep from "./PlanStep/PlanStep";

import styles from "./PurchasePlanModal.module.css";

const STEPS = ["Plan", "Members", "Payment"];

type Props = {
  onClose: () => void
  plan: PlanInfoInterface
}

const PurchasePlanModal = ({ onClose, plan }: Props) => {
  const [step, setStep] = useState(1);
  const [seatsCount, setSeatsCount] = useState(1);
  const [existingMembersCount, setExistingMembersCount] = useState(0);
  const [initialMembers, setInitialMembers] = useState<PurchasePlanMember[]>([]);
  const [members, setMembers] = useState<PurchasePlanMember[]>([]);

  const authController = useAuthController();

  useEffect(() => {
    organizationService.fetchMembers().then(r => {
      let members = r
        .map(item => {
        return {
          id: item.id,
          role: item.role,
          email: item.email,
          isNew: false,
          isActive: item.status === OrganizationMemberStatus.Active
        }
      })
      setMembers(members);
      setInitialMembers(members);
      setExistingMembersCount(members.length);
      setSeatsCount(members.length);
    });
  }, [])

  const getPricingBreakdown = () => {
    return {
      monthlyCredits: plan.creditsPerSeat * seatsCount,
      totalPrice: plan.pricePerSeat * seatsCount
    }
  }

  const handleMemberSave = async (member: PurchasePlanMember) => {
    setMembers(
      members.map(item => {
        if (item.id !== member.id) return item;
        return member;
      })
    );
  };

  const goToMembersStep = () => {
    //const activeMembersCount = initialMembers.filter(member => member.isActive).length;
    const activeMembersCount = initialMembers.length;
    let membersWithPrefilledSeats: PurchasePlanMember[] = []
    if (activeMembersCount <= seatsCount) {
      membersWithPrefilledSeats = padRight(initialMembers, seatsCount, getEmptyOrganizationMember);
    } else {
      membersWithPrefilledSeats = initialMembers;
    }
    const newMembers = membersWithPrefilledSeats.filter((member: PurchasePlanMember) => member.isNew);
    const existingMembers = membersWithPrefilledSeats.filter((member: PurchasePlanMember) => !member.isNew);
    setMembers([
      ...existingMembers,
      ...newMembers
    ])
    setStep(2);
  }

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <PlanStep
            planInfo={plan}
            breakdown={getPricingBreakdown()}
            onNext={goToMembersStep}
            seatsCount={seatsCount}
            existingMembersCount={existingMembersCount}
            onChangeSeatsCount={setSeatsCount}
          />
        );

      case 2:
        return (
          <MembersStep
            members={members}
            plan={plan}
            seatsCount={seatsCount}
            onMemberSave={handleMemberSave}
            onToggleMember={member => {
              setMembers(
                members.map(item => {
                  if (item.id !== member.id) return item;
                  return { ...item, isActive: !item.isActive };
                })
              );
            }}
            nextStep={() => setStep(3)}
            prevStep={() => {
              setMembers(
                members.map(item => {
                  if (item.isNew) return item;
                  return { ...item, isActive: initialMembers.find(member => member.id === item.id)!.isActive };
                })
              );
              setStep(1);
            }}
          />
        );

      case 3:
        return <PaymentStep planData={{ seatsCount, members, planId: plan.id }} breakdown={getPricingBreakdown()} prevStep={() => setStep(2)} />;

      default:
        return null;
    }
  };

  const renderSubtitle = () => {
    switch (step) {
      case 1:
        return "Choose how many members you need";
      case 2:
        return "Manage your organization members";
      case 3:
        return "Confirm your payment";
      default:
        return "";
    }
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Icon name="fa-xmark" className={modal.close} onClick={onClose} />
      <div className={modal.body}>
        <div className={modal.info}>
          <h3 className={modal.title}>Choose Plan</h3>
          <p className={modal.subtitle}>{renderSubtitle()}</p>
        </div>
        <Stepper steps={STEPS} activeStep={step} />
        <div className={styles.step}>{renderSteps()}</div>
      </div>
    </Modal>
  );
};

export default PurchasePlanModal;
