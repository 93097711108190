import * as React from "react";

/**
 * @template T
 * @param {function} callable
 * @param {any} [args]
 * @param {boolean} [skip]
 * @returns {{data: T, loading: boolean, error: unknown}}
 */
export function usePromise(callable, args = {}, skip) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(undefined);
  const [error, setError] = React.useState(null);

  const fetchData = async () => {
    if (skip === true) return null;

    try {
      setLoading(true);
      setError(false);
      const res = await callable(args);
      setData(res);
    } catch (e) {
      setError(e);
      setData(null);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [JSON.stringify(args || {})]);

  // @todo simplify loading
  return { data, loading: loading || (data === undefined && !skip), error, setData, fetchData };
}
