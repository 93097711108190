import * as React from 'react';
import cn from 'classnames';

import css from './PlanInfoCard.module.css'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export const PlanInfoCard = (props: Props) => {
  return (
    <div className={cn(css.holder, props.className)} style={props.style}>
      {props.children}
    </div>
  )
}

PlanInfoCard.Item = (props: Props) => <div className={css.item}>{props.children}</div>;
PlanInfoCard.ItemTitle = (props: Props) => <div className={css.itemTitle}>{props.children}</div>;
PlanInfoCard.ItemValue = (props: Props) => <div className={css.itemValue}>{props.children}</div>;