import React from 'react';
import css from './PaymentProcessing.module.css'

export const PaymentProcessing = () => {
  setTimeout(() => {
    if (window.location.pathname.includes('first-purchase')) {
      window.location.href = '/discover?first-purchase-success=true';
      return;
    }
    if (window.location.pathname.includes('extra-credits-purchase')) {
      window.location.href = '/profile/billing/plans?extra-credits-purchase-success=true';
      return;
    }
    window.location.href = '/profile/billing/plans?plan-update-success=true';
  }, 5000);
  return (
    <div style={{ textAlign: 'center', padding: '100px 0' }}>
      <h2 className={css.title}>Please wait, we're processing your payment...</h2>
    </div>
  )
}