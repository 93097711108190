import cn from 'classnames';
import * as React from 'react';

import styles from './FormMessage.module.css';

const theme = {
  success: styles.success,
  danger: styles.danger,
}

type Props = {
  theme: 'success' | 'danger'
  style?: React.CSSProperties
  children: React.ReactNode
}

export const FormMessage: React.FC<Props> = props => {
  return (
    <div className={cn(styles.container, theme[props.theme])} style={props.style}>
      {props.children}
    </div>
  )
}
