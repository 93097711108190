import React, { useState } from "react";
import { css } from "emotion";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../../components/button/CloseButton";
import ModalButtonContainer from "../../../../components/button/ModalButtonContainer";
import { Form } from "../../../../components/DiscoverNew/Form/Form";
import { FormField } from "../../../../components/DiscoverNew/Form/FormField/FormField";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { modalStyles } from "../../../../components/modals/modalStyles";
import { EnableWorkableIntegrationDTO } from "../../../../services/WorkableIntegrationService";

import styles from "./ModalWorkableIntegrationSettings.module.css";

type Props = {
  onSubmit: (data: EnableWorkableIntegrationDTO) => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalWorkableIntegrationSettings: React.FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<any>({ apiKey : "", subdomain : "" });
  const [errors, setErrors] = useState<any>({ apiKey : "", subdomain : "" });

  const onChange : React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name] : value });
    setErrors({ ...errors, [name] : "" });
  }

  const handleSubmit = async () => {
    const errors: any = {};
    if (!values.apiKey) {
      errors.apiKey = "API Key is required";
    }
    if (!values.subdomain) {
      errors.subdomain = "Subdomain is required";
    }
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    const data: EnableWorkableIntegrationDTO = {
      apiKey: values.apiKey,
      subdomain: values.subdomain,
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen} onHide={onClose}
      centered
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>Enable Workable Integration</div>
        <div className={modalStyles.modalFormContainer}>
          <Form>
            <Form.Row>
              <FormField label="API Key" error={errors.apiKey}>
                <Input name="apiKey" value={values.apiKey} onChange={onChange} placeholder="Enter your workable api key" />
              </FormField>
            </Form.Row>
            <Form.Row>
              <FormField label="Subdomain" error={errors.subdomain}>
                <div className={styles.subdomainField}>
                  <Input name="subdomain"
                    style={{ flex : 1 }}
                    classNames={{ input : styles.subdomainInput }} value={values.subdomain} onChange={onChange}
                    placeholder="Enter your workable subdomain"
                  />
                  <Input classNames={{ input : styles.subdomainPredefinedDomainInput }} defaultValue=".workable.com"
                         readOnly />
                </div>
              </FormField>
            </Form.Row>
          </Form>
        </div>
        <ModalButtonContainer
          title="Enable Integration"
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};