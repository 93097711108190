import React from "react";
import { cx, css } from "emotion/macro";

import * as globals from "../../constants/constants";

// Styles
import "../mobile/Mobile.css";
import { lg } from "../../css/mediaQueries";

// Used for social platform toggle buttons
const socialProfilesToggleUI = [
  {
    name: "twitter",
    backgroundColor: "#ffffff",
    color: "#4a4a4a",
    activeBackgroundColor: "#00acee",
    activeColor: "#ffffff",
    fontFamily: "initial",
    activeFontFamily: "Montserrat-Bold!important",
    fontWeight: "normal",
    activeFontWeight: "bold",
    border: "1px solid #9b9b9b",
    activeBorder: "1px solid rgb(255 255 255 / 0.5)",
  },
  {
    name: "instagram",
    // backgroundColor: "#ea4c89", // use this when support for IG available
    backgroundColor: "rgb(255 255 255 / 0.5)",
    color: "rgba(74, 74, 74, 0.4)",
    activeBackgroundColor: "rgb(255 255 255 / 0.5)",
    activeColor: "rgba(74, 74, 74, 0.4)",
    fontFamily: "initial",
    activeFontFamily: "initial",
    fontWeight: "normal",
    activeFontWeight: "bold",
    activeBorder: "1px solid rgb(128 128 128 / 0.2)",
    border: "1px solid rgb(128 128 128 / 0.2)",
  },
  {
    name: "linkedin",
    backgroundColor: "#ffffff",
    color: "#4a4a4a",
    activeColor: "#ffffff",
    activeBackgroundColor: "#0e76a8",
    border: "1px solid #9b9b9b",
    fontFamily: "initial",
    activeFontFamily: "initial",
    fontWeight: "normal",
    activeFontWeight: "bold",
    activeBorder: "1px solid rgb(128 128 128 / 0.2)",
  },
  {
    name: "facebook",
    // backgroundColor: "#4267b2", // use this when support for FB available
    backgroundColor: "rgb(255 255 255 / 0.5)",
    color: "rgba(74, 74, 74, 0.4)",
    activeBackgroundColor: "rgb(255 255 255 / 0.5)",
    activeColor: "rgba(74, 74, 74, 0.4)",
    fontFamily: "initial",
    activeFontFamily: "initial",
    fontWeight: "normal",
    activeFontWeight: "bold",
    activeBorder: "1px solid rgb(128 128 128 / 0.2)",
    border: "1px solid rgb(128 128 128 / 0.2)",
  },
];

const SocialPlatformToggleButton = ({ spUIObject, socialPlatform, onClick, socialPlatformDataMap }) => {
  const isActive = spUIObject?.name === socialPlatform;
  const isDisabled =
    spUIObject?.name === globals.SOCIAL_FACEBOOK ||
    spUIObject?.name === globals.SOCIAL_INSTAGRAM ||
    !socialPlatformDataMap[spUIObject?.name];

  return (
    <button
      disabled={isDisabled}
      className={cx(
        styles.socialPlatformButton,
        css`
          background-color: ${isActive ? spUIObject?.activeBackgroundColor : spUIObject?.backgroundColor};
          color: ${isActive ? spUIObject?.activeColor : spUIObject?.color};
          font-weight: ${isActive ? spUIObject?.activeFontWeight : spUIObject?.fontWeight};
          border: ${isActive ? spUIObject?.activeBorder : spUIObject?.border};
          text-transform: capitalize;
        `
      )}
      onClick={() => onClick(spUIObject?.name)}
    >
      {spUIObject?.name}
    </button>
  );
};

function SocialPlatformToggles({ socialPlatform, onClick, socialPlatformDataMap }) {
  return (
    <div className={cx(styles.socialPlatformToggleContainer)}>
      {socialProfilesToggleUI.map((sp, key) => {
        return (
          <SocialPlatformToggleButton
            key={key}
            spUIObject={sp}
            socialPlatform={socialPlatform}
            onClick={onClick}
            socialPlatformDataMap={socialPlatformDataMap}
          />
        );
      })}
    </div>
  );
}

const styles = {
  socialPlatformToggleContainer: css`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 22px;
    padding: 0px 30px;
    ${lg} {
      padding: 0px;
    }
  `,
  socialPlatformButton: css`
    border-radius: 25px;
    object-fit: contain;
    padding: 7px;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;

    min-width: 100px;
    height: 30px;
    // color: #4a4a4a;

    ${lg} {
      min-width: 65px;
      margin-right: 5px;
    }
  `,
};

export default SocialPlatformToggles;
