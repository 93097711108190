import React from "react";

import CandidateInitials from "./CandidateInitials";

const CandidateProfileImg = ({ candidate, initials, style, className }) => {
  if (candidate && candidate.profileImgUrl) {
    const defaultStyle = {
      width: "110px",
      height: "110px",
      borderRadius: "50%",
    };

    style = { ...defaultStyle, ...style };

    return (
      <div className={(["img-container", className].filter(Boolean).join(' '))} style={style}>
        <img src={candidate.profileImgUrl} alt="" style={style} />
      </div>
    );
  }

  return <CandidateInitials className={className} initials={initials} />;
};

export default CandidateProfileImg;
