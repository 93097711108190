import React, { useEffect, useState } from "react";
import { cx, css } from "emotion/macro";

import "./Dropdown.css";
import { xs } from "../../css/mediaQueries";
import dropdownCaret from "../../assets/icons/select-dropdown-caret.svg";

import * as api from "../../api/apiService";
import { getCurrentISOString } from "../../formatters/calendarFormatter";

/** CandidateStageDropdown Props
 * @param project {Object} will be a Project Object with a nested Stage object
 * @param handleOrderByOptionClick {Func} callback when updating the dropdown's value
 * @param isDropdownActive {boolean} value to determine whether or not to show dropdown options
 */
function CandidateStageDropdown({ project, handleOrderByOptionClick, isDropdownActive, disabled }) {
  const [options, setOptions] = useState([]);

  // Each option will be a Stage Object: {id, name}
  const updateValue = (option) => {
    const currentISO = getCurrentISOString();
    const updatedOption = { ...option, updatedAt: currentISO };
    handleOrderByOptionClick(updatedOption);
  };

  useEffect(() => {
    const fetchProjectStages = async () => {
      if (!project?.id) return;
      try {
        const {
          data: { stages },
        } = await api.GetStageFlowForProject(project.id);
        setOptions(stages);
      } catch (e) {
        console.error("could not fetch options", e);
      }
    };

    if (isDropdownActive) {
      fetchProjectStages();
    }
  }, [isDropdownActive, project]);

  return (
    <>
      <div className={"standard-theme-text __dropdown--newEntry" + (options?.length > 0 ? "" : "-hidden")}></div>
      <div className={cx("order-by-container", styles.orderByContainer)}>
        <label className={cx("sort-by-label-span", styles.sortBySpan)} htmlFor="orderBy">
          Sort By:{" "}
        </label>
        <div className={cx("sort-order-dropdown", styles.sortOrderDropdown)} style={{ backgroundImage : disabled ? 'none' : '' }}>
          <div className={"__dropdown--value"}>{project?.stage?.name || ""}</div>
        </div>
        {options?.length > 0 && (
          <div className={cx("sort-order-dropdown-options-container", styles.dropdownOptionsContainer)}>
            <div
              className={cx(
                { [styles.dropdownOptions]: isDropdownActive },
                { [styles.dropdownOptionsHidden]: !isDropdownActive }
              )}
            >
              {options.map((option, i) => (
                <div
                  key={i}
                  className={cx("sort-order-dropdown-option", styles.dropdownOption)}
                  onMouseDown={() => updateValue(option)}
                >
                  {option?.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  orderByContainer: css`
    display: flex;
    flex-flow: row;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 20px;
    min-width: 155px;
    position: relative;
  `,
  sortBySpan: css`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.49px;
    color: #888888;
    margin-bottom: initial;
    margin-right: 3px;
  `,
  sortOrderDropdown: css`
    height: 17px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.49px;
    text-align: right;
    color: #373737;
    border: none;
    outline: none;
    cursor: pointer;
    text-align-last: right;
    padding-right: 15px;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    background-image: url("${dropdownCaret}");
    background-size: 10px;
    background-position: 100% 61%;
    background-repeat: no-repeat;
    background-color: #fff;

    &::ms-expand {
      display: none;
    }
  `,
  orderByOption: css`
    direction: rtl;
  `,
  dropdownOptionsContainer: css`
    width: 153px;
    position: absolute;
    top: 34px;
  `,
  dropdownOptions: css`
    width: 100%;
    position: absolute;
    border-radius: 3px;
    border: solid 1px #9b9b9b;
    z-index: 10;
    border-top: none;
    color: #373737;
    ${xs} {
      max-height: calc(100vh - 400px);
      overflow-y: scroll;
    }
  `,
  dropdownOptionsHidden: css`
    display: none;
  `,
  dropdownOption: css`
    height: 40px;
    border-top: solid 1px #9b9b9b;
    background-color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;

    &:hover {
      background-color: #f2f2f2;
      color: #208bfe;
    }
  `,
};

export default CandidateStageDropdown;
