import * as React from "react";
import { userService } from "../../projects/UserService";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import AdminUserModal from "../../../components/modals/AdminUserModal";
import { BackButton } from "../../../components/ui/BackButton/BackButton";
import { InfoTable } from "../../../components/ui/InfoTable/InfoTable";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { useHistory } from "react-router-dom";
import { ContextMenuDropdown } from "../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

import css from "./AdminUser.module.css";
import { Dropdown } from "../../../components/DiscoverNew/UI/Dropdown/Dropdown";
import { authService } from "../../../components/DiscoverNew/services/AuthService";
import { useAuthController } from "../../../contexts/AuthProvider";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Divider from "../../../components/ui/Divider/Divider";
import { Link } from "../../../components/DiscoverNew/UI/Link/Link";

export class AdminUser extends React.Component {
  cleanStyleOverride;

  constructor(props) {
    super(props);
    this.state = {
      initializing : true,
      activating : false,
      user : undefined,
      isEditUserModalOpen : false,
    };
  }

  async componentDidMount() {
    this.cleanStyleOverride = injectStyleOverride();
    try {
      await this.fetchUser();
      this.setState({ initializing : false });
    } catch (err) {
      this.setState({ initializing : false });
      console.error(`couldn't fetch the user`, err);
    }
  }

  componentWillUnmount() {
    this.cleanStyleOverride();
  }

  fetchUser = async () => {
    const user = await userService.fetchOne(this.props.match.params.userId);
    this.setState({ user });
  };

  onEditUser = () => this.setState({ isEditUserModalOpen : true });
  onCloseEditUser = () => this.setState({ isEditUserModalOpen : false });
  onUserUpdated = () => {
    this.onCloseEditUser();
    this.fetchUser();
  };


  onLogin = async (close) => {
    close();
    const res = await userService.loginAs(this.state.user.id);
    authService.pauseSessionAndLoginAs(res, this.props.currentUser);
  }

  onActivate = async () => {
    const { confirm, notifications } = this.props;
    if (this.state.activating) {
      return;
    }

    const isConfirmed = await confirm.open({
      content : <>Are you sure you want to activate {Formatter.fullName(this.state.user)}?</>,
      confirmButtonTitle : "Activate",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      this.setState({ activating : true });
      await userService.activate(this.state.user.id);
      this.setState({ activating : false });
      this.fetchUser();
      notifications.showSuccess(`User has been activated!`);
    } catch (err) {
      this.setState({ activating : false });
      console.error("Couldn't activate the user", err);
      notifications.showError(`Couldn't activate the user!`);
    }
  };

  render() {
    const { initializing, user, isEditUserModalOpen, activating } = this.state;

    if (initializing) {
      return (
        <div className="app">
          <div className="max-width">
            <Loading />
          </div>
        </div>
      );
    }

    return (
      <div className="app">
        <div className="max-width">
          <BackButton to="/admin/users" />
          <div style={{ display : "flex", justifyContent : "space-between", marginBottom : 10 }}>
            <h1 className={css.title}>{Formatter.fullName(user)}</h1>
            <ContextMenuDropdown
              contentStyle={{ width : "unset" }}
              label={<ContextMenuDropdown.ActionButton />}
            >
              <ContextMenuDropdown.Item icon="pencil-2" onClick={this.onEditUser}>
                Edit Profile
              </ContextMenuDropdown.Item>
              <Dropdown.Divider />
              <ContextMenuDropdown.Item icon="person" onClick={this.onLogin}>
                Log In to Account
              </ContextMenuDropdown.Item>
            </ContextMenuDropdown>
          </div>
          <div style={{ display : "flex", alignItems : "flex-start" }}>
            <InfoTable>
              <tr>
                <td>Email:</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>Phone number:</td>
                <td>{user.phoneNumber}</td>
              </tr>
              <tr>
                <td>Activated:</td>
                <td>
                  {user.activatedAt ? (
                    Formatter.fullDate(user.activatedAt)
                  ) : (
                    <>No {<Link onClick={this.onActivate}>{activating ? "(activating...)" : "(activate)"}</Link>}</>
                  )}
                </td>
              </tr>
              <tr>
                <td>Created:</td>
                <td>{Formatter.fullDateTime(user.createdAt)}</td>
              </tr>
            </InfoTable>
          </div>
          <Divider />
          <SectionTitle level={2} style={{ marginBottom: 10 }}>
            Organization Info
          </SectionTitle>
          {user.organization ? (
            <InfoTable>
              <tr>
                <td>Name:</td>
                <td><Link style={{ fontSize: 'inherit'}} to={`/admin/organizations/${user.organization.id}`}>{user.organization.name}</Link></td>
              </tr>
            </InfoTable>
          ) : (
            <p>No organization</p>
          )}
          {isEditUserModalOpen && (
            <AdminUserModal onClose={this.onCloseEditUser} onSuccess={this.onUserUpdated} userId={user.id} />
          )}
        </div>
      </div>
    );
  }
}

AdminUser = (function withUserReducer(WrappedComponent) {
  return function(props) {
    const notifications = useNotifications();
    const history = useHistory();
    const { user } = useAuthController();
    return <WrappedComponent {...props} history={history} notifications={notifications} currentUser={user} />;
  };
})(AdminUser);

AdminUser = withConfirm(AdminUser);
