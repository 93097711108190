import * as api from "../../api/apiService";

class WhitelistUserEmailService {
  fetchAll = async (args) => {
    const res = await api.AdminGetUserWhitelistEmails(args);
    const resCount = await api.AdminGetUserWhitelistEmailsCount(args);
    return {
      nodes: res.data,
      pageInfo: {
        pageCount: Math.max(Math.ceil(resCount.data / args.limit), 1),
        total: resCount.data,
      },
    };
  };

  add = async (data) => {
    const res = await api.AdminCreateUserWhitelistEmail(data);
    return res.data || null;
  };

  update = async (id, data) => {
    const res = await api.UpdateUserWhitelistEmail(id, data)
    return res.data || null;
  };

  delete = async (id, value) => {
    const res = await api.AdminDeleteUserWhitelistEmail(id);
    return res.data || null;
  };
}

export const whitelistUserEmailService = new WhitelistUserEmailService();
