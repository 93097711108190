import React, { useContext, useReducer } from "react";

import { clone } from "lodash";

const initialState = {
  searchHits: [],
  isSearchFilterVisible: true,
  savedSearchCacheMap: {}, // holds a map of saved searches the user has chosen to view: Format: {[searchString]:true}
  lastSavedSearch: null, // the last saved search the user has created; used for the confirmation banner
  isShowSavedSearchBanner: false,
};

const updateSearchHits = (state, action) => {
  if (action.payload.candidate) {
    const candidate = action.payload.candidate;
    let clonedSearchHits = clone(state.searchHits);

    for (let i = 0; i < clonedSearchHits.length; i++) {
      // Avoid padded hits
      if (clonedSearchHits[i]?.hidden) continue;
      if (clonedSearchHits[i]._source.candidateId === candidate.candidateId) {
        clonedSearchHits[i]._source = candidate;
      }
    }

    return clonedSearchHits;
  }
  return state.searchHits;
};

const candidateReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_HITS":
      return { ...state, searchHits: [...payload.searchHits] };
    case "UPDATE_SEARCH_HITS":
      return { ...state, searchHits: updateSearchHits(state, action) };
    case "SET_SEARCH_FILTER_VIEW":
      return { ...state, isSearchFilterVisible: payload };
    case "SET_SAVED_SEARCH_CACHE_MAP":
      return { ...state, savedSearchCacheMap: { ...state.savedSearchCacheMap, ...payload } };
    case "SET_LAST_SAVED_SEARCH":
      return { ...state, lastSavedSearch: payload };
    default:
      return state;
  }
};

// The following context is responsible for holding the global candidate search hit state
// which will be used primarily for driving the Candidate Search UI
export const CandidatesContext = React.createContext();
export const useCandidates = () => useContext(CandidatesContext);
export const CandidatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(candidateReducer, initialState);

  return (
    <CandidatesContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CandidatesContext.Provider>
  );
};
