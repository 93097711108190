import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isFunction } from "lodash";
import { css, cx } from "emotion/macro";

import ModalInputField, { modalInputFieldStyles } from "../input/ModalInputField";
import ModalButtonContainer from "../button/ModalButtonContainer";
import DropdownOptions from "../dropdown/DropdownOptions";
import CloseButton from "../button/CloseButton";

import { modalStyles } from "./modalStyles";
import "../dropdown/Dropdown.css";
import "../input/Input.css";
import { Select } from "../DiscoverNew/Form/Select/Select";
import { projectService } from "../../screens/projects/ProjectService";
import { stageFlowService } from "../../screens/profile/ProfileStageFlow/StageFlowService";
import { useAuthController } from "../../contexts/AuthProvider";
import { companyService } from "../../screens/companies/CompanyService";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { Link } from "../DiscoverNew/UI/Link/Link";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";
import { ModalStageFlow } from "../../screens/profile/ProfileStageFlow/ModalStageFlow";
import { ModalCreateStageFlow } from "../../screens/profile/ProfileStageFlow/ModalCreateStageFlow";

/// NewProjectModal houses the form to create a new project
///
/// isCreateModalOpen: boolean to determine whether modal is open.
/// toggleCreateModalWindow: Function to toggle the modal.
/// close: Function to close the modal.
/// targetCompany: Company object that will usually be passed in from the Company Row component.
function NewProjectModal({
                           isCreateModalOpen,
                           toggleCreateModalWindow,
                           close,
                           project,
                           targetCompany = null,
                           uiSettings,
                           onUpdate,
                         }) {

  const [showCreateInterviewProcessModal, setShowCreateInterviewProcessModal] = useState(false);
  const notifications = useNotifications();

  if (!isFunction(close)) {
    close = () => console.log("ERROR: Unable to close the modal");
  }

  const authController = useAuthController();
  const user = authController.user;
  const [companies, setCompanies] = useState([]);
  const [stageFlowOptions, setStageFlowOptions] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    companyName: '',
    stageFlowId: ''
  });

  const fetchStageFlowOptions = async () => {
    const data = await stageFlowService.fetchAll(user.organizationId);
    return data.map(item => {
      return {
        value : item.stageFlowId,
        label : item.name,
      };
    });
  }

  const fetchData = async () => {
    try {
      const data = await companyService.fetchAll();
      setCompanies(data);
    } catch (e) {
      console.error("could not fetch companies");
    }

    try {
      const options = await fetchStageFlowOptions();
      setStageFlowOptions(options);
      if (!project?.stageFlowId) {
        setStageFlowId(options[0].value);
      }
    } catch (e) {
      console.error("could not fetch stage flows");
    }
  };

  useEffect(() => {
    let allow = true;

    if (allow) {
      fetchData();
    }

    const modal = document.querySelector("#create-project-modal")?.closest(".modal");
    if (modal) {
      modal.style.cssText = (modal.style.cssText || "") + "overflow-y: auto !important";
    }

    return () => {
      allow = false;
      if (modal) {
        modal.style.cssText = (modal.style.cssText || "").replace("overflow-y: auto !important", "");
      }
    };
  }, []);

  // Input State Vars
  const [projectName, setProjectName] = useState(project?.name || "");
  const [stageFlowId, setStageFlowId] = useState(project?.stageFlowId || "");
  const [description, setDescription] = useState(project?.description || "");
  const [tags, setTags] = useState(project?.tags || []);
  const [companyName, setCompanyName] = useState(targetCompany?.name ?? "");
  const [companyWebsite, setCompanyWebsite] = useState(targetCompany?.website ?? "");

  // Used for displaying the spinner
  const [isWaiting, setIsWaiting] = useState(false);

  // Company dropdown option related state vars
  const [suggestedCompanies, setSuggestedCompanies] = useState([]);
  const [showSuggestedCompanies, setShowSuggestedCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(targetCompany ?? null);

  const onCompanyNameChange = (e) => {
    const val = e.target.value || "";
    const lowerCaseVal = val.toLowerCase();

    setErrors({ ...errors, companyName : "" });
    setCompanyName(val);

    // if the original companies array is empty, skip building suggested companies
    if (!companies?.length) return;

    // reset selected company state
    if (selectedCompany) {
      setSelectedCompany(null);
      setCompanyWebsite("");
    }

    // If the value is an empty string reset suggestedCompanies list
    if (!val) {
      setSuggestedCompanies([]);
      return;
    }

    let doesInputValueExist = false;

    let filteredCompanies = companies.filter((c) => {
      if (c.name.toLowerCase() === lowerCaseVal) {
        doesInputValueExist = true;
      }
      return c.name.toLowerCase().startsWith(lowerCaseVal);
    });

    // if inputted value does not exist in the filtered array
    // and there are other items in filtered array we want to add
    // to the suggested list
    if (!doesInputValueExist && filteredCompanies?.length) {
      // Create new company object and set the company name
      // based on the current value of the input
      const inputCompany = {
        companyId : null,
        name : `Add Company: ${val}`,
        projectTotal : null,
        candidateTotal : null,
      };

      // push inputted company to front of array as first suggestion
      filteredCompanies.unshift(inputCompany);
    }

    setSuggestedCompanies(filteredCompanies);
    setShowSuggestedCompanies(true);
  };

  const handleCompanyOptionClick = (e, company) => {
    e.preventDefault();
    // refresh suggested companies list
    setSuggestedCompanies([]);

    // Strip out "Add Company:" Prefix
    let companyName = company?.name;
    let prefix = "Add Company:";
    if (companyName.includes(prefix)) {
      let parts = companyName.split(prefix);
      companyName = parts.length > 1 ? parts[1].trim() : "";
    }
    setCompanyName(companyName);

    // The incoming company does not exist yet and thus we do not
    // want to set the website nor save in our local state
    if (!company?.companyId) {
      return;
    }

    // If the incoming company has a website attribute, set website
    if (company?.website) {
      setCompanyWebsite(company.website);
    }

    // set selected company in order to send correct id in update request upon saving
    setSelectedCompany(company);
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();
    // also handle the case in which we typed a company name
    // that exists and set that as our target company
    verifyCompanyNameExists();
    setShowSuggestedCompanies(false);
  };

  const verifyCompanyNameExists = () => {
    if (!companyName) return;
    const existingCompany = companies.find((c) => c.name.toLowerCase() === companyName.toLowerCase());

    if (!existingCompany) return;

    setSelectedCompany(existingCompany);
    setCompanyName(existingCompany.name);

    if (existingCompany?.website) {
      setCompanyWebsite(existingCompany?.website);
    }
  };

  const clearFields = () => {
    setProjectName("");
    setCompanyName("");
    setCompanyWebsite("");
  };

  const handleProjectSave = async () => {
    const errors = {};
    if (!projectName) {
      errors.name = "Please enter name";
    }
    if (!companyName) {
      errors.companyName = "Please select a company";
    }
    if (!stageFlowId) {
      errors.stageFlowId = "Please select an interview process";
    }
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }


    setIsWaiting(true);

    let newProject;

    // If we did not select an existing company from the options container
    // create a new company
    if (!selectedCompany?.companyId) {
      try {
        const data = await companyService.create({ name : companyName, website : companyWebsite });
        if (data) {
          const company = data;
          window.analytics.track("Company Created", {
            company_id : company?.companyId,
            name : companyName,
            website : companyWebsite,
            app_location : "project",
          });
          newProject = await createProject(company?.companyId);
          notifications.showSuccess("Project created successfully!");
        }
      } catch (e) {
        setIsWaiting(false);
        console.error("could not create company", e);
        return;
      }
    }
      // Only call update company if we have a selected company and it has no website and
    // we have entered a value for companyWebsite input
    else if (selectedCompany?.companyId && !selectedCompany?.website && companyWebsite) {
      const reqCompany = {
        companyId : selectedCompany.companyId,
        name : companyName,
        website : companyWebsite,
      };
      // Update Company
      try {
        await companyService.update(reqCompany.companyId, reqCompany);
        newProject = await createProject(selectedCompany.companyId);
        notifications.showSuccess("Project created successfully!");
      } catch (e) {
        setIsWaiting(false);
        console.error("could not update company", e);
        return;
      }
    }
    // else just create the project
    else {
      newProject = await createProject(selectedCompany?.companyId);
      notifications.showSuccess("Project created successfully!");
    }
    clearFields();
    setIsWaiting(false);
    close(newProject);
  };

  const createProject = async (companyId) => {
    // Add New Project
    if (companyId) {
      const dto = {
        name : projectName,
        companyId,
        description,
        tags : tags.map((item) => item.label?.trim()).filter(Boolean),
      };
      if (project) {
        onUpdate(dto);
        return;
      }
      if (stageFlowId) {
        dto.interviewProcessId = stageFlowId;
      }
      try {
        const project = await projectService.create(dto);
        window.analytics.track("Project Created", {
          name : projectName,
          company_id : companyId,
          company_name : companyName,
        });
        return project;
      } catch (e) {
        console.error(e);
        setIsWaiting(false);
      }
    }
  };

  // Handles case in which user clicks X button or clicks outside of the modal
  const handleModalClose = (e) => {
    clearFields();
    toggleCreateModalWindow(e);
  };

  const onStageFlowCreated = async (id) => {
    const options = await fetchStageFlowOptions();
    setStageFlowOptions(options);
    setStageFlowId(id);
    setShowCreateInterviewProcessModal(false);
  }

  return (
    <>
      <Modal id="create-project-modal" centered={true} show={isCreateModalOpen} onHide={(e) => handleModalClose(e)}>
        <CloseButton onClick={(e) => handleModalClose(e)} />
        {/* Modal Container Body*/}
        <div className={modalStyles.modalBody}>
          <div className={modalStyles.modalTitle}>
            {project ? `Update ${uiSettings?.mappings?.project}` : `New ${uiSettings?.mappings?.project}`}
          </div>
          {/* Modal Form Container */}
          <div className={cx("modal-form-container", modalStyles.modalFormContainer)}>
            {/* Modal Inputs Container */}
            <div className="modal-inputs-container">
              <ModalInputField
                id="add-project-name-input"
                label={<div className={modalStyles.modalInputLabel}>{uiSettings?.mappings?.project} Name</div>}
                placeholder={uiSettings?.mappings?.sampleProjectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  setErrors({ ...errors, name : "" });
                }}
                value={projectName}
              >
                {!!errors.name && <div style={{ color: '#ff4d4f', fontSize: 12, marginTop: 4 }}>{errors.name}</div>}
              </ModalInputField>
              <ModalInputField
                id="description"
                style={{ minHeight : 70 }}
                label={<div className={modalStyles.modalInputLabel}>Description</div>}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                component="textarea"
                value={description}
              />
              <div className={modalInputFieldStyles.holder}>
                <div className={modalStyles.modalInputLabel}>Tags</div>
                <Select
                  placeholder="Enter tags here"
                  noOptionsMessage={() => "Start typing to add a tag"}
                  formatCreateLabel={(value) => `Add "${value}"`}
                  onChange={setTags}
                  value={tags}
                  defaultBorderStyle="1px solid #9b9b9b"
                  creatable
                  isMulti
                />
                <div className={modalInputFieldStyles.spacer} />
              </div>
              <div className={modalInputFieldStyles.holder}>
                <div className={modalStyles.modalInputLabel}>Interview Process</div>
                <Select
                  isDisabled={!!project}
                  placeholder="Choose a process"
                  options={stageFlowOptions}
                  onChange={(v) => {
                    setStageFlowId(v.value)
                    setErrors({ ...errors, stageFlowId : "" });
                  }}
                  value={stageFlowOptions?.find(item => item.value === stageFlowId)}
                />
                {!!errors.stageFlowId && <div style={{ color: '#ff4d4f', fontSize: 12, marginTop: 4 }}>{errors.stageFlowId}</div>}
                <div style={{ marginTop : 3 }}>
                  <Link leftIcon={<Icon name="plus" />} onClick={() => setShowCreateInterviewProcessModal(true)}>
                    Create Interview Process
                  </Link>
                </div>
                <div className={modalInputFieldStyles.spacer} />
              </div>
              {/* Company Name */}
              <ModalInputField
                id="add-company-name-input"
                label={
                  <div className={modalStyles.modalInputLabel}>
                    <div
                      className={cx(
                        css`
                          display: inline;
                        `,
                        { [modalStyles.disabledLabel] : selectedCompany && selectedCompany?.website },
                      )}
                    >
                      Company
                    </div>
                  </div>
                }
                placeholder="Company Name"
                onChange={onCompanyNameChange}
                onBlur={(e) => handleOnBlur(e)}
                onFocus={() => setShowSuggestedCompanies(true)}
                value={companyName}
                autocomplete="off"
                additionalClasses={cx({ [modalStyles.disabledLabel] : targetCompany && targetCompany?.name })}
                disabled={targetCompany && targetCompany?.name}
              >
                {!!errors.companyName && <div style={{ color: '#ff4d4f', fontSize: 12, marginTop: 4 }}>{errors.companyName}</div>}
                {/* Suggested Companies Dropdown*/}
                {companies.length > 0 && showSuggestedCompanies && suggestedCompanies?.length > 0 && (
                  <DropdownOptions options={suggestedCompanies} onMouseDown={handleCompanyOptionClick} />
                )}
              </ModalInputField>
              {/* Company Website */}
              <ModalInputField
                id="add-company-website-input"
                label={
                  <div className={modalStyles.modalInputLabel}>
                    <div
                      className={cx(
                        css`
                          display: inline;
                        `,
                        { [modalStyles.disabledLabel] : selectedCompany && selectedCompany?.website },
                      )}
                    >
                      Website
                    </div>
                    &nbsp;
                    <div
                      className={cx(
                        css`
                          display: inline;
                          color: #a5a5a5;
                        `,
                        { [modalStyles.disabledLabel] : selectedCompany && selectedCompany?.website },
                      )}
                    >
                      (for company logo)
                    </div>
                  </div>
                }
                additionalClasses={cx({ [modalStyles.disabledLabel] : selectedCompany && selectedCompany?.website })}
                placeholder="www.example.com"
                onChange={(e) => setCompanyWebsite(e.target.value)}
                value={companyWebsite}
                disabled={selectedCompany && selectedCompany?.website}
              />
            </div>
            <ModalButtonContainer
              title={project ? "Update" : "Create"}
              onClick={handleProjectSave}
              isWaiting={isWaiting}
            />
          </div>
        </div>
      </Modal>
      {showCreateInterviewProcessModal && (
        <ModalCreateStageFlow
          onCreated={onStageFlowCreated}
          onClose={() => setShowCreateInterviewProcessModal(false)}
        />
      )}
    </>
  );
}

export default NewProjectModal;
