import React, { createRef } from "react";
import { SearchBox } from "searchkit";
import { noop } from "lodash";
import { Input } from "../DiscoverNew/Form/Input/Input";

// This is a temporary hack to reuse Searchkit's searchbox component so that all of our search boxes match in style
class CustomSearchBox extends SearchBox {
  accessor = true;

  static defaultProps = {
    placeholder: "Search",
    isLoading: false,
    mod: "sk-search-box",
    onChange: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      input: "",
    };
    this.inputRef = createRef();
  }

  defineAccessor() {}

  onChange(e) {
    const inputValue = e.target.value;
    this.props.onChange(inputValue);
  }

  onSubmit() {}

  getValue() {
    return this.props.value;
  }

  translate(s) {
    return s;
  }

  render() {
    const block = this.bemBlocks.container;

    return (
      <div className={this.props.className}>
        <form onSubmit={this.onSubmit.bind(this)}>
          <Input
            clearable
            data-qa="query"
            className={block("text")}
            placeholder={this.props.placeholder || this.translate("searchbox.placeholder")}
            value={this.getValue()}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
            ref={this.inputRef}
            autoFocus={this.props.autofocus}
            onChange={this.onChange.bind(this)}
          />
          <input
            type="submit"
            value={this.translate("searchbox.button")}
            className={block("action")}
            data-qa="submit"
          />
        </form>
      </div>
    );
  }
}

export default CustomSearchBox;
