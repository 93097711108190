import { cx } from "emotion";
import * as React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../button/CloseButton";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { modalStyles } from "../modalStyles";

import css from "./ConfirmModal.module.css";

type Props = {
  title: string
  content: any
  onConfirm: () => void
  onCancel: () => void
  closeOnConfirm?: boolean
  confirmButtonTitle: string
  cancelText: string
  cancelButton: any
  destructive: boolean
  isOpen: boolean
  className?: string
}

export const ConfirmModal = (props: Props) => {
  const {
    title,
    content,
    onConfirm,
    onCancel,
    closeOnConfirm = true,
    confirmButtonTitle,
    cancelText,
    cancelButton,
    destructive,
    isOpen,
  } = props;

  const confirm = () => {
    onConfirm();
    closeOnConfirm && onCancel();
  };

  return (
    <Modal dialogClassName={cx(css.modal, props.className)} show={isOpen} onHide={onCancel} centered={true}>
      <CloseButton onClick={onCancel} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle} style={{ paddingBottom: 15 }}>
          {title}
        </div>
        <div className={css.text}>{content}</div>
        <div className={css.buttonRow}>
          {cancelButton !== false && (
            <Button className={cx(css.button, css.cancel)} onClick={onCancel} theme="outline">
              {cancelText || "Cancel"}
            </Button>
          )}

          {destructive ? (
            <Button className={css.button} onClick={confirm} theme="danger">
              {confirmButtonTitle || "Delete"}
            </Button>
          ) : (
            <Button className={css.button} onClick={confirm}>
              {confirmButtonTitle || "Confirm"}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};