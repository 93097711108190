import { omit, pick, withoutEmptyKeys } from "../util/util";
import { UrlHelper } from "../util/UrlHelper";
import { LOCATION_SEARCH_MODE } from "../../../constants/constants";

export class DiscoverSearchHelper {
  static serializeSearchToQueryString(filters, pageSize, currentPage) {
    return UrlHelper.stringifyParams({
      ...omit(filters, ["country", "addresses"]),
      addresses: encodeURIComponent(JSON.stringify(filters.addresses.map((obj) => withoutEmptyKeys(obj)))),
      ...(filters.country?.value
        ? {
            countryValue: filters.country.value,
            countryLabel: filters.country.label,
          }
        : {}),
      pageSize,
      currentPage,
    });
  }

  static deserializeSearchQueryStringToObject(searchParams, defaultFilters, defaultPagination) {
    const { currentPage, pageSize, addresses, excludeViewed, industries, degree, currentCompanySize, skillsMustSelect, skillsShouldSelect, ...filters } = searchParams;

    let addressFilter = defaultFilters.addresses;

    if (addresses) {
      let addressArray = JSON.parse(decodeURIComponent(addresses));
      addressFilter = addressArray.map((item) => {
        let locationSearchMode = LOCATION_SEARCH_MODE.zip;

        if (item.state?.value) {
          locationSearchMode = LOCATION_SEARCH_MODE.state;
        }
        if (item.city?.name) {
          locationSearchMode = LOCATION_SEARCH_MODE.city;
        }

        return {
          id: item.id,
          locationSearchMode,
          distance: item.distance ? parseInt(item.distance) : defaultFilters.addresses[0].distance,
          zip: item.zip || defaultFilters.addresses[0].zip,
          state: item.state?.value
            ? {
                value: item.state.value,
                label: item.state.label,
              }
            : defaultFilters.addresses[0].state,
          city: item.city?.name
            ? {
                name: item.city.name,
                lat: parseFloat(item.city.lat),
                lng: parseFloat(item.city.lng),
              }
            : defaultFilters.addresses[0].city,
        };
      });
    }

    return {
      filters: {
        ...defaultFilters,
        ...pick(filters, Object.keys(defaultFilters)),
        addresses: addressFilter,
        withEmailsOnly: filters.withEmailsOnly === "true",
        withPhonesOnly: filters.withPhonesOnly === "true",
        excludeViewed: excludeViewed === "true",
        industries: industries ? [].concat(industries) : defaultFilters.industries,
        degree: degree ? [].concat(degree) : defaultFilters.degree,
        major: filters.major ? [].concat(filters.major) : defaultFilters.major,
        currentCompanySize: currentCompanySize ? [].concat(currentCompanySize) : defaultFilters.currentCompanySize,
        skillsMustSelect: skillsMustSelect ? [].concat(skillsMustSelect) : defaultFilters.skillsMustSelect,
        skillsShouldSelect: skillsShouldSelect ? [].concat(skillsShouldSelect) : defaultFilters.skillsShouldSelect,
        country: filters.countryValue
          ? {
              value: filters.countryValue,
              label: filters.countryLabel,
            }
          : defaultFilters.country,
      },
      pagination: {
        currentPage: parseInt(currentPage || defaultPagination.currentPage),
        pageSize: parseInt(pageSize || defaultPagination.pageSize),
      },
    };
  }
}
