import { useStripe } from "@stripe/react-stripe-js";
import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { css as cssEmotion } from "emotion";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ROUTES, SUPPORT_EMAIL } from "../../../constants/constants";
import { useAuthController } from "../../../contexts/AuthProvider";
import {
  billingService,
  CurrentPlanInterface,
  PlanInfoInterface, UpdatePlanDTO,
} from "../../../services/BillingService";
import Counter from "../../Counter/Counter";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { Alert } from "../../ui/Alert/Alert";
import modal from "../modal.module.css";
import css from "../PurchasePlanModal/PlanStep/PlanStep.module.css";
import classes from "../PurchasePlanModal/PurchasePlanModal.module.css";

type Props = {
  onClose: () => void
}

const useSubscriptionUpgradePreview = ({ seatsCount } : { seatsCount : number }) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  React.useEffect(() => {
    setLoading(true);
    billingService.fetchPreviewSubscriptionUpgrade({
      seatsCount
    }).then(r => {
      setPrice(r.amount / 100);
    }).finally(() => {
      setLoading(false)
    });
  }, [seatsCount])

  return [price, loading];
}

const EditPlanModal = ({ onClose }: Props) => {
  const stripe = useStripe();
  const history = useHistory();
  const authController = useAuthController();
  const planInfo = authController.user.currentPlan.planInfo as PlanInfoInterface;
  const currentPlan = authController.user.currentPlan as CurrentPlanInterface;
  const [seatsCount, setSeatsCount] = useState(currentPlan.seats.count);
  const [price, priceLoading] = useSubscriptionUpgradePreview({ seatsCount });
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const onSubmit = async () => {
    setPurchaseLoading(true);
    const data = { seatsCount };
    try {
      const res = await billingService.updatePlan(data);
      const { clientSecret } = res.data.paymentIntent;
      const { error } = await stripe!.confirmPayment({
        clientSecret,
        redirect: "if_required",
        confirmParams : {
          return_url : window.location.origin + ROUTES.paymentProcessing,
        },
      });
      if (error) {
        throw error;
      }
      history.push(ROUTES.paymentProcessing);
    } finally {
      setPurchaseLoading(false);
    }
  }

  const getPricingBreakdown = () => {
    return {
      monthlyCredits : planInfo.creditsPerSeat * seatsCount,
      nextRenewalPrice : planInfo.pricePerSeat * seatsCount,
    };
  };

  const breakdown = getPricingBreakdown();
  const canUpgrade = seatsCount > currentPlan.seats.count;
  const isTheSamePlan = seatsCount === currentPlan.seats.count;
  const isLoading = priceLoading || purchaseLoading;

  return (
    <Modal show={true} onHide={onClose} centered dialogClassName={cssEmotion`
      .modal-content {
        min-height: unset;
      }
    `}>
      <Icon name="fa-xmark" className={modal.close} onClick={onClose} />
      <div className={modal.body} style={{ minHeight: 300 }}>
        <div className={modal.info} style={{ margin: 0 }}>
          <h3 className={modal.title}>{planInfo.title}</h3>
          <p className={modal.subtitle}>your current plan</p>
          <Counter label="yearly subscription seats" min={1} max={99} value={seatsCount} onChange={setSeatsCount}
                   className={classes.counter} />
          <div className={css.breakdown}>
            {/*<div className={css.breakdownItem}>*/}
            {/*  <div className={css.breakdownItemTitle}>*/}
            {/*    Yearly Credits*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <div className={css.breakdownItemValue}>*/}
            {/*      {breakdown.monthlyCredits.toLocaleString()}*/}
            {/*    </div>*/}
            {/*    <div className={css.subtext}>*/}
            {/*      {planInfo.creditsPerSeat.toLocaleString()} x {seatsCount} seat{seatsCount > 1 && "s"}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={css.breakdownItem}>
              <div className={css.breakdownItemTitle}>
                Next Renewal Cost
              </div>
              <div>
                <div className={css.breakdownItemValue}>
                  ${breakdown.nextRenewalPrice.toLocaleString()}
                </div>
                <div className={css.subtext}>
                  ${planInfo.pricePerSeat.toLocaleString()} x {seatsCount} seat{seatsCount > 1 && "s"}
                </div>
              </div>
            </div>
            {seatsCount > currentPlan.seats.count && (
              <div className={css.breakdownItem}>
                <div className={css.breakdownItemTitle}>
                  Total to Pay
                </div>
                <div>
                  <div className={css.breakdownItemValue}>
                    {priceLoading ? 'Calculating...' : `$${price.toLocaleString(undefined,{minimumFractionDigits: 2})}`}
                  </div>
                  <div className={css.subtext}>
                    pro-rated amount
                  </div>
                </div>
              </div>
            )}
          </div>
          {seatsCount < currentPlan.seats.count && (
              <Alert style={{ maxWidth: 320, margin: '0 auto' }}>
                To downgrade your plan please contact with support via <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank"
                                                                               rel="noopener noreferrer">{SUPPORT_EMAIL}</a></Alert>
          )}
          <div className={classes.navigation}>
            <Button className={classes.button} onClick={onSubmit} disabled={!canUpgrade || isLoading} style={{ width: 'unset', padding: '0 20px', minWidth: 151 }}>
              {purchaseLoading ? 'Processing...' :  isTheSamePlan ? 'This is your current plan' : 'Purchase'}
            </Button>
          </div>
          {canUpgrade && (
            <div className={css.subtext} style={{ textAlign : "center", marginTop : 2 }}>
              {seatsCount > currentPlan.seats.count ? (
                "you will be charged immediately"
              ) : (
                `changes will take effect on ${DateTime.fromISO(currentPlan.renewsAt!).toFormat("DD")}`
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditPlanModal;
