import React, { useState, useEffect, useCallback } from "react";
import { cx, css } from "emotion/macro";
import { isEmpty } from "lodash";

import * as api from "../../api/apiService";
import * as globals from "../../constants/constants";

// Components
import HorizontalStackedChart from "./HorizontalStackedChart";
import Histogram from "./Histogram";
import SocialPlatformToggles from "./SocialPlatformToggles";

// Styles
import "../mobile/Mobile.css";
import { md, mdU, lg } from "../../css/mediaQueries";

// Assets
import noSearchResultsCharacter from "../../assets/images/no-search-results-character.png";

const SubContainer = ({ children, header }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div
      className={cx(
        styles.subContainer,
        css`
          align-items: ${!isExpanded ? "center" : "initial"};
          flex-flow: ${!isExpanded ? "row" : "initial"};
        `
      )}
    >
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={cx(
          styles.subContainerHeader,
          css`
            font-family: ${isExpanded ? "initial" : "Montserrat-Bold!important"};
            height: ${isExpanded ? "30px" : "100%"};
          `
        )}
      >
        {header}
      </div>
      {isExpanded && children}
    </div>
  );
};

function InsightDetail({ candidate }) {
  const [socialPlatform, setSocialPlatform] = useState("");
  const [socialPlatformDataMap, setSocialPlatformDataMap] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [insightsObject, setInsightsObject] = useState(null);

  // Raw data maps
  const [timeSpentDataMap, setTimeSpentDataMap] = useState(null);
  const [totalTimeDataMap, setTotalTimeDataMap] = useState(null);
  const [timeOfDayDataMap, setTimeOfDayDataMap] = useState(null);

  // Transformed Time Spent Data
  const [transformedTimeSpentData, setTransformedTimeSpentData] = useState([]);

  // Bar Data Display
  const [totalTimeSeriesArray, setTotalTimeSeriesArray] = useState([]);
  const [timeOfDaySeriesArray, setTimeOfDaySeriesArray] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isMissingAllData, setIsMissingAllData] = useState(false);

  const handleMetricDataResponse = ({ data }, metric) => {
    if (!data || !metric) {
      return;
    }

    const metricResponse = data;
    switch (metric) {
      case globals.TIME_SPENT:
        setTimeSpentDataMap(metricResponse);
        break;
      case globals.TOTAL_TIME:
        setTotalTimeDataMap(metricResponse);
        break;
      case globals.TIME_OF_DAY:
        setTimeOfDayDataMap(metricResponse);
        break;
      default:
        return;
    }
  };

  const fetchSocialMetricData = useCallback(async (candidateId) => {
    if (!candidateId) {
      return;
    }

    try {
      // This will call all APIs asynchronously handle the response for each
      // metric in the `handleMetricDataResponse` func when the data arrives.
      await Promise.all([
        api
          .GetSocialActivityMetrics(candidateId, globals.TIME_SPENT)
          .then((data) => handleMetricDataResponse(data, globals.TIME_SPENT)),
        api
          .GetSocialActivityMetrics(candidateId, globals.TOTAL_TIME)
          .then((data) => handleMetricDataResponse(data, globals.TOTAL_TIME)),
        api
          .GetSocialActivityMetrics(candidateId, globals.TIME_OF_DAY)
          .then((data) => handleMetricDataResponse(data, globals.TIME_OF_DAY)),
      ]);

      setIsDataLoaded(true);
    } catch (e) {
      console.error("error fetching social activity metric data", e);
    }
  }, []);

  useEffect(() => {
    if (!isDataLoaded) {
      return;
    }

    let initialSocialPlatform = "";
    let platformHasDataMap = {};

    // Loop through ordered social platforms and set the first instance
    // in any of the maps as the selected social platform. Store social platforms
    // with data in a map to determine whether or not disable toggle button downstream.
    for (let sp of globals.ORDERED_SOCIAL_PLATFORMS) {
      if (timeSpentDataMap[sp] || totalTimeDataMap[sp] || timeOfDayDataMap[sp]) {
        if (!initialSocialPlatform) {
          initialSocialPlatform = sp;
        }
        platformHasDataMap[sp] = true;
      }
    }

    setSocialPlatform(initialSocialPlatform);
    setSocialPlatformDataMap(platformHasDataMap);
  }, [isDataLoaded, timeSpentDataMap, totalTimeDataMap, timeOfDayDataMap]);

  useEffect(() => {
    if (candidate?.candidateId) {
      fetchSocialMetricData(candidate?.candidateId);
    }
  }, [candidate, fetchSocialMetricData]);

  useEffect(() => {
    // Wait until we have handled the response for each metric
    if (!isDataLoaded) {
      return;
    }

    setIsLoading(false);

    if (isEmpty(timeSpentDataMap) && isEmpty(totalTimeDataMap) && isEmpty(timeOfDayDataMap)) {
      setIsMissingAllData(true);
    }
  }, [isDataLoaded, timeSpentDataMap, totalTimeDataMap, timeOfDayDataMap]);

  const handleSocialPlatformChange = (selectedSocialPlatform) => {
    if (!selectedSocialPlatform || selectedSocialPlatform === socialPlatform) {
      return;
    }

    // Track social platform based metric data
    window.analytics.track(`Candidate Social Metric Viewed`, {
      candidate_id: candidate?.candidateId,
      state: candidate?.location?.region,
      locality: candidate?.location?.locality,
      is_unlocked: candidate?.unlockedAt,
      social_platform: selectedSocialPlatform,
    });

    setSocialPlatform(selectedSocialPlatform);
  };

  useEffect(() => {
    if (!totalTimeDataMap) {
      return;
    }

    const totalTimeSeries = totalTimeDataMap[socialPlatform];
    // If we do not have data for the particular socialPlatform, set default array
    if (!totalTimeSeries) {
      setTotalTimeSeriesArray(globals.DEFAULT_TOTAL_TIME_DATA);
      return;
    }

    if (totalTimeSeries) {
      setTotalTimeSeriesArray(totalTimeSeries);
    }
  }, [socialPlatform, totalTimeDataMap]);

  useEffect(() => {
    if (!timeOfDayDataMap) {
      return;
    }

    const timeOfDaySeries = timeOfDayDataMap[socialPlatform];
    // If we do not have data for the particular socialPlatform, set default array
    if (!timeOfDaySeries) {
      setTimeOfDaySeriesArray(globals.DEFAULT_TIME_OF_DAY_DATA);
      return;
    }

    if (timeOfDaySeries) {
      setTimeOfDaySeriesArray(timeOfDaySeries);
    }
  }, [socialPlatform, timeOfDayDataMap]);

  useEffect(() => {
    if (isEmpty(timeSpentDataMap)) {
      setTransformedTimeSpentData([]);
      return;
    }

    let transformedData = [];
    let combinedObject = {};

    for (let key in timeSpentDataMap) {
      combinedObject[key] = timeSpentDataMap[key]?.percentage || 0;
    }

    const finalObj = { ...combinedObject };
    transformedData.push(finalObj);

    // setTransformedTimeSpentData
    if (transformedData) {
      setTransformedTimeSpentData(transformedData);
    }
  }, [socialPlatform, timeSpentDataMap]);

  useEffect(() => {
    if (!candidate || !totalTimeSeriesArray?.length || !timeOfDaySeriesArray?.length) {
      return;
    }

    let firstName = candidate?.firstName || "this candidate";

    // Find the object with the max value of time series
    const maxTotalTimeSeriesObject = totalTimeSeriesArray.reduce(function (prev, current) {
      return prev?.totalTime > current?.totalTime ? prev : current;
    });

    // Find the object with the max value of time of day
    const maxTimeOfDaySeriesObject = timeOfDaySeriesArray.reduce(function (prev, current) {
      return prev?.totalTime > current?.totalTime ? prev : current;
    });

    if (maxTotalTimeSeriesObject?.totalTime === 0 && maxTimeOfDaySeriesObject?.totalTime === 0) {
      // If no data available, reset insights object to prevent showing stale data
      setInsightsObject(null);
      return;
    }

    setInsightsObject({
      firstName: firstName,
      platform: socialPlatform,
      day: maxTotalTimeSeriesObject?.totalTime === 0 ? "" : maxTotalTimeSeriesObject?.name,
      time: maxTimeOfDaySeriesObject?.totalTime === 0 ? "" : maxTimeOfDaySeriesObject?.name,
    });
  }, [candidate, socialPlatform, totalTimeSeriesArray, timeOfDaySeriesArray]);

  const getPlatformColor = (platform) => {
    switch (platform) {
      case globals.SOCIAL_TWITTER:
        return "#00acee";
      case globals.SOCIAL_INSTAGRAM:
        return "#ea4c89";
      case globals.SOCIAL_LINKEDIN:
        return "#0e76a8";
      case globals.SOCIAL_FACEBOOK:
        return "#4267b2";
      default:
        return "#00acee";
    }
  };

  if (isLoading) {
    return "";
  }

  return (
    <div className={cx(styles.container)}>
      {isMissingAllData ? (
        <div
          className={css`
            margin: 0px 28px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            ${mdU} {
              height: calc(100% - 96px);
            }
            ${md} {
              height: 100%;
              justify-content: flex-start;
              text-align: center;
              margin-top: 0px;
            }
          `}
        >
          <div>
            <img
              className={css`
                width: 141px;
                height: 122px;
              `}
              src={noSearchResultsCharacter}
              alt=""
            />
          </div>
          <p
            className={css`
              font-family: Montserrat-Regular;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.44px;
              text-align: center;
              color: #373737;
            `}
          >
            We're finding the latest activity data for you. Check back in a minute.
          </p>
        </div>
      ) : (
        <>
          {insightsObject && (
            <SubContainer header={"Insights"}>
              <div className={cx(styles.insightsContentContainer)}>
                <div className={cx(styles.insightConnectContainer)}>
                  <div className={cx(styles.metricHeader)}>How To Connect</div>
                  <div className={styles.insightsTextContainer}>
                    <span className={styles.insightsText}>
                      The best chance of connecting with{" "}
                      <span
                        className={css`
                          text-transform: capitalize;
                        `}
                      >
                        {`${insightsObject?.firstName} ` || "this candidate "}
                      </span>
                      {insightsObject?.day ? "is on " : "is "}
                      <span className={styles.insightsTextEmphasized}>
                        {insightsObject?.day ? `${insightsObject?.day} ` : " "}
                      </span>
                      {insightsObject?.time ? "between " : ""}
                      <span className={styles.insightsTextEmphasized}>
                        {insightsObject?.time ? `${insightsObject?.time} ` : " "}
                      </span>
                      using{" "}
                      <span
                        className={cx(
                          styles.insightsTextEmphasized,
                          css`
                            text-transform: capitalize;
                            color: ${getPlatformColor(insightsObject?.platform)};
                          `
                        )}
                      >
                        {insightsObject?.platform ? `${insightsObject?.platform}` : "Twiter"}
                      </span>
                      <span>{"."}</span>
                    </span>
                  </div>
                </div>
              </div>
            </SubContainer>
          )}
          <SubContainer header={"Activity"}>
            <div className={styles.metricContainerWrapper}>
              <div className={cx(styles.metricContainer)}>
                <div className={cx(styles.metricHeader)}>Time Spent</div>
                <SocialPlatformToggles
                  socialPlatform={socialPlatform}
                  onClick={handleSocialPlatformChange}
                  socialPlatformDataMap={socialPlatformDataMap}
                />
                <div className={cx(styles.horizontalStackChartContainer)}>
                  {socialPlatform && (
                    <HorizontalStackedChart data={transformedTimeSpentData} socialPlatform={socialPlatform} />
                  )}
                </div>
              </div>
              <div className={cx(styles.metricContainer)}>
                <div className={styles.histogramContainer}>
                  <div className={cx(styles.histogramHeaderAndGraphContainer)}>
                    <div className={cx(styles.metricHeader)}>Total Time</div>
                    <Histogram
                      metric={globals.TOTAL_TIME}
                      data={totalTimeSeriesArray}
                      socialPlatform={socialPlatform}
                    ></Histogram>
                  </div>
                  <div className={cx(styles.histogramHeaderAndGraphContainer)}>
                    <div className={cx(styles.metricHeader)}>Time Of Day</div>
                    <Histogram
                      metric={globals.TIME_OF_DAY}
                      data={timeOfDaySeriesArray}
                      socialPlatform={socialPlatform}
                    ></Histogram>
                  </div>
                </div>
              </div>
            </div>
          </SubContainer>
          {/* <div className={cx(styles.subContainer)}>
            <div className={cx(styles.subContainerHeader)}>Actions</div>
          </div> */}
        </>
      )}
    </div>
  );
}

const styles = {
  container: css`
    height: 100%;
    padding: 20px 50px 55px 20px;
    overflow-y: auto;
  `,
  subContainer: css`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.71px;
    color: #373737;
    padding: 15px 3px;
    border-bottom: 1px solid #d6d6d6;

    &:first-child {
      padding-top: 0px;
    }
  `,
  subContainerHeader: css`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #373737;
    text-transform: uppercase;
    cursor: pointer;
  `,
  insightsContentContainer: css`
    margin: 0px;
  `,
  metricContainerWrapper: css`
    margin-top: 0px;
  `,
  metricContainer: css`
    margin: 23px auto;
    &:first-child {
      margin-top: 0px;
    }
  `,
  socialPlatformToggleContainer: css`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 22px;
    padding: 0px 30px;
    ${lg} {
      padding: 0px;
    }
  `,
  socialPlatformButton: css`
    border-radius: 25px;
    object-fit: contain;
    padding: 7px;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;

    min-width: 100px;
    height: 30px;
    // color: #4a4a4a;

    ${lg} {
      min-width: 65px;
      margin-right: 5px;
    }
  `,
  horizontalStackChartContainer: css`
    width: 100%;
    height: 60px;
    margin: 23px auto;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  histogramContainer: css`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    ${lg} {
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  `,
  histogramHeaderAndGraphContainer: css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    ${lg} {
      width: 100%;
      align-items: center;
    }
  `,
  metricHeader: css`
    font-family: Montserrat-Bold !important;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.71px;
    color: #373737;
    margin-left: 15px;
  `,
  insightsTextContainer: css`
    margin-left: 15px;
  `,
  valueContainerWrapper: css`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin: 23px auto;
  `,
  valueContainer: css`
    margin-right: 20px;
    & > span {
      margin: 0 2px 0 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.57px;
      text-align: center;
      color: #000000;
    }
    & > span:first-child {
      margin: 0 5px 0 0;
      font-size: 25px;
    }
  `,
  emptyBarDataContainer: css`
    width: 250px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  insightsText: css`
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.71px;
    color: #9b9b9b;
  `,
  insightsTextEmphasized: css`
    font-family: Montserrat-Bold !important;
  `,
};

export default InsightDetail;
