import { css } from "emotion/macro";
import { smU, xs } from "../../css/mediaQueries";

export const modalStyles = {
  modalBody: css`
    ${smU} {
      padding: 50px 40px 50px 40px;
      min-height: 412px;
    }
    ${xs} {
      height: 100%;
    }
  `,
  modalFormContainer: css`
    ${xs} {
      padding: 0px 40px;
    }
  `,

  modalTitle: css`
    padding-bottom: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #373737;

    ${xs} {
      margin-top: 65px;
      margin-bottom: 50px;
      padding-bottom: 0px;
    }
  `,

  modalSubtitle: css`
    margin: 0;
    padding-bottom: 19px;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: #222222;
  `,

  modalInputLabel: css`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #3f3f3f;
    display: flex;
    margin: auto 0;
    margin-bottom: 13px;

    ${xs} {
      width: 100%;
    }
  `,

  disabledLabel: css`
    opacity: 0.5;
    color: #373737;
  `,
};
