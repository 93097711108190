import * as React from "react";

import css from "./InfoTable.module.css";

export const InfoTable = (props) => {
  return (
    <table className={css.table} style={props.style}>
      <tbody>{props.children}</tbody>
    </table>
  );
};
