import { useEffect, useState } from "react";
import * as React from "react";
import * as api from "../../../../../../api/apiService";
import { DISCOVER_DEGREE_OPTIONS } from "../../../../../../constants/constants";
import { useUserData } from "../../../../../../contexts/userContext";
import { CompanyInterface } from "../../../../../../screens/companies/CompanyService";
import { ProjectInterface } from "../../../../../../screens/projects/CandidateService";
import { Form } from "../../../../../DiscoverNew/Form/Form";
import { FormField } from "../../../../../DiscoverNew/Form/FormField/FormField";
import { Select } from "../../../../../DiscoverNew/Form/Select/Select";
import { Icon } from "../../../../../DiscoverNew/UI/IconComponent/Icon";
import { Link } from "../../../../../DiscoverNew/UI/Link/Link";
import NewProjectModal from "../../../../../modals/NewProjectModal";

type Props = {
  values: any,
  errors: any,
  onChange: (value: any) => void,
}

export const AddCandidateManuallyOrganizeStep = ({ values, errors, onChange }: Props) => {
  const [companyWithProjectOptions, setCompanyWithProjectOptions] = useState([]);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const {
    state: { user },
  } = useUserData();
  const uiSettings = user.uiSettings;

  function fetchCompanyOptions() {
    return api.GetAllCompaniesAndProjects().then((r) => {
      return r.data
        .filter((company: CompanyInterface) => company.projects?.length)
        .map((company: CompanyInterface) => {
          return {
            label: company.name,
            options: company.projects.map((p) => ({
              value: p.projectId,
              label: p.name,
            })),
          };
        });
    });
  }

  function onProjectCreated(project: ProjectInterface) {
    onChange({
      project: {
        label: project.name,
        value: project.projectId,
      }
    });
    fetchCompanyOptions().then((options) => {
      setCompanyWithProjectOptions(options);
      setShowCreateProjectModal(false);
    });
  }

  useEffect(() => {
    fetchCompanyOptions().then((options) => {
      setCompanyWithProjectOptions(options);
    });
  }, [])

  return (
    <Form>
      <Form.Row>
        <FormField label="Tags" error={errors.tags}>
          <Select
            placeholder="Enter tags here"
            noOptionsMessage={() => "Start typing to add a tag"}
            formatCreateLabel={(value: string) => `Add "${value}"`}
            onChange={tags => onChange({ tags })}
            value={values.tags}
            defaultBorderStyle="1px solid #9b9b9b"
            creatable
            isMulti
          />
        </FormField>
      </Form.Row>
      <Form.Row>
        <FormField label="Project" error={errors.project}>
          <Select
            options={companyWithProjectOptions}
            onChange={(project) => onChange({ project })}
            value={values.project}
          />
          <div style={{ marginTop: 3 }}>
            <Link leftIcon={<Icon name="plus" />} onClick={() => setShowCreateProjectModal(true)}>
              Create {uiSettings?.mappings?.project}
            </Link>
          </div>
        </FormField>
      </Form.Row>

      {showCreateProjectModal && (
        //@ts-ignore
        <NewProjectModal
          uiSettings={uiSettings}
          isCreateModalOpen={showCreateProjectModal}
          toggleCreateModalWindow={() => setShowCreateProjectModal(!showCreateProjectModal)}
          close={onProjectCreated}
        />
      )}
    </Form>
  )
}