export const sort = (items, { field, fieldType, asc }) => {
  const list = [...items];

  list.sort((a, b) => {
    const val1 = a[field] || a;
    const val2 = b[field] || b;

    let result = 0;

    switch (fieldType) {
      case "string":
        result = val1.toLowerCase().localeCompare(val2.toLowerCase());
        break;
      case "number":
        result = val1 - val2;
        break;
      default:
    }

    return asc ? result : result * -1;
  });

  return list;
};

export const sortIterableValuesOfObject = (obj, sortOptions) => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: Array.isArray(value) ? sort(value, sortOptions) : value }),
    {}
  );
};

export const getOptionsSeparator = (title) => ({
  value: `placeholder-${title}`,
  label: `${title}`,
  disabled: true,
});
