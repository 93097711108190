import * as React from "react";
import css from "./AdminOrganizationListFilters.module.css";
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { FilterSelectHtml } from "../../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { PageSubtitle } from "../../../../components/ui/PageSubtitle/PageSubtitle";
import { SearchInput } from "../../../../components/ui/SearchInput/SearchInput";

export const AdminOrganizationListFilters = ({
  users,
  onChangeSort,
  sort,
  sortOptions,
  query,
  onChangeQuery,
  selectedOrganizations,
  onDeleteOrganizations,
}) => {
  return (
    <div>
      <div className={css.info}>
        <PageSubtitle>Organizations {users?.pageInfo?.total ? `(${users.pageInfo.total})` : ""}</PageSubtitle>
      </div>
      <div className={css.controls}>
        <div>
          <SearchInput
            value={query}
            onChange={(e) => onChangeQuery(e.target.value)}
            rightIcon={<Icon name="search" />}
            placeholder="Quick Search"
          />
          {!!Object.keys(selectedOrganizations).length && (
            <Button theme="dangerOutlined" onClick={onDeleteOrganizations} style={{ fontSize: 12, marginLeft: 12 }}>
              Delete Selected
            </Button>
          )}
        </div>
        <div>
          <FilterSelectHtml label="Sort By" onChange={onChangeSort} options={sortOptions} value={sort} />
        </div>
      </div>
    </div>
  );
};
