import { loadStripe } from "@stripe/stripe-js";
import { DateTime } from "luxon";
import {
  v2_AddOrganizationExtraCredits,
  v2_CancelOrganizationActiveSubscription,
  v2_CreateOrganizationSubscription,
  v2_CreatePaymentMethod,
  v2_DeletePaymentMethod,
  v2_GetAvailablePlans,
  v2_GetBillingHistory,
  v2_GetInvoiceDownloadURL,
  v2_GetOrganizationActiveSubscription,
  v2_GetOrganizationSeatCreditsUsage,
  v2_GetPaymentMethods,
  v2_GetPreviewSubscriptionUpgrade,
  v2_RenewOrganizationActiveSubscription,
  v2_SetDefaultPaymentMethod,
  v2_UpdateOrganizationSubscription,
} from "../api/apiServiceV2";
import { PurchasePlanMember } from "../types";
import { organizationService } from "./OrganizationService";

export enum ExternalSubscriptionStatus {
  active = "active",
  cancelled = "cancelled",
}

export type InvoiceInterface = {
  id: string
  description: string
  stripeId: string
  reference: string
  downloadUrl: string
  amount: number
  createdAt: number
  status: InvoiceStatus
}

export enum InvoiceStatus {
  paid = "paid",
  processing = "processing",
  new = "new",
  refunded = "refunded",
  cancelled = "cancelled"
}

export type PaymentMethodInterface = {
  id: string
  last4: string
  expMonth: string
  expYear: string
  brandName: string
  isDefault: boolean
}

export enum PlanName {
  trial = "trial",
  sourcing = "sourcing"
}

export enum BillingPeriod {
  one_time = "one_time",
  monthly = "monthly",
  yearly = "yearly"
}

enum OneTimeBillingDuration {
  days_14 = "days_14",
}

export type PlanInfoInterface = {
  id: number
  name: PlanName
  title: string
  pricePerSeat: number
  creditsPerSeat: number
  creditsPerSeatSingleUse: number
  maxPaidSeats: number
  oneTimeBillingDuration?: OneTimeBillingDuration
  billingPeriod: BillingPeriod
}

export type CurrentPlanInterface = {
  planInfo: PlanInfoInterface
  price: number
  singleUseCredits: {
    used: number
    count: number
  }
  seatCredits: {
    count: number
    used: number
  }
  organizationCredits: {
    count: number
    used: number
  }
  seats: {
    count: number
    used: number
  },
  billingContactEmail?: string
  renewsAt?: string
  expiresAt?: string
  cancelledAt?: string
  externalSubscriptionStatus: ExternalSubscriptionStatus
}

export type UpdatePlanDTO = {
  seatsCount: number
}

export type PurchasePlanDTO = {
  seatsCount: number
  members: PurchasePlanMember[]
}

const daysToOneTimeDuration: Record<OneTimeBillingDuration, number> = {
  days_14 : 14,
};

export type PlansInfoMap = Record<PlanName, PlanInfoInterface>

type InvoicesFetchAllFilter = {
  query: string
}

export function initializeStripe() {
  // @ts-ignore
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { locale: 'en' });
}

function makePlans(): PlansInfoMap {
  return {
    trial : {
      id : 0,
      name : PlanName.trial,
      title : "Trial",
      pricePerSeat : 0,
      creditsPerSeat : 0,
      creditsPerSeatSingleUse : 100,
      maxPaidSeats : 10,
      oneTimeBillingDuration : OneTimeBillingDuration.days_14,
      billingPeriod : BillingPeriod.one_time,
    },
    sourcing : {
      id : 1,
      name : PlanName.sourcing,
      title : "Sourcing",
      pricePerSeat : 50,
      creditsPerSeat : 1000,
      creditsPerSeatSingleUse : 0,
      maxPaidSeats : 10,
      billingPeriod : BillingPeriod.yearly,
    },
  };
}

function makePaymentMethods(): PaymentMethodInterface[] {
  return [
    { id : "0", last4 : "1234", expMonth : "12", expYear : "2022", brandName : "Visa", isDefault : true },
    { id : "1", last4 : "4321", expMonth : "12", expYear : "2022", brandName : "MasterCard", isDefault : false },
  ];
}

class BillingService {

  currentPlanInfo: CurrentPlanInterface | null = null;
  paymentMethods: PaymentMethodInterface[] = [];
  invoices: InvoiceInterface[] = [];

  __setUsedSeats = (count: number) => {
    if (!this.currentPlanInfo) {
      throw new Error("__setUsedSeats: No plan selected");
    }
    this.currentPlanInfo = {
      ...this.currentPlanInfo,
      seats : {
        ...this.currentPlanInfo.seats,
        used : count,
      },
    };
  };

  __getCurrentPlanInfo = (): CurrentPlanInterface | null => {
    return this.currentPlanInfo;
  };

  private makeMockFreeTrialData(): CurrentPlanInterface {
    const plans = makePlans();
    return {
      planInfo : plans.trial,
      price : 0,
      seats : {
        count : 10,
        used : 1,
      },
      organizationCredits : {
        count : 1000,
        used : 0,
      },
      seatCredits : {
        count : 0,
        used : 0,
      },
      singleUseCredits : {
        count : plans.trial.creditsPerSeatSingleUse,
        used : 0,
      },
      billingContactEmail : "",
      expiresAt : DateTime.local().plus({ day : daysToOneTimeDuration[plans.trial.oneTimeBillingDuration!] }).toISO(),
      externalSubscriptionStatus : ExternalSubscriptionStatus.active,
    };
  }

  addPaymentMethod = async (token: string) => {
    //this.paymentMethods = [
    //  ...this.paymentMethods,
    //  {
    //    id : this.paymentMethods.length.toString(),
    //    last4 : "1234",
    //    expMonth : "12",
    //    expYear : "2022",
    //    type : "visa",
    //    brandName : "Visa",
    //    isDefault : this.paymentMethods.length === 0,
    //  },
    //];
    return v2_CreatePaymentMethod(token);
  };

  setDefaultPaymentMethod = async (id: string) => {
    //this.paymentMethods = this.paymentMethods.map(method => ({
    //  ...method,
    //  isDefault : method.id === id,
    //}));
    return v2_SetDefaultPaymentMethod(id);
  };

  deletePaymentMethod = async (id: string) => {
    // todo do not allow to remove default payment method
    //this.paymentMethods = this.paymentMethods.filter(method => method.id !== id);
    return v2_DeletePaymentMethod(id);
  };

  fetchCurrentPlanInfo = async (): Promise<CurrentPlanInterface | null> => {
    // todo migration
    try {
      const subRes = await v2_GetOrganizationActiveSubscription();
      const creditsUsageRes = await v2_GetOrganizationSeatCreditsUsage();

      const currentUserCreditsLeft = creditsUsageRes.data.seat.CandidateUnlockCreditsTotal - creditsUsageRes.data.seat.CandidateUnlockCreditsRedeemed;
      const currentOrganizationCreditsLeft = creditsUsageRes.data.organization.CandidateUnlockCreditsTotal - creditsUsageRes.data.organization.CandidateUnlockCreditsRedeemed + creditsUsageRes.data.organizationExtraCredits.CandidateUnlockCredits;

      const sub = subRes.data.subscription;
      const usage = subRes.data.usage;
      const plan = sub.plan;
      const isTrial = plan.name.toLowerCase().includes("trial");
      return {
        planInfo : isTrial ? this.mapTrialPlanToInterface(plan) : this.mapSourcingPlanToInterface(plan),
        price : plan.pricingPerSeat / 100 * sub.seatCount,
        singleUseCredits : {
          used : 0,
          count : creditsUsageRes.data.organizationExtraCredits.CandidateUnlockCredits || 0,
        },
        organizationCredits : {
          count : creditsUsageRes.data.organization.CandidateUnlockCreditsTotal + (creditsUsageRes.data.organizationExtraCredits.CandidateUnlockCredits || 0),
          used : creditsUsageRes.data.organization.CandidateUnlockCreditsRedeemed,
        },
        seatCredits : {
          count : creditsUsageRes.data.seat.CandidateUnlockCreditsTotal,
          used : creditsUsageRes.data.seat.CandidateUnlockCreditsTotal - Math.min(currentOrganizationCreditsLeft, currentUserCreditsLeft),
        },
        seats : {
          count : isTrial ? Infinity : sub.seatCount,
          used : usage.paidSeats,
        },
        billingContactEmail : "",
        renewsAt : isTrial ? undefined : sub.subscriptionEndDate,
        expiresAt : isTrial ? sub.subscriptionEndDate : undefined,
        externalSubscriptionStatus : sub.externalSubscriptionStatus,
      };
    } catch (err) {
      return null;
    }
    //return this.currentPlanInfo;
  };

  mapTrialPlanToInterface = (trialPlan: any): PlanInfoInterface => {
    return {
      id : trialPlan.id,
      name : PlanName.trial,
      title : "Trial",
      pricePerSeat : 0,
      creditsPerSeat : 0,
      creditsPerSeatSingleUse : trialPlan.seatCandidateUnlockCredits,
      oneTimeBillingDuration : OneTimeBillingDuration.days_14,
      maxPaidSeats : Infinity,
      billingPeriod : BillingPeriod.one_time,
    };
  };

  mapSourcingPlanToInterface = (sourcingPlan: any): PlanInfoInterface => {
    return {
      id : sourcingPlan.id,
      name : PlanName.sourcing,
      title : "Sourcing",
      pricePerSeat : sourcingPlan.pricingPerSeat / 100,
      creditsPerSeat : sourcingPlan.seatCandidateUnlockCredits,
      maxPaidSeats : sourcingPlan.maxPaidSeats,
      creditsPerSeatSingleUse : 0,
      billingPeriod : sourcingPlan.billingPeriod,
    };
  };

  fetchAvailablePlans = async (): Promise<Record<PlanName, PlanInfoInterface>> => {
    const plansRes = await v2_GetAvailablePlans();
    const trialPlan = plansRes.data.find((item: any) => item.name.toLowerCase().includes("trial"));
    const sourcingPlan = plansRes.data.find((item: any) => item.name.toLowerCase().includes("sourcing"));
    return {
      trial : this.mapTrialPlanToInterface(trialPlan),
      sourcing : this.mapSourcingPlanToInterface(sourcingPlan),
    };
    //return makePlans();
  };

  fetchPaymentMethods = async (): Promise<PaymentMethodInterface[]> => {
    //return this.paymentMethods;
    try {
      const res = await v2_GetPaymentMethods();
      let hasDefault = false;
      const mappedData = res.data.map((item: any) => {
        if (item.is_default) {
          hasDefault = true;
        }
        return {
          id : item.id,
          last4 : item.card.last4,
          expMonth : item.card.exp_month,
          expYear : item.card.exp_year,
          type : item.type,
          brandName : item.card.brand,
          isDefault : item.is_default,
        };
      });
      if (!hasDefault && mappedData.length) {
        mappedData[0].isDefault = true;
        await v2_SetDefaultPaymentMethod(mappedData[0].id);
      }
      return mappedData;
    } catch (err) {
      console.error("fetchPaymentMethods", err);
      return [];
    }
  };

  fetchPreviewSubscriptionUpgrade = async (data: UpdatePlanDTO) => {
    const res = await v2_GetPreviewSubscriptionUpgrade({ seats : data.seatsCount });
    return res.data;
  };

  fetchInvoices = async (filter?: InvoicesFetchAllFilter): Promise<InvoiceInterface[]> => {
    //let invoices = this.invoices
    //if (filter?.query) {
    //  invoices = this.invoices.filter(invoice => invoice.description.includes(filter.query) || invoice.reference.includes(filter.query));
    //}
    //return invoices.sort((a, b) => b.createdAt - a.createdAt);
    const res = await v2_GetBillingHistory({ query : filter?.query || "" });

    const stripeStatusToInvoiceStatusMap: Record<string, InvoiceStatus> = {
      succeeded : InvoiceStatus.paid,
      requires_confirmation : InvoiceStatus.processing,
      requires_payment_method : InvoiceStatus.processing,
      requires_action : InvoiceStatus.processing,
      requires_capture : InvoiceStatus.processing,
      processing : InvoiceStatus.processing,
      canceled : InvoiceStatus.cancelled,
    };

    return res.data.map((item: any) => {
      return {
        id : item.id,
        stripeId : item.stripePaymentIntentInvoiceId,
        description : item.description,
        reference : item.stripePaymentIntentInvoiceNumber,
        downloadUrl : "",
        amount : item.stripePaymentIntentAmount / 100,
        createdAt : item.createdAt,
        status : stripeStatusToInvoiceStatusMap[item.stripePaymentIntentStatus] || InvoiceStatus.processing,
      };
    });
  };

  fetchInvoiceUrl = async (invoiceId: string) => {
    const res = await v2_GetInvoiceDownloadURL(invoiceId);
    return res.data;
  };

  startFreeTrial = async (planInfo: any) => {
    // todo migration
    //this.currentPlanInfo = this.makeMockFreeTrialData();
    //await organizationService.inviteMember(({
    //  firstName : user.firstName,
    //  lastName : user.lastName,
    //  email : user.email,
    //  role: OrganizationMemberRole.Owner
    //}))
    //organizationService.__setOwnerState();
    return v2_CreateOrganizationSubscription({
      planId : planInfo.id,
      seatCount : 1,
      period : "one_time",
    });
  };

  createPaymentIntent = async (planId: number, data: PurchasePlanDTO) => {
    const result = await v2_CreateOrganizationSubscription({
      planId,
      seatCount : data.seatsCount,
      period : "yearly",
      keepActive : data.members?.filter(item => item.isActive && !!item.id && !item.isNew).map(item => parseInt(item.id as string)) || [],
    });
    if (data.members) {
      data.members
        .filter(member => member.isNew)
        .forEach(member => {
          organizationService.inviteMember({
            ...member,
            projectIDs: member.projects || [],
            isActive: true,
          });
        });
    }
    return result.data;
    //const plans = makePlans();
    //const prevPlan = this.currentPlanInfo ? JSON.parse(JSON.stringify(this.currentPlanInfo)) as CurrentPlanInterface : null;
    //const planToPurchase = Object.values(plans).find(plan => plan.id === planId);
    //if (!planToPurchase) {
    //  throw new Error("Plan not found");
    //}
    //// todo calculate pro-rated price
    //const seatsCount = data.seatsCount || 1;
    //this.currentPlanInfo = {
    //  ...this.currentPlanInfo,
    //  planInfo : planToPurchase,
    //  seats : {
    //    used  : data.members.length,
    //    count : seatsCount,
    //  },
    //  seatCredits : {
    //    used : 0,
    //    count : seatsCount * planToPurchase.creditsPerSeat,
    //  },
    //  singleUseCredits : {
    //    used : 0,
    //    count : 0,
    //  },
    //  price : seatsCount * planToPurchase.pricePerSeat * (planToPurchase.billingPeriod === BillingPeriod.monthly ? 1 : 12),
    //};
    //if (planToPurchase.billingPeriod === BillingPeriod.one_time) {
    //  this.currentPlanInfo.renewsAt = DateTime.local().plus({ day : 14 }).valueOf();
    //} else if (planToPurchase.billingPeriod === BillingPeriod.monthly) {
    //  this.currentPlanInfo.renewsAt = DateTime.local().plus({ month : 1 }).valueOf();
    //} else if (planToPurchase.billingPeriod === BillingPeriod.yearly) {
    //  this.currentPlanInfo.renewsAt = DateTime.local().plus({ year : 1 }).valueOf();
    //} else {
    //  throw new Error("Unknown billing period");
    //}
    //if (!prevPlan || prevPlan.planInfo.name === PlanName.trial ) {
    //  this.addPaymentMethod('token-here');
    //}
    //if (!prevPlan || prevPlan.planInfo.name === PlanName.trial || prevPlan.seats.count < this.currentPlanInfo.seats.count) {
    //  this.invoices.push({
    //    id : Math.random().toString(16).slice(2),
    //    stripeInvoiceId: '123',
    //    description : planToPurchase.title,
    //    reference : "IN-" + Math.random().toString(16).slice(2).toUpperCase(),
    //    downloadUrl : "https://google.com",
    //    amount : this.currentPlanInfo.price,
    //    createdAt : Date.now(),
    //    status : InvoiceStatus.paid,
    //  });
    //}
    //// do not allow to invite more members than seats available
    //if (data.members) {
    //  data.members.forEach(member => {
    //    organizationService.inviteMember(member);
    //  })
    //}
    //organizationService.__setOwnerState();
  };

  cancelPlan = async () => {
    //if (!this.currentPlanInfo) {
    //  throw new Error("cancelPlan: No plan selected");
    //}
    //this.currentPlanInfo = {
    //  ...this.currentPlanInfo,
    //  expiresAt : this.currentPlanInfo.renewsAt,
    //  renewsAt : undefined,
    //  cancelledAt : DateTime.local().toISODate(),
    //};
    const result = await v2_CancelOrganizationActiveSubscription();
    return result.data;
  };

  renewPlan = async () => {
    // todo
    //if (!this.currentPlanInfo) {
    //  throw new Error("cancelPlan: No plan selected");
    //}
    //this.currentPlanInfo = {
    //  ...this.currentPlanInfo,
    //  expiresAt : this.currentPlanInfo.renewsAt,
    //  renewsAt : undefined,
    //  cancelledAt : DateTime.local().toISODate(),
    //};
    //const result = await v2_CancelOrganizationActiveSubscription();
    //return result.data;
    const result = await v2_RenewOrganizationActiveSubscription();
    return result.data;
  };

  updatePlan = async (data: UpdatePlanDTO) => {
    //if (!this.currentPlanInfo) {
    //  throw new Error("updatePlan: No plan selected");
    //}
    //const isMoreSeats = data.seatsCount > this.currentPlanInfo.seats.count;
    //const prevSeatsCount = this.currentPlanInfo.seats.count;
    //if (prevSeatsCount > data.seatsCount) {
    //  // todo handle downgrade
    //  return;
    //}
    //this.currentPlanInfo = {
    //  ...this.currentPlanInfo,
    //  seats : {
    //    ...this.currentPlanInfo.seats,
    //    count : data.seatsCount,
    //  },
    //  price : data.seatsCount * this.currentPlanInfo.planInfo.pricePerSeat * (this.currentPlanInfo.planInfo.billingPeriod === BillingPeriod.monthly ? 1 : 12),
    //};
    //if (isMoreSeats) {
    //  const purchasedSeatsCount = data.seatsCount - prevSeatsCount;
    //  this.invoices.push({
    //    id : Math.random().toString(16).slice(2),
    //    stripeId : "123123",
    //    description : `Purchased ${purchasedSeatsCount} seat${purchasedSeatsCount > 1 ? "s" : ""}`,
    //    reference : "IN-" + Math.random().toString(16).slice(2).toUpperCase(),
    //    downloadUrl : "https://google.com",
    //    amount : this.currentPlanInfo.price,
    //    createdAt : Date.now(),
    //    status : InvoiceStatus.paid,
    //  });
    //}
    return v2_UpdateOrganizationSubscription({ seatCount : data.seatsCount });
  };

  saveBillingContactEmail = async (email: string) => {

  };

  purchaseCredits = async (count: number) => {
    //if (!this.currentPlanInfo) {
    //  throw new Error("purchaseCredits: No plan selected");
    //}
    //this.currentPlanInfo = {
    //  ...this.currentPlanInfo,
    //  singleUseCredits : {
    //    ...this.currentPlanInfo.singleUseCredits,
    //    count : this.currentPlanInfo.singleUseCredits.count + count,
    //  },
    //};
    //this.invoices.push({
    //  id : Math.random().toString(16).slice(2),
    //  stripeId : "123123",
    //  description : `Purchased ${count} credit${count ? "s" : ""}`,
    //  reference : "IN-" + Math.random().toString(16).slice(2).toUpperCase(),
    //  downloadUrl : "https://google.com",
    //  amount : count * 0.1,
    //  createdAt : Date.now(),
    //  status : InvoiceStatus.paid,
    //});
    return v2_AddOrganizationExtraCredits(count / 1000);
  };

  getInvoiceStyle = (invoice: InvoiceInterface) => {
    if (invoice.status === InvoiceStatus.new) {
      return { color : "#408cf6", backgroundColor : "#dfecff" };
    }
    if (invoice.status === InvoiceStatus.paid) {
      return { color : "#56be24", backgroundColor : "#eafbe0" };
    }
    if (invoice.status === InvoiceStatus.refunded) {
      return { color : "#8259d9", backgroundColor : "#f3edff" };
    }
    if (invoice.status === InvoiceStatus.processing) {
      return { color : "#ffc700", backgroundColor : "rgba(255,199,0,0.2)" };
    }
  };
}

export const billingService = new BillingService();