import React from "react";
import { cx } from "emotion";

import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";

import classes from "./Counter.module.css";
import cn from "classnames";

const Counter = ({ value = 1, readOnly = false, onChange, label, max, min, step = 1, className, inputClassName = '' }) => {
  const handleDecrease = () => {
    const newVal = value - step;

    if (min && newVal < min) {
      return onChange(min);
    }

    return onChange(newVal);
  };

  const handleIncrease = () => {
    const newVal = value + step;

    if (max && newVal > max) {
      return onChange(max);
    }

    return onChange(newVal);
  };

  const handleInputChange = ({ target: { value } }) => {
    const parsedValue = +value;

    if (Number.isNaN(parsedValue)) {
      return;
    }

    if (parsedValue > max) {
      return onChange(max);
    }

    if (parsedValue < min) {
      return onChange(min);
    }

    onChange(parsedValue);
  };

  return (
    <div className={cx(classes.counter, className)}>
      <button onClick={handleDecrease} className={classes.button} disabled={value === 1}>
        <Icon name="fa-minus" className={classes.icon} />
      </button>
      <input
        type="number"
        id={label}
        value={value}
        readOnly={readOnly}
        onChange={handleInputChange}
        step={step}
        className={cn(classes.field, inputClassName)}
      />
      <button onClick={handleIncrease} className={classes.button}>
        <Icon name="fa-plus" className={classes.icon} />
      </button>
      {label && (
        <label htmlFor={label} className={classes.label}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Counter;
