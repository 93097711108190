import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cx } from "emotion";
import PropTypes from "prop-types";

import { useApp } from "../../contexts/appProvider";

import SectionTitle from "../SectionTitle/SectionTitle";
import SiderMenu from "./SiderMenu/SiderMenu";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";

import styles from "./Layout.module.css";

const Layout = ({ title, menuItems, children }) => {
  const { isMobile } = useApp();
  const { section } = useParams();

  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  const onToggle = () => {
    if (isMobile) {
      setIsOpen((prevValue) => !prevValue);
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <SectionTitle>{title}</SectionTitle>
      </div>
      <div className={styles.wrapper}>
        <div className={cx(styles.siderWrapper, { [styles.opened]: isOpen })}>
          {isMobile && (
            <Icon name={isOpen ? "menu-fold" : "menu-unfold"} className={styles.menuButton} onClick={onToggle} />
          )}
          <div className={styles.sider}>
            <div className={styles.siderChildren}>
              <SiderMenu activeItem={section} menuItems={menuItems} onItemClick={onToggle} />
            </div>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      lable: PropTypes.string,
    })
  ),
  children: PropTypes.element,
};

export default Layout;
