import _, { flow, toUpper, first, identity } from "lodash";
import { compose, map, split, cond, stubTrue } from "lodash/fp";

const createFullName = (names = [], capitalize = false) => ({
  fullName: _(names).filter(identity).join(" "),
  capitalize,
});

const extractInitials = ({ fullName, capitalizeInitials }) => {
  const formatter = cond([
    [capitalizeInitials, toUpper],
    [stubTrue, identity],
  ]);
  const result = flow([split(" "), map(compose(formatter, first))]);
  return result(fullName);
};

const generateInitials = flow([createFullName, extractInitials]);

export default generateInitials;