import React from "react";
import { useState } from "react";
import { Input, Label } from "reactstrap";
import { css, cx } from "emotion";

import { md } from "../../css/mediaQueries";
import { Select } from "../DiscoverNew/Form/Select/Select";

const ProjectEdit = ({ onSave, onCancel, project, uiSettings }) => {
  const [name, setName] = useState(project?.name || "");
  const [description, setDescription] = useState(project?.description || "");
  const [tags, setTags] = useState(project?.tags ? project.tags.map((item) => ({ value: item, label: item })) : []);
  const [isNameError, setIsNameError] = useState(false);

  const handleSaveButtonPressed = () => {
    const isValid = validateFields();
    if (!isValid) return;

    const trimmedName = name.trim();

    onSave({ name: trimmedName, description, tags });

    setName("");
    setDescription("");
    setTags([]);
  };

  const validateFields = () => {
    let valid = true;
    if (name === "") {
      setIsNameError(true);
      valid = false;
    }

    return valid;
  };

  const refreshErrorMessage = (field) => {
    switch (field) {
      case "name":
        setIsNameError(false);
        break;
      default:
        console.log("field not supported");
    }
  };

  return (
    <div className={cx("col-12")}>
      <div
        className={cx(
          "row",
          css`
            margin-top: 30px;
          `
        )}
      >
        <Label
          for="project-name-input"
          className={cx(
            "standard-theme-text",
            css`
              margin-bottom: 15px;
            `
          )}
        >
          {`${uiSettings?.mappings?.project} Name`}
        </Label>
        <div className={isNameError ? styles.inputContainerError : styles.inputContainer}>
          <Input
            id="project-name-input"
            bsSize="small"
            type="text"
            placeholder=""
            value={name}
            className={css`
              font-size: 16px;
            `}
            onChange={(e) => {
              refreshErrorMessage("name");
              setName(e.target.value);
            }}
          />
        </div>

        {isNameError && (
          <span
            className={css`
              width: 100%;
              margin: 5px 0 20px 0px;
              color: red;
              font-size: 11px;
              text-align: left;
            `}
          >
            Please enter the <span style={{ textTransform: "lowercase" }}>{uiSettings?.mappings?.project}</span> name.
          </span>
        )}

        <div className={styles.inputContainer}>
          <textarea
            id="project-description-input"
            placeholder=""
            value={description}
            className={css`
              font-size: 16px;
              width: 100%;
              padding: 0.375rem 0.75rem;
              font-weight: 400;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            `}
            onChange={(e) => {
              refreshErrorMessage("description");
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className={styles.inputContainer}>
          <Select
            placeholder="Enter tags here"
            noOptionsMessage={() => "Start typing to add a tag"}
            formatCreateLabel={(value) => `Add "${value}"`}
            onChange={setTags}
            value={tags}
            defaultBorderStyle="1px solid #9b9b9b"
            creatable
            isMulti
          />
        </div>

        <div
          className={css`
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            width: 100%;
            margin-bottom: 20px;
            ${md} {
              width: 100%;
              flex-flow: wrap-reverse;
            }
          `}
        >
          <div
            className={cx(
              css`
                width: 55%;
                ${md} {
                  width: 100%;
                  margin-top: 15px;
                  text-align: center;
                }
              `
            )}
          >
            <button
              onClick={() => onCancel()}
              className={cx(
                "btn btn-link",
                "standard-theme-text",
                css`
                  font-size: 18px;
                  letter-spacing: -0.69px;
                  color: #9b9b9b !important;
                  text-decoration: none !important;
                  height: 100%;
                  &:hover {
                    color: #787878 !important;
                  }
                `
              )}
            >
              Cancel
            </button>
          </div>
          <div
            className={cx(
              css`
                ${md} {
                  width: 100%;
                }
              `
            )}
          >
            <button
              onClick={() => handleSaveButtonPressed()}
              className={cx(
                "btn btn-outline-primary",
                css`
                  margin-top: 5px;
                  width: 93px;
                  height: 47px;
                  border-radius: 50px !important;
                  ${md} {
                    width: 100%;
                  }
                `
              )}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  inputContainer: css`
    width: 100%;
    margin-bottom: 30px;
  `,
  inputContainerError: css`
    width: 100%;
    margin-bottom: 0px;
  `,
};

export default ProjectEdit;
