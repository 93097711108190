import React from "react";
import { css, cx } from "emotion/macro";

export const SearchResultsStats = ({ count = 0, className, timeTaken = "", shouldHide = false }) => {
  if (shouldHide) {
    // Return an empty span to preserve spacing and prevent small UI movements when inserting the result count.
    return <span className={cx(styles.searchResultsStatsText, className)}>&nbsp;</span>;
  }

  const countQualifier = count >= 10000 ? "Over " : "";
  const resultString = count !== 1 ? "results" : "result";
  return (
    <span className={cx(styles.searchResultsStatsText, className)}>
      {countQualifier}
      {count} {resultString} {timeTaken ? `(${timeTaken} ms)` : ""}
    </span>
  );
};

const styles = {
  searchResultsStatsText: css`
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  `,
};

export default SearchResultsStats;
