import * as React from 'react';
import { useAuthController } from "../../../contexts/AuthProvider";
import { stageFlowService } from "../../../screens/profile/ProfileStageFlow/StageFlowService";
import { logger } from "../../../services/Logger";
import { CreateProjectFormInterviewProcessHasProcesses } from "./CreateProjectFormInterviewProcessHasProcesses";
import { CreateProjectFormInterviewProcessNoProcesses } from "./CreateProjectFormInterviewProcessNoProcesses";

type Props = {
  onSubmit: (interviewProcessId: string) => void
}

const useInterviewProcessOptions = (organizationId: number): [{ label: string, value: string }[], boolean] => {
  const [loading, setLoading] = React.useState(false);
  const [interviewProcessOptions, setInterviewProcessOptions] = React.useState<{ label: string, value: string }[]>([]);

  React.useEffect(() => {
    async function init() {
      setLoading(true);
      try {
        const interviewProcesses = await stageFlowService.fetchAll(organizationId, {
          archived: 'all',
          query: '',
          limit: 100,
        });
        const options = interviewProcesses.map(item => ({
          label: item.name,
          value: item.stageFlowId + '',
        }));
        setInterviewProcessOptions(options);
      } catch (err) {
        logger.error('Could not fetch interview processes', err);
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [organizationId]);

  return [interviewProcessOptions, loading];

}

export function CreateProjectFormInterviewProcess(props: Props) {
  const authController = useAuthController();
  const user = authController.user as any;

  const [interviewProcessOptions, loading] = useInterviewProcessOptions(user.organizationId);

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div style={{ paddingTop : 30 }}>
      {interviewProcessOptions?.length === 0 ? (
        <CreateProjectFormInterviewProcessNoProcesses onCreate={props.onSubmit} />
      ) : (
        <CreateProjectFormInterviewProcessHasProcesses onCreate={props.onSubmit} interviewProcessOptions={interviewProcessOptions} />
      )}
    </div>
  )
}