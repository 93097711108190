import * as React from "react";
import { CreateInterviewProcessForm } from "./CreateInterviewProcessForm/CreateInterviewProcessForm";

type Props = {
  onCreate: (interviewProcessId: string) => void
}

export function CreateProjectFormInterviewProcessNoProcesses(props: Props) {
  return (
    <div>
      <p>
        You currently don't have saved hiring process. Start by using suggested stages or create your own process. Processes are saved to My Account &gt; Interview Processes.
      </p>
      <CreateInterviewProcessForm onSubmit={v => props.onCreate(v.toString())} />
    </div>
  )
}