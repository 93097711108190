import { cx } from "emotion";
import React from "react";
import { PaymentMethodInterface } from "../../../../../../services/BillingService";

import { Icon } from "../../../../../../components/DiscoverNew/UI/IconComponent/Icon";

import styles from "./PaymentCard.module.css";

type Props = {
  className?: string;
  cardInfo: PaymentMethodInterface
  onCardRemove: () => void
  onCardClick: () => void
  isRemovable?: boolean
}

const PaymentCard = ({
  className,
  onCardRemove,
  onCardClick,
  isRemovable,
  cardInfo: { brandName, last4, expYear, expMonth, isDefault },
}: Props) => {

  const onRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCardRemove();
  };

  return (
    <div className={cx(styles.card, className, { [styles.cardActive]: isDefault })}>
      <div className={cx(styles.cardCol, styles.cardType)}>
        {/* todo there is much more than Visa / MasterCard */}
        {/*{brandName.includes("visa") ? <Icon name="color-visa" /> : <Icon name="color-mastercard" />}*/}
      </div>
      <div className={cx(styles.cardCol, styles.cardInfo)}>
        <span className={styles.cardInfoLastNumber}>{`${brandName} ending in ${last4}`}</span>
        <span className={styles.cardInfoExpired}>{`Expires ${expMonth}/${expYear}`}</span>
        {!isDefault && isRemovable && <Icon className={styles.trashIcon} name="fa-trash" onClick={onRemove} />}
      </div>
      <div className={cx(styles.cardCol, styles.cardRadioButtonWrapper)}>
        <span className={styles.cardRadioButton} onClick={onCardClick}>
          <Icon className={cx(styles.circleIcon, { [styles.circleIconActive]: isDefault })} name="fa-circle-check-filled" />
          <Icon className={cx(styles.circleIcon, { [styles.circleIconActive]: !isDefault })} name="fa-circle" />
        </span>
      </div>
    </div>
  );
};

export default PaymentCard;
