const regex = new RegExp(/\[(.*?)\]/);

// Takes in a string and converts to the correct
// saved search field model.
export const toSavedSearchFieldsModel = (url) => {
  if (!url) {
    return;
  }
  const sortedString = sortQueryString(url);
  const fieldsObj = {};

  const queryParams = new URLSearchParams(sortedString);

  for (let qp of queryParams.entries()) {
    if (qp?.length < 2) {
      continue;
    }

    const key = qp[0];
    const val = qp[1];

    const matches = key.match(regex);

    // We have a key string without brackets
    if (!matches) {
      fieldsObj[key] = val;
      continue;
    }

    // We have a key in either the key[string] or key[integer]
    // format and need more complex processing
    let topLevelKey;
    const keyParts = key.split("[");

    if (keyParts.length > 0) {
      topLevelKey = keyParts[0];
      if (matches?.length > 1) {
        const nestedKey = matches[1];
        // determine if value is string or int
        const isKeyInt = /\d/.test(nestedKey);
        // We have an object and can simply assign the value
        if (!isKeyInt) {
          if (!fieldsObj[topLevelKey]) {
            fieldsObj[topLevelKey] = {};
          }
          // if the value is an integer convert before saving
          const isValInt = /\d/.test(val);
          fieldsObj[topLevelKey][nestedKey] = !isValInt ? val : parseInt(val);
        } else {
          if (!fieldsObj[topLevelKey]) {
            fieldsObj[topLevelKey] = [];
          }
          fieldsObj[topLevelKey].push(val);
        }
      }
    }
  }

  // If we have specified a location, add default unit to mi
  if (fieldsObj?.location) {
    fieldsObj.location.unit = "mi";
  }

  return fieldsObj;
};

// Takes in the saved search fields object and converts to a string
// that will be used for query parameters
export const fromSavedSearchFieldsModel = (fieldsObj) => {
  let urlString = "?";

  for (let key in fieldsObj) {
    const val = fieldsObj[key];

    if (typeof val === "object" && !Array.isArray(val) && val !== null) {
      let subStr = "";

      for (let nestKey in val) {
        let targetVal = val[nestKey].toString();

        // If we have a zipcode with less than 5 digits, pad with 0's
        if (nestKey === "zipCode" && targetVal.length < 5) {
          targetVal = targetVal.padStart(5, "0");
        }

        subStr += `${key}[${nestKey}]=${targetVal}&`;
      }

      urlString += subStr;
      continue;
    } else if (Array.isArray(val)) {
      let subStr = "";

      for (let i = 0; i < val.length; i++) {
        subStr += `${key}[${i}]=${val[i]}&`;
      }

      urlString += subStr;
      continue;
    }

    urlString += `${key}=${val}&`;
  }

  // remove the last character in the string: `&`
  const replacedStr = urlString.replace(/.$/, "");
  const sortedString = sortQueryString(replacedStr);

  return sortedString;
};

// Takes in a search string and sorts it by it's keys
// with intermediate URLSearchParams conversion. The return
// string will be sorted and decoded for Searchkit use.
export const sortQueryString = (searchString) => {
  if (!searchString) {
    return;
  }

  const queryParams = new URLSearchParams(searchString);
  queryParams.sort();
  const sortedQueryParamString = `?${queryParams.toString()}`;

  const decodedURI = decodeURI(sortedQueryParamString);
  return decodedURI;
};
