import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { cx } from "emotion";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { shortId } from "../../util/util";

import css from "./Dropdown.module.css";

const DropdownContext = React.createContext({});

export function Dropdown(props) {
  const [opened, setOpened] = useState(false);

  const menuId = useRef(shortId());
  const animationFrameId = useRef(null);

  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  function open(e) {
    if (props.shouldStopPropagation) {
      e.stopPropagation();
    }
    if (!opened) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("scroll", handleOutsideClick, true);
      setOpened(true);

      if (props.onOpen) {
        props.onOpen();
      }
    }
  }

  const handleOutsideClick = useCallback(
    (e) => {
      if (typeof e.target.closest !== 'function') {
        return;
      }

      const dropdown = e.target.closest(`#${menuId.current}`);
      if (!dropdown) {
        close();
      }
    },
    //eslint-disable-next-line
    []
  );

  const close = useCallback(() => {
    setOpened(false);
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("scroll", handleOutsideClick, true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useLayoutEffect(() => {
    const dropdownStyle = {
      top: 0,
      left: 0,
    };

    if (opened && contentRef.current) {
      cancelAnimationFrame(animationFrameId.current);

      function followPosition() {
        animationFrameId.current = requestAnimationFrame(() => {
          if (!contentRef.current) {
            cancelAnimationFrame(animationFrameId.current);
            return;
          }
          const buttonRect = buttonRef.current.getBoundingClientRect();
          const dropdownContentRect = contentRef.current.getBoundingClientRect();

          const isOverflowRight = dropdownContentRect.width + buttonRect.left > window.innerWidth;
          const isOverflowBottom = dropdownContentRect.height + buttonRect.top + buttonRect.height > window.innerHeight;

          if (isOverflowRight) {
            dropdownStyle.left = buttonRect.left - dropdownContentRect.width + buttonRect.width;
          } else {
            dropdownStyle.left = buttonRect.left;
          }

          const verticalOffset = 0;
          if (isOverflowBottom) {
            dropdownStyle.top = Math.round(buttonRect.top - dropdownContentRect.height + verticalOffset);
          } else {
            dropdownStyle.top = Math.round(buttonRect.top + buttonRect.height + verticalOffset);
          }

          contentRef.current.style.top = dropdownStyle.top + "px";
          contentRef.current.style.left = dropdownStyle.left + "px";
          followPosition();
        });
      }

      followPosition();
    }

    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  });

  return (
    <DropdownContext.Provider value={{ close, opened }}>
      <div ref={buttonRef} style={props.style} className={cx(css.button, props.classNames?.button)} onClick={open}>
        {props.label}
      </div>
      {opened &&
        ReactDOM.createPortal(
          <div id={menuId.current} style={props.contentStyle} onClick={e => props.shouldStopPropagation ? e.stopPropagation() : null} ref={contentRef} className={cx(css.content, props.classNames?.content)}>
            {props.children}
          </div>,
          props.portal || document.body
        )}
    </DropdownContext.Provider>
  );
}

Dropdown.Context = DropdownContext;
Dropdown.Divider = (props) => <div className={cx(css.divider, props.className)} style={props.style} />;
Dropdown.Item = (props) => (
  <DropdownContext.Consumer>
    {(context) => {
      const { onClick, className, style, component, ...rest } = props;
      const Component = component || 'div';
      return (
        <Component onClick={() => onClick ? onClick(context.close) : null} className={cx(css.item, className)} style={style} {...rest}>
          {props.children}
        </Component>
      )
    }}
  </DropdownContext.Consumer>
);

Dropdown.propTypes = {
  style: PropTypes.any,
  shouldStopPropagation: PropTypes.bool,
  contentStyle: PropTypes.any,
  classNames: PropTypes.shape({
    content: PropTypes.string,
    button: PropTypes.string,
  }),
  children: PropTypes.any,
  portal: PropTypes.element,
  label: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
};
