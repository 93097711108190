import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { initializeStripe } from "../../../services/BillingService";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import CustomModal from "../CustomModal/CustomModal";
import { StripePaymentForm } from "../PurchasePlanModal/StripePaymentForm/StripePaymentForm";

import styles from "./NewPaymentMethodModal.module.css";

type Props = {
  onClose: () => void
  onSubmit: (token: string) => void
}

const stripePromise = initializeStripe();

const NewPaymentMethodModal = ({ onSubmit, onClose }: Props) => {
  return (
    // @ts-ignore
    <CustomModal
      className={styles.modal}
      title="New Payment Method"
      onCancel={onClose}
      show={true}
      footer={null}
    >
      <Elements stripe={stripePromise} options={{ paymentMethodCreation: 'manual', mode: 'setup', currency: 'usd' }}>
        <StripePaymentForm onCreated={onSubmit} type="create-method" controls={(isLoading) => (
          <div className={styles.footer}>
            <Button className={styles.button} type='submit' disabled={isLoading}>
              {isLoading ? 'Processing...' : 'Add payment method'}
            </Button>
          </div>
        )} />
      </Elements>
    </CustomModal>
  );
};

export default NewPaymentMethodModal;
