import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../components/button/CloseButton";
import ModalButtonContainer from "../../../components/button/ModalButtonContainer";
import { Input } from "../../../components/DiscoverNew/Form/Input/Input";
import { modalStyles } from "../../../components/modals/modalStyles";
import { OrganizationInterface } from "../../../services/OrganizationService";

type Props = {
  data: OrganizationInterface
  // todo
  onSubmit: (data: any) => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalOrganizationInfoEdit: React.FC<Props> = ({ isOpen, onClose, data, onSubmit }) => {
  const [name, setName] = useState(data.name || '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // todo
    const data: any = {
      name,
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>Edit Organization Information</div>
        <div className={modalStyles.modalFormContainer}>
          <Input value={name} onChange={(e: any) => setName(e.target.value)} placeholder="e.g. Example Company" />
        </div>
        <ModalButtonContainer
          title="Update"
          onClick={handleSubmit}
          isWaiting={loading}
        />
      </div>
    </Modal>
  );
};