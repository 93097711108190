import React from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import CloseButton from "../../button/CloseButton";
import { modalStyles } from "../modalStyles";
import { useUserData } from "../../../contexts/userContext";
import { OutOfCreditsAlert } from "../../ui/Alert/OutOfCreditsAlert";
import ModalButtonContainer from "../../button/ModalButtonContainer";

import cssModule from './InsufficientCreditsModal.module.css';
import { useAuthController } from "../../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";

function InsufficientCreditsModal({ onClose }) {
  const authController = useAuthController();
  const canPurchase = permissionService.can(authController.user, PermissionEnum.editOrganizationBilling);
  const { state: { userCredits } } = useUserData();

  return (
    <Modal
      show={true}
      onHide={onClose}
      backdropClassName={cssModule.modalBackdrop}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>
          Insufficient Credits
        </div>
        <div className={modalStyles.modalFormContainer}>
          <OutOfCreditsAlert
            userCredits={userCredits}
            onExtraCreditsPurchased={onClose}
            renderContent={canPurchase ? (open) => <ModalButtonContainer title={`Purchase more credits`} onClick={open} /> : undefined}
          />
        </div>
      </div>
    </Modal>
  );
}

export default InsufficientCreditsModal;
