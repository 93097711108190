import { cx } from "emotion";
import * as React from "react";
import capitalizeWords from "../../../../formatters/capitalizeWords";
import {
  CandidateNote,
  candidateService,
  ProjectCandidateInterface,
} from "../../../../screens/projects/CandidateService";
import { EntityConnection } from "../../../../types";
import { ButtonHTML } from "../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { PaginationFull } from "../../../DiscoverNew/UI/PaginationFull/PaginationFull";
import { Formatter } from "../../../DiscoverNew/util/Formatter";
import { CandidateContacts } from "../CandidatePreview/CandidateContacts/CandidateContacts";
import { CandidateSocials } from "../CandidatePreview/CandidateSocials/CandidateSocials";
import { CandidateVerificationIcon } from "../CandidateVerificationIcon/CandidateVerificationIcon";
import { UserImage } from "../../../ui/ProfileImage/UserImage";

import css from "./CandidateTable.module.css";

type Props = {
  candidates: EntityConnection<ProjectCandidateInterface>
  onOpenProfile: (candidate: ProjectCandidateInterface) => void;
  pagination: {
    pageCount: number
    pageSize: number
    onChangePageSize: (pageSize: string) => void
    currentPage: number
    onChangeCurrentPage: (page: string) => void
  }
}

export const CandidateTableAll: React.FC<Props> = (props) => {
  const { candidates, onOpenProfile } = props;
  const { pageCount, pageSize, currentPage, onChangePageSize, onChangeCurrentPage } = props.pagination;
  const [expandedCandidateId, setExpandedCandidateId] = React.useState<number | undefined>(undefined);
  const [expandedCandidateNotes, setExpandedCandidateNotes] = React.useState<Array<CandidateNote>>([]);

  // todo move it to top level and update candidate in fetched data
  const onExpandRow = (candidate: ProjectCandidateInterface) => {
    setExpandedCandidateId(candidate.candidateId);
    candidateService.fetchNotes(candidate.candidateId).then(notes => {
      setExpandedCandidateNotes(notes);
    })
  }

  const onCollapseRow = () => {
    setExpandedCandidateNotes([]);
    setExpandedCandidateId(undefined);
  }


  React.useEffect(() => {
    setExpandedCandidateId(undefined);
  }, [currentPage, pageSize])

  return (
    <div className={css.holder}>
      <table className={css.table}>
        <thead>
          <tr>
            <th className={css.tdName} style={{ width: 300 }}>Name</th>
            <th className={css.tdLocation}>Location</th>
            <th className={css.tdHeadline} style={{ width: '' }}>Headline</th>
            <th className={css.tdActions} />
          </tr>
        </thead>
        <tbody>
          {candidates.nodes.map((candidate, index) => {
            const address = candidateService.getAddressFromLocation(candidate.location);
            return (
              <React.Fragment key={candidate.candidateId}>
                <tr className={css.tr}>
                  <td className={css.tdName} style={{ width: 300 }}>
                    <span className={css.name} onClick={() => onOpenProfile(candidate)}>
                      <UserImage data={candidate} style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 8 }} />
                      {Formatter.fullName(candidate)}
                    </span>
                    <CandidateVerificationIcon style={{ position: 'relative', top: 2, marginLeft: 4 }} candidate={candidate} />
                    {!!candidate.integrations?.workable && <Icon style={{ position: 'relative', top: 2, marginLeft: 6 }} name='workable' />}
                    <ButtonHTML
                      className={css.actionsItem}
                      onClick={() => expandedCandidateId === candidate.candidateId ? onCollapseRow() : onExpandRow(candidate)}
                    >
                      <span style={{
                        position: 'relative', top: -2, marginLeft: 16,
                        ...(candidate.candidateId === expandedCandidateId ? {transform : "rotate(180deg)", display : "inline-block"} : {}) }}
                      >
                        <Icon name="chevron-thin" />
                      </span>
                    </ButtonHTML>
                  </td>
                  {/* todo [candidates] [feature] */}
                  {/*<td>-</td>*/}
                  <td className={css.tdLocation}>{Formatter.address(address)}</td>
                  <td className={css.tdHeadline}>{candidateService.getHeadlineText(candidate)}</td>
                  <td className={css.tdActions}>
                    <div className={css.actionsList}>

                    </div>
                  </td>
                </tr>
                {candidate.candidateId === expandedCandidateId && (
                  <React.Fragment>
                    <tr className={css.detailsTr}>
                      <td className={css.detailsTdContent}>
                        <div className={css.detailsSectionTitle}>
                          Current position
                        </div>
                          {candidate.latestExperience && (
                            <p className={css.detailsSectionText}>
                              {candidate.latestExperience.title ? (
                                <>
                                  <span>{capitalizeWords(candidate.latestExperience.title)}</span>
                                  {!!candidate.latestExperience.company?.name && <> | {capitalizeWords(candidate.latestExperience.company.name)}</>}
                                </>
                              ) : (
                                !!candidate.latestExperience.company?.name && <span>{capitalizeWords(candidate.latestExperience.company.name)}</span>
                              )}
                            </p>
                          )}
                      </td>
                      <td>
                        <div className={css.detailsSectionTitle}>
                          Contacts
                        </div>
                        <div>
                          <CandidateContacts style={{ paddingLeft: 2 }} data={candidate} />
                        </div>
                      </td>
                      <td>
                        <div className={css.detailsSectionTitle}>
                          Social Media
                        </div>
                        <div>
                          <CandidateSocials data={candidate} />
                        </div>
                      </td>
                      <td />
                    </tr>
                    <tr className={css.detailsTr}>
                      <td colSpan={4} className={css.detailsTdContent}>
                        <div className={css.detailsSectionTitle}>
                          Notes
                        </div>
                        <p className={css.detailsSectionText}>
                          {expandedCandidateNotes?.[0]?.note || '–'}
                        </p>
                      </td>
                    </tr>
                    <tr className={cx(css.detailsTr, css.last)}>
                      <td colSpan={4}  className={css.detailsTdContent}>
                        {/* todo [candidate] [feature] */}
                        <div className={css.detailsLinks}>
                          {/*<Link to={`/candidate/${candidate.candidateId}`}>Go to profile</Link>*/}
                          <a href='#' onClick={() => onOpenProfile(candidate)}>Open profile</a>
                          {/*<div className={css.detailsLinksDivider}>|</div>*/}
                          {/*<a href='#'>Send message</a>*/}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <PaginationFull
        className={css.pagination}
        pageCount={pageCount}
        pageSize={pageSize}
        onChangePageSize={onChangePageSize}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    </div>
  );
};