import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { css, cx } from "emotion/macro";

import { xs } from "../../css/mediaQueries";
import closeIcon from "./close.svg";

function DeleteConfirmationModal(props) {
  return (
    <Modal
      id="delete-confirmation-modal"
      isOpen={props.isOpen}
      toggle={props.onCancel}
      centered={true}
      className={styles.modal}
    >
      <div className={styles.closeContainer} onClick={props?.onCancel}>
        <img className={styles.closeIconImage} src={closeIcon} alt="" />
      </div>
      <ModalBody className={styles.modalBody}>
        <div className={styles.header}>{props?.header ? props.header : "Confirm Delete"}</div>
        <div className={styles.messageContainer}>
          <div className={styles.message}>
            {props.message || "Are you sure you want to delete"}{" "}
            {props?.objectTitle ? <span className={styles.objectTitle}>{`${props.objectTitle}`}</span> : "this item"}
            {props?.objectSubtitle ? <span>&nbsp;{props.objectSubtitle}</span> : ""}
            {"?"}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.actionButton} onClick={props?.onCancel} outline color="secondary">
            Cancel
          </Button>{" "}
          <Button className={styles.actionButton} onClick={props?.onConfirm} outline color="danger">
            {props.confirmButtonTitle || "Delete"}
          </Button>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
}

const styles = {
  modal: css`
    min-height: initial;
  `,
  closeContainer: css`
    position: absolute;
    width: 30px;
    height: 30px;
    top: 15px;
    cursor: pointer;
    left: 20px;
    text-align: center;
    z-index: 10;
  `,
  closeIconImage: css`
    width: 13px;
    height: 15px;
  `,
  modalBody: css`
    display: flex;
    flex-direction: column;
    padding: 0 40px 50px 40px !important;
  `,
  header: css`
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: Montserrat;
    text-align: center;
    font-size: 20px;
    letter-spacing: -1.02px;
    text-align: center;
    color: #373737;
    ${xs} {
      margin-top: 65px;
      margin-bottom: 50px;
      padding-bottom: 0px;
    }
  `,
  messageContainer: cx(
    "col-12",
    "nopadding",
    css`
      margin-top: 30px;
      margin-bottom: 30px;
      ${xs} {
        flex: initial !important;
        margin: 25px 0 0 0;
      }
    `
  ),
  message: css`
    text-align: center;
    width: 100%;
    word-break: break-word;
  `,
  objectTitle: css`
    font-weight: bold;
  `,
  buttonContainer: cx(
    "nopadding",
    css`
      align-items: flex-end;
      display: flex;
      flex-flow: row;
      width: 100%;
      justify-content: space-evenly;
      margin: 47px auto 0 auto;
      flex: 1;
      ${xs} {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin-top: 50px;
      }
    `
  ),
  actionButton: css`
    width: 175px;
    height: 39px;
    font-size: 14px !important;
    border-radius: 25px !important;
    ${xs} {
      width: 100%;
      margin-top: 50px;
    }
  `,
};

export default DeleteConfirmationModal;
