type UISettingsStageFlowConfigStage = {
  hiddenAt: string | undefined
}

type UISettingsStageFlowConfig = {
  stages: Record<string, UISettingsStageFlowConfigStage>
  stageFlowId: number
  organizationId: number
}

type UISettings = {
  stageFlow: Record<string, UISettingsStageFlowConfig>
}

export class UISettingsService {
  static getUISettings(): UISettings {
    const uiSettings = localStorage.getItem("uiSettings") || "{}";
    return JSON.parse(uiSettings);
  }

  static setUISettings(uiSettings: UISettings) {
    localStorage.setItem("uiSettings", JSON.stringify(uiSettings));
  }

  static updateUISettings(uiSettings: Partial<UISettings>) {
    const existingUISettings = this.getUISettings();
    const updatedUISettings = {
      ...existingUISettings,
      ...uiSettings,
    };
    this.setUISettings(updatedUISettings);
  }
}