import React from "react";
import ReactPhoneInput from "react-phone-input-2";

import 'react-phone-input-2/lib/style.css'
import css from './InputPhone.module.css'

export function InputPhone(props: any) {
  return (
    <ReactPhoneInput
      containerClass={css.container}
      onlyCountries={["us", "ca"]}
      country={"us"}
      {...props}
    />
  );
}