import * as React from "react";
import { css as emotionCss } from "emotion";
import { useEffect } from "react";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm, WithConfirmProps } from "../../../components/hoc/withConfirm";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { integrationService } from "../../../services/IntegrationService";
import { EnableWorkableIntegrationDTO, workableIntegrationService } from "../../../services/WorkableIntegrationService";
import billingCSS from "../ProfileBilling/ProfileBilling.module.css";
import { ModalWorkableIntegrationSettings } from "./ModalWorkableIntegrationSettings/ModalWorkableIntegrationSettings";
import css from "./ProfileIntegrations.module.css";
import { ProfileIntegrationsCard } from "./ProfileIntegrationsCard/ProfileIntegrationsCard";

type Props = WithConfirmProps & {}

const ProfileIntegrationsCore = (props: Props) => {
  const notifications = useNotifications();
  const [integrations, setIntegrations] = React.useState<Record<string, boolean>>({});
  const [isOpenWorkableIntegrationSettings, setIsOpenWorkableIntegrationSettings] = React.useState(false);

  useEffect(() => {
    integrationService.fetchAll().then(setIntegrations);
  }, []);

  const onEnableWorkableIntegration = () => {
    setIsOpenWorkableIntegrationSettings(true);
  };

  const onDisableWorkableIntegration = async () => {
    const isConfirmed = await props.confirm.open({
      title: "Disable Workable Integration",
      content: "Are you sure you want to disable Workable integration?",
      confirmButtonTitle: "Disable",
      className: emotionCss`
        .modal-content {
          width: 500px !important;
        }
      `
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await workableIntegrationService.disable();
      setIntegrations({
        ...integrations,
        workable: false,
      });
      notifications.showSuccess("Workable integration has been disabled!");
    } catch (err) {
      console.error("Couldn't disable Workable Integration", err);
      notifications.showError("Couldn't disable Workable integration");
    }
  };

  const enableWorkableIntegration = async (data: EnableWorkableIntegrationDTO) => {
    try {
      await workableIntegrationService.enable(data);
      setIntegrations((prev) => ({ ...prev, workable: true }));
      notifications.showSuccess("Workable integration has been enabled!");
      setIsOpenWorkableIntegrationSettings(false);
    } catch (e) {
      console.error("Error while enabling Workable integration", e);
      notifications.showError("Error while enabling Workable integration");
    }
  };

  return (
    <div>
      <div className={billingCSS.head}>
        <SectionTitle className={billingCSS.title} level={2}>
          Integrations
        </SectionTitle>
      </div>
      <div className={css.content}>
        <ProfileIntegrationsCard
          data={{
            title : "Workable",
            description : "Connect your Workable account to automatically sync your candidates with jobs.",
            icon : "workable",
          }}
          onEnable={onEnableWorkableIntegration}
          onDisable={onDisableWorkableIntegration}
          isEnabled={integrations.workable}
        />
      </div>
      {isOpenWorkableIntegrationSettings && (
        <ModalWorkableIntegrationSettings
          onSubmit={enableWorkableIntegration} isOpen={true}
          onClose={() => setIsOpenWorkableIntegrationSettings(false)}
        />
      )}
    </div>
  );
};

export const ProfileIntegrations = withConfirm(ProfileIntegrationsCore);