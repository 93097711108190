import * as React from "react";
import { useEffect } from "react";
import { Notifications } from "./contexts/Notification/notifications";
import { DiscoverNew } from "./DiscoverNew/DiscoverNew";

import "./DiscoverNewWrapper.css";

export function injectStyleOverride() {
  const styleTag = document.createElement("style");
  styleTag.id = "app2-styles";
  styleTag.innerHTML = `
    * {
      box-sizing: border-box;
    }
    
    html, body, #root, #app-container {
      height: 100%;
      min-height: unset;
      overflow: unset;
    }
    
    .app {
      overflow: auto;
    }
    
    body {
      font-size: 14px;
      line-height: 1.2;
      color: var(--c-text);
      min-width: 1200px;
    }
    
    * {
      font-family: "Montserrat-Medium", sans-serif !important;
    }
    
    h1, h2, h3, h4 {
      margin: 0;
    }    
   
    h1 {
      font-size: 26px;
      line-height: 1.23;
      margin: 0;
    }
    
    /* START @fixme temporal bootstrap override */

    dl, ol, ul {
      margin: 0
    }
    
    svg {
      vertical-align: initial;
      overflow: initial;
    }
    
    /* END @fixme temporal bootstrap override */    
  `;

  document.head.append(styleTag);

  return function cleanup() {
    styleTag.remove();
  };
}

export function DiscoverNewWrapper() {
  useEffect(() => {
    return injectStyleOverride();
  }, []);
  return (
    <Notifications>
      <div className="app">
        <DiscoverNew />
      </div>
    </Notifications>
  );
}
