import * as React from "react";
import { NoData } from "../../../../components/DiscoverNew/UI/NoData/NoData";
import css from "./ProjectsTable.module.css";
import { Link } from "react-router-dom";
import { ProjectRefWithCandidateCount } from "../StageFlowService";

type Props = {
  projects: ProjectRefWithCandidateCount[]; // todo: type
};

export const ProjectsTable: React.FC<Props> = ({ projects }) => {

  if (!projects.length) {
    return <NoData showIcon={false} text="There are no projects associated with this interview process" />
  }

  return (
    <table className={css.table}>
      <thead>
        <tr>
          <th className={css.tdOrder}>№</th>
          <th className={css.tdName}>Name</th>
          <th className={css.tdDays}>Candidates</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project, i) => {
          return (
            <tr key={i} className={css.tr}>
              <td className={css.tdOrder}>{i + 1}</td>
              <td className={css.tdName}>
                <Link to={`/my-candidates/${project.id}`} className={css.link}>{project.name}</Link>
              </td>
              <td className={css.tdCount}>{project.candidateCount || " - "}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
