import React from "react";
import { cx, css } from "emotion/macro";

import { md } from "../../css/mediaQueries";

function RowDetailSection({ title = "", children, showViewMore = false, marginBottom = "25px" }) {
  return (
    <div className={cx("candidate-detail-section")} style={{ marginBottom: marginBottom }}>
      <div
        className={cx(
          "candidate-detail-section-header-container",
          css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 17px;
            ${md} {
              margin-bottom: 10px;
            }
          `
        )}
      >
        <span className={cx(styles.candidateProfileInfoHeader, "candidate-profile-info-header")}>{title}</span>
      </div>
      {React.Children.map(children, (child) => React.cloneElement(child))}
    </div>
  );
}

const styles = {
  candidateProfileInfoHeader: css`
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.71px;
    color: #373737;
    text-transform: capitalize;
    font-family: Montserrat-Bold !important;
  `,
  viewMoreButton: css`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.61px;
    text-align: right;
    color: #208bfe;
  `,
  viewMoreButtonDisabled: css`
    font-size: 12px;
    opacity: 0.25;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.61px;
    text-align: right;
    color: #208bfe;
  `,
};

export default RowDetailSection;
