import * as React from 'react';
import { Input } from "../../DiscoverNew/Form/Input/Input";
import { ButtonHTML } from "../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";

import css from './InlineEditable.module.css'

type Props = {
  value: any
  label?: any
  onSave?: (value: string) => void
  validator?: (value: string) => boolean
  inputStyle?: React.CSSProperties
}

export const InlineEditable: React.FC<Props> = ({ value, label, onSave, validator, inputStyle }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentValue(value);
  };

  const handleSave = () => {
    setIsEditing(false);
    if (validator && !validator(currentValue)) {
      setCurrentValue(value);
      return;
    }

    if (onSave) {
      onSave(currentValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div>
      {!isEditing && (
        <div>
          {label || value}
          <ButtonHTML className={css.buttonEdit} onClick={() => setIsEditing(true)}><Icon name='pencil-2'/></ButtonHTML>
        </div>
      )}
      {isEditing && (
        <div className={css.inputHolder}>
          <Input
            classNames={{ input: css.input }}
            inputStyle={inputStyle}
            type="text"
            value={currentValue}
            onChange={(e: any) => setCurrentValue(e.target.value)}
            onKeyDown={handleKeyDown}
            clearable={false}
          />
          <div className={css.actions}>
            <ButtonHTML className={css.buttonSave} onClick={handleSave}><Icon name='fa-circle-check'/></ButtonHTML>
            <ButtonHTML className={css.buttonCancel} onClick={handleCancel}><Icon name='fa-circle-xmark'/></ButtonHTML>
          </div>
        </div>
      )}
    </div>
  );
}