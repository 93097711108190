import { cx } from "emotion";
import * as React from "react";
import {
  StageFlowItemInterface,
} from "../../../../../../screens/profile/ProfileStageFlow/StageFlowService";
import { CandidateInterface, candidateService } from "../../../../../../screens/projects/CandidateService";

import css from "./CandidateBoardStageInfo.module.css";
import { BoardViewColumn } from "components/ui/BoardView/BoardView";

type Props = {
  data: BoardViewColumn<CandidateInterface> & { stage: StageFlowItemInterface };
  className?: string
  htmlID?: string
}

export const CandidateBoardStageInfo: React.FC<Props> = props => {
  return (
    <div className={cx(css.holder, props.className)} id={props.htmlID}>
      <div className={css.daysOnStage}>
        <span className={css.label} style={{ marginRight : 4 }}>Days on stage: <b>{props.data.stage?.hoursToComplete ? props.data.stage.hoursToComplete / 24 : "-"}</b></span>
      </div>
      <div className={css.daysOnStage}>
        <span className={css.label} style={{ marginRight : 4 }}>Candidates: <b>{props.data.cards ? props.data.cards.length : "-"}</b></span>
      </div>
    </div>
  );
};