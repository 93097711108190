import { DateTime } from "luxon";
import * as api from "../../api/apiService";
import { Formatter } from "../../components/DiscoverNew/util/Formatter";
import { BOUNCER_VALID_STATUS, IMPORTANT_SOCIAL_PROFILES } from "../../constants/constants";
import capitalizeWords from "../../formatters/capitalizeWords";
import abbreviateState from "../../formatters/stateAbbreviator";
import { StageColor, StageFlowItemInterface } from "../profile/ProfileStageFlow/StageFlowService";
import { CollaboratorInterface } from "./CollaboratorService";

export type CandidateEmail = {
  address: string
  type: "current_professional" | "professional" | "personal"
  status?: string
}

export type CandidatePhoneNumber = {
  e164: string
  isActive: boolean
  isVerified: boolean
  phoneNumberId: string
}

export type CandidateNote = {
  note: string
  author: string
  authorId: number
  createdAt: string
  noteId: number
  updatedAt: string
}

export type CandidateSocialProfile = {
  site: string
  url: string
  username: string
}

export type CandidateInterface = {
  id: number;
  emails?: CandidateEmail[]
  phoneNumbers?: CandidatePhoneNumber[]
  firstName: string;
  lastName: string;
  position: string;
  verificationStatus?: "positive" | "negative" | "unverified"
  unlockedAt: boolean;
  candidateId: number;
  // todo
  experiences: any,
  integrations: {
    // todo
    workable?: any
  }
  socialProfiles: CandidateSocialProfile[]
  notes: CandidateNote[]
  location: {
    locality: string;
    region: string;
  };
}

export type ProjectCandidateInterface = CandidateInterface & {
  createdAt: string;
  archivedAt: string;
  lastStageChangedAt: string;
  project: {
    id: number;
    name: string;
  }
  stage: StageFlowItemInterface
  latestExperience: {
    title: string;
    company: {
      name: string;
    }
  };
}

export type ProjectInterface = {
  id: number;
  projectId: number;
  name: string;
  description: string
  candidates: ProjectCandidateInterface[]
  collaborators: CollaboratorInterface[]
  ownedBy: {
    id: number,
    firstName: string,
    lastName: string,
  }
  // todo
  company: any;
  currentUserRole: "OWNER" | "ADMIN" | "EDITOR" | "VIEWER"
  candidateTotal: number
  tags: string[]
  archivedAt: string
}

export type CandidateInputDTO = {
  firstName: string
  lastName: string
  phone: string
  email: string
  address1: string
  address2: string
  gender: string
}

export type CreateCandidateManuallyParams = {
  organizationId: number
  projectId?: number
  verify?: boolean
}

class CandidateService {

  mapToInterface(obj: any): CandidateInterface {
    return {
      id : obj.candidateId,
      candidateId : obj.candidateId,
      firstName : obj.firstName,
      lastName : obj.lastName,
      position : obj.position,
      experiences : obj.experiences,
      socialProfiles : obj.socialProfiles,
      verificationStatus : obj.verificationStatus,
      notes : obj.notes,
      unlockedAt : obj.unlockedAt,
      location : obj.location,
      integrations : {
        workable : obj.workable,
      },
    };
  }

  createManually = async (data: CandidateInputDTO, params: CreateCandidateManuallyParams) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null)
      }, 2000);
    })
  }

  fetchNotes = async (candidateId: number): Promise<CandidateNote[]> => {
    const response = await api.GetCandidateNotes(candidateId);
    if (response.data) {
      return response.data.map((item: any) => {
        return {
          note : item.note,
          author : Formatter.fullName(item.createdBy),
          authorId : item.createdBy.id,
          createdAt : item.createdAt,
          noteId : item.noteId,
          updatedAt : item.updatedAt,
        };
      });
    }
    throw response.error;
  };

  getAddressFromLocation(candidateLocation: any) {
    if (!candidateLocation) {
      return null;
    }
    return {
      city : candidateLocation.locality ? capitalizeWords(candidateLocation.locality) : null,
      state : candidateLocation.region ? abbreviateState(candidateLocation.region) : null,
    };
  }

  getStageStyle = (stage: StageFlowItemInterface) => {
    if (stage?.color) {
      return this.getStageStyleByColor(stage.color);
    }
    if (stage.name.includes("New")) {
      return { color : "#408cf6", backgroundColor : "#dfecff" };
    }
    if (stage.name.includes("Accepted")) {
      return { color : "#56be24", backgroundColor : "#eafbe0" };
    }
    if (stage.name.includes("Rejected")) {
      return { color : "#d95968", backgroundColor : "#ffecec" };
    }
    if (stage.name.includes("Not Interested") || stage.name.includes("Declined")) {
      return { color : "#7e7e7e", backgroundColor : "#eaeaea" };
    }
    return { color : "#8259d9", backgroundColor : "#f3edff" };
  };

  getStageStyleByColor(color: string) {
    if (color === StageColor.GRAY) {
      return { color : "#868686", backgroundColor : "rgba(134,134,134,0.2)" };
    }
    if (color === StageColor.RED) {
      return { color : "#d40000", backgroundColor : "rgba(212,0,0,0.2)" };
    }
    if (color === StageColor.ORANGE) {
      return { color : "#ff7a00", backgroundColor : "rgba(255,122,0,0.2)" };
    }
    if (color === StageColor.YELLOW) {
      return { color : "#ffc700", backgroundColor : "rgba(255,199,0,0.2)" };
    }
    if (color === StageColor.PINK) {
      return { color : "#ff00a8", backgroundColor : "rgba(255,0,168,0.2)" };
    }
    if (color === StageColor.VIOLET) {
      return { color : "#8000ff", backgroundColor : "rgba(128,0,255,0.2)" };
    }
    if (color === StageColor.BLUE) {
      return { color : "#0066ff", backgroundColor : "rgba(0,102,255,0.2)" };
    }
    if (color === StageColor.TURQUOISE) {
      return { color : "#00c2ff", backgroundColor : "rgba(0,194,255,0.2)" };
    }
    if (color === StageColor.DARKGREEN) {
      return { color : "#0b8500", backgroundColor : "rgba(11,133,0,0.2)" };
    }
    if (color === StageColor.GREEN) {
      return { color : "#94ef8c", backgroundColor : "rgba(148,239,140,0.2)" };
    }
    return { color : "#8259d9", backgroundColor : "rgba(130,89,217,0.2)" };
  }

  downloadPDF = async (candidate: CandidateInterface) => {
    let candidateId = candidate.candidateId;
    try {
      const { data : candidateResp } = await api.DownloadCandidatePDF(candidateId);
      let url = window.URL.createObjectURL(candidateResp);
      let a = document.createElement("a");
      a.href = url;
      a.download = candidate.firstName + "_" + candidate.lastName + ".pdf";
      a.click();

      // @ts-ignore
      window.analytics.track("Candidate PDF Downloaded", {
        candidate_id : candidate.candidateId,
        state : candidate.location?.region,
        locality : candidate.location?.locality,
        is_unlocked : candidate.unlockedAt,
      });
    } catch (e) {
      console.error("could not download pdf");
    }
  };

  getHeadlineText(candidate: ProjectCandidateInterface) {
    if (candidate.latestExperience) {
      const { company } = candidate.latestExperience;
      return capitalizeWords(`${candidate.latestExperience.title} ${company?.name ? `at ${company.name}` : `–`}`);
    }
    return "–";
  }

  isStageOverdue = (candidate: ProjectCandidateInterface) => {
    const dateRaw = candidate.lastStageChangedAt || candidate.createdAt || null;
    if (!dateRaw || !candidate?.stage?.hoursToComplete) {
      return false;
    }
    const date = DateTime.fromISO(dateRaw);
    const now = DateTime.fromJSDate(new Date());
    const hoursFromLastStageChange = now.diff(date, "hours").hours;
    return hoursFromLastStageChange > candidate.stage.hoursToComplete;
  };

  getSortedEmails = (emails: CandidateEmail[]) => {
    const sortedEmails = [...emails].sort((a, b) => {

      const aType = a.type || "none";
      const bType = b.type || "none";

      if (aType === "personal" && bType !== "personal") {
        return -1;
      } else if (aType !== "personal" && bType === "personal") {
        return 1;
      } else if (aType.startsWith("current_") && !bType.startsWith("current_")) {
        return -1;
      } else if (!aType.startsWith("current_") && bType.startsWith("current_")) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedEmails;
  };

  getValidatedEmails = async (candidateId: number, emails: CandidateEmail[]) => {
    try {
      const { data: { items: validationData }} = (await api.ValidateEmails(candidateId)) as any;

      if (!validationData.length) {
        return;
      }

      let validatedEmails: any = emails.map((email) => {
        const validationItem = validationData.find((item: any) => item.email === email.address);
        // todo we need to handle the case where the email is not in the validationData, otherwise we will have "undefined" items in result array
        if (validationItem) {
          return {
            address: validationItem.email,
            status: validationItem.status,
            type: email.type || 'none',
          }
        }
      });

      // sort by personal first then by if type begin with 'current_' then by status
      validatedEmails = this.getSortedEmails(validatedEmails as any);

      // that's to override the sort above, can't find a better way to do it
      validatedEmails = validatedEmails.sort((a: any, b: any) => {
        if (a.status === BOUNCER_VALID_STATUS && b.status !== BOUNCER_VALID_STATUS) {
          return -1;
        } else if (a.status !== BOUNCER_VALID_STATUS && b.status === BOUNCER_VALID_STATUS) {
          return 1;
        } else {
          return 0;
        }
      });

      return validatedEmails;
    } catch (e) {
      console.error('Validation error: ', e);
    }
  };

  getSortedSocialMedia = (socialProfiles: CandidateSocialProfile[]) => {
    if (!socialProfiles) {
      return socialProfiles;
    }
    if (!socialProfiles.length) {
      return [];
    }

    let sortedSocials = socialProfiles.slice().sort((a, b) => {
      if (IMPORTANT_SOCIAL_PROFILES.includes(a.site) && IMPORTANT_SOCIAL_PROFILES.includes(b.site)) {
        return IMPORTANT_SOCIAL_PROFILES.indexOf(a.site) - IMPORTANT_SOCIAL_PROFILES.indexOf(b.site);
      } else if (IMPORTANT_SOCIAL_PROFILES.includes(a.site)) {
        return -1;
      } else if (IMPORTANT_SOCIAL_PROFILES.includes(b.site)) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedSocials;
  }

  unlockCandidate = (candidateId: number) => {
    return api.UnlockCandidate(candidateId);
  }

}

export const candidateService = new CandidateService();