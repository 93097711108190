import * as React from 'react';

import css from './AddCandidateVerificationStep.module.css'

type Props = {
  candidatesCount: number
  creditsCount: number
}

export const AddCandidateVerificationStep = (props: Props) => {

  if (props.candidatesCount === 1) {
    return (
      <div className={css.content}>
        Would you like to verify the candidate's<br/> contact information?<br/><br/>
        You have <b>{props.creditsCount}</b> credits available to your plan.
      </div>
    )
  }

  return (
    <div className={css.content}>
      We are adding <b>{props.candidatesCount}</b> unverified contacts to All.<br/><br/>
      You have <b>{props.creditsCount}</b> credits available to your plan.
      Do you want verify these new candidates?
    </div>
  )
}