import { cx } from "emotion";
import * as React from "react";
import { Icon } from "../IconComponent/Icon";

import css from "./ViewModeSwitcher.module.css";

export enum ViewMode {
  table = 'table',
  board = 'board',
}

type Props = {
  value: ViewMode
  onChange: (value: ViewMode) => void
  className?: string
}

export const ViewModeSwitcher: React.FC<Props> = props => {
  const { value, onChange, className } = props;
  return (
    <div className={cx(css.holder, className)}>
      <button className={cx(css.button, value === ViewMode.table && css.active)} onClick={() => onChange(ViewMode.table)}>
        <Icon name='table-view' />
      </button>
      <button className={cx(css.button, value === ViewMode.board && css.active)} onClick={() => onChange(ViewMode.board)}>
        <Icon name='board-view' />
      </button>
    </div>
  )
}