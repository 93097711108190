import { humanize } from "inflected";
import * as React from "react";
import { useEffect } from "react";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Link as CustomLink } from "../../../../components/DiscoverNew/UI/Link/Link";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import Table from "../../../../components/project/Table/Table";
import { Pill } from "../../../../components/ui/Pill/Pill";
import { AdminOrganizationMemberInterface } from "../../../../services/AdminService";
import { organizationService } from "../../../../services/OrganizationService";

import css from "./AdminOrganizationMemberList.module.css";

type Props = {
  //match: any
  members: AdminOrganizationMemberInterface[]
}

export const AdminOrganizationMemberList = (props: Props) => {

  //const [members, setMembers] = React.useState<EntityConnection<AdminOrganizationMemberInterface>>();

  useEffect(() => {
    //adminService.fetchOrganizationMembers(props.match.params.id, {}).then(setMembers);
  }, []);

  const onEditMember = (sub: AdminOrganizationMemberInterface) => {

  };

  return (
    <Table
      noData="No members"
      actions={{
        //edit : {
        //  render : (v: any) => {
        //    return <Icon name="pencil" onClick={() => onEditMember(v)} className={css.editButton} />;
        //  },
        //},
      }}
      dataObject={{
        data : {
          //members: members ? members.nodes : []
          members : props.members || [],
        },
      }}
      columns={[
        {
          dataIndex : "name",
          title : "Name",
          render : (v: any, item: AdminOrganizationMemberInterface) => (
            <CustomLink to={`/admin/users/${item.id}`}>
              {Formatter.fullName(item)}
            </CustomLink>
          )
        },
        { dataIndex : "email" },
        { dataIndex : "role", render : (v: string) => humanize(v) },
        {
          dataIndex : "status", render : (v: string, item: AdminOrganizationMemberInterface) => {
            return <Pill style={organizationService.getMemberStyle(item)}>{humanize(item.status)}</Pill>;
          },
        },
        {
          dataIndex : "credits", title: 'Credits Limit', render : (v: any, item: AdminOrganizationMemberInterface) => {
            //return <span>{item.credits.used?.toLocaleString() || 0} of {item.credits.limit?.toLocaleString() || 0}</span>
            return <span>{item.credits.limit?.toLocaleString() || 0}</span>
          },
        },
        {
          dataIndex : "invitedAt", title: 'Added', render : (v: string) => {
            return v ? Formatter.fullDate(v) : undefined
          },
        },
      ]}
    />
  );
};