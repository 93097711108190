import React from "react";

import "./Spinner.css";

function Spinner() {
  return (
    <div className="__spinner">
      <div className="__spinner--bounce1" />
      <div className="__spinner--bounce2" />
      <div className="__spinner--bounce3" />
    </div>
  );
}

export default Spinner;
