import { humanize } from "inflected";
import * as React from "react";
import { useEffect } from "react";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { ButtonHTML } from "../../../components/DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { useAuthController } from "../../../contexts/AuthProvider";
import formatPhoneNumber from "../../../formatters/phoneFormatter";
import { OrganizationMemberRole, organizationService } from "../../../services/OrganizationService";
import { PermissionEnum, permissionService } from "../../projects/PermissionService";
import { userService } from "../../projects/UserService";

import billingCSS from "../ProfileBilling/ProfileBilling.module.css";
import { EditableUserImage } from "./EditableUserImage/EditableUserImage";
import { ModalOrganizationInfoEdit } from "./ModalOrganizationInfoEdit";
import { ModalPersonaInfoEdit } from "./ModalPersonalInfoEdit";
import css from "./ProfileDetails.module.css";

const ProfileDetails = () => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isEditOrganizationInfoModalOpen, setIsEditOrganizationInfoModalOpen] = React.useState(false);
  const authController = useAuthController();
  const notifications = useNotifications();
  const user = authController.user as any;

  useEffect(() => {
    if (!user.id) {
      return;
    }
    // todo analytics service
    // @ts-ignore
    window.analytics.identify(user.id, {
      external_id : user.externalId,
      name : user.name,
      email : user.email,
      organization_id : user.organizationId,
      organization_type : user.organizationType,
      subscription_name : user.subscriptionName,
      subscription_end_date : user.subscriptionEndDate,
      platform : "web-app",
    });
  }, [user?.id]);

  const onEditPersonalInfo = () => {
    setIsEditModalOpen(true);
  };

  const onEditOrganizationInfo = () => {
    setIsEditOrganizationInfoModalOpen(true);
  };

  const onUpdatePersonalInfo = async (data: any) => {
    await userService.updateProfile({
      firstName : data.firstName,
      lastName : data.lastName,
      phoneNumbers : [{ e164 : data.phoneNumber }],
    });
    authController.fetchUser();
    // @ts-ignore
    window.analytics.track("User Profile Edited", {
      name : Formatter.fullName(data.name),
      email : user.email,
      profile_image : "false"
    });
    notifications.showSuccess("Profile has been updated!");
    setIsEditModalOpen(false);
  };

  const onUpdateOrganizationInfo = async (data: any) => {
    await organizationService.update({
      name : data.name,
    });
    authController.fetchUser();
    notifications.showSuccess("Organization has been updated!");
    setIsEditOrganizationInfoModalOpen(false);
  };

  const onUpdateImage = async (blob: Blob) => {
    let mimeType = blob.type.split("/");
    if (!mimeType?.length) {
      return;
    }
    const extension = mimeType[1];
    await userService.updateProfileImage(blob, extension);
    authController.fetchUser();
    // @ts-ignore
    window.analytics.track("User Profile Edited", {
      name : Formatter.fullName(user.name),
      email : user.email,
      profile_image : "true"
    });
    notifications.showSuccess("Profile image has been updated!");
  }

  return (
    <div>
      <div className={billingCSS.head}>
        <SectionTitle className={billingCSS.title} level={2}>
          Account Details
        </SectionTitle>
      </div>
      <div className={css.content}>
        <div className={css.mainInfo}>
          <EditableUserImage onChange={onUpdateImage} data={user} />
          <div>
            <h3 className={css.name}>{Formatter.fullName(user)}</h3>
            <p style={{ visibility : user.createdAt ? "visible" : "hidden" }}
               className={css.registered}>Registered {Formatter.fullDate(user.createdAt)}</p>
          </div>
        </div>
        <hr className={css.divider} />
        <SectionTitle className={css.title} level={2}>
          Personal Information
          <ButtonHTML className={css.editButton} onClick={onEditPersonalInfo}><Icon name="pencil-2" /></ButtonHTML>
        </SectionTitle>
        <div style={{ display : "flex" }}>
          <div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>First name</div>
              <div className={css.infoItemValue}>{user.firstName}</div>
            </div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>Last name</div>
              <div className={css.infoItemValue}>{user.lastName}</div>
            </div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>Phone</div>
              <div
                className={css.infoItemValue}>{user.phoneNumber || "–"}</div>
            </div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>Email</div>
              <div className={css.infoItemValue}>{user.email}</div>
            </div>
          </div>
          {/*<div>*/}
          {/*  <div className={css.infoItem}>*/}
          {/*    <div className={css.infoItemLabel}>Address</div>*/}
          {/*    <div className={css.infoItemValue}>–</div>*/}
          {/*  </div>*/}
          {/*  <div className={css.infoItem}>*/}
          {/*    <div className={css.infoItemLabel}>Zip-code</div>*/}
          {/*    <div className={css.infoItemValue}>–</div>*/}
          {/*  </div>*/}
          {/*  <div className={css.infoItem}>*/}
          {/*    <div className={css.infoItemLabel}>Country</div>*/}
          {/*    <div className={css.infoItemValue}>–</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <hr className={css.divider} />
        <SectionTitle className={css.title} level={2}>
          Organization Information
          {permissionService.can(user, PermissionEnum.editOrganizationInfo) && (
            <ButtonHTML className={css.editButton} onClick={onEditOrganizationInfo}><Icon name="pencil-2" /></ButtonHTML>
          )}
        </SectionTitle>
        <div style={{ display : "flex" }}>
          <div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>Name</div>
              <div className={css.infoItemValue}>{user.organizationV2.name}</div>
            </div>
            <div className={css.infoItem}>
              <div className={css.infoItemLabel}>Role</div>
              <div className={css.infoItemValue}>{humanize(user.seat.role)}</div>
            </div>
          </div>
        </div>
        {/*<hr className={css.divider} />*/}
        {/*<SectionTitle className={css.title} level={2}>Social Media</SectionTitle>*/}
        {/*<div style={{ display : "flex" }}>*/}
        {/*  <div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Linkedin</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Instagram</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Facebook</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Tiktok</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<hr className={css.divider} />*/}
        {/*<SectionTitle className={css.title} level={2}>Company information</SectionTitle>*/}
        {/*<div style={{ display : "flex" }}>*/}
        {/*  <div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Name</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Industry</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Position</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Address</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Location</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*    <div className={css.infoItem}>*/}
        {/*      <div className={css.infoItemLabel}>Zip-code</div>*/}
        {/*      <div className={css.infoItemValue}>–</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <hr className={css.divider} />
      </div>
      <ModalPersonaInfoEdit
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={onUpdatePersonalInfo} data={user}
      />
      <ModalOrganizationInfoEdit
        isOpen={isEditOrganizationInfoModalOpen}
        onClose={() => setIsEditOrganizationInfoModalOpen(false)}
        onSubmit={onUpdateOrganizationInfo} data={user.organizationV2}
      />
    </div>
  );
};

export default ProfileDetails;
