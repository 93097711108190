import React from "react";
import { Modal } from "react-bootstrap";
import { cx } from "emotion";
import PropTypes from "prop-types";

import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Button } from "../../DiscoverNew/UI/Button/Button";

import styles from "./CustomModal.module.css";

const CustomModal = ({
  className,
  headerClassName,
  titleClassName,
  footerClassName,
  show,
  title,
  subTitle,
  header,
  footer,
  cancelText,
  confirmText,
  children,
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered className={cx(styles.modal, className)}>
      <Modal.Header className={cx(styles.header, headerClassName)}>
        {header === null
          ? null
          : header || (
              <>
                <Modal.Title className={cx(styles.title, titleClassName)}>{title}</Modal.Title>
                <Modal.Title className={styles.subTitle}>{subTitle}</Modal.Title>
                <Icon name="fa-xmark" className={styles.close} onClick={onCancel} />
              </>
            )}
      </Modal.Header>
      <Modal.Body className={styles.body}>{children}</Modal.Body>
      {footer === null ? null : (
        <Modal.Footer className={cx(styles.footer, footerClassName)}>
          {footer || (
            <>
              <Button className={styles.button} onClick={onCancel} isNewStyle>
                {cancelText || "Cancel"}
              </Button>
              <Button className={styles.button} onClick={onConfirm} theme="primary" isNewStyle>
                {confirmText || "OK"}
              </Button>
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

CustomModal.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  header: PropTypes.element,
  footer: PropTypes.element,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

export default CustomModal;
