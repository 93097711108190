import countries from "./countries.json";
import states from "./states.json";
import { DISCOVER_DEFAULT_STATE_OPTION } from "../../../../constants/constants";

export class DiscoverSearchLocationHelper {
  static getCountryOptions = () => {
    const excludedCountryCodes = ["MX"];
    return countries
      .filter((item) => !excludedCountryCodes.includes(item.code))
      .map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });
  };

  static getStateOptionsForCountry = (countryCode) => {
    return [
      DISCOVER_DEFAULT_STATE_OPTION,
      ...states[countryCode].map((item) => {
        return {
          value: item.name.toLowerCase(),
          label: item.name,
        };
      }),
    ];
  };

  static getStateLabel = (countryCode) => {
    let label = "region";
    if (["US", "MX"].includes(countryCode)) {
      label = "state";
    }
    return label[0].toUpperCase() + label.slice(1);
  };
}
