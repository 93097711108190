import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

const RouteSwitch = ({ routes }) => {
  return (
    <Switch>
      {routes.map(({ path, Component, type, routes, ...props }) =>
        type === "redirect" ? (
          <Redirect key={`${type}-${path}`} to={path} {...props} />
        ) : (
          <Route key={path} path={path} {...props}>
            <Component routes={routes} />
          </Route>
        )
      )}
    </Switch>
  );
};

RouteSwitch.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      Component: PropTypes.elementType,
      exact: PropTypes.bool,
      type: PropTypes.string,
      routes: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default RouteSwitch;
