import React from "react";
import { cx } from "emotion";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import { backendUrlTo, urlTo } from "../../DiscoverNew/util/UrlHelper";
import { NoData } from "../../DiscoverNew/UI/NoData/NoData";
import { shortId } from "../../DiscoverNew/util/util";

import "./Table.css";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Link } from "../../DiscoverNew/UI/Link/Link";
import { Checkbox } from "../../DiscoverNew/Form/Checkbox/Chekbox";
import { Loading } from "../../DiscoverNew/UI/Loading/Loading";

const ActionIcons = {
  edit: "pencil",
  delete: "delete",
};

class Table extends React.Component {
  static defaultProps = {
    withCursor: false,
    noData: "No data to display.",
  };

  renderContent = (data) => {
    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan={100}>
            <NoData text={this.props.noData} />
          </td>
        </tr>
      );
    }

    const { withCursor, columns, getRowClassName, selectable, onSelect, selected } = this.props;

    return data.map((item) => (
      <tr key={item.id || item.node?.id || shortId()} className={getRowClassName ? getRowClassName(item) : undefined}>
        {selectable && (
          <td style={{ paddingRight: 4 }}>
            <Checkbox onClick={() => onSelect(withCursor ? item.node : item)} checked={selected[item.id]} />
          </td>
        )}
        {columns.map((col, i) => this.renderColumn(col, withCursor ? item.node : item, i))}
        {this.renderActions(item)}
      </tr>
    ));
  };

  hasActions = () => !!this.props.actions && !!Object.keys(this.props.actions).length;

  renderActions(item) {
    const { withCursor, actions } = this.props;

    if (!this.hasActions()) {
      return null;
    }

    return (
      <td className="table__actions">
        <div className="admin-table-actions">
          {Object.keys(actions).map((key) => {
            const props = {};
            const itemData = withCursor ? key.node : key;
            const action = actions[key];

            let Component = "button";

            if (action.render) {
              const result = action.render(item);
              return <React.Fragment key={shortId()}>{result}</React.Fragment>;
            }

            if (action.route) {
              const urlFn = action.backendUrl ? backendUrlTo : urlTo;
              if (action.backendUrl) {
                props.href = urlFn(action.route, item);
                Component = "a";
              } else {
                props.to = urlFn(action.route, item);
                Component = Link;
              }
            } else if (action.onClick) {
              props.onClick = () => action.onClick(item);
            }

            return (
              <Component {...props} key={itemData.id + key}>
                {action.icon || <Icon name={ActionIcons[key]} />}
              </Component>
            );
          })}
        </div>
      </td>
    );
  }

  renderColumn = (col, record, index) => {
    const value = col.dataIndex && record ? record[col.dataIndex] : record;
    let content = value;

    if (col.render) {
      content = col.render(value, record, this.props);
    }

    if (content === undefined || content === null || content === "") {
      content = "–";
    }

    if (col.route) {
      let LinkComponent = Link;
      const props = {
        to: urlTo(col.route, record),
      };
      if (col.route.startsWith("http")) {
        props.target = "_blank";
        props.href = props.to;
        props.as = "a";
      }
      content = <LinkComponent {...props}>{content}</LinkComponent>;
    } else if (col.formClick) {
      const callback = this.props.actions.edit.onClick;
      content = (
        <span role="link" className="admin-view-action" onClick={() => callback(record)}>
          {content}
        </span>
      );
    }

    return (
      <td key={index} className={col.className}>
        {content}
      </td>
    );
  };

  renderBody = () => {
    let { dataSource } = this.props;
    const { dataObject, columns } = this.props;
    if (dataObject) {
      if (dataObject.error) {
        let errorMessage = `couldn't load the data`;
        try {
          errorMessage = dataObject.error.toString() || JSON.stringify(dataObject.error);
        } catch (err) {}
        return (
          <tr>
            <td colSpan={columns.length}>
              <b>Error Loading Data:</b>
              <p style={{ wordBreak: "break-word", overflow: "hidden" }}>{errorMessage}</p>
            </td>
          </tr>
        );
      }
      const keys = Object.keys(dataObject.data || {});
      const hasNoData = !keys.length || (!dataObject.data[keys[0]]?.edges && !dataObject.data[keys[0]]);
      if (dataObject.loading && hasNoData) {
        return (
          <tr>
            <td colSpan={1000}>
              <Loading />
            </td>
          </tr>
        );
      }
      if (dataObject.data && !dataSource) {
        const keys = Object.keys(dataObject.data);
        if (keys.length < 1) {
          return null;
        }
        dataSource = dataObject.data[keys[0]]?.edges || dataObject.data[keys[0]];
      }
    }
    return this.renderContent(dataSource);
  };

  render() {
    const { columns, className, style, selectable, isAllSelected, onSelectAll } = this.props;
    return (
      <table className={cx("admin-table", className)} style={style}>
        <thead>
          <tr>
            {selectable && (
              <th>
                <Checkbox onClick={onSelectAll} checked={isAllSelected} />
              </th>
            )}
            {columns.map((col) => {
              const title = col.title || (col.dataIndex ? Formatter.humanize(col.dataIndex) : "n/a");
              return (
                <th key={title} className={col.className} style={col.style}>
                  {title}
                </th>
              );
            })}
            {this.hasActions() && <th className="table__actions">Actions</th>}
          </tr>
        </thead>
        <tbody>{this.renderBody()}</tbody>
      </table>
    );
  }
}

export default Table;
