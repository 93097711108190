import { defaultTo } from "lodash";

import capitalizeWords from "./capitalizeWords";
import abbreviateState from "./stateAbbreviator";
import filterExistsAndJoin from "./filterExistsAndJoin";

export const constructLocation = (location) => {
  const { locality, region } = defaultTo(location, {});
  return location && filterExistsAndJoin([capitalizeWords(locality), abbreviateState(capitalizeWords(region))], ", ");
};

export default constructLocation;
