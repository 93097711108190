import { css, cx } from "emotion/macro";
import React from "react";

import { xs } from "../../css/mediaQueries";

// TODO: refactor this to take a styles object so we can make more modular
const CandidateInitials = ({
  initials = [],
  width = "110px",
  height = "110px",
  fontSize = "48px",
  style,
  title,
  onClick,
  className,
}) => {
  const getInitials = () => {
    if (initials.length > 2) {
      return initials[0] + initials[1];
    }
    return initials;
  };
  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
    color: '#333333',
    fontSize: fontSize,
    fontWeight: 400,
    width: width,
    height: height,
    borderRadius: "50%",
    ...(style || {}),
  };

  return (
    <div
      onClick={onClick}
      title={title}
      className={cx(
        "initials-container",
        css`
          ${xs} {
            width: 100px;
            height: 100px;
          }
        `,
        className
      )}
      style={styles}
    >
      <span
        className={css`
          text-transform: uppercase;
        `}
      >
        {getInitials()}
      </span>
    </div>
  );
};

export default CandidateInitials;
