import * as React from "react";
import { ConfirmModal } from "../modals/ConfirmModal/ConfirmModal";
import CustomModal from "../modals/CustomModal/CustomModal";

export type WithConfirmProps = {
  confirm: ConfirmObj
}

type ConfirmObj = {
  open: (params: Partial<State>) => Promise<any>
  close: () => void
}

type Props = {
  isCustomModal?: boolean
}

type State = {
  title: string
  confirmButtonTitle: string
  cancelButtonTitle: string
  content: any
  onConfirm: (() => void) | undefined
  onCancel: (() => void) | undefined
  destructive: boolean
  closable: boolean
  showCancelButton: boolean
  isOpen: boolean
  className?: string
}

const getInitialState = (): State => ({
  title: "Are you sure?",
  confirmButtonTitle: "Confirm",
  cancelButtonTitle: "Cancel",
  content: null,
  onConfirm: undefined,
  onCancel: undefined,
  destructive: false,
  closable: false,
  showCancelButton: true,
  isOpen: false,
  className: undefined,
});

export function withConfirm(WrappedComponent: React.ComponentType<any>) {
  return class WithConfirm extends React.Component<Props, State> {
    timeout: any;
    modalRef;

    confirm: ConfirmObj;

    state = getInitialState();

    constructor(props: Props) {
      super(props);
      this.modalRef = React.createRef();
      this.confirm = {
        open: this.open,
        close: this.close,
      };
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    open = ({
      title,
      confirmButtonTitle,
      cancelButtonTitle,
      content,
      showCancelButton,
      closable,
      destructive,
      onConfirm,
      className,
    }: Partial<State>) => {
      return new Promise((resolve) => {
        const initialState = getInitialState();
        this.setState({
          title : title || initialState.title,
          content : content || initialState.content,
          confirmButtonTitle: confirmButtonTitle || initialState.confirmButtonTitle,
          cancelButtonTitle : cancelButtonTitle || initialState.cancelButtonTitle,
          showCancelButton : showCancelButton || initialState.showCancelButton,
          destructive : destructive || initialState.destructive,
          closable : closable || initialState.closable,
          onConfirm: onConfirm || (() => resolve(true)),
          onCancel: () => resolve(false),
          isOpen: true,
          className: className || initialState.className,
        });
      });
    };

    close = () => {
      this.setState({ isOpen: false });
      setTimeout(() => {
        this.setState(getInitialState());
      }, 300);
    };

    onCancel = () => {
      const { onCancel } = this.state;
      if (onCancel) {
        onCancel();
      }
      this.close();
    };

    onConfirm = () => {
      const { onConfirm } = this.state;
      if (onConfirm) {
        onConfirm();
      }
      this.close();
    };

    render() {
      const {
        title,
        content,
        destructive,
        confirmButtonTitle,
        showCancelButton,
        cancelButtonTitle,
        isOpen,
        className
      } = this.state;

      return (
        <>
          <WrappedComponent {...this.props} confirm={this.confirm} />
          {this.props.isCustomModal ? (
            <CustomModal
              show={isOpen}
              title={title}
              onCancel={this.onCancel}
              onConfirm={this.onConfirm}
              cancelText={cancelButtonTitle}
              confirmText={confirmButtonTitle}
            >
              {content}
            </CustomModal>
          ) : (
            <ConfirmModal
              className={className}
              isOpen={isOpen}
              title={title}
              content={content}
              confirmButtonTitle={confirmButtonTitle}
              cancelText={cancelButtonTitle}
              cancelButton={showCancelButton}
              destructive={destructive}
              onCancel={this.onCancel}
              onConfirm={this.onConfirm}
            />
          )}
        </>
      );
    }
  };
}
