import { cx } from "emotion";
import { DateTime } from "luxon";
import * as React from "react";
import capitalizeWords from "../../../../../formatters/capitalizeWords";
import {
  candidateService,
  ProjectCandidateInterface,
  ProjectInterface,
} from "../../../../../screens/projects/CandidateService";
import { ButtonHTML } from "../../../../DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { Icon } from "../../../../DiscoverNew/UI/IconComponent/Icon";
import { Formatter } from "../../../../DiscoverNew/util/Formatter";
import { CandidateVerificationIcon } from "../../CandidateVerificationIcon/CandidateVerificationIcon";
import { UserImage } from "../../../../ui/ProfileImage/UserImage";
import { CandidateContextMenu } from "../CandidateContextMenu/CandidateContextMenu";

import css from "./CandidateBoardCard.module.css";

type Props = {
  data: ProjectCandidateInterface;
  project: ProjectInterface
  isDragging: boolean
  onClick?: () => void;
  onOpenProfile: () => void;
  onRemove: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  onChangeStage: (stageId: number) => void;
}

export const CandidateBoardCard: React.FC<Props> = (props) => {
  const latestExperience = props.data.latestExperience;
  const address = candidateService.getAddressFromLocation(props.data.location);
  const stageStyle = candidateService.getStageStyle(props.data.stage);

  const isOverdue = candidateService.isStageOverdue(props.data);

  return (
    // todo pass styles to props instead of passing "isDragging" prop. Keep the component as dumb as possible
    <div onClick={props.onClick} className={cx(css.card, { [css.overdue] : isOverdue })} style={{
      borderColor : stageStyle.color,
    }}>
      <div className={css.headline}>
        <UserImage
          className={css.profileImage}
          data={props.data}
          style={{ width : 24, height : 24 }}
        />
        <div className={css.mainInfo}>
          <div className={css.name}>
            {Formatter.fullName(props.data)}
            <CandidateVerificationIcon style={{ marginLeft: 4, position: 'relative', top: 3 }} candidate={props.data} />
            {!!props.data.integrations?.workable && <Icon style={{ position: 'relative', top: 4, marginLeft: 4 }} name='workable' />}
          </div>
          <div className={css.location}>{Formatter.address(address)}</div>
        </div>
      </div>
      {latestExperience && (
        <p className={css.position}>
          {latestExperience.title ? (
            <>
              <span className={css.positionTitle}>{capitalizeWords(latestExperience.title)}</span>
              {!!latestExperience.company?.name && <> | {capitalizeWords(latestExperience.company.name)}</>}
            </>
          ) : (
            !!latestExperience.company?.name && <span>{capitalizeWords(latestExperience.company.name)}</span>
          )}
        </p>
      )}
      {!!props.data.lastStageChangedAt && (
        <div className={css.lastUpdated}>
          {DateTime.fromISO(props.data.lastStageChangedAt).toRelative({ locale : "en" })}
        </div>
      )}
      <div className={css.actions}>
        <ButtonHTML className={css.actionsItem} onClick={props.onOpenProfile}>
          <Icon name="eye" />
        </ButtonHTML>
        <div className={css.actionsItem}>
          <CandidateContextMenu
            project={props.project}
            candidate={props.data}
            onRemove={props.onRemove}
            onArchive={props.onArchive}
            onUnarchive={props.onUnarchive}
            onChangeStage={props.onChangeStage}
          />
        </div>
      </div>
    </div>
  );
};