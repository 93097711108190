import React, { useContext, useReducer } from "react";

const initialState = {
  candidateSort: { index: 0, isAscending: true },
  projectSort: { index: 0, isAscending: true },
};

const sortReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_CANDIDATE_SORT":
      return { ...state, candidateSort: { ...payload } };
    case "SET_COMPANY_SORT":
      return { ...state, companySort: { ...payload } };
    case "SET_PROJECT_SORT":
      return { ...state, projectSort: { ...payload } };
    default:
      return state;
  }
};

// The following context is responsible for holding global state for Sort
export const SortContext = React.createContext();
export const useSort = () => useContext(SortContext);
export const SortProvider = ({ children }) => {
  const [state, sortDispatch] = useReducer(sortReducer, initialState);

  return (
    <SortContext.Provider
      value={{
        state,
        sortDispatch,
      }}
    >
      {children}
    </SortContext.Provider>
  );
};
