import { css } from "emotion/macro";
import React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../button/CloseButton";
import { Button } from "../../../DiscoverNew/UI/Button/Button";
import { modalStyles } from "../../../modals/modalStyles";

import styles from "./ModalCandidatesExportDelayed.module.css";

type Props = {
  onClose: () => void;
  onRestart: () => void
  allowRestart?: boolean;
}

export const ModalCandidatesExportDelayed: React.FC<Props> = ({ onClose, allowRestart, onRestart }) => {

  React.useEffect(() => {
    // @ts-ignore
    window.__refetchExportList && window.__refetchExportList();
  }, [])

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-dialog {
          max-width: 570px;
        }
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>We're preparing your data</div>
        <div className={modalStyles.modalFormContainer}>
          <p className={styles.text}>
            We’re preparing your data and verifying the email addresses.<br/> The export process can take some time, especially
            if you have a large amount of data. We'll email you when your data export is ready.
          </p>
          {!!allowRestart && (
            <div className={styles.restart} onClick={onRestart}>
              If you still didn't receive an email, <span className={styles.restartLink}>restart the export</span>
            </div>
          )}
        </div>
        <footer className={styles.buttonRow}>
          <Button className={styles.button} onClick={onClose}>Close</Button>
        </footer>
      </div>
    </Modal>
  );
};