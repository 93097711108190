import React, { useContext, useReducer } from "react";

const initialState = {
  currentProject: {},
  currentCompany: {},
  currentCandidate: {},
};

const modalNavigatorReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_CURRENT_PROJECT":
      return { ...state, currentProject: { ...payload } };
    case "SET_CURRENT_COMPANY":
      return { ...state, currentCompany: { ...payload } };
    case "SET_CURRENT_CANDIDATE":
      return { ...state, currentCandidate: { ...payload } };
    default:
      return state;
  }
};

// The following context is responsible for holding the selected project
//  and selected company within the modal navigator component
export const ModalNavigatorContext = React.createContext();
export const useModalNavigator = () => useContext(ModalNavigatorContext);
export const ModalNavigatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalNavigatorReducer, initialState);

  return (
    <ModalNavigatorContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ModalNavigatorContext.Provider>
  );
};
