import React from "react";
import { cx } from "emotion";

import styles from "./SectionTitle.module.css";

type Props = {
  className?: string
  level: 1 | 2 | 3 | 4
  children: React.ReactNode
  style?: React.CSSProperties
}

const SectionTitle = ({ className, level = 1, children, style }: Props) => {
  const Component = `h${level}`;
  // @ts-ignore
  return <Component className={cx(styles.title, className)} style={style}>{children}</Component>;
};

export default SectionTitle;
