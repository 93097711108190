import * as api from "../../api/apiService";

class BlacklistDomainService {
  fetchAll = async (args) => {
    const res = await api.AdminGetBlacklistDomains(args);
    const resCount = await api.AdminGetBlacklistDomainsCount(args);
    return {
      nodes: res.data,
      pageInfo: {
        pageCount: Math.max(Math.ceil(resCount.data / args.limit), 1),
        total: resCount.data,
      },
    };
  };

  add = async (data) => {
    const res = await api.AdminAddBlacklistDomain(data);
    return res.data || null;
  };

  update = async (id, data) => {
    const res = await api.AdminUpdateBlacklistDomain(id, data);
    return res.data || null;
  };

  enable = async (id, value) => {
    const res = await api.AdminEnableBlacklistDomain(id, { value });
    return res.data || null;
  };
}

export const blacklistDomainService = new BlacklistDomainService();
