import React from "react";

import { cx, css } from "emotion/macro";

function NavigatorButton({ isSelected = false, text = "", onClick = () => {} }) {
  return (
    <div
      className={cx(
        "navigator-button",
        css`
          font-family: Montserrat-Bold !important;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.81px;
          color: #373737;

          flex: 1;
          border-bottom: ${isSelected ? "2px" : "1px"} solid ${isSelected ? "#208bfe" : "#d2d2d2"};
          cursor: pointer;
          text-align: center;
          padding-bottom: 8px;
        `
      )}
      onClick={onClick}
    >
      {text}
    </div>
  );
}

export default NavigatorButton;
