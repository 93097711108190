import { Form, Input, Select } from "antd";

import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import * as React from "react";

import { EMAIL_MASK } from "utils/constants";
import { errorMessages } from "utils/messages";
import {
  getEmptyOrganizationMember,
  ORGANIZATION_MEMBER_ROLES,
  ORGANIZATION_ROLE_OPTIONS,
  OrganizationMemberInviteDTO,
  OrganizationMemberRole,
} from "../../../../services/OrganizationService";
import { projectService } from "../../../projects/ProjectService";

import styles from "./BillingMember.module.css";

type Props = {
  member?: OrganizationMemberInviteDTO
  membersCount?: number
  renderControls: (submitAllowed: boolean, loading: boolean) => React.ReactNode
  onSubmit?: (values: any) => Promise<string | void>
}

const BillingMemberEditForm = ({ member = getEmptyOrganizationMember(), membersCount, renderControls, ...props }: Props) => {
  const [values, setValues] = React.useState<OrganizationMemberInviteDTO>(member);
  const [projects, setProjects] = React.useState<any[]>([]);
  const allowProjectSelection = [OrganizationMemberRole.Member].includes(values.role);

  const ref = React.useRef<any>();
  const [loading, setLoading] = React.useState(false);

  const isDisabled = membersCount === 1;

  const onSubmit = async (values: any) => {
    if (!props.onSubmit) {
      return;
    }
    setLoading(true);
    try {
      await props.onSubmit(values);
    } catch (err) {
      console.log(err);
      ref.current.setFields([{ name: 'email', errors: [err] }]);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    projectService.fetchProjects({ ownership : "organization" })
      .then(r => {
        setProjects(r.nodes.map(item => {
          return ({
            value : item.id,
            label : item.name,
          });
        }));
      });
  }, []);


  return (
    <Form
      className={styles.form}
      initialValues={values}
      ref={ref}
      layout="vertical"
      requiredMark={false}
      onValuesChange={(changedValues) => setValues({ ...values, ...changedValues })}
      onFinish={onSubmit}
    >
      <Form.Item
        label={<span className={styles.formLabel}>Member's email</span>}
        name="email"
        hasFeedback
        rules={[
          {
            pattern: EMAIL_MASK,
            message: errorMessages.INVALID_EMAIL,
            required: true,
          },
        ]}
      >
        <Input disabled={isDisabled} className={styles.formControl} type="email" placeholder="Enter the member's email" />
      </Form.Item>
      <Form.Item label={<span className={styles.formLabel}>Member's role</span>} name="role">
        <Select
          className={styles.select}
          disabled={isDisabled}
          defaultValue={ORGANIZATION_ROLE_OPTIONS[0].value}
          options={isDisabled ? ORGANIZATION_ROLE_OPTIONS : ORGANIZATION_ROLE_OPTIONS.filter(item => item.value !== OrganizationMemberRole.Owner)}
          suffixIcon={<Icon name="fa-chevron" className={styles.chevronIcon} />}
          popupClassName={styles.options}
        />
      </Form.Item>
      {allowProjectSelection && !!projects?.length && (
        <Form.Item label={<span className={styles.formLabel}>Invite to projects <small>(optional)</small></span>} name="projects">
          <Select
            mode="multiple"
            placeholder="Select projects"
            className={styles.select}
            options={projects}
            suffixIcon={<Icon name="fa-chevron" className={styles.chevronIcon} />}
            popupClassName={styles.options}
          />
        </Form.Item>
      )}
      {!!ORGANIZATION_MEMBER_ROLES[values.role].permissions.length && (
        <div className={styles.text}>
          <p>
            <span>{ORGANIZATION_MEMBER_ROLES[values.role].label}</span>'s permissions
          </p>
          <ul>
            {ORGANIZATION_MEMBER_ROLES[values.role].permissions.map((permission) => (
              <li key={permission}>{permission}</li>
            ))}
          </ul>
        </div>
      )}
      {renderControls(!!values.email?.match(EMAIL_MASK), loading)}
    </Form>
  );
};

export default BillingMemberEditForm;
