import React, { useContext, useReducer } from "react";

const initialState = {
  addingToWorkable: { state: false, message: null },
};

const processingStateReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case PROCESSGING_STATE_ACTION_TYPES.setAddingToWorkable:
      return { ...state, addingToWorkable: { ...state.addingToWorkable, ...payload } };
    default:
      return state;
  }
};

export const PROCESSGING_STATE_ACTION_TYPES = {
  setAddingToWorkable: "PROCESSING_STATE/SET_ADDING_TO_WORKABLE",
};

export const ProcessingStateContext = React.createContext();

export const useProcessingState = () => useContext(ProcessingStateContext);

export const ProcessingStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(processingStateReducer, initialState);

  return (
    <ProcessingStateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ProcessingStateContext.Provider>
  );
};
