import * as React from 'react';

import css from './ProgressBar.module.css'

type Props = {
  value: number
}

export function ProgressBar(props: Props) {
  return (
    <div className={css.holder}>
      <div className={css.progress} style={{ width: `${props.value}%` }} />
    </div>
  )
}