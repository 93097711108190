import * as React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { modalStyles } from "../../../components/modals/modalStyles";
import { projectService } from "../ProjectService";
import { useQueryString } from "../../../hooks/useQueryString";
import { cx } from "emotion";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { landingUrlTo } from "../../../components/DiscoverNew/util/UrlHelper";

import css from "./AcceptInvite.module.css";
import { projectInviteService } from "../ProjectInviteService";
import { useAuthController } from "../../../contexts/AuthProvider";
import { useUserData } from "../../../contexts/userContext";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";

export function injectStyleOverride() {
  const styleTag = document.createElement("style");
  styleTag.id = "app2-styles";
  styleTag.innerHTML = `
    #app-container {
      display: flex;
      flex-direction: column;
    }
  `;
  document.head.append(styleTag);
  return function cleanup() {
    styleTag.remove();
  };
}

export const AcceptInvite = (props) => {
  const { isAuthenticated } = useAuthController();
  const params = useQueryString();
  const history = useHistory();
  const userData = useUserData();
  const notifications = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState();
  const [tokenInfo, setTokenInfo] = useState();
  const [tokenError, setTokenError] = useState();
  const [acceptingError, setAcceptingError] = useState();

  async function onAccept() {
    try {
      setIsAccepting(true);
      await projectService.acceptInvite(tokenInfo.id);
      notifications.showSuccess("Invitation has been accepted!");
      history.push("/projects");
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message?.includes("already")) {
        history.push("/projects");
        return;
      }
      setIsAccepting(false);
      setAcceptingError(err);
    }
  }

  React.useEffect(() => {
    const cb = injectStyleOverride();

    projectInviteService
      .fetchInvite(params.get("token"))
      .then((p) => {
        setTokenInfo(p);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTokenError(err);
        setIsLoading(false);
      });

    return cb;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToSignIn = () => {
    window.location.href = landingUrlTo(`/sign-in?project_invite=${params.get("token")}`);
  };

  return (
    <div className={css.holder}>
      <div className={css.formHolder}>
        <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
          <div className={modalStyles.modalTitle}>Accept Invite</div>
          {isLoading && <p className={cx(modalStyles.modalSubtitle, css.subtitle)}>Loading...</p>}
          {!!tokenError && (
            <div>
              <p className={cx(modalStyles.modalSubtitle, css.subtitle)}>The link is expired or broken</p>
              <div style={{ marginTop: 20 }}>
                <Button component="a" to="/login" className={css.button}>
                  Go to Login
                </Button>
              </div>
            </div>
          )}

          {tokenInfo &&
            (isAuthenticated && userData?.state?.user?.email !== tokenInfo.email ? (
              <div>
                <div>
                  <p className={cx(modalStyles.modalSubtitle, css.subtitle)}>
                    This invite does not belong to you. Please sign in with the correct account to accept the invite.
                  </p>
                  <div style={{ marginTop : 20 }}>
                    <Button onClick={redirectToSignIn} className={css.button}>
                      Sign In to Different Account
                    </Button>
                  </div>
                </div>
              </div>
            ) : tokenInfo.status === "accepted" ? (
              <div>
                <p className={cx(modalStyles.modalSubtitle, css.subtitle)}>
                  You have already accepted {Formatter.fullName(tokenInfo.createdBy)}'s invitation to collaborate on
                  the <b>{tokenInfo.project.name}</b> project!
                </p>
                <div style={{ marginTop : 20 }}>
                  <Button component={Link}
                          to={tokenInfo.project?.id ? `/projects/${tokenInfo.project.id}` : `/projects`}
                          className={css.button}>
                    Open Project
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p className={cx(modalStyles.modalSubtitle, css.subtitle)}>
                  {Formatter.fullName(tokenInfo.createdBy)} invited you to collaborate on <b>{tokenInfo.project.name}</b> project. Click
                  the button below to accept the invite
                </p>
                <div style={{ marginTop: 20 }}>
                  {isAuthenticated ? (
                    <Button className={css.button} onClick={onAccept} disabled={isAccepting}>
                      {isAccepting ? "Processing..." : "Accept Invite"}
                    </Button>
                  ) : (
                    <Button onClick={redirectToSignIn} className={css.button}>
                      Sign In to Accept
                    </Button>
                  )}
                  {!!acceptingError && <p className={css.error}>Couldn't accept the invite.</p>}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
