import * as React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { cx } from "emotion";
import { Icon } from "../IconComponent/Icon";

import css from "./Pagination.module.css";

export function Pagination(props) {
  const { pageCount, currentPage, onChangeCurrentPage, pageRangeDisplayed, className } = props;

  if (pageCount <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      previousLabel={
        <>
          <Icon name="arrow" />
          &nbsp;Prev
        </>
      }
      nextLabel={
        <>
          Next&nbsp;
          <Icon name="arrow" />
        </>
      }
      breakLabel={<span>...</span>}
      breakClassName={css.spread}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={pageRangeDisplayed}
      forcePage={currentPage - 1}
      onPageChange={({ selected }) => onChangeCurrentPage(selected + 1)}
      containerClassName={cx(css.pagination, className)}
    />
  );
}

Pagination.defaultProps = {
  pageRangeDisplayed: 5,
  pageCount: 0,
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  onChangeCurrentPage: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
};
