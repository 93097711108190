import React from "react";
import { cx } from "emotion";

import styles from "./Divider.module.css";

type Props = {
  className?: string
}

const Divider = ({ className }: Props) => {
  return <hr className={cx(styles.divider, className)} />;
};

export default Divider;
