import * as React from "react";
import { organizationService } from "../../../services/OrganizationService";
import { FormField } from "../../DiscoverNew/Form/FormField/FormField";
import { Input } from "../../DiscoverNew/Form/Input/Input";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import css from "../SubscriptionRequired/SubscriptionRequired.module.css";

type Props = {
  onCreated: () => void;
};

export const AuthCreateOrganization = (props: Props) => {
  const [organizationName, setOrganizationName] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const createOrganization = async () => {
    if (!organizationName || !organizationName.trim()) {
      setError('Please enter the organization name');
      return;
    }

    setLoading(true);
    organizationService.createOrganization(organizationName).then(() => {
      props.onCreated();
      setLoading(false);
    }).catch(err => {
      console.error('createOrganization');
      console.dir(err);
      if (err.response.data.message === "organization name taken") {
        setError("Organization name is already taken");
      } else {
        setError(err.response.data.message);
      }
      setLoading(false);
    })
  }

  return (
    <>
      <h2 className={css.title}>Welcome to ChatterWorks!</h2>
      <p className={css.text}>
        To start using ChatterWorks, you need to create a new organization.
        <br />
        Please, enter the new organization name below:
      </p>
      <div style={{ maxWidth: 318, margin: '50px auto 50px', textAlign: 'left' }}>
        <FormField label="Organization Name" error={error}>
          <Input placeholder="Enter organization name" value={organizationName} onChange={(e: any) => setOrganizationName(e.target.value)}/>
        </FormField>
      </div>
      <Button style={{ minWidth: 200 }} disabled={loading} onClick={createOrganization}>{loading ? 'Processing...' : 'Create Organization'}</Button>
    </>
  )
}