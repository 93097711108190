import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Toast from "..";
import classes from "../Toast.module.css";

const Processing = ({ show, onClose }) => {
  return (
    <Toast
      title={
        <>
          <Spinner size="sm" animation="border" role="status" className={classes.loadingSpinner} />
          <span className={classes.title}>"We're verifying and adding these candidates to your Workable."</span>
        </>
      }
      body="It might take a little while. You'll be notified when it's done."
      show={show}
      onClose={onClose}
    />
  );
};

export default Processing;
