import { DateTime } from "luxon";
import * as React from "react";
import { ButtonText } from "../../../../../../components/DiscoverNew/UI/ButtonText/ButtonText";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import { ProgressBar } from "../../../../../../components/ui/ProgressBar/ProgressBar";
import { CurrentPlanInterface, PlanInfoInterface, PlansInfoMap } from "../../../../../../services/BillingService";
import { PlanInfoCard } from "../../../ui/PlanInfoCard/PlanInfoCard";
import PlanCard from "../../ChoosePlan/PlanCard/PlanCard";

import css from "./CurrentPlanTrialView.module.css";

type Props = {
  currentPlan: CurrentPlanInterface
  plans: PlansInfoMap
  onPurchasePlan: (plan: PlanInfoInterface) => void
  onInviteMember: () => void
}

export const CurrentPlanTrialView = ({ currentPlan, plans, onPurchasePlan, onInviteMember }: Props) => {
  return (
    <div className={css.holder}>
      <div className={css.purchase}>
        <SectionTitle level={2}>Purchase Subscription</SectionTitle>
        <PlanCard align='left' data={plans.sourcing} onChoose={() => onPurchasePlan(plans.sourcing)} buttonTitle="Upgrade" />
      </div>
      <div className={css.info}>
        <SectionTitle level={2}>Current Plan</SectionTitle>
        <PlanInfoCard style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch' }}>
          <div style={{ display: 'flex', gap: 50 }}>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Plan</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{currentPlan.planInfo.title}</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Seats</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>Unlimited</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Credits</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{currentPlan.planInfo.creditsPerSeatSingleUse} <small>/ seat</small></PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Expiration Date</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{DateTime.fromISO(currentPlan.expiresAt!).toFormat('DD')}</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <ButtonText onClick={() => onPurchasePlan(plans.sourcing)} iconName="fa-credit-card">Upgrade</ButtonText>
            <ButtonText iconName="paper-plane" onClick={onInviteMember}>Invite new member</ButtonText>
          </div>
        </PlanInfoCard>
        <PlanInfoCard className={css.progressBarCard}>
          <div className={css.progressBarCardRow}>
            <PlanInfoCard.ItemTitle>Credits</PlanInfoCard.ItemTitle>
            <div className={css.progressBarCardValue}>
              {currentPlan.organizationCredits.used.toLocaleString()} of {currentPlan.organizationCredits.count.toLocaleString()} credits used
            </div>
          </div>
          <ProgressBar value={currentPlan.organizationCredits.used / currentPlan.organizationCredits.count * 100} />
        </PlanInfoCard>
      </div>
    </div>
  )
}