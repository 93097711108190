import * as React from "react";
import css from "./Checkbox.module.css";
import { Icon } from "../../UI/IconComponent/Icon";
import { cx } from "emotion";

type Props = {
  checked: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  className?: string
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
}

export function Checkbox(props: Props) {
  return (
    <div className={cx(css.checkbox, { [css.checked]: props.checked }, props.className)} onClick={props.onClick} style={props.style}>
      <Icon name="tick2" className={css.icon} iconStyle={props.iconStyle} />
    </div>
  );
}
