import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import { Select } from "../DiscoverNew/Form/Select/Select";
import { Link } from "../DiscoverNew/UI/Link/Link";
import { Icon } from "../DiscoverNew/UI/IconComponent/Icon";
import { useUserData } from "../../contexts/userContext";
import { projectService } from "../../screens/projects/ProjectService";
import { OutOfCreditsAlert } from "../ui/Alert/OutOfCreditsAlert";
import { OrganizationMemberRole } from "../../services/OrganizationService";
import { useAuthController } from "../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../../screens/projects/PermissionService";
import { CreateProjectModal } from "./CreateProjectModal/CreateProjectModal";

function AddCandidatesToProjectModal({ isOpen, onClose, onSubmit, candidates, candidatesCount }) {
  const isUnmounted = useRef(false);
  const [project, setProject] = useState(undefined);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [companyWithProjectOptions, setCompanyWithProjectOptions] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);

  // todo permissions
  const authController = useAuthController();

  const {
    state: { user, userCredits },
    fetchUserAndUsageData,
  } = useUserData();
  const uiSettings = user.uiSettings;

  async function handleSubmit() {
    setIsWaiting(true);
    try {
      await onSubmit(project.value);
      if (!isUnmounted.current) {
        setIsWaiting(false);
        onClose();
      }
    } catch (e) {
      setIsWaiting(false);
      if (e?.response?.data?.message === 'not enough credits for the user') {
        fetchUserAndUsageData();
      }
    }
  }

  function onProjectCreated(project) {
    setProject({
      label: project.name,
      value: project.id,
    });
    fetchCompanyOptions().then((options) => {
      setCompanyWithProjectOptions(options);
      setShowCreateProjectModal(false);
    });
  }

  async function fetchCompanyOptions() {
    const ownership = permissionService.can(null, PermissionEnum.projectCanFetchOrganization) ? 'organization' : undefined;
    const projects = await projectService.fetchProjects({ limit: 200, ownership });
    const companiesWithProjects = projects.nodes.reduce((acc, item) => {
      if (acc[item.company.id]) {
        acc[item.company.id].options.push({ value: item.id, label: item.name });
      } else {
        acc[item.company.id] = {
          label: item.company.name,
          options: [{ value: item.id, label: item.name }],
        };
      }
      return acc;
    }, {});
    return Object.values(companiesWithProjects);
    // return api.GetAllCompaniesAndProjects().then((r) => {
    //   return r.data
    //     .filter((company) => company.projects?.length)
    //     .map((company) => {
    //       return {
    //         label: company.name,
    //         options: company.projects.map((p) => ({
    //           value: p.id,
    //           label: p.name,
    //         })),
    //       };
    //     })
    // });
  }

  useEffect(() => {
    fetchCompanyOptions().then((options) => {
      if (isUnmounted.current) {
        return;
      }
      setCompanyWithProjectOptions(options);
    });

    return () => {
      isUnmounted.current = true;
    };
  }, []);

  let lockedCount = 0;

  for (const item of Object.values(candidates)) {
    if (item.isLocked) {
      lockedCount++;
    }
  }

  const canAddToProject = userCredits >= lockedCount;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>
          Add {uiSettings?.mappings?.candidate}
          {candidatesCount > 1 && "s"} to {uiSettings?.mappings?.project}
        </div>
        <div className={modalStyles.modalFormContainer}>
          <div className={modalStyles.modalInputLabel}>Choose {uiSettings?.mappings?.project}</div>
          <Select onChange={setProject} value={project} options={companyWithProjectOptions} />
          <div style={{ marginTop: 3 }}>
            <Link leftIcon={<Icon name="plus" />} onClick={() => setShowCreateProjectModal(true)}>
              Create {uiSettings?.mappings?.project}
            </Link>
          </div>
        </div>
        {!canAddToProject && <div style={{ marginTop: 20 }}><OutOfCreditsAlert userCredits={userCredits} /></div>}
        <ModalButtonContainer
          title={`Add ${uiSettings?.mappings?.candidate}${candidatesCount > 1 ? "s" : ""}`}
          onClick={handleSubmit}
          isWaiting={isWaiting}
          isDisabled={!project || !canAddToProject}
        />
      </div>

      {showCreateProjectModal && (
        <CreateProjectModal
          onClose={() => setShowCreateProjectModal(false)}
          onCreated={onProjectCreated}
        />
      )}
    </Modal>
  );
}

export default AddCandidatesToProjectModal;
