import React, { useRef } from "react";
import { StageFlowItemInterface } from "../../../../../screens/profile/ProfileStageFlow/StageFlowService";
import { CandidateInterface, candidateService } from "../../../../../screens/projects/CandidateService";
import { Icon } from "../../../../DiscoverNew/UI/IconComponent/Icon";
import { SelectHtml } from "../../../../DiscoverNew/UI/SelectHtml/SelectHtml";
import { BoardViewColumn } from "../../../../ui/BoardView/BoardView";
import { Pill } from "../../../../ui/Pill/Pill";

import css from "./CandidateBoardColumnHeader.module.css";
import { shortId } from "components/DiscoverNew/util/util";
import { CandidateBoardStageInfo } from "./CandidateBoardStageInfo/CandidateBoardStageInfo";
  
type Props = {
  data: BoardViewColumn<CandidateInterface> & { stage: StageFlowItemInterface };
  sortOptions: { value: string; label: string }[];
  onChangeSort: (sort: string) => void;
  sort: string;
};

export const CandidateBoardColumnHeader: React.FC<Props> = (props) => {
  const { data, sort, onChangeSort, sortOptions } = props;
  const id = useRef(shortId());
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);

  const toggleOpen = (e: MouseEvent) => {
    if (isSettingsOpen) {
      closeSettings(e);
    } else {
      setIsSettingsOpen(true);
      document.body.addEventListener("click", closeSettings);
    }
  };

  const closeSettings = (e: MouseEvent | null) => {
    if (e && (e.target as HTMLElement).closest(`#${id.current}`)) {
      return;
    }

    setIsSettingsOpen(false);
    document.body.removeEventListener("click", closeSettings);
  };

  return (
    <div className={css.holder}>
      <Pill style={candidateService.getStageStyle(data.stage)} onClick={toggleOpen} className={css.stageName}>
        <span className={css.title}>{data.title}</span>
      </Pill>
      <div className={css.sort}>
        <Icon name="sort" />
        <SelectHtml className={css.sortSelect} onChange={onChangeSort} value={sort} options={sortOptions} />
      </div>
      {isSettingsOpen && (
        <CandidateBoardStageInfo
          htmlID={id.current}
          className={css.settings}
          data={data}
        />
      )}
    </div>
  );
};
