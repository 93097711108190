import * as React from 'react';
import cn from 'classnames'
import { Icon } from "../IconComponent/Icon";

import css from './ButtonText.module.css'

type Theme = 'primary' | 'secondary' | 'danger'

type Props<T> = {
  children?: React.ReactNode;
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  theme?: Theme
  iconName?: string
  disabled?: boolean
  component?: React.ElementType
  componentProps?: T
}

const themeToClassName: Record<Theme, string> = {
  primary: css.themePrimary,
  secondary: css.themeSecondary,
  danger: css.themeDanger
}

export function ButtonText<T>(props : Props<T>) {
  const theme = props.theme || 'primary';
  const className = cn(
    css.button,
    themeToClassName[theme],
    props.className,
  )

  const Component: React.ElementType = props.component || 'button';

  return (
    <Component {...props.componentProps || {}} className={className} disabled={props.disabled} onClick={props.onClick}>
      {props.iconName && <div className={css.icon}><Icon name={props.iconName}/></div>}
      {props.children}
    </Component>
  )
}
