import * as React from "react";
import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import "./BoardView.css";

export type BoardViewColumn<C> = {
  id: number;
  title: string;
  cards: C[];
}

type Props<C> = {
  columns: BoardViewColumn<C>[];
  renderColumnHeader?: any
  renderCard?: any
  disableColumnDrag?: boolean
  disableCardDrag?: boolean
  onCardDragEnd?: any
}

export function BoardView<C>(props: Props<C>) {
  const { columns, disableColumnDrag, renderColumnHeader, renderCard, onCardDragEnd, disableCardDrag } = props;
  return (
    <Board
      disableColumnDrag={disableColumnDrag}
      disableCardDrag={disableCardDrag}
      renderCard={renderCard}
      onCardDragEnd={onCardDragEnd}
      renderColumnHeader={renderColumnHeader}>
      {{ columns }}
    </Board>
  );
}