import React, { useEffect, useState } from "react";
import { css, cx } from "emotion";

// Bootstrap Imports
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Toast from "react-bootstrap/Toast";

// Contact Logos
import callIcon from "../../assets/images/contact/call.svg";
import messageIcon from "../../assets/images/contact/message.svg";
import mailIcon from "../../assets/images/contact/mail.svg";
import videoIcon from "../../assets/images/contact/video.svg";
import referralIcon from "../../assets/images/contact/referral.svg";
import lockIcon from "../../assets/images/contact/lock.svg";
import unlockIcon from "../../assets/images/contact/unlock.svg";
import copyIcon from "../../assets/images/contact/copy-icon.svg";
import validationPendingIcon from "../../assets/images/contact/email-badge-pending.svg";
import validationTrustedIcon from "../../assets/images/contact/email-badge-trusted.svg";
import validationUntrustedIcon from "../../assets/images/contact/email-badge-untrusted.svg";

import { formatPhoneNumber } from "../../formatters/phoneFormatter";

import "./icons.css";

import { lg, xs } from "../../css/mediaQueries";

// import { useUserData } from "../../contexts/user";
import { useUserData } from "../../contexts/userContext";
import { copyText } from "../DiscoverNew/util/util";
import { BOUNCER_VALID_STATUS } from "../../constants/constants";
import { candidateService } from "../../screens/projects/CandidateService";
import InsufficientCreditsModal from "../modals/InsufficientCreditsModal/InsufficientCreditsModal";

const candidateHasEmails = (candidate) => candidate?.emails && candidate?.emails?.length > 0;

const candidateHasPhoneNumbers = (candidate) => candidate?.phoneNumbers && candidate?.phoneNumbers?.length > 0;

function ContactIcons({ candidateData = {}, fetchCandidate = () => {} }) {
  const {
    state: { userCredits },
    dispatch: userDispatch,
    fetchUserAndUsageData
  } = useUserData();

  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);

  const isViewable = candidateData?.unlockedAt;
  const isUnlockable = candidateData?.emails?.length || candidateData?.phoneNumbers?.length;

  const [isToastShow, setIsToastShow] = useState(false);

  // Popover UI state visibility vars
  const [phoneIconShow, setPhoneIconShow] = useState(false);
  const [messageIconShow, setMessageIconShow] = useState(false);
  const [mailIconShow, setMailIconShow] = useState(false);
  const [validatedEmails, setValidatedEmails] = React.useState(null);
  
  // const [videoIconShow, setVideoIconShow] = useState(false);
  // const [referralIconShow, setReferralIconShow] = useState(false);
  
  const validateEmails = async(id, emails) => {
    if (emails.length) {
      let result = await candidateService.getValidatedEmails(id, emails);
      if (result) {
        setValidatedEmails(result);
      }
    }
  }

  useEffect(() => {
    if (candidateData?.emails !== undefined) {
      const candidateId = candidateData?.candidateId;
      if (candidateId != undefined && candidateData.unlockedAt) {
        validateEmails(candidateId, candidateData?.emails || []);
      }
    }
  }, [candidateData]);

  const handleUnlockCandidateContactInfo = async (e) => {
    e.stopPropagation();
    const candidateId = candidateData?.candidateId;
    if (!isUnlockable) {
      return;
    }

    if (userCredits <= 0) {
      setShowInsufficientCreditsModal(true);
      return;
    }

    // unlock the candidate
    if (candidateId) {
      try {
        const response = await candidateService.unlockCandidate(candidateId);
        if (response.status === 204) {
          // Segment Tracking
          window.analytics.track("Candidate Unlocked", {
            candidate_id: candidateData?.candidateId,
            state: candidateData?.location?.region,
            locality: candidateData?.location?.locality,
            is_unlocked: candidateData?.unlockedAt,
          });
        }
        // refetch the candidate
        await fetchCandidate();
        fetchUserAndUsageData()

      } catch (e) {
        console.error("could not unlock candidate", e);
        if (e?.response?.data?.message === 'not enough credits for the user') {
          setShowInsufficientCreditsModal(true);
          return;
        }
      }
    }
  };

  const renderPhonePopoverContent = (contactType) => {
    if (!candidateData.phoneNumbers || candidateData.phoneNumbers.length === 0) return;

    return (
      <>
        {candidateData.phoneNumbers.map((phoneNumberObj, key) => {
          return (
            <div key={key} style={{ display: "flex", flexFlow: "column" }}>
              <span className={cx(styles.contactBubbleHeader)}>{contactType}{phoneNumberObj?.type ? ": " + phoneNumberObj.type : ""}</span>
              <div className="contact-bubble-text-container">
                <div className="d-flex">
                  <div className="small-icon-background-container contact-icon-background-default-dark">
                    <img src={callIcon} alt="" width="12px" height="6px" className="align-super" />
                  </div>
                  {renderPhoneNumberText(phoneNumberObj, contactType)}
                </div>
                <div className="contact-bubble-unlock-container">
                  {renderContactBubbleUnlockInfo(phoneNumberObj?.e164)}
                </div>
              </div>
            </div>
          );
        })}
        {isToastShow && (
          <div className="contact-toast-container">
            <Toast
              onClose={() => setIsToastShow(false)}
              show={isToastShow}
              delay={300}
              autohide
              className="clipboard-success-toast-container"
            >
              <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
            </Toast>
          </div>
        )}
      </>
    );
  };

  const renderPhoneNumberText = (phoneObj, contactType) => {
    if (!phoneObj?.e164) return <span className="contact-icon-span-text">Number Unavailable</span>;

    switch (contactType) {
      case "Phone":
        return (
          <span
            onClick={(e) => openContactApp(e, "tel", phoneObj.e164, isViewable)}
            className={isViewable ? "icon-blue contact-icon-span-text active" : "icon-grey contact-icon-span-text"}
          >
            {formatPhoneNumber(phoneObj?.e164)}
          </span>
        );
      case "Message":
        return (
          <span
            onClick={(e) => openContactApp(e, "sms", phoneObj.e164, isViewable)}
            className={isViewable ? "icon-blue contact-icon-span-text active" : "icon-grey contact-icon-span-text"}
          >
            {formatPhoneNumber(phoneObj?.e164)}
          </span>
        );
      default:
        return <span className="contact-icon-span-text">Unsupported Type</span>;
    }
  };

  const getEmailCategoryTitleByType = (type) => {
    switch (type) {
      case "personal":
        return "Personal Email";
      case "professional":
        return "Professional Email";
      case "current_professional":
        return "Current Professional Email";
      default:
        return "Email";
    }
  };

  const renderEmailPopOverContent = () => {
    if (!candidateData.emails || candidateData.emails.length === 0) return null;
    let lastGroup = "";
    return (
      <>
        {(candidateService.getSortedEmails(validatedEmails ?? candidateData.emails) || []).map((emailObject, key) => {

          let emailGroup = getEmailCategoryTitleByType(emailObject.type);
          let showGroup = emailGroup !== lastGroup;
          lastGroup = emailGroup;

          return (
            <div key={key} style={{ display: "flex", flexFlow: "column" }}>
              {showGroup && (
                <span className={"contact-bubble-label"}>{lastGroup}</span>
              )}
              <div className="contact-bubble-text-container">
                <div className="d-flex">
                  {candidateData.unlockedAt && (
                    <div className={cx(styles.iconContainer, "small-icon-background-container")}>
                      {renderVerificationIcon(emailObject.status)}
                    </div>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        className={css`
                          & > .tooltip-inner {
                            max-width: 100%;
                          }
                        `}
                      >
                        {emailObject.address}
                      </Tooltip>
                    }
                  >
                    <span
                      className={
                        candidateData?.unlockedAt
                          ? "icon-blue contact-icon-span-text active contact-bubble-truncate"
                          : "icon-grey contact-icon-span-text contact-bubble-truncate"
                      }
                      onClick={(e) => openContactApp(e, "mailto", emailObject.address, isViewable)}
                    >
                      {" "}
                      {emailObject.address}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="ml-auto">
                  {renderContactBubbleUnlockInfo(emailObject.address)}
                </div>
              </div>
            </div>
          );
        })}
        {isToastShow && (
          <div className="contact-toast-container">
            <Toast
              onClose={() => setIsToastShow(false)}
              show={isToastShow}
              delay={300}
              autohide
              className="clipboard-success-toast-container"
            >
              <Toast.Body className="clipboard-success-toast-content">Copied!</Toast.Body>
            </Toast>
          </div>
        )}
      </>
    );
  };

  const renderVerificationIcon = (status) => {
    let src = null;

    if (!status) {
      src = validationPendingIcon;
    } else if (status === BOUNCER_VALID_STATUS) {
      src = validationTrustedIcon;
    } else {
      src = validationUntrustedIcon;
    }

    return (
      <img
        src={src}
        alt=""
        width="12px"
        height="12px"
        className={cx("align-super", "icon", { ["pendingIcon"]: !status })}
      />
    );
  };

  const renderContactBubbleUnlockInfo = (contact) => {
    if (
      !candidateData?.emails?.length &&
      !candidateData?.phoneNumbers?.length &&
      !candidateData?.socialProfiles?.length
    )
      return;

    const isViewable = candidateData?.unlockedAt;
    if (!isViewable) {
      return (
        <div onClick={(e) => handleUnlockCandidateContactInfo(e)} unselectable="on">
          <span className="unlock-candidate-button no-select">Unlock</span>
          <img src={lockIcon} alt="" width="7px" />
        </div>
      );
    } else {
      return (
        <div className="copy-button-container">
          <span className="unlock-candidate-button" onClick={(e) => onCopyText(e, contact)}>
            <img src={copyIcon} alt="" width="13px" height="auto" className="align-center" />
          </span>
        </div>
      );
    }
  };

  const onCopyText = (e, text) => {
    e.stopPropagation();
    copyText(text);
    setIsToastShow(true);
  };

  const openContactApp = (e, contactType, num, isViewable) => {
    e.stopPropagation();
    // We have an obfuscated number
    if (!isViewable) return;
    if (!contactType || !num) return;
    window.open(`${contactType}:${num}`, "_top");
  };

  const trackContactView = (contactType) => {
    window.analytics.track("Candidate Contact Info Viewed", {
      candidate_id: candidateData?.candidateId,
      state: candidateData?.location?.region,
      locality: candidateData?.location?.locality,
      is_unlocked: candidateData?.unlockedAt,
      type: contactType,
    });
  };

  const UnavailableContactItems = () => {
    return (
      <div style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
        <span>Just around the corner!</span>
      </div>
    );
  };

  const renderUnlockInfo = () => {
    if (!isUnlockable) {
      return (
        <div unselectable="on">
          <span className="unlock-candidate-button-disabled no-select">Unlock Info ({userCredits ? userCredits?.toLocaleString() : 0} left)</span>
        </div>
      );
    }

    const isViewable = candidateData?.unlockedAt;
    if (!isViewable) {
      return (
        <div onClick={(e) => handleUnlockCandidateContactInfo(e)} unselectable="on" style={{ cursor: "pointer" }}>
          <span className="unlock-candidate-button no-select">Unlock Info ({userCredits ? userCredits?.toLocaleString() : 0} left)</span>
          <img src={lockIcon} alt="" width="7px" />
        </div>
      );
    } else {
      return (
        <div className="unlock-icon-container">
          <img src={unlockIcon} alt="" width="10px" />
        </div>
      );
    }
  };

  // if the icon show state is true --> isPopover should be visibile.
  const determineIconClass = (contactList, isPopoverOpen) => {
    if (contactList?.length && isPopoverOpen) {
      return "contact-icon-container-selected";
    } else if (contactList?.length) {
      return "icon-background-container contact-icon-background-active";
    }
    return "icon-background-container contact-icon-background-container contact-icon-background-default";
  };

  return (
    //   Contact Header
    <div
      className={cx(
        "contact-container",
        css`
          ${xs} {
            width: initial;
          }
        `
      )}
    >
      <div
        className={cx(
          "contact-header-container",
          css`
            display: flex;
            justify-content: space-between;
            flex-flow: row;
            align-items: center;
            min-height: 24px;
            margin-bottom: 8px;
          `
        )}
      >
        <div
          className={cx(
            "social-contact-header-text",
            css`
              // width: 100%;
              font-weight: bold;
              letter-spacing: -0.71px;
              color: #373737;
              font-size: 14px;
              margin-bottom: 0px;
            `
          )}
        >
          Contact
        </div>
        <div className="text-right unlock-container">{renderUnlockInfo()}</div>
      </div>
      <div
        className={cx(
          "contact-icon-row",
          css`
            padding-left: 10px;
            width: 100%;
            ${lg} {
              padding: 0;
            }
          `
        )}
      >
        <div
          className={cx(
            "contact-icon-row-wrapper",
            css`
              min-height: 60px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `
          )}
        >
          {/* PHONE ICON */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            onEntered={() => {
              trackContactView("phone");
              setPhoneIconShow(true);
            }}
            onExited={() => setPhoneIconShow(false)}
            overlay={
              <Popover
                placement="bottom"
                style={{ minWidth: "225px" }}
                className={cx({ [styles.displayNone]: !candidateHasPhoneNumbers(candidateData) })}
              >
                <Popover.Content>{renderPhonePopoverContent("Phone")}</Popover.Content>
              </Popover>
            }
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={
                candidateHasPhoneNumbers(candidateData)
                  ? "contact-bubble-container text-center p-0 hover"
                  : "contact-bubble-container text-center p-0"
              }
            >
              <div className={determineIconClass(candidateData?.phoneNumbers, phoneIconShow)}>
                <img className="contact-icon-image pointer-event-none no-select" src={callIcon} alt="" width="17px" />
              </div>
            </div>
          </OverlayTrigger>

          {/* MESSAGE ICON */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            onEntered={() => {
              trackContactView("message");
              setMessageIconShow(true);
            }}
            onExited={() => setMessageIconShow(false)}
            overlay={
              <Popover
                placement="bottom"
                style={{ minWidth: "225px" }}
                className={cx({ [styles.displayNone]: !candidateHasPhoneNumbers(candidateData) })}
              >
                <Popover.Content>{renderPhonePopoverContent("Message")}</Popover.Content>
              </Popover>
            }
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={
                candidateHasPhoneNumbers(candidateData)
                  ? "contact-bubble-container text-center p-0 hover"
                  : "contact-bubble-container text-center p-0"
              }
            >
              <div className={determineIconClass(candidateData?.phoneNumbers, messageIconShow)}>
                <img
                  className="contact-icon-image pointer-event-none no-select"
                  src={messageIcon}
                  alt=""
                  width="17px"
                />
              </div>
            </div>
          </OverlayTrigger>

          {/* MAIL ICON */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            onEntered={() => {
              trackContactView("mail");
              setMailIconShow(true);
            }}
            onExited={() => setMailIconShow(false)}
            overlay={
              <Popover
                placement="bottom"
                style={{ minWidth: "225px" }}
                className={cx({
                  [styles.displayNone]: !candidateHasEmails(candidateData),
                })}
              >
                <Popover.Content>{renderEmailPopOverContent()}</Popover.Content>
              </Popover>
            }
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={
                candidateHasEmails(candidateData)
                  ? "contact-bubble-container text-center p-0 hover"
                  : "contact-bubble-container text-center p-0"
              }
            >
              <div className={determineIconClass(candidateData?.emails, mailIconShow)}>
                <img className="contact-icon-image pointer-event-none no-select" src={mailIcon} alt="" width="17px" />
              </div>
            </div>
          </OverlayTrigger>

          {/* VIDEO ICON */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            onEntered={() => trackContactView("video")}
            overlay={
              <Popover placement="bottom" style={{ minWidth: "175px" }}>
                <Popover.Content>
                  <UnavailableContactItems />
                </Popover.Content>
              </Popover>
            }
          >
            <div onClick={(e) => e.stopPropagation()} className="contact-bubble-container text-center p-0 hover">
              <div className={cx(styles.iconContainer, "icon-background-container", "contact-icon-background-default")}>
                <img src={videoIcon} alt="" className={styles.iconImg} />
              </div>
            </div>
          </OverlayTrigger>

          {/* REFERRAL ICON */}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            onEntered={() => trackContactView("referral")}
            overlay={
              <Popover placement="bottom" style={{ minWidth: "175px" }}>
                <Popover.Content>
                  <UnavailableContactItems />
                </Popover.Content>
              </Popover>
            }
          >
            <div className="contact-bubble-container text-center p-0 hover" onClick={(e) => e.stopPropagation()}>
              <div className={cx(styles.iconContainer, "icon-background-container", "contact-icon-background-default")}>
                <img src={referralIcon} alt="" className={styles.iconImg} />
              </div>
            </div>
          </OverlayTrigger>
        </div>
      </div>
      {showInsufficientCreditsModal && <InsufficientCreditsModal onClose={() => setShowInsufficientCreditsModal(false)} />}
    </div>
  );
}

const styles = {
  iconContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  iconImg: css`
    width: 21px;
    height: 21px;
  `,
  contactBubbleHeader: css`
    font-family: Montserrat-Medium !important;
    color: #9b9b9b;
    font-size: 12px;
  `,
  displayNone: css`
    display: none !important;
  `,
};

export default ContactIcons;
