import React from "react";

import { css, cx } from "emotion/macro";

import { xs, sm, lg } from "../../css/mediaQueries";

function CandidateInfo({ candidate, styleClass = "row" }) {
  const lineOne = candidate.location;

  const lineTwo = [candidate.title, candidate.company].filter((x) => x).join(" | ");

  return (
    <div
      className={cx(
        "candidate-info-container",
        css`
          ${lg} {
            margin-bottom: 10px !important;
            padding: initial !important;
            margin-top: 10px !important;
          }
          ${xs} {
            width: 100%;
          }
        `
      )}
    >
      <div className={cx("name truncate", styles.name[styleClass])}>{candidate.name}</div>
      <div className={cx("truncate", styles.lineOne[styleClass])}>{lineOne}&nbsp;</div>
      <div className={cx("truncate", styles.lineTwo[styleClass])}>{lineTwo} &nbsp; </div>
    </div>
  );
}

const styles = {
  name: {
    row: css`
      font-size: 16px;
      letter-spacing: -0.82px;
      color: #000000;
      margin-bottom: 3px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
    `,
    grid: css`
      margin-bottom: 5px;
      font-size: 26.4px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.08px;
      ${sm} {
        margin-bottom: 3px;
        font-size: 29.4px;
        letter-spacing: -1.5px;
      }
      ${xs} {
        width: 100%;
      }
    `,
    detail: css`
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.22px;
      color: #000000;
      margin-bottom: 5px;
      width: 100%;
    `,
  },
  lineOne: {
    row: css`
      font-size: 12px;
      margin-bottom: 5px;
      font-weight: bolder;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.61px;
      color: #373737;
    `,
    grid: css`
      font-size: 13.2px;
      margin-bottom: 5px;
      font-weight: bolder;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.54px;
      ${sm} {
        font-size: 19.4px;
        margin-bottom: 7px;
        letter-spacing: -0.99px;
      }
      ${xs} {
        width: 100%;
      }
    `,
    detail: css`
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.61px;
      text-align: center;
      margin-bottom: 8px;
      width: 100%;
    `,
  },
  lineTwo: {
    row: css`
      font-size: 12px;
      display: inline-block;
      font-weight: bolder;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.61px;
      color: #a8a8a8;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap !important;
      width: 95%;

      ${xs} {
        white-space: initial !important;
      }
    `,
    grid: css`
      font-size: 15.4px;
      display: inline-block;
      color: rgb(168, 168, 168);
      font-weight: bolder;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.63px;
      ${sm} {
        font-size: 19.4px;
        letter-spacing: -0.99px;
      }
    `,
    detail: css`
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: -0.61px;
      text-align: center;
      color: #a8a8a8;
      margin-bottom: 5px;
      width: 100%;
    `,
  },
};

export default CandidateInfo;
