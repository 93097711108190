import React, { useEffect, useState, useRef } from "react";
import { ModalBody } from "reactstrap";
import { capitalize, get, flow, first, isEmpty } from "lodash";
import { map, split } from "lodash/fp";
import { cx, css } from "emotion/macro";

import * as api from "../../api/apiService";
import { useModalNavigator } from "../../contexts/modalNavigator";
import * as globals from "../../constants/constants";

// Components
import CandidateProfileImg from "../candidate/CandidateProfileImg";
import CandidateDetailModalBody from "../../components/modals/candidateDetails";

import ModalNavigator from "./ModalNavigator";
import CompanyImg from "../company/CompanyImg";
import ProjectEdit from "../../components/project/ProjectEdit";
import CloseIcon from "../../icons/Close";
import CandidateLastViewed from "../calendar/CandidateLastViewed";

// CSS
import transition from "../../css/transitions";
import { xs, sm, md, lg, xlgU } from "../../css/mediaQueries";

// Formatters
import filterExistsAndJoin from "../../formatters/filterExistsAndJoin";
import capitalizeWords from "../../formatters/capitalizeWords";
import constructLocation from "../../formatters/locationFormatter";
import { getCompanyInitials } from "../../screens/companies/Companies";
import { PermissionEnum, permissionService } from "../../screens/projects/PermissionService";
import { projectService } from "../../screens/projects/ProjectService";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Link } from "react-router-dom";

const constructName = (firstname, lastname) => filterExistsAndJoin([capitalize(firstname), capitalize(lastname)], " ");

const constructLocationSalary = (location, salary) => filterExistsAndJoin([location, salary], " • ");

const constructPositionCompany = (positionTitle, companyName) =>
  filterExistsAndJoin([capitalizeWords(positionTitle), capitalizeWords(companyName)], " | ");

const extractInitials = (name) => {
  const result = name && flow([split(" "), map(first)])(name);
  return result;
};

const IconButton = ({ Icon, onClick, label }) => {
  return (
    <div
      onClick={onClick}
      className={css`
        cursor: pointer;
        color: #a8a8a8;
        margin-top: 5px;

        svg {
          fill: #a8a8a8;
          stroke: #a8a8a8;
          transition: all 0.2s ease-in-out;
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }

        &:hover svg {
          fill: #373737;
          stroke: #373737;
        }

        font-weight: 400;
      `}
    >
      <Icon />
      {label}
    </div>
  );
};

const DeleteButton = ({ onClick, shouldShowRemove }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div className={shouldShowRemove ? "slide-out-left" : ""}>
      <IconButton Icon={CloseIcon} onClick={handleClick} />
    </div>
  );
};

function useOutsideClick(containerRef, onOutsideClick) {
  useEffect(() => {
    function onOutsideClickHandler(e) {
      if (containerRef.current && containerRef.current.contains(e.target)) {
        return;
      }

      onOutsideClick();
    }

    document.addEventListener("mousedown", onOutsideClickHandler);
    return () => {
      document.removeEventListener("mousedown", onOutsideClickHandler);
    };
  }, [containerRef, onOutsideClick]);
}

const ClickOutsideAlerter = (props) => {
  const ref = useRef();
  useOutsideClick(ref, props.onOutsideClick);

  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
      `}
      ref={ref}
    >
      {props.children}
    </div>
  );
};

const RemoveComponent = ({ handleRemoveClick }) => {
  const [shouldShowRemove, setShouldShowRemove] = useState(false);

  const initialRemoveClick = () => {
    setShouldShowRemove(!shouldShowRemove);
  };

  const onOutsideClick = () => {
    setShouldShowRemove(false);
  };

  return (
    <>
      <ClickOutsideAlerter onOutsideClick={onOutsideClick}>
        <div
          className={css`
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
          `}
        >
          <DeleteButton shouldShowRemove={shouldShowRemove} onClick={initialRemoveClick} />
          <div
            className={cx(
              shouldShowRemove ? "fade-in" : "faded",
              css`
                font-family: Montserrat-Medium !important;
                font-size: 15.4px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.78px;
                color: #008bff;
                cursor: pointer;
                opacity: 0;
                margin-top: 8px;
                ${xlgU} {
                  position: absolute;
                }
              `
            )}
            onClick={handleRemoveClick}
          >
            Remove
          </div>
        </div>
      </ClickOutsideAlerter>
    </>
  );
};

const Candidate = ({ idx, candidate, project, onClick, handleRemoveCandidate }) => {
  const { firstName, lastName, experiences = [], location } = candidate;

  const formattedLocation = constructLocation(location);

  const companyName = get(experiences, "[0].company.name");
  const positionTitle = get(experiences, "[0].title");

  const getCandidateInitials = () => {
    let name = constructName(firstName, lastName);
    let initials = extractInitials(name);
    return initials;
  };

  const handleConfirmationItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemoveCandidate(e, candidate);
  };

  const canEdit = permissionService.can(project, PermissionEnum.projectEdit);

  return (
    <div
      onClick={onClick}
      className={cx(
        "row",
        "candidate-list-item-container",
        styles.listItemContainer,
        {
          [css`
            cursor: pointer;
          `]: onClick,
        },
        css`
          ${xlgU} {
            position: relative;
          }
          &:hover [data-candidate-name] {
            color: #208bfe;
          }
        `
      )}
      key={idx}
      style={{ display: "flex", flexFlow: "row" }}
    >
      <div style={{ marginRight: "14px" }}>
        <CandidateProfileImg
          candidate={candidate}
          initials={getCandidateInitials()}
          style={{ width: "75px", height: "75px" }}
        />
      </div>
      {/* Candidate Details */}
      <div className={styles.listItemDetailContainer}>
        <span
          data-candidate-name
          className={cx(
            "standard-theme-text",
            styles.lineOne,
            styles.truncateText,
            css`
              ${transition({ type: "color" })}
            `
          )}
        >
          {constructName(firstName, lastName)}
        </span>
        <span className={cx("standard-theme-text", styles.lineTwo, styles.truncateText)}>
          {constructLocationSalary(formattedLocation)}
        </span>
        <span className={cx("standard-theme-text", styles.lineThree, styles.truncateText)}>
          {constructPositionCompany(positionTitle, companyName)}
        </span>
        <div className={cx(styles.candidateLastViewedContainer)}>
          <CandidateLastViewed candidate={candidate} />
        </div>
      </div>
      {canEdit && (
        <div
          className={css`
            width: 100%;
          `}
        >
          <RemoveComponent handleRemoveClick={handleConfirmationItemClick} />
        </div>
      )}
    </div>
  );
};

const ProjectCandidates = ({ candidates, currentProject, handleRemoveCandidate, push, uiSettings }) => {
  // If we have no candidates return Candidates Header with Empty List Message
  if (!candidates?.length) {
    return (
      <>
        <header className={cx("list-header", styles.listHeader)}>{uiSettings?.mappings?.candidates}</header>
        <div className={styles.emptyListMessage}>No {uiSettings?.mappings?.candidates}</div>
      </>
    );
  }

  // If we have exactly 1 candidate whether or not the candidate is recent or not
  // or if we have two candidates and the first one does not have lastViewedAt property
  // do not include Recent Candidate Container.
  if (candidates?.length === 1) {
    return (
      <>
        <header className={cx("list-header", styles.listHeader)}>{uiSettings?.mappings?.candidates}</header>
        {candidates.map((candidate, idx) => (
          <Candidate
            key={JSON.stringify(candidate)}
            idx={idx}
            candidate={candidate}
            project={currentProject}
            handleRemoveCandidate={handleRemoveCandidate}
            onClick={() =>
              push(
                currentProject?.name,
                (push, pop) => (
                  <CandidateDetailModalBody push={push} pop={pop} projectId={currentProject?.id} candidate={candidate} uiSettings={uiSettings} />
                ),
                globals.CANDIDATE_MODAL_NAME
              )
            }
          />
        ))}
      </>
    );
  }

  const recentCandidate = candidates[0];
  const remainingCandidates = candidates.slice(1);
  return (
    <>
      <header className={cx("list-header", styles.listHeader)}>Recent {uiSettings?.mappings?.candidate}</header>
      <Candidate
        uiSettings={uiSettings}
        key={JSON.stringify(recentCandidate)}
        idx={0}
        candidate={recentCandidate}
        project={currentProject}
        handleRemoveCandidate={handleRemoveCandidate}
        onClick={() =>
          push(
            currentProject?.name,
            (push, pop) => (
              <CandidateDetailModalBody push={push} pop={pop} projectId={currentProject?.id} candidate={recentCandidate} uiSettings={uiSettings} />
            ),
            globals.CANDIDATE_MODAL_NAME
          )
        }
      />
      <header className={cx("list-header", styles.listHeader)}>{uiSettings?.mappings?.candidates}</header>
      {remainingCandidates.map((candidate, idx) => (
        <Candidate
          uiSettings={uiSettings}
          key={JSON.stringify(candidate)}
          idx={idx}
          candidate={candidate}
          project={currentProject}
          handleRemoveCandidate={handleRemoveCandidate}
          onClick={() =>
            push(
              currentProject?.name,
              (push, pop) => (
                <CandidateDetailModalBody push={push} pop={pop} projectId={currentProject?.id} candidate={candidate} uiSettings={uiSettings} />
              ),
              globals.CANDIDATE_MODAL_NAME
            )
          }
        />
      ))}
    </>
  );
};

export const ViewProjectModalBody = ({
  push,
  pop,
  currentProjectInitials,
  toggleModalWindow,
  onProjectUpdated,
  onProjectDeleted,
  onProjectArchived,
  onProjectUnarchived,
  uiSettings,
}) => {
  const [candidates, setCandidates] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
  const [isOpenUnarchiveModal, setIsOpenUnarchiveModal] = useState(false);
  const notificationController = useNotifications();

  const {
    state: { currentProject, currentCompany },
    dispatch,
  } = useModalNavigator();

  useEffect(() => {
    const fetchData = async () => {
      // in order prevent lint warnings for dependencies, pass in project id
      await fetchProjectCandidates(currentProject?.id);
    };

    if (currentProject) {
      fetchData();
    }
  }, [currentProject]);

  const fetchProjectCandidates = async (projectId) => {
    if (!projectId) return;
    try {
      const { data } = await api.GetProjectCandidates(projectId);
      if (data) {
        setCandidates(data);
      }
    } catch (e) {
      console.error("could not fetch project candidates");
    }
  };

  const handleProjectEditSave = async (data) => {
    if (!data.name) return;
    const dto = {
      ...data,
      tags: data.tags?.map((item) => item.value),
    };
    await api.UpdateProject(currentProject?.id, dto);

    const updatedProject = { ...currentProject, ...data };
    dispatch({ type: "SET_CURRENT_PROJECT", payload: { ...updatedProject } });

    // Update company project list with modified project name
    if (currentCompany?.projects?.length) {
      const targetProject = currentCompany.projects.find((p) => p.id === updatedProject?.id);
      if (targetProject) {
        targetProject.name = updatedProject.name;
      }
    }
    onProjectUpdated();
    toggleEditMode();
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const buildTotalCountString = (total, singular = "", plural = "") => {
    if (!total) return "";
    if (total === 1) return `${total} ${singular}`;
    return `${total} ${plural}`;
  };

  const deleteProject = async () => {
    try {
      await projectService.delete(currentProject.projectId);
      // @todo analytics service
      window.analytics.track("Project Deleted", {
        project_id: currentProject.projectId,
        name: currentProject.name,
        company_id: currentProject.company?.companyId,
        company_name: currentProject.company?.name,
      });

      notificationController.showSuccess("Project has been deleted!");
      toggleModalWindow();
      onProjectDeleted();
    } catch (err) {
      notificationController.showError("Couldn't delete the project!");
    }
  };

  const archiveProject = async () => {
    try {
      await projectService.archive(currentProject.projectId);
      // @todo analytics service
      window.analytics.track("Project Archived", {
        project_id: currentProject.projectId,
        name: currentProject.name,
        company_id: currentProject.company?.companyId,
        company_name: currentProject.company?.name,
      });

      notificationController.showSuccess("Project has been archived!");
      toggleModalWindow();
      onProjectArchived();
    } catch (err) {
      notificationController.showError("Couldn't archive the project!");
    }
  };

  const unarchiveProject = async () => {
    try {
      await projectService.unarchive(currentProject.projectId);
      // @todo analytics service
      window.analytics.track("Project Unarchived", {
        project_id: currentProject.projectId,
        name: currentProject.name,
        company_id: currentProject.company?.companyId,
        company_name: currentProject.company?.name,
      });

      notificationController.showSuccess("Project has been unarchived!");
      toggleModalWindow();
      onProjectUnarchived();
    } catch (err) {
      notificationController.showError("Couldn't unarchive the project!");
    }
  };

  const handleRemoveCandidate = async (e, candidate) => {
    if (!candidate?.candidateId) return;
    e.stopPropagation();
    // Remove Candidate From Project
    try {
      const response = await api.RemoveCandidatesFromProject(currentProject.projectId, [candidate?.candidateId]);
      if (response.status === 204) {
        window.analytics.track("Candidate Removed From Project", {
          candidate_id: candidate?.candidateId,
          state: candidate?.location?.region,
          locality: candidate?.location?.locality,
          is_unlocked: candidate?.unlockedAt,
          project_id: currentProject.projectId,
        });
      }
    } catch (e) {
      console.error("could not remove candidate from project", e);
    }

    // refresh project candidate list
    const filteredCandidates = candidates.filter((c) => c.candidateId !== candidate.candidateId);
    const updatedProject = { ...currentProject, candidates: [...filteredCandidates] };
    dispatch({ type: "SET_CURRENT_PROJECT", payload: { ...updatedProject } });
  };

  const canEdit = permissionService.can(currentProject, PermissionEnum.projectEdit);

  return (
    <>
      <ModalBody
        className={css`
          padding: 29px 45px !important;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-flow: row;
            ${md} {
              flex-flow: column;
            }
          `}
        >
          <div
            className={cx(
              "project-body project-detail-modal-body",
              css`
                margin-top: -5px;
                margin-left: 0px;
                margin-right: 0px;
                width: 320px;
                min-width: 320px;
                ${md} {
                  justify-content: center;
                  width: 100%;
                  min-width: initial;
                }
              `
            )}
          >
            <CompanyImg
              company={isEmpty(currentCompany) ? currentProject?.company : currentCompany}
              initials={currentProjectInitials || getCompanyInitials(currentProject.company.name)}
              style={{ width: "110px", height: "110px", flexShrink: 0 }}
            />
            {!isEditMode && (
              <>
                <div className={cx(styles.projectName, styles.truncateText)}>
                  <Link to={`/projects/${currentProject?.id}`} className={styles.link}>{currentProject?.name}</Link>
                </div>
                <div className={cx(styles.companyName, styles.truncateText)}>
                  {currentCompany?.name || currentProject?.company?.name}
                </div>
                <div className={cx(styles.companyDescription)}>{currentProject?.description || ""}</div>
                <span className={cx(styles.projectCandidateCount, "candidates-text")}>
                  {buildTotalCountString(
                    candidates?.length,
                    uiSettings?.mappings?.candidate,
                    uiSettings?.mappings?.candidates
                  )}
                </span>
              </>
            )}
            {/* Edit Button */}
            {!isEditMode && canEdit && (
              <div className={cx("standard-theme-text", styles.editButtonContainer)}>
                <span
                  onClick={toggleEditMode}
                  className={cx(
                    css`
                      font-family: Montserrat-Medium !important;
                      cursor: pointer;
                    `
                  )}
                >
                  Edit
                </span>
              </div>
            )}
            {canEdit && (
              <div className={cx("standard-theme-text", styles.deleteButtonContainer)}>
                <span
                  onClick={() => setIsOpenDeleteModal(true)}
                  className={cx(
                    css`
                      font-family: Montserrat-Medium !important;
                      cursor: pointer;
                    `
                  )}
                >
                  Delete
                </span>
              </div>
            )}
            {canEdit &&
              (currentProject.archivedAt ? (
                <div className={cx("standard-theme-text", styles.archiveButtonContainer)}>
                  <span
                    onClick={() => setIsOpenUnarchiveModal(true)}
                    className={cx(
                      css`
                        font-family: Montserrat-Medium !important;
                        cursor: pointer;
                      `
                    )}
                  >
                    Unarchive
                  </span>
                </div>
              ) : (
                <div className={cx("standard-theme-text", styles.archiveButtonContainer)}>
                  <span
                    onClick={() => setIsOpenArchiveModal(true)}
                    className={cx(
                      css`
                        font-family: Montserrat-Medium !important;
                        cursor: pointer;
                      `
                    )}
                  >
                    Archive
                  </span>
                </div>
              ))}
            {/* Project Edit Fields */}
            {isEditMode && (
              <ProjectEdit
                onSave={handleProjectEditSave}
                onCancel={toggleEditMode}
                project={currentProject}
                uiSettings={uiSettings}
              />
            )}
          </div>
          <div
            className={cx(
              "candidate-list-container",
              css`
                margin-left: 25px;
                width: calc(100% - 320px);
                ${lg} {
                  margin-left: initial;
                }
                ${md} {
                  width: 100%;
                }
              `
            )}
          >
            {/* Candidate Row */}
            <ProjectCandidates
              uiSettings={uiSettings}
              candidates={candidates}
              currentProject={currentProject}
              handleRemoveCandidate={handleRemoveCandidate}
              push={push}
              pop={pop}
            />
          </div>
        </div>
      </ModalBody>

      {isOpenDeleteModal && (
        <DeleteConfirmationModal
          isOpen
          uiSettings={uiSettings}
          onConfirm={deleteProject}
          onCancel={() => setIsOpenDeleteModal(false)}
          header={`Delete ${uiSettings?.mappings?.project}`}
          objectTitle={currentProject?.name}
        />
      )}
      {isOpenArchiveModal && (
        <DeleteConfirmationModal
          isOpen
          uiSettings={uiSettings}
          onConfirm={archiveProject}
          onCancel={() => setIsOpenArchiveModal(false)}
          header={`Archive ${uiSettings?.mappings?.project}`}
          objectTitle={currentProject?.name}
          confirmButtonTitle="Archive"
          message="Are you sure you want to archive"
        />
      )}
      {isOpenUnarchiveModal && (
        <DeleteConfirmationModal
          isOpen
          uiSettings={uiSettings}
          onConfirm={unarchiveProject}
          onCancel={() => setIsOpenUnarchiveModal(false)}
          header={`Unarchive ${uiSettings?.mappings?.project}`}
          objectTitle={currentProject?.name}
          confirmButtonTitle="Unarchive"
          message="Are you sure you want to unarchive"
        />
      )}
    </>
  );
};

const ViewProjectModal = ({
  viewModalOpenState,
  currentProjectInitials,
  toggleModalWindow,
  onProjectUpdated,
  onProjectDeleted,
  onProjectArchived,
  onProjectUnarchived,
  uiSettings,
}) => (
  <ModalNavigator isOpen={viewModalOpenState} toggle={toggleModalWindow} initialView={globals.PROJECT_MODAL_NAME}>
    {(push, pop) => (
      <ViewProjectModalBody
        push={push}
        pop={pop}
        toggleModalWindow={toggleModalWindow}
        currentProjectInitials={currentProjectInitials}
        onProjectUpdated={onProjectUpdated}
        onProjectDeleted={onProjectDeleted}
        onProjectArchived={onProjectArchived}
        onProjectUnarchived={onProjectUnarchived}
        uiSettings={uiSettings}
      />
    )}
  </ModalNavigator>
);

const styles = {
  downloadShareContainer: css`
    display: flex;
    justifty-content: center;
    align-items: center;
    float: right;
  `,
  downloadIconSpan: css`
    color: #27a0fe;
    cursor: pointer;
  `,
  shareIconSpan: css`
    cursor: pointer;
    margin-left: 15px;
  `,
  downloadShareIcon: css`
    width: 12px;
    height: 12px;
  `,
  editButtonContainer: css`
    font-size: 14px;
    letter-spacing: -0.49px;
    text-align: center;
    color: #208bfe;
    padding: initial !important;
    margin: 10px 0px;
    ${sm} {
      text-align: center;
      padding: initial !important;
    }
  `,
  deleteButtonContainer: css`
    font-size: 14px;
    letter-spacing: -0.49px;
    text-align: center;
    color: var(--c-red);
    padding: initial !important;
    margin: 10px 0px;
    ${sm} {
      text-align: center;
      padding: initial !important;
    }
  `,
  archiveButtonContainer: css`
    font-size: 14px;
    letter-spacing: -0.49px;
    text-align: center;
    padding: initial !important;
    margin: 10px 0px;
    ${sm} {
      text-align: center;
      padding: initial !important;
    }
  `,
  projectName: css`
    width: 100%;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.31px;
    color: #000000;
  `,
  companyName: css`
    font-weight: bold;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.65px;
    text-align: center;
    color: #373737;
    margin-bottom: 5px;
  `,
  companyDescription: css`
    margin-top: 2px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.65px;
    text-align: center;
    color: var(--c-text-dimmed);
    margin-bottom: 5px;
    white-space: normal;
  `,
  projectCandidateCount: css`
    font-weight: bold;
    font-size: 18.7px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.76px;
    text-align: center;
    color: #a8a8a8;
  `,
  truncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  listItemTruncateText: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${lg} {
      width: 250px;
    }
    ${xs} {
      width: 200px;
      word-break: break-word;
      white-space: initial;
    }
  `,
  listHeader: css`
    font-family: Montserrat-Bold !important;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.02px;
    color: #373737;
    margin-bottom: 22px;
    padding-left: 3px;
  `,
  listItemContainer: css`
    padding: 0px 10px 0px 17px;
    margin-bottom: 46px;
  `,
  listItemDetailContainer: css`
    display: flex;
    flex-flow: column;
    padding-top: 6px;
    width: calc(100% - 95px);
  `,
  lineOne: css`
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1.02px;
    color: #373737;
    margin-bottom: 3px;
    ${lg} {
      width: 75%;
      overflow: initial;
      text-overflow: initial;
      white-space: initial;
      word-wrap: break-word;
    }
  `,
  lineTwo: css`
    width: 100%;
    font-weight: bold;
    font-size: 13.2px;
    letter-spacing: -0.67px;
    color: #373737;
    margin-bottom: 3px;
  `,
  lineThree: css`
    width: 100%;
    font-weight: bold;
    font-size: 15.4px;
    letter-spacing: -0.78px;
    color: #a8a8a8;
  `,
  candidateLastViewedContainer: css`
    margin-top: 6px;
    .last-viewed-text {
      font-size: 12px;
    }
  `,
  emptyListMessage: css`
    padding-left: 5px;
  `,
  link: css`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  `,
};

export default ViewProjectModal;
