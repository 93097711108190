import * as React from "react";
import { createContext, useContext, useState } from "react";
import { shortId } from "../../util/util";
import { NotificationsContainer } from "./NotificationsContainer";
import { NOTIFICATION_TYPE } from "../../../../constants/constants";

export const NotificationsContext = createContext();
export const useNotifications = () => useContext(NotificationsContext);

export function Notifications(props) {
  const [notifications, setNotifications] = useState([]);

  const show = (notificationData) => {
    setNotifications([
      {
        id: shortId(),
        ...notificationData,
      },
      ...notifications.slice(0, 2),
    ]);
  };

  const hide = (id) => {
    setNotifications(notifications.filter((item) => item.id !== id));
  };

  const showSuccess = (message) => {
    show({ type: NOTIFICATION_TYPE.success, message });
  };

  const showError = (message = "We have an error on our side. Please try again in a couple of hours") => {
    show({ type: NOTIFICATION_TYPE.error, message });
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        showSuccess,
        showError,
        hide,
      }}
    >
      <>
        <NotificationsContainer />
        {props.children}
      </>
    </NotificationsContext.Provider>
  );
}

export const withNotifications = (WrappedComponent) =>
  function (props) {
    return (
      <NotificationsContext.Consumer>
        {(context) => <WrappedComponent {...props} notificationController={context} />}
      </NotificationsContext.Consumer>
    );
  };
