import * as React from "react";
import { Form } from "../Form";
import { FormField } from "../FormField/FormField";
import { Radio } from "../Radio/Radio";
import { LOCATION_SEARCH_MODE } from "../../../../constants/constants";
import { DiscoverSearchLocationHelper } from "../../helpers/location/DiscoverSearchLocationHelper";
import css from "../../DiscoverNew/DiscoverFilters/DiscoverFilters.module.css";
import { InputZip } from "../InputZip/InputZip";
import { InputDistance } from "../InputDistance/InputDistance";
import { InputCity } from "../InputCity/InputCity";
import { Select } from "../Select/Select";
import { Icon } from "../../UI/IconComponent/Icon";
import { Link } from "../../UI/Link/Link";
import { getDefaultAddressFilter } from "../../DiscoverNew/DiscoverNew";

export const InputLocation = (props) => {
  const { locationSearchMode, city, state, zip, distance, id } = props.data;
  const { onChange, onDelete, onAdd, canDelete, canAdd, country } = props;

  function withInternalId(fieldId) {
    return `${id}–${fieldId}`;
  }

  function onChangeLocationMode(value) {
    if (locationSearchMode === value) {
      return;
    }

    const nextFilter = {
      ...props.data,
      locationSearchMode: value,
    };

    if (value === LOCATION_SEARCH_MODE.state) {
      const defaultFilters = getDefaultAddressFilter();
      nextFilter.zip = defaultFilters.zip;
      nextFilter.distance = defaultFilters.distance;
      nextFilter.city = defaultFilters.city;
    }

    if (value === LOCATION_SEARCH_MODE.city) {
      const defaultFilters = getDefaultAddressFilter();
      nextFilter.state = defaultFilters.state;
      nextFilter.zip = defaultFilters.zip;
    }

    if (value === LOCATION_SEARCH_MODE.zip) {
      const defaultFilters = getDefaultAddressFilter();
      nextFilter.state = defaultFilters.state;
      nextFilter.city = defaultFilters.city;
    }

    onChange(nextFilter);
  }

  return (
    <div style={{ position: "relative" }}>
      <Form.Row style={{ position: "relative" }}>
        <FormField
          htmlFor={withInternalId("search-mode-zip")}
          labelStyle={{ margin: 0, cursor: "pointer" }}
          style={{ display: "flex", flexDirection: "row-reverse", flexGrow: 0 }}
          label="Zip"
        >
          <Radio
            checked={locationSearchMode === LOCATION_SEARCH_MODE.zip}
            onChange={() => onChangeLocationMode(LOCATION_SEARCH_MODE.zip)}
            id={withInternalId("search-mode-zip")}
            style={{ marginRight: 7 }}
          />
        </FormField>
        <FormField
          htmlFor={withInternalId("search-mode-city")}
          labelStyle={{ margin: 0, cursor: "pointer" }}
          style={{ display: "flex", flexDirection: "row-reverse", flexGrow: 0 }}
          label="City"
        >
          <Radio
            checked={locationSearchMode === LOCATION_SEARCH_MODE.city}
            onChange={() => onChangeLocationMode(LOCATION_SEARCH_MODE.city)}
            id={withInternalId("search-mode-city")}
            style={{ marginRight: 7 }}
          />
        </FormField>
        <FormField
          htmlFor={withInternalId("search-mode-state")}
          labelStyle={{ margin: 0, cursor: "pointer" }}
          style={{ display: "flex", flexDirection: "row-reverse", flexGrow: 0 }}
          label={DiscoverSearchLocationHelper.getStateLabel(country.value)}
        >
          <Radio
            checked={locationSearchMode === LOCATION_SEARCH_MODE.state}
            onChange={() => onChangeLocationMode(LOCATION_SEARCH_MODE.state)}
            id={withInternalId("search-mode-state")}
            style={{ marginRight: 7 }}
          />
        </FormField>
        {canDelete && (
          <Icon
            name="trash-cancel"
            onClick={onDelete}
            style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
          />
        )}
      </Form.Row>
      {locationSearchMode === LOCATION_SEARCH_MODE.zip && (
        <>
          <FormField label="Zip" className={css.zip}>
            <InputZip placeholder="e.g. 10001" onChange={(e) => onChange({ zip: e.target.value })} value={zip} />
          </FormField>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: 8 }}>
            <InputDistance onChange={(value) => onChange({ distance: parseInt(value) })} value={distance} />
            {canAdd && <Link onClick={onAdd}>+ Add Location</Link>}
          </div>
        </>
      )}
      {locationSearchMode === LOCATION_SEARCH_MODE.city && (
        <>
          <FormField label="City" className={css.city}>
            <InputCity
              key={country.value}
              country={country.value}
              placeholder="e.g. New York"
              onChange={(city) => onChange({ city })}
              value={city}
            />
          </FormField>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: 8 }}>
            <InputDistance onChange={(value) => onChange({ distance: parseInt(value) })} value={distance} />
            {canAdd && <Link onClick={onAdd}>+ Add Location</Link>}
          </div>
        </>
      )}
      {locationSearchMode === LOCATION_SEARCH_MODE.state && (
        <>
          <FormField label={DiscoverSearchLocationHelper.getStateLabel(country.value)}>
            <Select
              name="state"
              placeholder=""
              onChange={(state) => onChange({ state })}
              value={state}
              options={DiscoverSearchLocationHelper.getStateOptionsForCountry(country.value)}
            />
          </FormField>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: 8 }}>
            <div />
            {canAdd && <Link onClick={onAdd}>+ Add Location</Link>}
          </div>
        </>
      )}
    </div>
  );
};
