import * as React from "react";
import { Link as CustomLink } from "../../DiscoverNew/UI/Link/Link";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { useHistory } from "react-router-dom";

import css from "./BackButton.module.css";

export const BackButton = (props) => {
  const history = useHistory()

  return (
    <div className={css.holder}>
      <CustomLink className={css.link} leftIcon={<Icon name="arrow-right-short" />} onClick={
        () => {
          if (props.goBack) {
            history.goBack();
          } else if (props.onClick) {
            props.onClick();
          } else {
            history.push(props.to);
          }
        }
      }>
        back
      </CustomLink>
    </div>
  );
};
