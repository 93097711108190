import { cx } from "emotion";
import * as React from "react";
import generateInitials from "../../../formatters/generateInitials";
import { Formatter } from "../../DiscoverNew/util/Formatter";

import css from "./ProfileImage.module.css";

type Props = {
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
  data: {
    name?: string
    firstName?: string
    lastName?: string
    profileImgUrl?: string
  }
}

const useCheckValidImage = (url?: string) => {
  const [isValid, setIsValid] = React.useState(false);
  React.useEffect(() => {
    const img = new Image();
    if (!url) {
      setIsValid(false);
      return;
    }
    img.onload = () => setIsValid(true);
    img.onerror = () => setIsValid(false);
    img.src = url;
  }, [url]);
  return isValid;
}

export const UserImage: React.FC<Props> = props => {
  const isValidImage = useCheckValidImage(props.data?.profileImgUrl);

  if (!props.data) {
    return null;
  }

  const firstName = props.data.firstName || props.data.name?.split(' ')?.[0];
  const lastName = props.data.lastName || props.data.name?.split(' ')?.[1];
  let initials = '';
  if (firstName || lastName) {
    initials = generateInitials([firstName, lastName]);
  }
  return (
    <div className={cx(css.holder, props.className)} style={props.style} onClick={props.onClick} title={Formatter.fullName({ firstName, lastName })}>
      {isValidImage && <div style={{ backgroundImage: `url(${props.data.profileImgUrl})` }} className={css.image} />}
      <span className={css.initials}>{initials}</span>
    </div>
  )
}