import React, { useState } from "react";
import { ButtonText } from "../../../../../components/DiscoverNew/UI/ButtonText/ButtonText";
import { withConfirm } from "../../../../../components/hoc/withConfirm";
import NewPaymentMethodModal from "../../../../../components/modals/NewPaymentMethodModal/NewPaymentMethodModal";
import { PaymentMethodInterface } from "../../../../../services/BillingService";

import PaymentCard from "./PaymentCard/PaymentCard";

import styles from "./PaymentMethods.module.css";

type Props = {
  paymentMethods: PaymentMethodInterface[]
  onAddPaymentMethod: () => void
  onSelectDefaultPaymentMethod: (paymentMethod: PaymentMethodInterface) => void
  onDeletePaymentMethod: (paymentMethod: PaymentMethodInterface) => void
  isRemoveAbility?: boolean
  withoutConfirm?: boolean
  // todo
  confirm?: any
}

const PaymentMethods = ({
  paymentMethods,
  isRemoveAbility,
  withoutConfirm,
  onAddPaymentMethod,
  onDeletePaymentMethod,
  onSelectDefaultPaymentMethod,
  confirm
}: Props) => {

  const onSelectDefaultPaymentMethodClick = (card: PaymentMethodInterface) => () => {
    confirm.open({
      title : "Set card default",
      content : (
        <div className={styles.confirmTextWrapper}>
          <span>You’re about to change your card default to</span>
          <span className={styles.confirmHighlightedText}>
            {card.brandName} ending in {card.last4}
          </span>
        </div>
      ),
      onConfirm : () => {
        onSelectDefaultPaymentMethod(card);
      },
    });
  };

  const onDeletePaymentMethodClick = (card: PaymentMethodInterface) => () => {
    confirm.open({
      title : "Delete card",
      confirmButtonTitle : "Delete",
      destructive : true,
      content : (
        <div className={styles.confirmTextWrapper}>
          <span>You’re about to delete your card</span>
          <span className={styles.confirmHighlightedText}>
              {card.brandName} ending in {card.last4}
            </span>
        </div>
      ),
      onConfirm : () => {
        onDeletePaymentMethod(card);
      },
    });
  };

  return (
    <div className={styles.choosePaymentCard}>
      <div className={styles.cardList}>
        {paymentMethods.map((item) => (
          <PaymentCard
            key={item.id}
            className={styles.cardItem}
            onCardClick={item.isDefault ? () => null : onSelectDefaultPaymentMethodClick(item)}
            onCardRemove={onDeletePaymentMethodClick(item)}
            isRemovable={isRemoveAbility}
            cardInfo={item}
          />
        ))}
      </div>
      <div className={styles.addCardButton}>
        <ButtonText onClick={onAddPaymentMethod} iconName="fa-plus">Add payment method</ButtonText>
      </div>
    </div>
  );
};

// todo
export default withConfirm(PaymentMethods) as any;
