import * as React from 'react';
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";

import css from './ProfileIntegrationsCard.module.css'

type Props = {
  data : {
    title: string
    description: string
    icon: string
  }
  onEnable: () => void
  onDisable: () => void
  isEnabled: boolean
}

export const ProfileIntegrationsCard = (props: Props) => {
  const { data } = props;
  return (
    <div className={css.holder}>
      <div className={css.controls}>
        {props.isEnabled ? (
            <Button className={css.button} onClick={props.onDisable} theme="outline">Disable</Button>
        ) : (
            <Button className={css.button} onClick={props.onEnable}>Enable</Button>
        )}
      </div>
      <div className={css.logo}>
        <Icon name={data.icon} />
      </div>
      <div className={css.title}>{data.title}</div>
      <p className={css.description}>{data.description}</p>
    </div>
  )
}