import React from "react";
import { cx } from "emotion";
import { Icon } from "../../UI/IconComponent/Icon";
import { NOTIFICATION_TYPE } from "../../../../constants/constants";

import css from "./Notification.module.css";

const IconMap = {
  [NOTIFICATION_TYPE.success]: () => (
    <Icon
      name="check"
      style={{
        marginRight: 7,
        color: "#77bb41",
        width: 24,
        height: 24,
        display: "inline-block",
      }}
    />
  ),
  [NOTIFICATION_TYPE.error]: () => (
    <Icon
      name="error"
      style={{
        marginRight: 7,
        color: "#f85959",
        width: 24,
        height: 24,
        display: "inline-block",
      }}
    />
  ),
};

export function Notification(props) {
  const Icon = IconMap[props.type];

  React.useEffect(() => {
    const timer = setTimeout(props.onHide, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [props.onHide]);

  return (
    <div className={cx(css.notification, css.popupMode)}>
      <Icon />
      {props.message}
    </div>
  );
}
