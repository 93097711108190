import * as React from "react";
import ReactSelect, { components } from "react-select";
import ReactSelectAsync from "react-select/async";
import ReactSelectCreatable from "react-select/creatable";
import ReactSelectAsyncCreatable from "react-select/creatable";
import PropTypes from "prop-types";
import { AUTOCOMPLETE_OFF } from "../../../../constants/constants";
import { useRef } from "react";
import { shortId } from "../../util/util";

const focusedBorder = "1px solid #408cf6";
const errorBorder = "1px solid #F9654D";
const defaultBorder = "1px solid #d6d6d6";

const SingleValue = ({ children, ...props }) => {
  const { formatDisplayedValue } = props.selectProps;
  let content = formatDisplayedValue ? formatDisplayedValue(props) : children;
  return <components.SingleValue {...props}>{content}</components.SingleValue>;
};

const Input = ({ autoComplete, ...props }) => {
  const name = useRef(shortId());
  return <components.Input {...props} autoComplete={AUTOCOMPLETE_OFF} name={name.current} />;
};

export function Select(props) {
  let Component = ReactSelect;
  if (props.creatable) {
    Component = ReactSelectCreatable;
  }
  if (props.loadOptions) {
    Component = ReactSelectAsync;
  }
  if (props.creatable && props.loadOptions) {
    Component = ReactSelectAsyncCreatable;
  }

  return (
    <Component
      {...props}
      defaultOptions
      isDisabled={props.isDisabled}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      menuPlacement="auto"
      components={{
        SingleValue,
        Input,
        IndicatorSeparator: null,
      }}
      styles={{
        dropdownIndicator: (styles) => {
          return {
            ...styles,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 3,
          };
        },
        option: (styles, state) => {
          return {
            ...styles,
            color: state.value === null && !state.isSelected ? "#222222" : styles.color,
          };
        },
        menu: props.styles?.menu,
        menuPortal: props.styles?.menuPortal,
        control: (styles, { isFocused }) => ({
          ...styles,
          borderRadius: 2,
          boxShadow: "none",
          minHeight: 30,
          border: isFocused ? focusedBorder : props.error ? errorBorder : props.defaultBorderStyle || defaultBorder,
          ":hover": {
            border: isFocused ? focusedBorder : props.error ? errorBorder : props.defaultBorderStyle || defaultBorder,
          },
        }),
        input: (styles) => ({
          ...styles,
          paddingTop: 1,
          paddingBottom: 0,
        }),
        container: (styles) => ({
          ...styles,
          ...(props.style || {}),
          width: "100%",
        }),
      }}
      loadOptions={props.loadOptions}
    />
  );
}

const SingleSelectValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]);
const SelectValueType = PropTypes.oneOfType([SingleSelectValueType, PropTypes.arrayOf(SingleSelectValueType)]);

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: SingleSelectValueType,
    })
  ),
  value: SelectValueType,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  defaultBorderStyle: PropTypes.string,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  creatable: PropTypes.bool,
  error: PropTypes.any,
};

Select.defaultProps = {
  options: undefined,
  value: undefined,
  loadOptions: undefined,
};
