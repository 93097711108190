import React from "react";
import { css, cx } from "emotion/macro";

import calendarIcon from "../../assets/icons/calendar.svg";

import { getRelativeCalendarDate } from "../../formatters/calendarFormatter";

function CandidateLastViewed({ candidate }) {
  return (
    <div
      className={cx("recent-date-container", styles.recentDateContainer, {
        [styles.visibilityHidden]: !candidate?.lastViewedAt,
      })}
    >
      <img className={cx(styles.calendarIcon)} src={calendarIcon} alt="" />
      <span className={cx("last-viewed-text", styles.lastViewedAtText)}>
        {getRelativeCalendarDate(candidate?.lastViewedAt)}
      </span>
    </div>
  );
}

const styles = {
  calendarIcon: css`
    margin-right: 7px;
  `,
  lastViewedAtText: css`
    font-family: Montserrat;
    font-size: 13.2px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.54px;
    text-align: center;
    color: #9b9b9b;
    text-transform: uppercase;
  `,
  visibilityHidden: css`
    visibility: hidden;
  `,
};

export default CandidateLastViewed;
