import * as React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { useNotifications } from "./notifications";
import { Notification } from "./Notification";

// The code in this package has moved. We recommend you to use CSSTransitionGroup from react-transition-group instead.

export function NotificationsContainer() {
  const { notifications, hide } = useNotifications();
  return (
    <>
      <ReactCSSTransitionGroup
        transitionName="notifications-slide-down-transition"
        transitionAppear={false}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {notifications.map((item) => (
          <Notification key={item.id} onHide={() => hide(item.id)} {...item} />
        ))}
      </ReactCSSTransitionGroup>
    </>
  );
}
