import React, { useState, useContext } from "react";

// The following context is responsible for holding the global grid view state
// which will be used primarily in the candidate, project, and company screens
export const GridViewContext = React.createContext();
export const useGridView = () => useContext(GridViewContext);
export const GridViewProvider = ({ children }) => {
  // Potential values for grid view: [list, grid]
  const [gridView, setGridView] = useState("list");

  const updateGridView = (view) => {
    setGridView(view);
  };

  return (
    <GridViewContext.Provider
      value={{
        gridView,
        updateGridView,
      }}
    >
      {children}
    </GridViewContext.Provider>
  );
};
