import React, { useEffect } from "react";

import CandidateDetailModalBody from "./candidateDetails";
import * as globals from "../../constants/constants";

// Navigator
import ModalNavigator from "./ModalNavigator";

const CandidateDetailModal = ({
  projectId,
  uiSettings,
  viewModalOpenState,
  toggleModalWindow,
  candidateInitials,
  candidate,
  initialPage,
  isNavigatorView,
  onCandidateUpdated,
  searchHighlights
}) => {
  return (
    <ModalNavigator
      size="xl"
      isOpen={viewModalOpenState}
      toggle={toggleModalWindow}
      initialView={globals.CANDIDATE_MODAL_NAME}
      uiSettings={uiSettings}
    >
      {(push, pop) => (
        <CandidateDetailModalBody
          projectId={projectId}
          onCandidateUpdated={onCandidateUpdated}
          uiSettings={uiSettings}
          push={push}
          pop={pop}
          candidateInitials={candidateInitials}
          candidate={candidate}
          initialPage={initialPage}
          isNavView={isNavigatorView}
          searchHighlights={searchHighlights}
        />
      )}
    </ModalNavigator>
  );
};

export default CandidateDetailModal;
