import * as React from "react";
import { getDefaultAddressFilter } from "../../DiscoverNew/DiscoverNew";
import { InputLocation } from "../InputLocation/InputLocation";
import css from "../../DiscoverNew/DiscoverFilters/DiscoverFilters.module.css";

export const InputLocationArray = (props) => {
  const { onChange, country, value } = props;

  function onDelete(id) {
    const indexToDelete = value.findIndex((item) => item.id === id);
    const nextData = [...value.slice(0, indexToDelete), ...value.slice(indexToDelete + 1)];
    onChange(nextData);
  }

  function onAdd() {
    const nextData = [...value.slice(), getDefaultAddressFilter()];
    onChange(nextData);
  }

  function onChangeLocation(id, nextValue) {
    const nextData = value.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...nextValue,
        };
      }
      return item;
    });
    onChange(nextData);
  }

  return (
    <>
      {value.map((item, i) => {
        return (
          <div key={item.id}>
            <InputLocation
              data={item}
              country={country}
              canDelete={value.length > 1}
              canAdd={i === value.length - 1}
              onAdd={onAdd}
              onDelete={() => onDelete(item.id)}
              onChange={(v) => onChangeLocation(item.id, v)}
            />
            {value.length > 1 && i !== value.length - 1 && <div className={css.divider} style={{ margin: "18px 0" }} />}
          </div>
        );
      })}
    </>
  );
};
