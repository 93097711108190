import React, { useRef } from "react";
import { Input } from "../Input/Input";
import { AUTOCOMPLETE_OFF } from "../../../../constants/constants";

export function InputZip(props) {
  const oldCursor = useRef();
  const oldValue = useRef();

  const onKeyDown = (e) => {
    oldCursor.current = e.target.selectionEnd;
    oldValue.current = e.target.value;
  };

  const onInput = (e) => {
    const { target: el } = e;
    const newValue = el.value.replace(/\D/g, "").match(/(\d{0,5})/)[0];
    el.value = newValue;
    if (oldValue.current.length <= newValue.length) {
      el.setSelectionRange(oldCursor.current + 1, oldCursor.current + 1);
    } else if (oldValue.current.length > newValue.length) {
      const newCursor = oldCursor.current - 1 <= 0 ? 0 : oldCursor.current - 1;
      el.setSelectionRange(newCursor, newCursor);
    }
  };

  return <Input {...props} autoComplete={AUTOCOMPLETE_OFF} onInput={onInput} onKeyDown={onKeyDown} />;
}
