import { cx } from "emotion";
import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../../components/button/CloseButton";
import ModalButtonContainer from "../../../../components/button/ModalButtonContainer";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { FilterSelectHtml } from "../../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { modalStyles } from "../../../../components/modals/modalStyles";

import cssModule from "./ModalAddWhitelistUserEmail.module.css";

const TRIAL_CREATE_OPTIONS = [
  { value: "ENABLED", label: "Enabled" },
  { value: "DISABLED", label: "Disabled" },
  { value: "CUSTOM", label: "Custom" },
];

export const ModalAddWhitelistUserEmail = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [trial, setTrial] = useState(TRIAL_CREATE_OPTIONS[0]);

  const [customDaysInterval, setCustomDaysInterval] = useState(7);
  const [customUnlocksCount, setCustomUnlocksCount] = useState(10000);

  const handleSubmit = async () => {
    const data = {
      email,
      trial: trial.value,
      customDaysInterval: customDaysInterval,
      customUnlocksCount: customUnlocksCount,
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={cx(modalStyles.modalBody, cssModule.holder)} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>Add Whitelist Email</div>
        <div className={modalStyles.modalFormContainer}>
          <div style={{ display: "flex", marginTop: 16, justifyContent: "space-between" }}>
            <div style={{ width: 300 }}>
              <div style={{ marginBottom: 8 }}>
                <span className={cssModule.label}>Email & Trial Options</span>
              </div>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="user@site.com" />
            </div>
            <div style={{ alignItems: "flex-end", paddingTop: 24 }}>
              <FilterSelectHtml label="" onChange={(v) => setTrial(v)} options={TRIAL_CREATE_OPTIONS} value={trial} />
            </div>
          </div>

          {trial.value === "CUSTOM" && (
            <>
              <div style={{ marginBottom: 8, marginTop: 16 }}>
                <span className={cssModule.label}>Custom Trial Interval (Days)</span>
              </div>
              <Input value={customDaysInterval} onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);
                if (isNaN(parsed)) { 
                  setCustomDaysInterval(0);
                } else {
                  setCustomDaysInterval(parsed);
                }
              }} />
              <div style={{ marginBottom: 8, marginTop: 16 }}>
                <span className={cssModule.label}>Custom Unlock Credits</span>
              </div>
              <Input value={customUnlocksCount} onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);
                if (isNaN(parsed)) { 
                  setCustomUnlocksCount(0);
                } else {
                  setCustomUnlocksCount(parsed);
                }
              }} />
            </>
          )}
        </div>
        <ModalButtonContainer
          title={`Add`}
          onClick={handleSubmit}
          isWaiting={loading}
          isDisabled={!email || !email.trim()}
        />
      </div>
    </Modal>
  );
};
