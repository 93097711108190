import * as React from 'react';
import { useAuthController } from "../../../contexts/AuthProvider";
import { authService } from "../../DiscoverNew/services/AuthService";
import { Formatter } from "../../DiscoverNew/util/Formatter";

import css from './ImpersonatedBanner.module.css';

export const ImpersonatedBanner = () => {
  const authController = useAuthController();
  const user = authController.user as any;
  const { user_name, user_email } = authService.getPausedSessionData() as any;
  return (
    <div className={css.holder}>
      You're logged in under <span className={css.user}>{Formatter.fullName(user)}</span> ({user.email}). <span className={css.back} onClick={authService.resumePausedSession}>Back to <span className={css.user}>{user_name}</span></span>
    </div>
  )
}