import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { css } from "emotion/macro";
import ModalButtonContainer from "../button/ModalButtonContainer";
import CloseButton from "../button/CloseButton";
import { modalStyles } from "./modalStyles";
import ModalInputField from "../input/ModalInputField";
import { userService } from "../../screens/projects/UserService";
import { Loading } from "../DiscoverNew/UI/Loading/Loading";
import { useNotifications } from "../DiscoverNew/contexts/Notification/notifications";
import { AUTOCOMPLETE_OFF } from "../../constants/constants";

function AdminUserModal({ onClose, userId, onSuccess }) {
  const isUnmounted = useRef(false);
  const notifications = useNotifications();
  const [formError, setFormError] = useState();
  const [initializing, setInitializing] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [errors, setErrors] = useState();

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const onChange = (value) => {
    const fieldName = Object.keys(value)[0];
    setErrors({
      ...errors,
      [fieldName]: null,
    });
    const nextValues = {
      ...values,
      ...value,
    };
    setValues(nextValues);
  };

  async function handleSubmit() {
    setSubmitting(true);
    try {
      await userService.update(userId, values);
      notifications.showSuccess(`User has been updated!`);
      onSuccess();
    } catch (err) {
      notifications.showError(`Couldn't update the user!`);
    }
    if (!isUnmounted.current) {
      setSubmitting(false);
      onClose();
    }
  }

  useEffect(() => {
    async function fetchUser() {
      setInitializing(true);
      try {
        const user = await userService.fetchOne(userId);
        setValues({
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phoneNumber,
        });
        setInitializing(false);
      } catch (err) {
        console.error("Couldn't fetch the user", err);
        setFormError(err);
        setInitializing(false);
      }
    }
    fetchUser();
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>Edit User</div>
        {initializing ? (
          <Loading />
        ) : formError ? (
          formError
        ) : (
          <>
            <div className={modalStyles.modalFormContainer}>
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>First Name</div>}
                placeholder="Enter first name"
                autocomplete={AUTOCOMPLETE_OFF}
                onChange={(e) => onChange({ firstName: e.target.value })}
                value={values.firstName}
              />
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>Last Name</div>}
                placeholder="Enter last name"
                autocomplete={AUTOCOMPLETE_OFF}
                onChange={(e) => onChange({ lastName: e.target.value })}
                value={values.lastName}
              />
              <ModalInputField
                label={<div className={modalStyles.modalInputLabel}>Phone</div>}
                placeholder="Enter phone"
                autocomplete={AUTOCOMPLETE_OFF}
                onChange={(e) => onChange({ phone: e.target.value })}
                value={values.phone}
              />
            </div>
            <ModalButtonContainer title={`Update User`} onClick={handleSubmit} isWaiting={submitting || initializing} />
          </>
        )}
      </div>
    </Modal>
  );
}

export default AdminUserModal;
