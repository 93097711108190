import * as globals from "../constants/constants.js";
import { isAfter } from "../formatters/calendarFormatter";
import { getOptionsSeparator } from ".";

// canExportToCSV takes in a user object and determines
// whether the user has access to download a project's candidates
// as a CSV.
export const canExportToCSV = (user) => {
  if (!user?.organizationId) {
    return;
  }

  const subscriptionName = user?.subscriptionName;
  const subscriptionEndDate = user?.subscriptionEndDate;

  // If the subscription end date is not in the future
  // prevent ability to export.
  if (!subscriptionEndDate || !isAfter(subscriptionEndDate)) {
    return false;
  }

  // If the current user's subscription is not in the
  // CSV Download Subscription map, prevent ability to export.
  if (!subscriptionName || !globals.CSV_DOWNLOAD_SUBSCRIPTIONS[subscriptionName]) {
    return false;
  }

  return true;
};

export const reduceWorkableJobsToObject = (acc, job) => {
  const { state } = job;

  const item = {
    value: job.shortcode,
    label: job.fullTitle,
    createDate: new Date(job.created_at),
  };

  if (acc[state]) {
    acc[state].push(item);
  } else {
    acc[state] = [item];
  }

  return acc;
};

export const unselectedWorkableJob = { value: "-1", label: "-", disabled: true };
const workableJobStatuses = ["draft", "published", "closed", "archived"];

export const mapWorkableJobsToArray = (workableJobs) => {
  const jobs = [unselectedWorkableJob];

  workableJobStatuses.forEach((status) => {
    if (!workableJobs[status] || !workableJobs[status].length) {
      return;
    }

    jobs.push(getOptionsSeparator(status.toUpperCase()));
    jobs.push(...workableJobs[status]);
  });

  return jobs;
};
