import { cx } from "emotion";
import * as React from "react";
import { Icon } from "../../UI/IconComponent/Icon";

import css from "./Input.module.css";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  name?: string
  classNames?: {
    input?: string
    rightIcon?: string
    leftIcon?: string
  }
  inputStyle?: React.CSSProperties
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  clearable?: boolean
  "data-qa"?: string
  error?: string
};

const Input = React.forwardRef<null, Props>((props, ref) => {
  const { name, onChange, value, placeholder, defaultValue, onInput, onKeyDown, autoComplete, readOnly } = props;

  const inputProps: any = {
    name,
    readOnly,
    style: props.inputStyle
  };

  if (onChange) {
    inputProps.onChange = onChange;
    inputProps.value = value || "";
  } else {
    inputProps.defaultValue = defaultValue;
  }

  return (
    <div
      style={props.style}
      className={cx(css.holder, props.className, {
        [css.hasRightIcon] : !!props.rightIcon,
        [css.hasLeftIcon] : !!props.leftIcon,
        [css.clearable] : !!props.clearable && !!props.value,
      })}
    >
      <input
        ref={ref}
        data-qa={props["data-qa"]}
        {...inputProps}
        autoComplete={autoComplete}
        onInput={onInput}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={cx(css.input, props.classNames?.input)}
      />
      {!!props.rightIcon && <div className={cx(css.rightIcon, props.classNames?.rightIcon)}>{props.rightIcon}</div>}
      {!!props.leftIcon && <div className={cx(css.leftIcon, props.classNames?.leftIcon)}>{props.leftIcon}</div>}
      {/* @ts-ignore */}
      {!!props.clearable && <InputClearButton onClick={() => props.onChange({ target : { value : "" } })} />}
      {!!props.error && <div className={css.error}>{props.error}</div>}
    </div>
  );
});

export const InputClearButton = (props: any) => {
  return (
    <button className={css.clearButton} onClick={props.onClick} type="button">
      <Icon name="cross" />
    </button>
  );
}

export { Input };
