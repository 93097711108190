import { useState } from "react";
import * as React from "react";
import { useAuthController } from "../../../../../contexts/AuthProvider";
import { useUserData } from "../../../../../contexts/userContext";
import { candidateService } from "../../../../../screens/projects/CandidateService";
import { useNotifications } from "../../../../DiscoverNew/contexts/Notification/notifications";
import { Button } from "../../../../DiscoverNew/UI/Button/Button";
import Stepper from "../../../../ui/Stepper/Stepper";
import { AddCandidateVerificationStep } from "../AddCandidateVerificationStep/AddCandidateVerificationStep";
import { AddCandidateManuallyInfoStep } from "./AddCandidateManuallyInfoStep/AddCandidateManuallyInfoStep";
import { AddCandidateManuallyOrganizeStep } from "./AddCandidateManuallyOrganizeStep/AddCandidateManuallyOrganizeStep";

import css from "./AddCandidateManually.module.css";

type Props = {
  onSuccess: () => void
}

type FormValues = {
  firstName: string
  lastName: string
  phone: string
  email: string
  address1: string
  address2: string
  gender: string
  project?: { value: string, label: string }
}

const getInitialValues = (): FormValues => ({
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address1: "",
  address2: "",
  gender: "",
  project: undefined,
})

export const AddCandidateManually = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();
  const authController = useAuthController();
  const user = authController.user as any;
  const [step, setStep] = React.useState<number>(1);
  const [values, setValues] = React.useState<FormValues>(getInitialValues());
  const [errors, setErrors] = React.useState<FormValues>(getInitialValues());
  const { state: { userCredits }, } = useUserData();

  const createCandidate = async (verify: boolean) => {
    setLoading(true);
    try {
      const res = await candidateService.createManually({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        address1: values.address1,
        address2: values.address2,
        gender: values.gender,
      }, {
        projectId: values.project ? parseInt(values.project.value) : undefined,
        organizationId: user.organizationId,
        verify,
      })
      props.onSuccess();
      notifications.showSuccess("The candidate has been added!");
      setLoading(false);
    } catch (err) {
      const errMessage = "Couldn't add the candidate. Please contact with support."
      console.error(errMessage, err)
      notifications.showError(errMessage);
      setLoading(false);
    }
  }

  const onPrevStep = () => {
    setStep(step - 1);
  };

  const onNextStep = () => {
    setStep(step + 1);
  };

  const renderControls = () => {
    let controls = null;
    switch (step) {
      case 1:
        controls = <Button onClick={onNextStep}>Next</Button>;
        break;
      case 2:
        controls = (
          <>
            <Button theme="outline" onClick={onPrevStep}>Back</Button>
            <Button onClick={onNextStep}>Next</Button>
          </>
        );
        break;
      case 3:
        controls = (
          <>
            <Button disabled={loading} theme="outline" onClick={() => createCandidate(false)}>Not Now</Button>
            <Button disabled={loading} onClick={() => createCandidate(true)}>Verify</Button>
          </>
        );
        break;
    }
    return controls;
  };

  return (
    <div>
      {step < 3 && (
        <Stepper steps={["Information", "Organize"]} activeStep={step} />
      )}
      <div className={css.holder}>
        {step === 1 && <AddCandidateManuallyInfoStep values={values} errors={errors} onChange={setValues} />}
        {step === 2 && <AddCandidateManuallyOrganizeStep values={values} errors={errors} onChange={setValues} />}
        {step === 3 && <AddCandidateVerificationStep candidatesCount={1} creditsCount={userCredits} />}
      </div>
      <div className={css.controls}>
        {renderControls()}
      </div>
    </div>
  );
};