import { humanize } from "inflected";
import React, { useEffect } from "react";
import { injectStyleOverride } from "../../../../components/DiscoverNew/DiscoverNewWrapper";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { ButtonText } from "../../../../components/DiscoverNew/UI/ButtonText/ButtonText";
import { Loading } from "../../../../components/DiscoverNew/UI/Loading/Loading";
import { NoData } from "../../../../components/DiscoverNew/UI/NoData/NoData";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import { Pill } from "../../../../components/ui/Pill/Pill";
import { usePagination } from "../../../../hooks/usePagination";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { billingService, InvoiceInterface } from "../../../../services/BillingService";

import css from "./BillingHistory.module.css";

const BillingHistory = (props: any) => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  const pagination = usePagination();
  const queryParams = useQueryParams({ query : "" });
  const [search, setSearch] = React.useState(queryParams.params.query);

  const [isInviteMemberOpen, setIsInviteMemberOpen] = React.useState(false);
  const [invoices, setInvoices] = React.useState<InvoiceInterface[]>([]);
  const [loading, setLoading] = React.useState(false);

  function fetchData(params = queryParams.params) {
    setLoading(true);
    billingService.fetchInvoices(params)
      .then((invoices) => {
        setInvoices(invoices);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchData(queryParams.params);
  }, [queryParams.params.query, pagination.page, pagination.limit, pagination.skip]);

  const onSearchChange = (value: string) => {
    setSearch(value);
    queryParams.debouncedSetParams({ query : value });
  };

  const downloadInvoice = async (invoice: InvoiceInterface) => {
    const url = await billingService.fetchInvoiceUrl(invoice.stripeId);
    window.open(url, "_blank");
  };

  return (
    <div className={css.holder}>
      <header className={css.header}>
        <div>
          <Input
            className={css.search} placeholder="Search by reference"
            value={search}
            onChange={(e: any) => onSearchChange(e.target.value)}
          />
        </div>
      </header>
      {loading ? <Loading /> :
        <div className={css.content}>
          <table className={css.table}>
            <thead>
              <tr>
                <th className={css.tdName}>Invoice</th>
                <th>Description</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Date</th>
                <th className={css.tdActions} />
              </tr>
            </thead>
            <tbody>
              {invoices?.length ? invoices.map((invoice, index) => {
                return (
                  <tr key={invoice.id} className={css.tr}>
                    <td className={css.tdName}>{invoice.reference}</td>
                    <td>{invoice.description}</td>
                    <td>
                      <Pill style={billingService.getInvoiceStyle(invoice)}>{humanize(invoice.status)}</Pill>
                    </td>
                    <td>${invoice.amount.toLocaleString()}</td>
                    <td>{invoice.createdAt ? Formatter.fullDateTime(invoice.createdAt) : "–"}</td>
                    <td className={css.tdActions}>
                      <ButtonText onClick={() => downloadInvoice(invoice)} iconName="fa-download">Download</ButtonText>
                    </td>
                  </tr>
                );
              }) : <tr><td colSpan={6}><NoData text='No billing history found' /></td></tr>}
            </tbody>
          </table>
        </div>
      }
    </div>
  );
};

export default BillingHistory;
