import * as React from 'react';
import { Form } from "../../../../../DiscoverNew/Form/Form";
import { FormField } from "../../../../../DiscoverNew/Form/FormField/FormField";
import { Input } from "../../../../../DiscoverNew/Form/Input/Input";
import { InputPhone } from "../../../../../DiscoverNew/Form/InputPhone/InputPhone";

import css from './AddCandidateManuallyInfoStep.module.css'

type Props = {
  values: any,
  errors: any,
  onChange: (value: any) => void,
}

const fields = [
  { name: 'firstName', label: 'First Name', placeholder: "Enter candidate's first name" },
  { name: 'lastName', label: 'Last Name', placeholder: "Enter candidate's last name" },
  { name: 'address1', label: 'Address', placeholder: "Enter address line 1" },
  { name: 'address2', label: ' ', placeholder: "Enter address line 2" },
  { name: 'phone', label: 'Phone', placeholder: '(000) 124-3467', type: 'phone' },
  { name: 'email', label: 'Email', placeholder: "Enter candidate's email" },
]

export const AddCandidateManuallyInfoStep = ({ values, errors, onChange }: Props) => {
  return (
    <Form>
      {fields.map(item => {
        let InputComponent = <Input name={item.name} value={values[item.name]} onChange={onChange} placeholder={item.placeholder} />;
        if (item.type === 'phone') {
          InputComponent = <InputPhone name={item.name} value={values[item.name]} onChange={onChange} placeholder={item.placeholder} />;
        }
        return (
          <Form.Row>
            <FormField labelClassName={css.label} className={css.field} label={item.label} error={errors[item.name]}>
              {InputComponent}
            </FormField>
          </Form.Row>
        )
      })}
    </Form>
  )
}