export const errorMessages = {
  ALREADY_MEMBER_EMAIL: "Member with this email has already been added",
  INVALID_EMAIL: "Email is invalid",
  INVITE_MEMBER_GENERAL: "This email address is associated with another organization. This user must leave their current organization before joining yours. Please contact support@chatterworks.ai for assistance.",
  INVITE_MEMBER_ALREADY_IN_ORG: "User is already in organization",
  NOT_BUSINESS_EMAIL: "Please provide a business email",
  INTERVIEW_PROCESS_NAME_EXISTS: "You already have an interview process with this name"
};

export const apiErrorMessages = {
  INVITE_MEMBER_ALREADY_IN_ORG : "user is already in organization",
  INVITE_MEMBER_GENERAL : "specified email is not a business email",
  NOT_BUSINESS_EMAIL : "specified email is not a business email",
}

export const apiErrorToFrontendError = {
  [apiErrorMessages.INVITE_MEMBER_ALREADY_IN_ORG] : errorMessages.INVITE_MEMBER_ALREADY_IN_ORG,
  [apiErrorMessages.INVITE_MEMBER_GENERAL] : errorMessages.INVITE_MEMBER_GENERAL,
  [apiErrorMessages.NOT_BUSINESS_EMAIL] : errorMessages.NOT_BUSINESS_EMAIL,
}