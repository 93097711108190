import * as React from "react";
import PropTypes from "prop-types";
import css from "./InputDistance.module.css";
import { DISTANCE_OPTIONS } from "../../../../constants/constants";
import { UnderlinedSelectHtml } from "../../UI/SelectHtml/UnderlinedSelectHtml/UnderlinedSelectHtml";

export function InputDistance(props) {
  const selected = DISTANCE_OPTIONS.find((item) => item.value === props.value);
  return (
    <div className={css.inputDistance}>
      <span className={css.label}>Within</span>
      <UnderlinedSelectHtml
        onChange={props.onChange}
        value={selected.value}
        label={selected.label}
        options={DISTANCE_OPTIONS}
      />
    </div>
  );
}

InputDistance.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};
