import Counter from "components/Counter/Counter";
import { Button } from "components/DiscoverNew/UI/Button/Button";
import React from "react";
import { SUPPORT_EMAIL } from "../../../../constants/constants";
import { PlanInfoInterface } from "../../../../services/BillingService";
import { Alert } from "../../../ui/Alert/Alert";
import classes from "../PurchasePlanModal.module.css";

import css from "./PlanStep.module.css";

type Props = {
  breakdown: {
    monthlyCredits: number,
    totalPrice: number,
  }
  planInfo: PlanInfoInterface,
  onNext: () => void,
  onChangeSeatsCount: (count: number) => void,
  seatsCount: number,
  existingMembersCount: number
}

const PlanStep = ({ seatsCount, onNext, onChangeSeatsCount, breakdown, planInfo, existingMembersCount }: Props) => {
  return (
    <>
      <Counter label="yearly subscription seats" min={1} max={planInfo.maxPaidSeats} value={seatsCount} onChange={onChangeSeatsCount} className={classes.counter} />
      <div className={css.breakdown}>
        <div className={css.breakdownItem}>
          <div className={css.breakdownItemTitle}>
            Yearly Credits
          </div>
          <div>
            <div className={css.breakdownItemValue}>
              {breakdown.monthlyCredits.toLocaleString()}
            </div>
            <div className={css.subtext}>
              {planInfo.creditsPerSeat.toLocaleString()} x {seatsCount} seat{seatsCount > 1 && 's'}
            </div>
          </div>
        </div>
        <div className={css.breakdownItem}>
          <div className={css.breakdownItemTitle}>
            Total Price
          </div>
          <div>
            <div className={css.breakdownItemValue}>
              ${breakdown.totalPrice.toLocaleString()}
            </div>
            <div className={css.subtext}>
              ${planInfo.pricePerSeat.toLocaleString()} x {seatsCount} seat{seatsCount > 1 && 's'}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.navigation}>
        <Button className={classes.button} onClick={onNext}>
          Next
        </Button>
      </div>
      <div className={css.subtext} style={{ textAlign: 'center', marginTop: 2 }}>
        you will not be charged yet
      </div>
    </>
  );
};

export default PlanStep;
