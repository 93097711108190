import { GEOCODE_FIELD_TYPES } from "../../../constants/constants";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
let queue = [];

class GoogleApiService {
  parseAddress = (geocodingAddress) => {
    const address = {};
    const city = geocodingAddress.address_components.find((item) =>
      item.types.some((t) => GEOCODE_FIELD_TYPES.city.includes(t))
    );
    const state = geocodingAddress.address_components.find((item) =>
      item.types.some((t) => GEOCODE_FIELD_TYPES.state.includes(t))
    );
    const zip = geocodingAddress.address_components.find((item) =>
      item.types.some((t) => GEOCODE_FIELD_TYPES.zip.includes(t))
    );

    if (city) {
      address.city = {
        short: city.short_name,
        long: city.long_name,
      };
    }

    if (state) {
      address.state = {
        short: state.short_name,
        long: state.long_name,
      };
    }

    if (zip) {
      address.zip = zip.long_name;
    }

    address.formattedAddress = geocodingAddress.formatted_address;

    return address;
  };

  loadMapsApi = () => {
    return new Promise((resolve, reject) => {
      const id = "google-maps-api-script";
      const existingScript = document.getElementById(id);

      if (existingScript) {
        const state = existingScript.getAttribute("data-state");
        if (state === "ready") {
          resolve();
        } else if (state === "error") {
          reject();
        } else {
          queue.push({ resolve, reject });
        }
        return;
      } else {
        queue.push({ resolve, reject });
      }

      // @ts-ignore
      window.initGoogleMapsApi = () => {
        document.getElementById(id).setAttribute("data-state", "ready");
        queue.forEach((item) => item.resolve());
        queue = [];
      };
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en&callback=initGoogleMapsApi`;
      script.setAttribute("id", id);
      script.setAttribute("data-state", "loading");
      script.onerror = () => {
        script.setAttribute("data-state", "error");
        queue.forEach((item) => item.reject());
        queue = [];
      };
      document.body.appendChild(script);
    });
  };
}

export const googleApiService = new GoogleApiService();
