//Reference: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
// Output: "+1 (714) 555-8464"
export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "";

  // The number is obfuscated, return as is
  if (phoneNumberString.includes("*")) {
    return phoneNumberString;
  }

  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [match[1], " (", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return phoneNumberString;
};

export default formatPhoneNumber;
