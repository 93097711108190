import React from "react";
import { Nav, Navbar, NavbarBrand, NavItem } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { css, cx } from "emotion/macro";

import { useUserData } from "../../contexts/userContext";

import logo from "../../assets/images/chatterworks-logo.svg";
import paperclipIcon from "../../assets/images/chatterworks-clip.svg";
import hamburgerIcon from "../../assets/images/bars-solid-white.svg";
import { lg, md, mdU, sm, xs } from "../../css/mediaQueries";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { useAuthController } from "../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../../screens/projects/PermissionService";

const NavigationBar = () => {
  const { isAuthenticated, user } = useAuthController();
  const {
    state: { user: userForUI },
  } = useUserData();
  const uiSettings = userForUI.uiSettings;

  const location = useLocation();
  const history = useHistory();

  const navigate = (route) => {
    if (route === location.pathname) return;
    history.push(route);
  };


  if (!isAuthenticated) {
    return (
      <div
        className={css`
          background-color: #208bfe;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          ${mdU} {
            height: 44px;
          }
          ${sm} {
            padding: 7px 0px;
          }
          ${xs} {
            padding: 7px 0px;
          }
        `}
      >
        <Navbar collapseOnSelect expand="md" className={styles.navbarContainer}>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <div
              className={css`
                width: 20px;
                height: 20px;
                background-image: url(${hamburgerIcon});
                background-repeat: no-repeat;
                background-size: contain;
              `}
            />
          </Navbar.Toggle>
          <NavbarBrand
            className={cx(
              "nav-logo-container",
              css`
                flex: 1;
                ${sm} {
                  display: none !important;
                }
                ${xs} {
                  display: none !important;
                }
              `
            )}
          >
            <img
              onClick={() => navigate(`/discover`)}
              src={logo}
              alt=""
              style={{ verticalAlign: "text-bottom", cursor: "pointer" }}
            />
          </NavbarBrand>
        </Navbar>
      </div>
    );
  }

  if (!user.hasActiveSubscription) {
    return (
      <div
        className={css`
          background-color: #208bfe;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          ${mdU} {
            height: 44px;
          }
          ${sm} {
            padding: 7px 0px;
          }
          ${xs} {
            padding: 7px 0px;
          }
        `}
      >
        <Navbar collapseOnSelect expand="md" className={styles.navbarContainer}>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <div
              className={css`
                width: 20px;
                height: 20px;
                background-image: url(${hamburgerIcon});
                background-repeat: no-repeat;
                background-size: contain;
              `}
            />
          </Navbar.Toggle>
          <NavbarBrand
            className={cx(
              "nav-logo-container",
              css`
                flex: 1;
                ${sm} {
                  display: none !important;
                }
                ${xs} {
                  display: none !important;
                }
              `
            )}
          >
            <img
              onClick={() => navigate(`/discover`)}
              src={logo}
              alt=""
              style={{ verticalAlign: "text-bottom", cursor: "pointer" }}
            />
          </NavbarBrand>
          <Nav className={styles.profileContainer}>
            {user.organizationV2 && (
              <div style={{ marginRight: 6 }}>{user.organizationV2.name}</div>
            )}
            <NavbarUserDropdown />
          </Nav>
        </Navbar>
      </div>
    );
  }

  return (
    <div
      className={css`
        background-color: #208bfe;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        ${mdU} {
          height: 44px;
        }
        ${sm} {
          padding: 7px 0px;
        }
        ${xs} {
          padding: 7px 0px;
        }
      `}
    >
      <Navbar collapseOnSelect expand="md" className={styles.navbarContainer}>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <div
            className={css`
              width: 20px;
              height: 20px;
              background-image: url(${hamburgerIcon});
              background-repeat: no-repeat;
              background-size: contain;
            `}
          />
        </Navbar.Toggle>
        <NavbarBrand
          className={cx(
            "nav-logo-container",
            css`
              flex: 1;
              ${sm} {
                display: none !important;
              }
              ${xs} {
                display: none !important;
              }
            `
          )}
        >
          <img
            onClick={() => navigate(`/discover`)}
            src={logo}
            alt=""
            style={{ verticalAlign: "text-bottom", cursor: "pointer" }}
          />
        </NavbarBrand>
        <Nav className="paperclip-icon-container">
          <NavItem>
            <img
              onClick={() => navigate(`/discover`)}
              src={paperclipIcon}
              alt=""
              style={{ verticalAlign: "middle" }}
              width="50px"
              height="30px"
            />
          </NavItem>
        </Nav>
        <Nav className={styles.profileContainer}>
          {user.organizationV2 && (
            <div style={{ marginRight: 6 }}>{user.organizationV2.name}</div>
          )}
          <NavbarUserDropdown />
        </Nav>
        <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarLinksContainer}>
          <Nav navbar>
            {permissionService.can(null, PermissionEnum.discover) && (
              <NavItem className={styles.navbarLink}>
                <Link
                  to="/discover"
                  className={
                    (location.pathname.includes("discover") || location.pathname.includes("candidates")) &&
                    !location.pathname.includes("my-candidates")
                      ? "nav-bar-link-active "
                      : "nav-bar-link "
                  }
                >
                  Discover
                </Link>
              </NavItem>
            )}
            <NavItem className={styles.navbarLink}>
              <Link
                to="/my-candidates"
                className={location.pathname.includes("my-candidates") ? "nav-bar-link-active " : "nav-bar-link "}
              >
                {uiSettings?.mappings?.candidates}
              </Link>
            </NavItem>
            <NavItem className={styles.navbarLink}>
              <Link
                to='/projects'
                onClick={() => {
                  window.__refetchExportList && window.__refetchExportList();
                }}
                className={location.pathname === "/projects" ? "nav-bar-link-active " : "nav-bar-link "}
              >
                {uiSettings?.mappings?.projects}
              </Link>
            </NavItem>
            <NavItem className={styles.navbarLink}>
              <Link
                to='/companies'
                className={location.pathname === "/companies" ? "nav-bar-link-active " : "nav-bar-link "}
              >
                {uiSettings?.mappings?.companies}
              </Link>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

const styles = {
  navbarContainer: css`
    padding: 0px 61px 0px 23px !important;
    width: 100%;
    ${md} {
      padding: 0px 15px !important;
    }
    ${sm} {
      padding: 0px !important;
    }
    .navbar-brand {
      margin: 0;
    }
  `,
  navbarLinksContainer: css`
    justify-content: center;
    flex: 1 !important;
    ${sm} {
      flex-basis: 100% !important;
      padding-left: 14px;
    }
  `,
  navbarLink: css`
    margin: 0 36px;
    font-size: 14px;
    flex-shrink: 0;
    ${lg} {
      margin: 0 30px;
    }
    ${md} {
      margin: 0 20px;
    }
    ${sm} {
      margin: 0;
    }
    ${xs} {
      width: 30%;
      font-size: 16px;
    }
  `,
  profileContainer: css`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${xs} {
      flex-direction: row !important;
    }
    ${sm} {
      flex: initial;
    }
    ${mdU} {
      order: 2;
    }
  `,
  jobLinkContainer: css`
    ${sm} {
      display: none;
    }
    ${xs} {
      display: none;
    }
  `,
  jobLink: css`
    color: #ffffff;
    opacity: 0.6;
    letter-spacing: -0.57px;
    margin-right: 20px;
    &:hover {
      cursor: pointer;
      color: #ffffff;
      opacity: 1;
      text-shadow: 0.65px 0px 0px white;
      text-decoration: none;
    }
    ${xs} {
      font-size: 16px;
      margin-right: 0px;
      &:hover {
        font-weight: normal;
        opacity: 0.6;
      }
    }
  `,
  mobileJobLinkContainer: css`
    ${mdU} {
      display: none;
    }
  `,
};

export default NavigationBar;
