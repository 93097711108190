import { cx } from "emotion";
import { css } from "emotion/macro";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../../components/button/CloseButton";
import ModalButtonContainer from "../../../../components/button/ModalButtonContainer";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { FilterSelectHtml } from "../../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { modalStyles } from "../../../../components/modals/modalStyles";

import cssModule from "./ModalAddBlacklistDomain.module.css";

const SCOPE_OPTIONS = [
  {
    value: "sign-up",
    label: "Sign Up",
  },
  {
    value: "free-trial",
    label: "Free Trial",
  },
  {
    value: "all",
    label: "All",
  },
];

export const ModalAddBlacklistDomain = ({ isOpen, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [reason, setReason] = useState("");
  const [scope, setScope] = useState(SCOPE_OPTIONS[2]);

  const handleSubmit = async () => {
    const data = {
      domain,
      reason,
      scope: scope.value,
    };
    try {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={cx(modalStyles.modalBody, cssModule.holder)} style={{ minHeight: "initial" }}>
        <div className={modalStyles.modalTitle}>Blacklist Domain</div>
        <div className={modalStyles.modalFormContainer}>
          <div style={{ display: "flex", marginTop: 16, justifyContent: "space-between" }}>
            <div style={{ width: 300 }}>
              <div style={{ marginBottom: 8 }}>
                <span className={cssModule.label}>Domain & Scope</span>
              </div>
              <Input value={domain} onChange={(e) => setDomain(e.target.value)} placeholder="site.com" />
            </div>
            <div style={{ alignItems: "flex-end", paddingTop: 24 }}>
              <FilterSelectHtml
                label=""
                onChange={(v) => setScope(v)}
                options={SCOPE_OPTIONS}
                value={scope}
              />
            </div>
          </div>

          <div style={{ marginBottom: 8, marginTop: 16 }}>
            <span className={cssModule.label}>Reason</span>
          </div>
          <Input value={reason} onChange={(e) => setReason(e.target.value)} placeholder="" />
        </div>
        <ModalButtonContainer
          title={`Add`}
          onClick={handleSubmit}
          isWaiting={loading}
          isDisabled={!domain || !domain.trim()}
        />
      </div>
    </Modal>
  );
};
