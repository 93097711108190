import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { cx } from "emotion";
import { useUserData } from "../../../contexts/userContext";
import capitalizeWords from "../../../formatters/capitalizeWords";
import { Tag } from "../../DiscoverNew/UI/Tag/Tag";
import { Link as CustomLink } from "../../DiscoverNew/UI/Link/Link";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import CandidateDetailModalWrapper from "../../modals/CandidateDetailModalWrapper";
import CompanyImg from "../../company/CompanyImg";
import { Link } from "react-router-dom";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { Formatter } from "../../DiscoverNew/util/Formatter";
import { PermissionEnum, permissionService } from "../../../screens/projects/PermissionService";
import { COLLABORATOR_ROLE } from "../../../constants/constants";
import { ProjectCardCollaborators } from "./ProjectCardCollaborators";
import { extractInitials, getCompanyInitials } from "../../../screens/companies/Companies";

import css from "./ProjectCard.module.css";

export function ProjectCard(props) {
  const { data, className, hideCompanyImage } = props;
  const {
    state: {
      user: { uiSettings },
    },
  } = useUserData();

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  function renderCandidates() {
    const tags = encodeURI(data.tags?.join(" OR "));
    const canAddCandidate = permissionService.can(data, PermissionEnum.projectEdit);
    const canDiscover = permissionService.can(null, PermissionEnum.discover);

    const findMatchButton = (
      <Button
        className={css.findMatchButton}
        leftIcon={<Icon name="plus" />}
        component={Link}
        to={`/discover${tags ? `?skills=${tags}` : ""}`}
      >
        Find Match
      </Button>
    );

    if (data.candidates?.length) {

      const candidate1 = data.candidates.find(item => item.candidateId === 130338703);
      if (candidate1) {
        candidate1.experiences = candidate1.experiences.map(item => {
          if (item.company?.name === 'chatterworks') {
            item.isPrimary = true;
          }
          return item;
        })
      }

      const candidate2 = data.candidates.find(item => item.candidateId === 104351687);
      if (candidate2) {
        candidate2.experiences = candidate2.experiences.map(item => {
          if (item.company?.name === 'mdc-ventures') {
            item.isPrimary = true;
          }
          return item;
        })
      }

      return (
        <div>
          {data.candidates.map((item) => {

            let latestExperience = item.experiences?.find(item => item.isPrimary);
            if (!latestExperience) {
              latestExperience = data.experiences?.[0];
            }

            const fullName = Formatter.fullName(item);
            return (
              <div key={item.candidateId} className={css.candidateItem} onClick={() => setSelectedCandidate(item)}>
                <CompanyImg
                  className={css.candidateInitials}
                  style={{ width: 26, height: 26, fontSize: 11 }}
                  initials={extractInitials([item.firstName, item.lastName].join(" "))}
                  title={fullName}
                />
                <div>
                  <div className={css.candidateName}>{fullName}</div>
                  {!!item.experiences?.length && (
                    <div className={css.candidatePosition}>
                      <span
                        title={latestExperience?.title}
                        style={{
                          maxWidth: 150,
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginRight: !!latestExperience?.company?.name ? 3 : 0,
                        }}
                      >
                        {capitalizeWords(latestExperience?.title)}
                      </span>
                      {!!latestExperience?.company?.name && (
                        <>| {capitalizeWords(latestExperience.company.name)}</>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {data.candidateTotal > data.candidates.length && (
            <CustomLink style={{ fontSize: 14 }} to={`/projects/${data.id}`}>
              view {data.candidateTotal - data.candidates.length} more
            </CustomLink>
          )}
          {canAddCandidate && canDiscover && <div style={{ marginTop: 15 }}>{findMatchButton}</div>}
        </div>
      );
    }

    if (canAddCandidate) {
      return findMatchButton;
    } else {
      return <p className={css.noData}>There is no candidates yet</p>;
    }
  }

  return (
    <>
      <article className={cx(className, css.container)}>
        <div className={cx(css.imageHolder, hideCompanyImage && css.imageHolderHidden)}>
          <CompanyImg
            style={{ width: 44, height: 44, fontSize: 18 }}
            company={{
              imgUrl: data.company.imgUrl,
            }}
            initials={getCompanyInitials(data.company.name)}
          />
        </div>
        <div className={css.mainSection}>
          <Link to={`/my-candidates/${data.id}`} className={css.title}>
          {/*<Link to={`/projects/${data.id}`} className={css.title}>*/}
            {data.name}
            {!!data.archivedAt && <span className={css.archivedLabel}>archived</span>}
          </Link>
          {data.company && <div className={css.company}>{data.company.name}</div>}
          {/* @todo */}
          {/*<div className={css.socials}>*/}
          {/*  <ProjectCardSocials data={data} />*/}
          {/*</div>*/}
          <div className={css.description}>{data.description}</div>
          <div className={css.tags}>
            {data.tags?.slice(0, 5)?.map((item) => {
              return <Tag key={item}>{capitalizeWords(item)}</Tag>;
            })}
          </div>
        </div>
        <div className={css.divider} />
        <div className={css.section}>
          <div>
            <ProjectCardCollaborators data={data} onUpdated={props.onUpdated} />
          </div>
          <div>
            <div className={css.sectionTitle}>
              {capitalizeWords(uiSettings?.mappings?.candidates)}{" "}
              {!!data.candidateTotal && <span>({data.candidateTotal})</span>}
            </div>
            {renderCandidates()}
          </div>
        </div>
      </article>

      {!!selectedCandidate && (
        <CandidateDetailModalWrapper
          projectId={data.id}
          uiSettings={uiSettings}
          initialPage="detail"
          toggleModalWindow={() => setSelectedCandidate(null)}
          viewModalOpenState={true}
          candidate={selectedCandidate}
        />
      )}
    </>
  );
}

ProjectCard.propTypes = {
  onUpdated: PropTypes.func,
  hideCompanyImage: PropTypes.bool,
  data: PropTypes.shape({
    projectId: PropTypes.number,
    candidateTotal: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    imgUrl: PropTypes.string,
    socialProfiles: PropTypes.shape({
      facebook: PropTypes.shape({ url: PropTypes.string }),
      twitter: PropTypes.shape({ url: PropTypes.string }),
      linkedin: PropTypes.shape({ url: PropTypes.string }),
      github: PropTypes.shape({ url: PropTypes.string }),
      dribbble: PropTypes.shape({ url: PropTypes.string }),
    }),
    company: PropTypes.shape({
      companyId: PropTypes.number,
      name: PropTypes.string,
      imgUrl: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
    candidates: PropTypes.arrayOf(
      PropTypes.shape({
        candidateId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        lastViewedAt: PropTypes.string,
      })
    ),
    collaborators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.oneOf(Object.values(COLLABORATOR_ROLE)),
        invitedAt: PropTypes.instanceOf(Date),
      })
    ),
    currentUserRole: PropTypes.oneOf(Object.values(COLLABORATOR_ROLE)),
    ownedBy: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
};
