import * as React from "react";
import { ContextMenuDropdown } from "../../DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

type Props = {
  onChange: (value: CandidatesExportMode) => void
  label?: any
}

export enum CandidatesExportMode {
  All = 'all',
  Verified = 'verified'
}

export function CandidateExportSelect(props: Props) {

  const onClick = (close: () => void, mode: CandidatesExportMode) => {
    props.onChange(mode);
    close();
  }

  return (
    <ContextMenuDropdown contentStyle={{ minWidth: 300 }} label={props.label}>
      <ContextMenuDropdown.Item onClick={(close: () => void) => onClick(close, CandidatesExportMode.All)}>All data (including unverified emails)</ContextMenuDropdown.Item>
      <ContextMenuDropdown.Item onClick={(close: () => void) => onClick(close, CandidatesExportMode.Verified)}>Only data with verified email deliverability</ContextMenuDropdown.Item>
    </ContextMenuDropdown>
  )
}